<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="col-sm-12 nopads">
        <transition name="fade">
            <div class="list-content result-table">
                <b-table
                    show-empty
                    small
                    striped
                    style="cursor:pointer;"
                    @row-clicked="rowClicked"
                    :items="roadSigns"
                    :fields="fields"
                >
                    <template v-slot:cell(created_at)="row">
                        {{ getDateString(row.value) }}
                    </template>
                    <template v-slot:cell(removed_at)="row">
                        {{ getDateString(row.value) }}
                    </template>
                    <template v-slot:cell(contract)="row">
                        {{ row.value.name }}
                    </template>
                    <template v-slot:cell(sign)="row">
                        <img :src="getImageSource(row.value)" height="25px" alt="">
                    </template>
                    <template v-slot:cell(installation)="row">
                        {{ getInstallationString(row.value) }}
                    </template>
                    <template v-slot:cell(side)="row">
                        {{ getSideString(row.value) }}
                    </template>
                    <template v-slot:cell(material)="row">
                        {{ getMaterialString(row.value) }}
                    </template>
                    <template v-slot:cell(road_name)="row">
                        {{ getFormattedValue(row.value) }}
                    </template>
                    <template v-slot:cell(road_num)="row">
                        {{ getFormattedValue(row.value) }}
                    </template>
                    <template v-slot:cell(sec_num)="row">
                        {{ getFormattedValue(row.value) }}
                    </template>
                    <template v-slot:cell(sign_condition)="row">
                        {{ getConditionString(row.value) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>

import {timeUtils} from "@/components/mixins/TimeUtils";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";

export default {
    name: 'road-sign-list',
    mixins: [timeUtils, roadSignHelper, conversionModelHelper],
    props: {
        roadSigns: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'id',
            sortDesc: false
        }
    },
    computed: {
        fields() {
            return [
                {key: 'sign', label: this.$t('road_sign.sign'), sortable: true},
                {key: 'id', label: this.$t('common.id'), sortable: true},
                {key: 'contract', label: this.$t('orders.contract'), sortable: true},
                {key: 'created_at', label: this.$t('road_sign.created_at'), sortable: true},
                {key: 'removed_at', label: this.$t('road_sign.removed_at'), sortable: true},
                {key: 'value', label: this.$t('road_sign.value'), sortable: true},
                {key: 'direction', label: this.$t('road_sign.road_direction'), sortable: true},
                {key: 'road_name', label: this.$t('road_sign.road_name'), sortable: true},
                {key: 'road_num', label: this.$t('road_sign.road_num'), sortable: true},
                {key: 'sec_num', label: this.$t('road_sign.sec_num'), sortable: true},
                {key: 'distance', label: this.$t('road_sign.distance'), sortable: true},
                {key: 'lane', label: this.$t('road_sign.sign_lane'), sortable: true},
                {key: 'installation', label: this.$t('road_sign.installation'), sortable: true},
                {key: 'sign_direction', label: this.$t('road_sign.sign_direction'), sortable: true},
                {key: 'side', label: this.$t('road_sign.side'), sortable: true},
                {key: 'material', label: this.$t('road_sign.material'), sortable: true},
                {key: 'sign_condition', label: this.$t('road_sign.condition'), sortable: true},
                {key: 'info', label: this.$t('road_sign.info'), sortable: true}
            ]
        }
    },
    methods: {
        rowClicked: function (details) {
            this.$emit('rowClicked', details)
        },
        getFormattedValue: function (value) {
            if (!value || value < 1) {
                return ""
            }
            return value
        },
        getImageSource(imageName) {
            try {
                return require(`@/assets/roadsign/${this.getSignIcon(imageName)}`);
            } catch (error) {
                // If the image is not found, return a fallback image source or an empty string
                return "";
            }
        },
    }
}
</script>
