<template>
    <div class="col-sm-12 nopads">

        <!-- Area report wizard -->
        <transition name="fade">
            <b-form
                v-if="areaReportWizardMode"
                class="col-sm-12 editor-form"
            >
                <div class="col-sm-12 form-data">
                    <span class="span-title">{{ $t('area_report.select_area') }}</span>
                    <b-form-group
                        class="title"
                        label-for="area"
                    >
                        <b-form-select
                            id="area"
                            v-model="selectedArea"
                            size="sm"
                            :disabled="nearbyAreas.length < 1"
                        >
                            <option :value="null">
                                {{ $t('area_report.select_area') }}
                            </option>
                            <option
                                v-for="area in nearbyAreas"
                                :key="area.id"
                                :value="area.id"
                            >
                                {{ area.name }}
                            </option>
                        </b-form-select>
                    </b-form-group>
                    <span
                        v-if="!userPosition"
                        class="hint-text"
                    >
              {{ $t('common.fetching_location') }}
            </span>
                    <span
                        v-else-if="nearbyAreas.length === 0"
                        class="hint-text"
                    >
                        {{ $t('area_report.location_hint') }}
            </span>

                    <div class="col-sm-12 button-container">
                        <b-button
                            variant="success"
                            class="routa-button"
                            :disabled="loading || selectedArea === null"
                            @click.stop="addAreaReport"
                        >
                            <i class="fa fa-arrow-right"/>
                            <span class="button-text">{{ $t('common.next') }}</span>
                        </b-button>
                    </div>
                </div>
            </b-form>
        </transition>

        <transition name="fade">
            <area-equipment-report-editor
                v-if="equipmentReportMode && !areaReportWizardMode"
                :area-report="areaReportUpdate"
                @areaEquipmentReport="editAreaReport"
            />
        </transition>

        <!--    Area report editor-->
        <div class="nopads" v-if="!equipmentReportMode && !areaReportWizardMode">
            <!-- Area report -->
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-title editor-title">
                    <h2 class="form-title editor-title">
                        {{ $t('area_report_details.area_inspection') }}
                    </h2>
                </div>
                <div class="col-sm-12 form-data">
                    <span class="span-title">{{ $t('area_report.area_name') }}</span>
                    <b-form-group
                        class="title"
                        label-for="area"
                    >
                        <span id="area"><strong>{{ areaReportUpdate.area.name }}</strong></span>
                    </b-form-group>
                    <span class="span-title">{{ $t('area_report.note') }}</span>
                    <b-form-group
                        class="title"
                        label-for="note"
                    >
                        <b-form-textarea
                            id="note"
                            v-model="note"
                            rows="3"
                            max-rows="10"
                            type="text"
                            size="mb"
                        />
                    </b-form-group>
                    <div class="col-sm-12 button-container">
                        <b-button
                            variant="danger"
                            class="routa-button"
                            @click.stop="$emit('close')"
                        >
                            <i class="fa fa-times"/>
                            <span class="button-text">{{ $t('common.cancel') }}</span>
                        </b-button>
                        <b-button
                            variant="success"
                            class="routa-button"
                            :disabled="loading"
                            @click.stop="submitAreaReport"
                        >
                            <i class="fa fa-save"/>
                            <span class="button-text">{{ $t('common.save') }}</span>
                        </b-button>
                    </div>
                </div>
            </b-form>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import {areaReportHelper} from '../../mixins/AreaReportMixin.js'
import AreaEquipmentReportEditor from '../areaequipment/AreaEquipmentReportEditor'
import {isPointInPolygon} from "geolib";

export default {
    name: 'AreaReportEditor',
    mixins: [areaReportHelper, restApi],
    components: {AreaEquipmentReportEditor},
    props: {
        areaReport: {
            type: Object,
            default: null
        }
    },

    data: function () {
        return {
            loading: false,
            note: '',
            equipmentReportMode: false,
            areaReportWizardMode: true,
            userPosition: null,
            selectedArea: null,
            areas: [],
            nearbyAreas: [],
            areaReportUpdate: this.areaReport ? this.copyReport(this.areaReport) : null,
            watchID: null
        }
    },

    created() {
        this.fetchAreas()
        this.initPosition()
        this.watchUserPosition()
    },

    mounted() {
        if (this.areaReport) {
            this.editEquipmentReport()
        }
    },

    beforeDestroy() {
        if (this.watchID) {
            navigator.geolocation.clearWatch(this.watchID);
        }
    },

    methods: {
        initPosition: function () {
            navigator.geolocation.getCurrentPosition(
                function (pos) {
                    this.setPosition(pos)
                }
            )
        },

        fetchAreas: function () {
            this.loading = true
            this.restFetchParams(this.areaUrl, {no_geom: 0}, this.handleAreaResponse)
        },

        handleAreaResponse: function (response) {
            this.loading = false
            this.areas = response.data
            this.filterNearbyAreas()
        },

        filterNearbyAreas: function () {
            // TODO - user's distance to area can also be checked and then results sorted by distance
            this.nearbyAreas = []
            if (this.userPosition) {
                this.areas.forEach(area => {
                    if (isPointInPolygon(
                        {latitude: this.userPosition.coords.latitude, longitude: this.userPosition.coords.longitude},
                        this.getAreaPolygon(area))) {
                        this.nearbyAreas.push(area)
                    }
                }, this)
            }
        },

        watchUserPosition: function () {
            if (navigator.geolocation) {
                this.watchID = navigator.geolocation.watchPosition(position => {
                    this.setPosition(position)
                }, () => {
                }, {timeout: 30000, enableHighAccuracy: true})
            }
        },

        setPosition: function (position) {
            this.userPosition = position
            this.filterNearbyAreas()
        },
        submitAreaReport: function () {
            if (this.areaReportUpdate !== undefined && this.areaReportUpdate !== null) {
                this.loading = true
                this.restAdd(this.areaReportSubmitUrl, {
                    'report_id': this.areaReportUpdate.id,
                    'note': this.note
                }, this.submitAreaReportSuccess, this.submitAreaReportFail)
            }
        },

        submitAreaReportSuccess: function () {
            this.$emit('closeAndUpdate')
            this.loading = false
        },

        submitAreaReportFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },

        editAreaReport: function (areaReport) {
            this.areaReportUpdate = areaReport
            this.equipmentReportMode = false
        },

        addAreaReport: function () {
            let area = this.areas.find(area => area.id === this.selectedArea)
            this.areaReportUpdate = this.newAreaReport()
            this.areaReportUpdate.area = {id: area.id}
            this.loading = true
            this.restAdd(this.areaReportUrl, this.areaReportUpdate, this.addAreaSuccess, this.addAreaFail)
        },

        addAreaSuccess: function (responseData) {
            this.areaReportUpdate = responseData
            this.loading = false
            this.editEquipmentReport()
        },

        addAreaFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
            this.loading = false
        },

        editEquipmentReport: function () {
            this.areaReportWizardMode = false
            this.equipmentReportMode = true
        }
    }
}
</script>
