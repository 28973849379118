import {EventBus} from "@/event-bus";

export const velhoHelper = {
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        contractToVelhoParam(contract) {
            if (contract) {
                return {
                    id: contract.id,
                    external_contract_id: contract.external_contract && contract.external_contract.external_id ? contract.external_contract.external_id : null
                };
            }
            return null;
        },
        getVelhoRepairPriorityLevelString: function (code) {
            switch (code) {
                case repairPriorityLevels.IMMEDIATELY:
                    return this.$t('velho_equipment.damage.immediately')
                case repairPriorityLevels.WITHIN_2_YEARS:
                    return this.$t('velho_equipment.damage.within_2_years')
                case repairPriorityLevels.WITHIN_4_YEARS:
                    return this.$t('velho_equipment.damage.within_4_years')
                case repairPriorityLevels.LATER:
                    return this.$t('velho_equipment.damage.later')
                case repairPriorityLevels.DO_NOT_REPAIR:
                    return this.$t('velho_equipment.damage.do_not_repair')
                default:
                    return ""
            }
        },
        getVelhoDamageTypeString: function (code) {
            switch (code) {
                case damageTypes.UNDEFINED:
                    return this.$t('velho_equipment.damage.undefined')
                case damageTypes.TWIST:
                    return this.$t('velho_equipment.damage.twist')
                case damageTypes.COMPRESSION:
                    return this.$t('velho_equipment.damage.compression')
                case damageTypes.DEFORMATION:
                    return this.$t('velho_equipment.damage.deformation')
                case damageTypes.DEFLECTION:
                    return this.$t('velho_equipment.damage.deflection')
                case damageTypes.SETTLEMENT:
                    return this.$t('velho_equipment.damage.settlement')
                case damageTypes.DENT:
                    return this.$t('velho_equipment.damage.dent')
                case damageTypes.MISALIGNED:
                    return this.$t('velho_equipment.damage.misaligned')
                case damageTypes.SHIFTED:
                    return this.$t('velho_equipment.damage.shifted')
                case damageTypes.FROST_HEAVE:
                    return this.$t('velho_equipment.damage.frost_heave')
                case damageTypes.CRACKING:
                    return this.$t('velho_equipment.damage.cracking')
                case damageTypes.CRAZING:
                    return this.$t('velho_equipment.damage.crazing')
                case damageTypes.DISINTEGRATION:
                    return this.$t('velho_equipment.damage.disintegration')
                case damageTypes.WEAR:
                    return this.$t('velho_equipment.damage.wear')
                case damageTypes.ROTTING:
                    return this.$t('velho_equipment.damage.rotting')
                case damageTypes.WEATHERING:
                    return this.$t('velho_equipment.damage.weathering')
                case damageTypes.EROSION_DAMAGE:
                    return this.$t('velho_equipment.damage.erosion_damage')
                case damageTypes.SILTED:
                    return this.$t('velho_equipment.damage.silted')
                case damageTypes.CLOGGED:
                    return this.$t('velho_equipment.damage.clogged')
                case damageTypes.EXCESSIVE_VEGETATION:
                    return this.$t('velho_equipment.damage.excessive_vegetation')
                case damageTypes.MAINTENANCE_ISSUES:
                    return this.$t('velho_equipment.damage.maintenance_issues')
                case damageTypes.SILT_TRAP_FULL_ABOVE_PIPES:
                    return this.$t('velho_equipment.damage.silt_trap_full_above_pipes')
                case damageTypes.SILT_TRAP_FULL_NEAR_PIPE_LEVEL:
                    return this.$t('velho_equipment.damage.silt_trap_full_near_pipe_level')
                case damageTypes.FLAKING:
                    return this.$t('velho_equipment.damage.flaking')
                case damageTypes.BUBBLING:
                    return this.$t('velho_equipment.damage.bubbling')
                case damageTypes.POOR_PAINT_CONDITION:
                    return this.$t('velho_equipment.damage.poor_paint_condition')
                case damageTypes.GRAFFITI:
                    return this.$t('velho_equipment.damage.graffiti')
                case damageTypes.AESTHETIC_DEFECT:
                    return this.$t('velho_equipment.damage.aesthetic_defect')
                case damageTypes.RUSTED:
                    return this.$t('velho_equipment.damage.rusted')
                case damageTypes.LOOSE:
                    return this.$t('velho_equipment.damage.loose')
                case damageTypes.DETACHED_DISPLACED_LOOSE_RINGS_OPEN_SEAMS:
                    return this.$t('velho_equipment.damage.detached_displaced_loose_rings_open_seams')
                case damageTypes.DAMAGED_ATTACHMENTS:
                    return this.$t('velho_equipment.damage.damaged_attachments')
                case damageTypes.DAMAGED_POSTS:
                    return this.$t('velho_equipment.damage.damaged_posts')
                case damageTypes.BROKEN_CRACKED:
                    return this.$t('velho_equipment.damage.broken_cracked')
                case damageTypes.WATER_LEAK:
                    return this.$t('velho_equipment.damage.water_leak')
                case damageTypes.DENTED_FRACTURED_CHIPPED_DINGED:
                    return this.$t('velho_equipment.damage.dented_fractured_chipped_dinged')
                case damageTypes.COLLAPSE:
                    return this.$t('velho_equipment.damage.collapse')
                case damageTypes.MISSING_REFLECTOR:
                    return this.$t('velho_equipment.damage.missing_reflector')
                case damageTypes.CRACK_AT_JOINT:
                    return this.$t('velho_equipment.damage.crack_at_joint')
                case damageTypes.TOO_LOW:
                    return this.$t('velho_equipment.damage.too_low')
                case damageTypes.TOO_HIGH:
                    return this.$t('velho_equipment.damage.too_high')
                case damageTypes.TOO_SHORT:
                    return this.$t('velho_equipment.damage.too_short')
                case damageTypes.TOO_NARROW:
                    return this.$t('velho_equipment.damage.too_narrow')
                case damageTypes.INADEQUATE_TOO_SMALL:
                    return this.$t('velho_equipment.damage.inadequate_too_small')
                case damageTypes.LOCATED_IN_PAVEMENT_POTHOLE_IN_WHEEL_PATH:
                    return this.$t('velho_equipment.damage.located_in_pavement_pothole_in_wheel_path')
                case damageTypes.WRONG_LOCATION:
                    return this.$t('velho_equipment.damage.wrong_location')
                case damageTypes.WRONG_STRUCTURAL_TYPE:
                    return this.$t('velho_equipment.damage.wrong_structural_type')
                case damageTypes.NOT_ZEROED:
                    return this.$t('velho_equipment.damage.not_zeroed')
                case damageTypes.CASTING_DEFECT:
                    return this.$t('velho_equipment.damage.casting_defect')
                case damageTypes.QUALITY_LEVEL:
                    return this.$t('velho_equipment.damage.quality_level')
                case damageTypes.TOPPLED:
                    return this.$t('velho_equipment.damage.toppled')
                default:
                    return ""
            }
        },
        getVelhoDamageCategoryString: function (code) {
            switch (code) {
                case damageCategories.MINOR:
                    return this.$t('velho_equipment.damage.minor')
                case damageCategories.SIGNIFICANT:
                    return this.$t('velho_equipment.damage.significant')
                case damageCategories.SEVERE:
                    return this.$t('velho_equipment.damage.severe')
                case damageCategories.VERY_SEVERE:
                    return this.$t('velho_equipment.damage.very_severe')
                default:
                    return ""
            }
        },
        getVelhoSideString: function (code) {
            switch (code) {
                case side.RIGHT:
                    return this.$t('road_sign.side_right')
                case side.LEFT:
                    return this.$t('road_sign.side_left')
                case side.BETWEEN:
                    return this.$t('velho_conversion.between')
                case side.ACROSS:
                    return this.$t('velho_conversion.across')
                default:
                    return ""
            }
        },
        getVelhoTrackString: function (track) {
            switch (track) {
                case tracks.TRACK0:
                    return this.$t('velho_equipment.track_0')
                case tracks.TRACK1:
                    return this.$t('velho_equipment.track_1')
                case tracks.TRACK2:
                    return this.$t('velho_equipment.track_2')
                default:
                    return ""
            }
        },
        getVelhoLaneString: function (code) {
            switch (code) {
                case lane.KANU11:
                    return this.$t('velho_conversion.KANU11')
                case lane.KANU12:
                    return this.$t('velho_conversion.KANU12')
                case lane.KANU13:
                    return this.$t('velho_conversion.KANU13')
                case lane.KANU14:
                    return this.$t('velho_conversion.KANU14')
                case lane.KANU15:
                    return this.$t('velho_conversion.KANU15')
                case lane.KANU16:
                    return this.$t('velho_conversion.KANU16')
                case lane.KANU17:
                    return this.$t('velho_conversion.KANU17')
                case lane.KANU18:
                    return this.$t('velho_conversion.KANU18')
                case lane.KANU19:
                    return this.$t('velho_conversion.KANU19')
                case lane.KANU21:
                    return this.$t('velho_conversion.KANU21')
                case lane.KANU22:
                    return this.$t('velho_conversion.KANU22')
                case lane.KANU23:
                    return this.$t('velho_conversion.KANU23')
                case lane.KANU24:
                    return this.$t('velho_conversion.KANU24')
                case lane.KANU25:
                    return this.$t('velho_conversion.KANU25')
                case lane.KANU26:
                    return this.$t('velho_conversion.KANU26')
                case lane.KANU27:
                    return this.$t('velho_conversion.KANU27')
                case lane.KANU28:
                    return this.$t('velho_conversion.KANU28')
                case lane.KANU29:
                    return this.$t('velho_conversion.KANU29')
                case lane.KANU31:
                    return this.$t('velho_conversion.KANU31')
                case lane.KANU32:
                    return this.$t('velho_conversion.KANU32')
                case lane.KANU33:
                    return this.$t('velho_conversion.KANU33')
                case lane.KANU34:
                    return this.$t('velho_conversion.KANU34')
                case lane.KANU35:
                    return this.$t('velho_conversion.KANU35')
                case lane.KANU36:
                    return this.$t('velho_conversion.KANU36')
                case lane.KANU37:
                    return this.$t('velho_conversion.KANU37')
                case lane.KANU38:
                    return this.$t('velho_conversion.KANU38')
                case lane.KANU39:
                    return this.$t('velho_conversion.KANU39')
                default:
                    return ""
            }
        },
        submitEquipment: function (url, equipment, selectedContract) {
            this.loading = true
            equipment.contract = this.contractToVelhoParam(selectedContract)
            this.restUpdate(url, equipment, this.success, this.fail)
        },
        submitEquipmentsreplace: function (url, equipments, selectedContract) {
            this.loading = true
            if(!Array.isArray(equipments)) {this.fail}
            equipments.forEach(x => x.contract = this.contractToVelhoParam(selectedContract))
            // It takes some time to update the equipment in Velho, so we need to wait a bit before showing the success message
            // We can't know when equipment is actually updated in velho, so duplicate markers are possible but the old one cannot be opened
            this.restAdd(url, equipments, () => setTimeout(this.success, 4000), this.fail)
        },
        success: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_success'), 'success')
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },
        getLocationString(item) {
            let result = '';
            if (item) {
                if (item.road_num) {
                    result += item.road_num
                    if (item.road_sec) {
                        result += ' / ' + item.road_sec
                    }
                    if (item.distance) {
                        result += ', ' + item.distance + "m"
                    }
                }
            }
            return result;
        },
        removalConfirmed (url, equipment, selectedContract) {
            this.loading = true
            equipment.contract = this.contractToVelhoParam(selectedContract)
            this.restAdd(this.extEquipmentRemovalUrl, equipment, this.success, this.fail)
        }

    }
}

export const side = {
    RIGHT: 1, //p01
    LEFT: 2, //p02
    BETWEEN: 3, //p03
    ACROSS: 4 //p06
}

export const tracks = {
    TRACK0: 0, //arj0
    TRACK1: 1, //arj1
    TRACK2: 2 //arj2
}

export const lane = {
    KANU11: 11,
    KANU12: 12,
    KANU13: 13,
    KANU14: 14,
    KANU15: 15,
    KANU16: 16,
    KANU17: 17,
    KANU18: 18,
    KANU19: 19,
    KANU21: 21,
    KANU22: 22,
    KANU23: 23,
    KANU24: 24,
    KANU25: 25,
    KANU26: 26,
    KANU27: 27,
    KANU28: 28,
    KANU29: 29,
    KANU31: 31,
    KANU32: 32,
    KANU33: 33,
    KANU34: 34,
    KANU35: 35,
    KANU36: 36,
    KANU37: 37,
    KANU38: 38,
    KANU39: 39
}

export const repairPriorityLevels = {
    IMMEDIATELY: 1,
    WITHIN_2_YEARS: 2,
    WITHIN_4_YEARS: 3,
    LATER: 4,
    DO_NOT_REPAIR: 5
}

export const damageCategories = {
    MINOR: 1,
    SIGNIFICANT: 2,
    SEVERE: 3,
    VERY_SEVERE: 4
}

export const damageTypes = {
    UNDEFINED: 1,
    TWIST: 2,
    COMPRESSION: 3,
    DEFORMATION: 4,
    DEFLECTION: 5,
    SETTLEMENT: 6,
    DENT: 7,
    MISALIGNED: 8,
    SHIFTED: 9,
    FROST_HEAVE: 10,
    CRACKING: 11,
    CRAZING: 12,
    DISINTEGRATION: 13,
    WEAR: 14,
    ROTTING: 15,
    WEATHERING: 16,
    EROSION_DAMAGE: 17,
    SILTED: 18,
    CLOGGED: 19,
    EXCESSIVE_VEGETATION: 20,
    MAINTENANCE_ISSUES: 21,
    SILT_TRAP_FULL_ABOVE_PIPES: 22,
    SILT_TRAP_FULL_NEAR_PIPE_LEVEL: 23,
    FLAKING: 24,
    BUBBLING: 25,
    POOR_PAINT_CONDITION: 26,
    GRAFFITI: 27,
    AESTHETIC_DEFECT: 28,
    RUSTED: 29,
    LOOSE: 30,
    DETACHED_DISPLACED_LOOSE_RINGS_OPEN_SEAMS: 31,
    DAMAGED_ATTACHMENTS: 32,
    DAMAGED_POSTS: 33,
    BROKEN_CRACKED: 34,
    WATER_LEAK: 35,
    DENTED_FRACTURED_CHIPPED_DINGED: 36,
    COLLAPSE: 37,
    MISSING_REFLECTOR: 38,
    CRACK_AT_JOINT: 39,
    TOO_LOW: 40,
    TOO_HIGH: 41,
    TOO_SHORT: 42,
    TOO_NARROW: 43,
    INADEQUATE_TOO_SMALL: 44,
    LOCATED_IN_PAVEMENT_POTHOLE_IN_WHEEL_PATH: 45,
    WRONG_LOCATION: 46,
    WRONG_STRUCTURAL_TYPE: 47,
    NOT_ZEROED: 48,
    CASTING_DEFECT: 49,
    QUALITY_LEVEL: 50,
    TOPPLED: 51
}
