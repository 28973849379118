<template>
    <div class="map-item-filter" v-if="isMapViewer || isObserver">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <span
            class="vehicle-list-title"
            @click.stop="toggleList">
            {{ $t('vehicle_list.title') }} ({{ logEntries ? logEntries.length : 0 }})
        </span>
            <div
                class="vehicle-list-caret"
                v-bind:class="{ caret_open: listOpen }"
                @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>
            <!-- Accordion content -->
            <div
                class="col-sm-12 nopads map-accordion-content"
                v-bind:class="{ 'map-accordion-open': listOpen }"
            >
                <div v-bind:class="{ 'm-2': listOpen }">
            <!-- History toggle -->
            <b-form-checkbox
                v-model="showHistoryValue"
                size="md"
                class="ml-2 mr-2 text-light"
                :disabled="showHistoryWithoutTimeLimit"
                @change="emitShowHistory">
                {{ $t('vehicle_list.show_history') }} ( {{ historyValue }} h)
            </b-form-checkbox>
            <!-- show history without time limit button -->
            <b-form-checkbox
                v-if="selectedContractId || (selectedContractIds && selectedContractIds.length > 0) && showHistoryValue"
                v-model="showHistoryWithoutTimeLimit"
                size="md"
                class="ml-2 mr-2 text-light"
                @change="emitShowHistory">
                {{ $t('vehicle_list.show_history_without_time_range') }}
            </b-form-checkbox>
            <vue-slider
                v-if="showHistoryValue"
                v-model="historyValue"
                class="m-1 ml-3 mr-3"
                :tooltip="'none'"
                :min="minHours"
                :max="maxHours"
                :disabled="showHistoryWithoutTimeLimit"
                :lazy="true"/>
            <!-- History road type selection -->
            <div class="col-12">
                <div class="badge-filters__wrapper pt-2">
                    <div class="badge-filters">
                        <b-badge
                            v-for="item in roadOwnerOptions"
                            :key="item.value"
                            @click="roadOwnership = item.value"
                            :variant="roadOwnership === item.value ? 'primary' : 'light'"
                            style="padding: .5em 1em"
                        >
                            {{ item.text }}
                        </b-badge>
                    </div>
                </div>
            </div>
            <!-- Expandable vehicle list -->
            <div class="col-sm-12 nopads vehicle-list-container vehicle-list-smaller-max-height"
                    v-if="logEntries && logEntries.length > 0 && listOpen">
                <div
                    v-for="item of logEntries"
                    :key="item.id"
                    class="vehicle-list-item"
                    @click.stop="showVehicleInfo(item.vehicle.id)"
                >
                    <b-row class="nopads">
                        <div class="vehicle-list-icon">
                            <font-awesome-icon
                                :icon="getIcon(item.vehicle.vehicle_type)"
                                v-bind:class="{ fresh: isActive(item) }"/>
                        </div>
                        <div v-if="isObserver" class="vehicle-list-header">{{ item.vehicle.license_plate }}</div>
                    </b-row>
                    <div v-if="isObserver">{{ item.vehicle.make }} {{ item.vehicle.vehicle_model }}</div>
                    <div v-else-if="isMapViewer">{{ getVehicleTypeName(item.vehicle.vehicle_type) }}</div>
                    <div v-if="isObserver" class="vehicle-list-company">{{ item.vehicle.company.name }}</div>
                    <b-row v-if="item.routes && item.routes.length > 0" class="nopads">
                        <div
                            v-if="item.routes[0].contract"
                            class="col-6 vehicle-list-task word-break"
                        >
                            {{ item.routes[0].contract }}
                        </div>
                        <div
                            v-if="item.routes[0].order"
                            class="col-6 vehicle-list-task"
                        >
                            <div class="task-type-circle"
                                    v-bind:style="{ backgroundColor: getTraceColor(item.routes[0].mode, item.routes[0].trace_color) }"/>
                            {{ item.routes[0].order }}
                        </div>
                    </b-row>
                    <div v-else class="vehicle-list-task">{{ $t('vehicle_list.no_contract') }}</div>
                </div>
            </div>
            <span v-else-if="!logEntries || logEntries.length < 1"
                    class="vehicle-list-no-results">{{ $t('vehicle_list.no_results') }}</span>
            </div>
        </div>
    </div>
    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {logModes, vehicleHelper} from '../mixins/VehicleMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {mapActions, mapState} from "vuex";

export default {
    name: 'MapVehicles',
    mixins: [restApi, vehicleHelper, timeUtils, mapHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean,
        isMapViewer: Boolean,
        showHistory: {
            type: Boolean,
            default: false
        },
        hours: {
            type: Number,
            default: 6
        },
        roadOwner: {
            type: Number,
            default: null
        }
    },
    data: function () {
        return {
            minHours: 1,
            maxHours: 24,
            historyValue: 6,
            loading: false,
            listOpen: false,
            infoVehicle: -1,
            logEntries: [],
            polyLines: [],
            contracts: [],
            logModes: logModes,
            timer: null,
            destroyed: false,
            fetching: false,
            selectedTaskTypeValue: null,
            showHistoryValue: false,
            showHistoryWithoutTimeLimit: false,
            roadOwnership: null,
            roadOwnerOptions: [
                { text: this.$t('common.all'), value: null },
                { text: this.$t('common.municipality'), value: 2 },
                { text: this.$t('common.government'), value: 1 },
                { text: this.$t('common.private'), value: 3 }
                /*{ text: this.$t('common.unknown'), value: 99 }*/
            ]
        }
    },
    computed: {
        ...mapState({
            selectedContractId: (state) => state.contracts.selectedContractId,
            selectedContractIds: (state) => state.contracts.selectedContractIds,
            selectedContract: (state) => state.contracts.selectedContract,
            selectedTaskType: (state) => state.contracts.selectedTaskType,
            selectedOrder: (state) => state.contracts.selectedOrder,
            isLoadingContracts: (state) => state.contracts.isLoading
        }),
        tableFields() {
            if (this.isObserver) {
                return [
                    {
                        vehicle: {
                            label: this.$t('vehicle_position.vehicle'),
                            sortable: false
                        }
                    }
                    ,
                    {
                        time: {
                            label: this.$t('vehicle_position.time'),
                            sortable: false
                        }
                    },
                    {
                        mode: {
                            label: this.$t('vehicle_position.mode'),
                            sortable: false
                        }
                    },
                    {
                        company: {
                            label: this.$t('vehicle_position.company'),
                            sortable: false
                        }
                    },
                    {
                        customer: {
                            label: this.$t('vehicle_position.customer'),
                            sortable: false
                        }
                    },
                    {
                        contract: {
                            label: this.$t('vehicle_position.contract'),
                            sortable: false
                        }
                    },
                    {
                        order: {
                            label: this.$t('vehicle_position.order'),
                            sortable: false
                        }
                    }
                ]
            }
            return [
                {
                    vehicle: {
                        label: this.$t('vehicle_position.vehicle'),
                        sortable: false
                    }
                }
                ,
                {
                    time: {
                        label: this.$t('vehicle_position.time'),
                        sortable: false
                    }
                },
                {
                    mode: {
                        label: this.$t('vehicle_position.mode'),
                        sortable: false
                    }
                },
                {
                    contract: {
                        label: this.$t('vehicle_position.contract'),
                        sortable: false
                    }
                },
                {
                    order: {
                        label: this.$t('vehicle_position.order'),
                        sortable: false
                    }
                }
            ]
        }
    },
    watch: {
        map() {
            this.$nextTick(function () {
            this.fetchVehicles()
          })
        },
        historyValue() {
            if (this.showHistoryValue) {
                this.emitShowHistory()
            }
        },
        showHistoryWithoutTimeLimit() {
            this.emitShowHistory()
            this.fetchVehicles()
        },
        selectedContractIds() {
            this.hideVehicles()
            this.emitShowHistory()
            this.fetchVehicles()
        },
        selectedContractId() {
            this.hideVehicles()
            this.emitShowHistory()
            this.fetchVehicles()
        },
        selectedTaskType() {
            this.hideVehicles()
            this.emitShowHistory()
            this.fetchVehicles()
        },
        selectedOrder() {
            this.hideVehicles()
            this.emitShowHistory()
            this.fetchVehicles()
        },
        roadOwnership() {
            this.emitShowHistory()
        }
    },
    mounted: function () {
        this.showHistoryValue = this.showHistory
        this.historyValue = this.hours;
        this.roadOwnership = this.roadOwner;
        this.fetchVehicles()
        this.fetchContracts()
    },
    beforeDestroy: function () {
        this.destroyed = true
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.hideVehicles()
    },
    methods: {
        ...mapActions('contracts', ['selectTaskType']),
        emitShowHistory() {
            if(!this.selectedContractId && (!this.selectedContractIds || this.selectedContractIds.length < 1) && this.showHistoryWithoutTimeLimit) {
                this.showHistoryWithoutTimeLimit = false
            }
            this.$emit('toggleHistory', this.showHistoryValue, this.historyValue, this.roadOwnership,
                this.showHistoryWithoutTimeLimit)
        },

        fetchVehicles: function () {
            if (!this.fetching) {
                let params = {}
                this.fetching = true
                if (this.timer) {
                    clearTimeout(this.timer)
                } else {
                    this.loading = true
                }
                if(this.selectedContractIds) {
                    params.contract_id = this.selectedContractIds
                }else if(this.selectedContractId) {
                    params.contract_id = this.selectedContractId
                }
                if(this.selectedOrder) {
                    params.order = this.selectedOrder
                } else if (this.selectedTaskType) {
                    params.taskType = this.selectedTaskType
                }
                this.restFetchParams(this.lastPositionUrl, params, this.handleResponse)
            }
        },

        fetchContracts: function() {
            this.loading = true
            this.restFetch(this.contractUrl, this.handleContracts)
        },

        handleContracts: function (response) {
            this.contracts = response.data
            this.loading = false
        },

        handleResponse: function (response) {
            this.loading = false
            this.fetching = false
            if (response) {
                this.logEntries = response.data
                this.showVehicles()
                if (this.infoVehicle > 0) {
                    this.showVehicleInfo(this.infoVehicle)
                }
            }
            if (!this.destroyed) {
                this.timer = setTimeout(this.fetchVehicles, 30000)
            }
        },

      hideVehicles: function () {
        if (this.timer) {
          clearTimeout(this.timer)
        }
        if (this.map) {
          this.logEntries.forEach(item => {
            this.map.removeMapMarker(item.vehicle.id, this.VEHICLE)
          })
          this.polyLines.forEach(line => {
            this.map.removePolyline(line, this.TRACE)
          })
          this.hideVehicleInfo()
          this.hideTraceInfo()
        }
      },

        getFreshLimit: function () {
            return Date.now() - 1000 * 60 * 5 // 5 minute old vehicle entries shown as active, older as passive
        },

        showVehicles: function () {
            var freshLimit = this.getFreshLimit()
            // One 'logEntry' is an object containing information about the vehicle and it's routes
            this.logEntries.forEach(item => {
                if (item.routes && item.routes.length > 0) {
                    // Draw marker for latest point
                    var vehicle = item.vehicle;
                    var latest = item.routes[0]
                    latest.endTime = this.setTime(latest.endTime)
                    var fresh = Date.parse(latest.endTime) > freshLimit
                    var marker = this.getVehicleMarker(vehicle.vehicle_type, fresh, this.map.getMarkerStore())
                    this.map.showMapMarker(vehicle.id, this.VEHICLE, latest.points[0].y, latest.points[0].x, marker)
                    item.routes.forEach(log => {
                        if (log.points.length > 1) {
                            this.drawVehicleTrace(log.id, log.points, log.mode, log.trace_color)
                            this.polyLines.push(log.id)
                        }
                    })
                }
            })
        },

        drawVehicleTrace: function (id, coords, mode, color) {
            var dash = false
            switch (mode) {
                case logModes.WORK:
                    if (!color) {
                        color = '#00FF21'
                    }
                    break
                case logModes.PROGRAM:
                    color = '#2121FF'
                    break
                case logModes.RESETTLE:
                    color = '#89898a'
                    dash = true
                    break
            }
            this.map.drawPolyLine(id, this.TRACE, coords, color, dash)
        },

        onMarkerTap: function (data) {
            if (data.id === this.infoVehicle) {
                this.hideVehicleInfo()
            } else {
                this.showVehicleInfo(data.id)
            }
        },

        onPolylineTap: function (data) {
            if (data.type && data.type === this.TRACE) {
                if (data.id === this.infoTrace) {
                    this.hideTraceInfo()
                } else {
                    this.showTraceInfo(data.id)
                }
            }
        },

        showTraceInfo: function (id) {
            this.hideVehicleInfo()
            var trace = null
            let vehicle
            for (let i = 0; i < this.logEntries.length; i++) {
                let item = this.logEntries[i]
                trace = item.routes.find(route => route.id === Number(id))
                if (trace) {
                    vehicle = item.vehicle
                    break
                }
            }
            if (!trace) {
                return
            }
            this.infoTrace = id
            this.map.showMapInfo(this.tableFields, this.getInfoContent(vehicle, trace), false)
        },

        hideTraceInfo: function () {
            if (this.infoTrace) {
                this.map.hideMapInfo()
                this.infoTrace = -1
            }
        },

        onMapInfoClosed: function () {
            this.infoVehicle = -1
            this.infoTrace = -1
        },

        showVehicleInfo: function (id) {
            this.hideTraceInfo()
            var item = this.logEntries.find(item => item.vehicle.id === id)
            if (!item || !item.routes) {
                return
            }
            this.infoVehicle = id
            this.map.showMapInfo(this.tableFields, this.getInfoContent(item.vehicle, item.routes[0]), false)
            this.map.zoomToPosition(item.routes[0].points[0].y, item.routes[0].points[0].x)
        },

        hideVehicleInfo: function () {
            if (this.infoVehicle > 0) {
                this.map.hideMapInfo()
                this.infoVehicle = -1
            }
        },

        getVehicleStr: function (vehicle) {
            var result = ''
            if (vehicle.make) {
                result += vehicle.make + ' '
            }
            if (vehicle.vehicle_model) {
                result += vehicle.vehicle_model + ' '
            }
            if (vehicle.license_plate) {
                result += vehicle.license_plate
            }
            return result
        },

        getInfoContent: function (vehicle, route) {
            if (this.isObserver) {
                return [{
                    vehicle: this.getVehicleStr(vehicle),
                    time: route.startTime && route.endTime ? this.$d(new Date(this.setTime(route.startTime)), 'short') + ' - ' +
                        this.$d(new Date(this.setTime(route.endTime)), 'short') : '-',
                    mode: route.mode ? this.getTripModeString(route.mode) : '-',
                    company: vehicle.company.name ? vehicle.company.name : '-',
                    customer: route.customer ? route.customer : '-',
                    contract: route.contract ? route.contract : '-',
                    order: route.order ? route.order : '-'
                }]
            }
            // Show less for map viewer
            return [{
                vehicle: this.getVehicleTypeName(vehicle.vehicle_type),
                time: route.startTime && route.endTime ? this.$d(new Date(this.setTime(route.startTime)), 'short') + ' - ' +
                    this.$d(new Date(this.setTime(route.endTime)), 'short') : '-',
                mode: route.mode ? this.getTripModeString(route.mode) : '-',
                contract: route.contract ? route.contract : '-',
                order: route.order ? route.order : '-'
            }]

        },

        getLocationStr: function (entry) {
            var result = ''
            if (entry.road_num) {
                result += entry.road_num + ' '
            }
            if (entry.sec_num) {
                result += '/ ' + entry.sec_num + ' '
            }
            if (entry.road_name) {
                result += entry.road_name + ' '
            }
            if (entry.city) {
                result += entry.city.name
            }
            return result
        },

        isActive: function (item) {
            if (item && item.routes && item.routes.length > 0) {
                return Date.parse(item.routes[0].endTime) > this.getFreshLimit()
            }
            return false
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        getTraceColor: function (mode, color) {
            if (mode === logModes.WORK) {
                return color
            }
            return '#bcbcbc'
        },
    }
}
</script>
