<template>
    <div class="col-12 nopads">
        <transition name="fade">
            <power-station-wizard
                v-if="showPowerStationWizardOnCoord !== null || powerStation != null"
                style="pointer-events: all"
                :selectedContract="contract"
                :coordinate="showPowerStationWizardOnCoord"
                :power-station="powerStation"
                @close="closePowerStationWizard"
                @closeAndUpdate="closeAndUpdatePowerStations"
            />
        </transition>
        <transition name="fade">
            <street-light-wizard
                v-if="showStreetLightWizardOnCoord !== null || streetLight != null"
                style="pointer-events: all"
                :selectedContract="contract"
                :coordinate="showStreetLightWizardOnCoord"
                :street-light="streetLight"
                @close="closeStreetLightWizard"
                @closeAndUpdate="closeAndUpdateStreetLights"
            />
        </transition>
        <street-light-map-filter
            :selectedContractIds="selectedContractIds"
            @stationSelected="updateStreetLights"/>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import PowerStationWizard from "@/components/streetlights/PowerStationWizard";
import StreetLightWizard from "@/components/streetlights/StreetLightWizard";
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import StreetLightMapFilter from "@/components/streetlights/StreetLightMapFilter";

export default {
    name: 'StreetLightMap',
    components: {StreetLightMapFilter, StreetLightWizard, PowerStationWizard},
    mixins: [timeUtils, mapHelper, restApi, geometryMixin],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            loading: false,
            powerStations: [],
            streetLights: [],
            visibleStations: [],
            visibleLights: [],
            streetLight: null,
            powerStation: null,
            showPowerStationWizardOnCoord: null,
            showStreetLightWizardOnCoord: null,
            markerStore: null,
            selectedStation: null,
            contract: null,
            stationFilter: null,
            bbox: null,
            visibilityZoomLevel: 15
        }
    },

    watch: {
      map() {
        this.$nextTick(function () {
          this.initView()
        })
      },
      selectedContractId() {
          this.updateView(true)
      },
      selectedContractIds() {
          this.updateView(true)
      },
      boundingBox() {
         this.updateView()
      }
    },
    beforeDestroy() {
        this.hideAll()
    },
    mounted() {
        this.initView()
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideAll()
            } else if (force || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.005
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchStreetLights()
                this.fetchPowerStations()
            }
        },

        addPowerStation(coordinates) {
            this.showPowerStationWizardOnCoord = coordinates
        },

        editPowerStation(id) {
            this.loading = true
            this.restFetch(this.powerStationUrl + '/' + id, this.handleStation)
        },

        handleStation(response) {
            this.loading = false
            if (response && response.data) {
                this.powerStation = response.data
            }
        },

        fetchPowerStations: function () {
            let params = {}
            this.results = []
            if(this.selectedContractIds) {
                params.contract = this.selectedContractIds
            } else if(this.selectedContractId) {
                params.contract = this.selectedContractId
            }
            if(this.bbox && this.bbox.length > 0) {
                params.bbox = this.bbox
                this.loading = true
                this.restFetchParams(this.powerStationMapUrl, params, this.handleSearch, () => {
                    this.loading = false
                })
            }
        },

        filterByStation(station) {
            this.hideStreetLights()
            this.hideStations()
            if (station) {
                this.visibleStations = this.powerStations.filter(item => item.id === station.id);
                this.visibleLights = this.streetLights.filter(item => item.power_station.id === station.id )
            } else {
                this.visibleStations = this.powerStations
                this.visibleLights = this.streetLights
            }
            if (this.visibleStations.length > 0) {
                this.drawStations()
            }
            if (this.visibleLights.length > 0) {
                this.drawStreetLights()
            }
            this.stationFilter = station
        },

        handleSearch: function (response) {
            this.hideStations()
            if (response && response.data) {
                this.powerStations = response.data
                this.visibleStations = this.stationFilter ? this.powerStations.filter(item => item.id === this.stationFilter.id) : this.powerStations
                this.drawStations()
            }
            this.loading = false
        },

        drawStations: function () {
            this.visibleStations.forEach(function (station) {
                this.drawStation(station)
            }, this)
        },

        drawStation: function (station) {
            let icon = this.markerStore.getPowerStationIcon(station.control_method)
            this.map.showMapMarker(station.id, this.POWER_CENTER, station.position.y, station.position.x, icon)
        },

        hideStations: function () {
          if (this.map) {
            this.map.removeMapItemsByType(this.POWER_CENTER)
          }
          this.visibleSigns = []
        },

        hideAll() {
            this.hideStations()
            this.hideStreetLights()
        },

        getPowerStation(id) {
            return this.powerStations.find(item => item.id === id)
        },

        addStreetLight(coordinates) {
            this.showStreetLightWizardOnCoord = coordinates
        },

        editStreetLight(id) {
            this.loading = true
            this.restFetch(this.streetLightUrl + '/' + id, this.handleLight)
        },

        handleLight(response) {
            this.loading = false
            if (response && response.data) {
                this.streetLight = response.data
            }
        },

        fetchStreetLights: function () {
            let params = {}
            if(this.selectedContractIds) {
                params.contract = this.selectedContractIds
            } else if(this.selectedContractId) {
                params.contract = this.selectedContractId
            }
            if(this.bbox && this.bbox.length > 0) {
                params.bbox = this.bbox
                this.loading = true
                this.restFetchParams(this.streetLightMapUrl, params, this.handleLightSearch, () => {
                    this.loading = false
                })
            }
        },

        handleLightSearch: function (response) {
            this.hideStreetLights()
            if (response && response.data) {
                this.streetLights = response.data
                this.visibleLights = this.stationFilter ? this.streetLights.filter(item => item.power_station.id === this.stationFilter.id) : this.streetLights
                this.drawStreetLights()
            }
            this.loading = false
        },

        drawStreetLights: function () {
            this.visibleLights.forEach(function (light) {
                this.drawStreetLight(light)
            }, this)
        },

        drawStreetLight: function (light) {
            let DEFECT = 2;
            let REMOVED = 3;
            let PLANNED = 4;
            let icon = light.status === DEFECT ?
                this.markerStore.getStreetLightDefectIcon() :
                light.status === REMOVED ?
                this.markerStore.getStreetLightRemovedIcon() :
                light.status === PLANNED ?
                this.markerStore.getStreetLightPlannedIcon() :
                this.markerStore.getStreetLightIcon()
            this.map.showMapMarker(light.id, this.STREET_LIGHT, light.position.y, light.position.x, icon)
        },

        hideStreetLights: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.STREET_LIGHT)
            }
            this.visibleSigns = []
        },

        getStreetlight(id) {
            return this.streetLights.find(item => item.id === id)
        },

        closePowerStationWizard() {
            this.showPowerStationWizardOnCoord = null
            this.powerStation = null
        },

        closeAndUpdatePowerStations() {
            this.closePowerStationWizard()
            this.fetchPowerStations()
        },

        closeStreetLightWizard() {
            this.showStreetLightWizardOnCoord = null
            this.streetLight = null
        },

        closeAndUpdateStreetLights() {
            this.closeStreetLightWizard()
            this.fetchStreetLights()
        },

        getStreetLights() {
            return this.streetLights
        },

        updateStreetLights(station) {
          this.filterByStation(station)
        }

    }
}
</script>
