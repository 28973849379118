<template>
    <b-modal
        class="col-12 file-dialog"
        ref="modal"
        centered
        hide-footer
        :title="$t('orders.add_geometry_from_observations')"
        @close="resolve(null)"
    >
        <div class="row">
            <span>{{ $t('orders.new_geometry_name') }}</span>
        </div>
        <b-form-input
            id="name"
            type="text"
            size="mb"
            v-model="geometry.name"
        />

        <div class="row flex items-center">
            <span class="mr-2 h-min">
                {{ $t('orders.new_geometry_color') }}
            </span>
            <color-picker @selectColor="(color) => geometry.color = color" :noRedColor="true" :defaultColor="geometry.color" />
        </div>

        <div class="row mt-2">
            <span>{{ $t('observation_filter.observation_type') }}</span>
        </div>
        <b-form-select
            size="sm"
            :disabled="!observationTypes"
            v-model="observationType"
            :options="observationTypeOptions"
        />

        <div>
            <hr>
            <div class="col-2 mt-2">
                <span>{{ $t('orders.new_geometry_properties') }}</span>
            </div>
            <order-geometry-property-editor
                ref="orderGeometryPropertyEditor"
                :newProperties="geometry.geometryProperties"
                @addProperty="addProperty"
                @removeProperty="removeProperty"
            />
        </div>
        <div class="button-container pr-1">
            <b-button
                variant="secondary"
                size="sm"
                class="form-button"
                @click.stop="resolve(null)"
            >
                {{ $t('common.cancel') }}
            </b-button>
            <b-button
                variant="success"
                size="sm"
                class="form-button"
                :disabled="!geometry.name || !observationType"
                @click.stop="resolve(geometry)"
            >
                {{ $t('orders.add_geometry_from_observations_short') }}
            </b-button>
        </div>
    </b-modal>
</template>

<script>
import {restApi} from "../mixins/RestApiMixin";
import OrderGeometryPropertyEditor from "../order/OrderGeometryPropertyEditor.vue";
import ColorPicker from "../ColorPicker.vue";
import {geomTypes} from "../mixins/ObservationMixin";
import {geometryMixin} from "../mixins/GeometryMixin";

const defaultGeometry = (importedItemIndex) => ({
    name: "",
    label: null,
    geometry: null,
    color: "#20c997",
    type: null,
    geometryProperties: [],
    calculate: false,
    visibility: true,
    lineWidth: 3,
    addPolylineEnds: false,
    importedItemIndex
})

export default {
    name: 'ObservationToRoadGeometry',
    components: {ColorPicker, OrderGeometryPropertyEditor},
    mixins: [restApi, geometryMixin],
    data() {
        return {
            geometry: defaultGeometry(),
            observationType: null,
            observationTypes: null,
            resolve: null,
        }
    },
    computed: {
        observationTypeOptions() {
            if (!this.observationTypes) {
                return [{ value: null, text: this.$t('common.loading') }]
            }
            return [
                { value: null, text: this.$t('observations.select_observation_type') },
                ...this.observationTypes.map(type => ({
                    value: type, text: type.name
                }))
            ]
        }
    },
    methods: {
        addProperty(newProperty) {
            this.geometry.geometryProperties.push(newProperty)
        },
        removeProperty(index) {
            if(this.geometry.geometryProperties.length > 0) {
                this.geometry.geometryProperties.splice(index, 1)
            }
        },
        createRoadGeometryFrom(observations, importedItemIndex) {
            if (this.resolve != null) {
                this.resolve(null)
            }
            this.reset(importedItemIndex)
            this.$refs.modal.show()

            // Fetch observation types in background
            this.getObservationTypes(observations)
                .then(types => this.observationTypes = types)

            return new Promise(resolve => {
                this.resolve = (roadGeometry) => {
                    this.$refs.modal.hide()
                    if (roadGeometry != null) {
                        resolve(this.addObservationFeatures(roadGeometry, observations))
                    } else {
                        resolve(null)
                    }
                }
            })
        },
        reset(importedItemIndex) {
            this.geometry = defaultGeometry(importedItemIndex)
            this.observationType = null
            this.observationTypes = null
            this.resolve = null
        },
        async getObservationTypes(observations) {
            let typeIds = observations.map(o => o.type_id)
                .filter((v, i, a) => a.indexOf(v) === i) // Filter to unique ids
                .sort((a, b) => a - b) // Sort as numbers

            const types = await this.fetchObservationTypes()
            return typeIds.map(id => types.find(e => e.id === id))
        },
        async fetchObservationTypes() {
            const response = await this.axios.get(this.observationTypeUrl)
            return response.data
        },
        geometryTypeIdToString(typeId) {
            return {
                [geomTypes.POINT]: 'Point',
                [geomTypes.LINESTRING]: 'LineString',
            }[typeId]
        },
        coordinateToArray(coord) {
            return [coord.x, coord.y]
        },
        addObservationFeatures(roadGeometry, observations) {
            const typeId = this.observationType.id
            const type = this.geometryTypeIdToString(this.observationType.geometry_type)
            return {
                ...roadGeometry,
                type,
                geometry: {
                    features: observations
                        .filter(o => o.type_id === typeId)
                        .map(({geometry}, index) => ({
                            geometry: type === 'Point' ? {
                                bbox: this.getPointBbox(this.coordinateToArray(geometry.point)),
                                coordinates: this.coordinateToArray(geometry.point),
                                type,
                            } : {
                                bbox: this.getPolylineBbox(geometry.line_string.points),
                                coordinates: geometry.line_string.points,
                                type,
                            },
                            properties: {
                                ...Object.fromEntries(roadGeometry.geometryProperties.map(prop => ([prop, ""]))),
                                id: index,
                                importedItemIndex: this.geometry.importedItemIndex,
                            },
                            type: 'Feature'
                        })),
                    type: 'FeatureCollection'
                }
            }
        },
    }
}
</script>
