<template>
    <div>
        <!-- Company filter for admin -->
        <div class="col-sm-6">
            <keep-alive>
                <company-filter
                    ref="companyFilter"
                    v-if="isAdmin"
                    v-model="company"
                />
            </keep-alive>
        </div>
        <b-row class="nopads">
            <div class="col-sm-3">
                <!-- Customer selection, that triggers contract search -->
                <b-form-group
                    class="nopads"
                    label-for="customerOrder"
                >
                    <span class="span-title">{{ $t('orders.customer') }}</span>
                    <b-form-select
                        id="customerOrder"
                        v-model="customer"
                        size="sm"
                        class="sm-3"
                        :disabled="customers.length < 1 || contract > 0"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('orders.select_customer') }}
                            </option>
                        </template>
                        <option
                            v-if="!customers.some(item => item.id === user.company.id)"
                            :value="user.company.id">
                            {{ user.company.name }}
                        </option>
                        <option
                            v-for="option in customers"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col-sm-3">
                <!-- Contractor selection, that triggers contract search -->
                <b-form-group
                    class="nopads"
                    label-for="contractorOrder"
                >
                    <span class="span-title">{{ $t('orders.contractor') }}</span>
                    <b-form-select
                        id="contractorOrder"
                        v-model="contractor"
                        size="sm"
                        class="sm-3"
                        :disabled="contractors.length < 1 || contract > 0"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('orders.select_contractor') }}
                            </option>
                        </template>
                        <option
                            :value="user.company.id"
                            v-if="!contractors.some(item => item.id === user.company.id)"
                        >
                            {{ user.company.name }}
                        </option>
                        <option
                            v-if="noContractorOption"
                            :value="-1">
                            {{ $t('orders.no_contractor') }}
                        </option>
                        <option
                            v-for="option in contractors"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <div class="col-sm-3">
                <!-- Contract selection, that updates customer and contractor selections if needed -->
                <b-form-group
                    class="title nopads"
                    label-for="contract"
                >
                    <span class="span-title">{{ $t('orders.contract') }}</span>
                    <b-form-select
                        id="contract"
                        v-model="contract"
                        size="sm"
                        class="sm-3"
                        :disabled="contracts.length < 1"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('orders.select_contract') }}
                            </option>
                        </template>
                        <option
                            v-for="option in contracts"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
                <b-form-group v-if="showOnlyMyContractsOption" label-for="companies">
                    <b-form-checkbox
                        v-model="onlyUserOwnedContracts"
                        size="md"
                        type="text"
                        value=1
                        unchecked-value=0>
                        {{ $t('contracts.only_user_owned_contracts') }}
                    </b-form-checkbox>
                </b-form-group>
            </div>
            <div v-if=" orderFilterOption" class="col-sm-3">
                <!-- Order selection -->
                <b-form-group
                    class="title nopads"
                    label-for="order"
                >
                    <span class="span-title">{{ $t('orders.order') }}</span>
                    <b-form-select
                        id="order"
                        v-model="order"
                        size="sm"
                        class="sm-3"
                        :disabled="!selectedContract && selectedContractOrders.length < 1"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('menu.select_order') }}
                            </option>
                        </template>
                        <option
                            v-for="option in selectedContractOrders"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.task_type.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
        </b-row>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import CompanyFilter from '../company/CompanyFilter'

export default {
    name: 'ContractFilter',
    components: {CompanyFilter},
    mixins: [restApi],
    props: {
        items: {
            type: Array,
            default: function () {
                return []
            }
        },
        contractId: {
            type: Number,
            default: function () {
                return null
            }
        },
        user: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        orderFilterOption: {
            type: Boolean,
            default: false
        },
        noContractorOption: {
            type: Boolean,
            default: false
        },
        showOnlyMyContractsOption: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            company: null,
            customersLoaded: false,
            contractorsLoaded: false,
            customers: [],
            contractors: [],
            contracts: this.items,
            customer: null,
            contractor: null,
            order: null,
            contract: this.contractId,
            onlyUserOwnedContracts: 0
        }
    },

    watch: {
        contract: function () {
            if (this.contract > 0) {
                var contract = this.contracts.find(item => item.id === this.contract)
                this.customer = contract.customer.id
                this.contractor = contract.contractor.id
                this.order = null
                this.$emit('contractSet', contract.id)
                this.$emit('contractNameSet', contract.name)
            } else {
                this.$emit('contractNameSet', '')
                this.$emit('contractSet', null)
            }
        },
        order: function () {
            this.$nextTick(() => {
                this.$emit('orderSet', this.order)
            })
        },
        customer: function () {
            this.$nextTick(() => {
                this.fetchContracts()
                this.$emit('customerSet', this.customer)
            })
        },
        contractor: function () {
            this.$nextTick(() => {
                this.fetchContracts()
                this.$emit('contractorSet', this.contractor)
            })
        },
        company: function () {
            this.$nextTick(() => {
                this.fetchCustomers()
                this.fetchContractors()
                this.$emit('companySet', this.company)
            })
        },
        onlyUserOwnedContracts: function() {
            this.fetchContracts()
        }
    },

    mounted: function () {
        if (!this.isAdmin) {
            this.fetchCustomers()
            this.fetchContractors()
        }
    },

    computed: {
        selectedContract() {
            if(this.contracts && this.contracts.length > 0) {
                let index = null
                index = this.contracts.findIndex(item => item.id === this.contract)
                if(index >= 0) {
                    return this.contracts[index]
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        selectedContractOrders() {
            let selectedContractOrders = []
             this.selectedContract ? this.selectedContract.task_types.forEach(task_type => {
                if(task_type.orders.length > 0) {
                    task_type.orders.forEach(order => {
                        selectedContractOrders.push(order);
                    })

                }
            }) : []
            return selectedContractOrders
        }
    },

    methods: {
        resetData: function () {
            this.customer = null
            this.contractor = null
            this.contract = null
            this.order = null
            this.contracts = []
        },

        fetchCustomers: function () {
            this.customersLoaded = false
            this.loading = true
            let params = {}
            if (this.company && this.company > 0) {
                params.company = this.company
            }
            this.restFetchParams(this.customerUrl, params, this.handleCustomers)
        },

        handleCustomers: function (response) {
            this.customersLoaded = true
            this.loading = !this.customersLoaded && !this.contractorsLoaded
            this.customers = response.data
        },

        fetchContractors: function () {
            this.contractorsLoaded = false
            this.loading = true
            let params = {}
            if (this.company && this.company > 0) {
                params.company = this.company
            }
            this.restFetchParams(this.contractorUrl, params, this.handleContractors)
        },

        handleContractors: function (response) {
            this.contractorsLoaded = true
            this.loading = !this.customersLoaded && !this.contractorsLoaded
            this.contractors = response.data
        },

        fetchContracts: function () {
            this.contracts = []
            let params = {}
            if (this.customer && this.customer > 0) {
                params.customer = this.customer
            }
            if (this.contractor && this.contractor > 0) {
                params.contractor = this.contractor
            }
            if(parseInt(this.onlyUserOwnedContracts) === 1) {
                params.onlyUserOwnedContracts = 1
            }
            if (params.customer || params.contractor) {
                this.loading = true
                this.restFetchParams(this.contractUrl, params, this.handleContracts)
            }
        },

        handleContracts: function (response) {
            this.contracts = response.data
            this.loading = false
        },
        reset: function () {
            this.customer = null
            this.contractor = null
            this.contract = null
            this.order = null
            if (this.isAdmin) {
                this.company = null
            }
        }
    }
}
</script>
