<template>
    <div class="nopads">
        <b-form-radio-group
            id="radio-group-1"
            v-model="taskType"
            name="radio-options"
            class="mb-2"
        >
            <b-form-radio size="sm" value="1">Kokonaishintaiset</b-form-radio>
            <b-form-radio size="sm" value="2">Yksikköhintaiset</b-form-radio>
        </b-form-radio-group>
        <b-row class="nopads">
            <multiselect
                v-model="selectedTask"
                :placeholder="$t('orders.select_task')"
                :deselect-label="$t('common.remove_selection')"
                :select-label="$t('common.select')"
                :close-on-select="true"
                :multiple="false"
                label="selite"
                track-by="selite"
                :searchable="true"
                :internal-search="true"
                :selected-label="$t('common.selected')"
                :options="taskOptions"
            >
                <template
                    slot="singleLabel"
                    slot-scope="props"
                >
                    <b-row class="nopads">
                        <span class="option__title" style="font-weight: 400">
                            {{ props.option.id  }} - {{ props.option.selite  }} ({{ props.option.yksikko }})
                        </span>
                    </b-row>
                </template>

                <template slot="option"
                          slot-scope="props">
                    <b-row class="nopads">
                        <span class="option__title" style="font-weight: 400">
                            {{ props.option.id  }} - {{ props.option.selite  }} ({{ props.option.yksikko }})
                        </span>
                    </b-row>
                </template>
                <span slot="noResult">{{ $t('common.not_found') }}</span>
                <span slot="noOptions">{{ $t('common.no_results') }}</span>
            </multiselect>
        </b-row>
    </div>
</template>

<script>


export default {
    name: 'ExternalTaskSelector',
    props: {
        tasks: {
            type: Object,
            default() {
                return []
            }
        }
    },
    data: function () {
        return {
            taskType: 1,
            selectedTask: null,
            taskOptions: []
        }
    },
    watch: {
        taskType() {
            this.initTaskOptions()
        },
        selectedTask() {
            this.$emit('notifyIsTaskSelected', this.selectedTask != null);
        }
    },
    mounted() {
        this.initTaskOptions()
    },
    methods: {
        initTaskOptions(){
            const items = this.taskType === 1 ? this.tasks.kokonaishintaiset : this.tasks.yksikkohintaiset
            this.taskOptions = []
            items.forEach(task => this.taskOptions.push(task.tehtava))
        },
        getSelectedTask() {
            this.selectedTask.type = this.taskType
            return this.selectedTask
        }
    }
}
</script>
<style>
</style>
