<template>
    <div class="details-container">
        <div class="dynamic_width nopads" style="background-color: #FFFFFF; margin: auto">
            <div class="col-12 p-2" ><h3 class="nopads">{{ $t('common.import_from_file')}}</h3></div>
            <div style="position: relative; height: 40vh; padding: .2em; text-align: left">
                <map-container
                    ref="mapContainer"
                    :find-user="false"
                    :center="mapCenter"
                    @onMapChanged="mapChanged"
                />
            </div>
            <div v-if="importedStations && importedStations.length > 0" class="col-12">
                <b-table
                    small
                    :items="importedStations"
                    :fields="fields"
                >
                    <template v-slot:cell(created_at)="row">
                        {{ getDateString(row.value) }}
                    </template>
                    <template v-slot:cell(user)="row">
                        {{ row.value ? row.value.last_name + ' ' + row.value.first_name : '' }}
                    </template>

                </b-table>
            </div>
            <div class="col-sm-12 p-1 pt-3">
                <b-form-group
                    class="title"
                >
                    <b-form-file
                        v-model="importFile"
                        :accept="'csv'"
                        :placeholder="$t('common.attachment_placeholder')"
                    />
                </b-form-group>
            </div>
            <div class="col-12 p-1 pt-3 pb-3 button-container">
                <b-button
                    size="sm"
                    class="form-button"
                    variant="secondary"
                    :disabled="loading"
                    @click.stop="$emit('close')">
                    {{ $t('common.cancel')}}
                </b-button>
                <b-button
                    size="sm"
                    class="form-button"
                    variant="primary"
                    :disabled="loading || !importFile"
                    @click.stop="submitFile">
                    {{ $t('common.read_file')}}
                </b-button>
                <b-button
                    size="sm"
                    class="form-button"
                    variant="success"
                    :disabled="loading || !importedStations || importedStations.length < 1 "
                    @click.stop="submitStations">
                    {{ $t('common.save')}}
                </b-button>
            </div>


            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from "@/event-bus";
import {mapHelper} from '../mixins/MapMixin'
import MapContainer from "@/components/map/MapContainer";

export default {
    name: 'PowerStationsImporter',
    components: {MapContainer},
    mixins: [restApi, mapHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            loading: false,
            importFile: null,
            importedStations: null,
            mapCenter: null,
            map: null,
            fields: [
                {key: 'location_name', label: this.$t('power_stations.location_name'), sortable: false},
                {key: 'street', label: this.$t('power_stations.street'), sortable: false},
                {key: 'city', label: this.$t('power_stations.city'), sortable: false},
            ]
        }
    },

    mounted() {
        if(this.getMapSettingsIn(['center'])){
            let center = this.getMapSettingsIn(['center'])
            this.mapCenter = {
                y: center.lat,
                x: center.lng
            }
        }
        this.$nextTick(() => {
            this.map = this.$refs.mapContainer.getMap()
        })
    },

    methods: {
        mapChanged(map) {
            this.map = map
            // TODO - refresh map when map is changed
        },
        submitFile() {
            this.hideStations()
            if (this.importFile) {
                let formData = new FormData()
                formData.append('importFile', this.importFile)
                this.restPostWithFile(this.powerStationImportUrl, {}, formData,
                    (response) => {
                        this.loading = false
                        this.importedStations = response
                        this.drawStations()
                    }, this.fail
                )
            }
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        drawStations: function () {
            this.importedStations.forEach(function (station, idx) {
                this.drawStation(station, idx+1)
            }, this)
            // Center map to first station
            if (this.importedStations && this.importedStations.length > 0) {
                this.map.zoomToPosition(this.importedStations[0].position.y, this.importedStations[0].position.x)
            }
        },

        drawStation: function (station, idx) {
            let icon = this.map.getMarkerStore().getPowerStationIcon()
            this.map.showMapMarker(idx, this.POWER_CENTER, station.position.y, station.position.x, icon)
        },

        hideStations: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.POWER_CENTER)
            }
            this.visibleSigns = []
        },
        submitStations() {
            if (this.importedStations && this.importedStations.length > 0) {
                this.restAdd(this.powerStationBulkUrl, this.importedStations,
                    () => {
                        this.loading = false
                        this.importFile = null
                        this.hideStations()
                        EventBus.$emit('show-alert', this.$t('street_lights.station_import_successful'), 'success')
                    }, this.fail
                )
            }
        }
    }
}
</script>
