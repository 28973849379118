<template>
    <!-- Company List -->
    <div class="col-sm-12">
        <div
            v-if="isAdmin"
            class="data-list-item add"
            @click.stop="$emit('add')"
        >
            <div class="item-img-cont add-title">
                <i class="fa fa-plus"/>
            </div>
            <div class="item-text-cont">
        <span class="item-title-add">
          {{ $t('common.add_new') }}
        </span>
                <font-awesome-icon
                    icon="coins"
                    class="card-body-icon"
                />
            </div>
        </div>

        <transition name="fade">
            <div
                v-if="results && results.length"
                id="accordion"
                class="col-sm-12 panel-group data-container"
            >
                <div
                    v-for="result of results"
                    :key="result.id"
                    v-b-toggle="'accordion-'+result.id"
                    class="data-list-item"
                >
                    <div class="col-sm-12 item-header">
                        <div class="item-img-cont">
                            <font-awesome-icon icon="coins"/>
                        </div>
                        <div class="item-text-cont">
              <span class="item-title-single">
                {{ result.name }}
              </span>
                        </div>
                    </div>
                    <b-collapse
                        :id="'accordion-'+result.id"
                        class="mt-2 item-details"
                    >
                        <div class="row nopads">
                            <div class="col-sm-6 item-detail-container">
                                <div class="item-detail-title">
                                    {{ $t('companies.name') }}
                                </div>
                                <div class="item-detail-text">
                  <span>
                    {{ result.name }}
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.business_id') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.business_id">
                    {{ result.business_id }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.vat_id') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.vat_id">
                    {{ result.vat_id }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.street') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.address && result.address.street_address">
                    {{ result.address.street_address }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.postal') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.address && result.address.postal">
                    {{ result.address.postal }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.city') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.address && result.address.city">
                    {{ result.address.city }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>
                            </div>
                            <div class="col-sm-6 item-detail-container">
                <span class="title">
                  {{ $t('companies.invoicing_address') }}
                </span>
                                <div class="item-detail-title">
                                    {{ $t('companies.street') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.invoicing_address && result.invoicing_address.street_address">
                    {{ result.invoicing_address.street_address }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.postal') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.invoicing_address && result.invoicing_address.postal">
                    {{ result.invoicing_address.postal }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.city') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.invoicing_address && result.invoicing_address.city">
                    {{ result.invoicing_address.city }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.invoicing_net') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.invoicing_net_operator">
                    {{ result.invoicing_net_operator }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>

                                <div class="item-detail-title">
                                    {{ $t('companies.invoicing_code') }}
                                </div>
                                <div class="item-detail-text">
                  <span v-if="result.invoicing_net_code">
                    {{ result.invoicing_net_code }}
                  </span>
                                    <span v-else>
                    -
                  </span>
                                </div>
                            </div>
                            <div
                                v-if="isAdmin"
                                class="col-sm-12 button-container"
                            >
                                <b-button
                                    variant="primary"
                                    class="routa-button"
                                    @click.stop="$emit('edit', result)"
                                >
                                    <i class="fa fa-edit"/>
                                    <span class="button-text">
                    {{ $t('common.edit') }}
                  </span>
                                </b-button>
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'

export default {
    name: 'CompanyList',
    mixins: [workManagementHelper],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        isAdmin: Boolean
    }
}
</script>
