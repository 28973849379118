<template>
    <div style="user-select: none" v-show="shown">
        <div class="order-text pl-2"  :style="{width: textColWidthPercentage + '%', marginTop: '-2px', marginBottom: '-2px'}">
            <b-row class="col-12 nopads">
                <div class="col-9 nopads single-line">
                    <font-awesome-icon
                        icon="caret-right"
                        class="pointer ml-1"
                        style="outline: none; width: 2.5em; height: 1.1em"
                        :class="{ 'fa-rotate-90': assignmentsVisible }"
                        @click.stop="toggleAssignments"/>
                    <span :id="'o_t'+orderObject.id" class="ml-2 pointer" @click.stop="$emit('showOrder', orderObject)">
                        {{ orderObject.id }} - {{ getOrderAndExecutorStr(orderObject) }}
                    </span>
                </div>
                <div class="col-3 nopads text-right">
                    <order-status-badge
                        :item-id="orderObject.id"
                        :status="orderObject.status"
                        @orderStatusChanged="updateOrderStatus"
                    />
                </div>

            </b-row>
        </div>
            <div style="display: inline-block; user-select: none">
            <div id="timeline-container" style="display: flex;  position: relative">
                <div class="week-item"
                     :style="{width: weekColWidth + 'px'}"
                     v-for="(week, index) in weeks"
                     v-bind:key="index">{{ '-' }}</div>
                <!-- Draggable and resizable time line for order schedule -->
                <time-line-item
                    :item-id="orderObject.id"
                    :time-col-width="weekColWidth"
                    :timeline-start-date="new Date(calendarStartDate)"
                    :calendar-start-week="calendarStartWeek"
                    :calendar-start-date="new Date(calendarStartDate)"
                    :visible-weeks-limit="visibleWeeksLimit"
                    :color="orderObject.contract_task_type.task_type.color"
                    :startDate="orderObject.planned_start ? getDateFromString(orderObject.planned_start) : null"
                    :endDate="orderObject.planned_ready ? getDateFromString(orderObject.planned_ready) : null"
                    @datesChanged="updateOrderDates"/>
            </div>
        </div>
        <b-collapse :id="'accordion-assignments'+this.orderObject.id" v-model="assignmentsVisible">
            <div class="order-text" :style="{width: textColWidthPercentage + '%', borderTop: 'none'}">
                <b-row class="col-12" style="padding-left: 2.2em">
                    {{ $t('calendar.ordered_amount') }}: {{ orderObject.ordered_amount ? orderObject.ordered_amount : 0 }}  {{ orderObject.unit ? getUnitText(orderObject.unit) : null }}
                </b-row>
                <div class="order-info" v-if="orderObject.info">{{ orderObject.info }}</div>
            </div>
            <br>
            <div class="order-text sub-item" :style="{width: textColWidthPercentage + '%', color: '#636364', borderTop: 'none'}">
                <b-row class="nopads">
                    <div class="col-9 nopads" style="font-weight: bold;">
                        {{ $t('menu.work_assignment') }} ({{ assignments.length }})
                    </div>
                    <div class="col-3 nopads text-right pr-2">
                        <b-dropdown
                            class="dropdown-adder"
                            variant="outline-secondary"
                            size="sm"
                        >
                            <b-dropdown-item size="sm" @click.stop="emitAddAssignment">{{ $t('calendar.add_work_assignment_order') }}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </b-row>

            </div>
            <div style="display: inline-block; user-select: none">
                <div id="timeline-container" style="display: flex;  position: relative">
                    <div class="fill-item"
                         :style="{width: weekColWidth + 'px'}"
                         v-for="(week, index) in weeks"
                         v-bind:key="index">{{ '-' }}</div>
                </div>
            </div>
            <!-- Assignments -->
            <assignment-calendar-item
                v-for="assignment in assignments"
                v-bind:key="assignment.id"
                :assignment="assignment"
                :time-col-width="weekColWidth"
                :visible-weeks-limit="visibleWeeksLimit"
                :calendar-start-week="calendarStartWeek"
                :calendar-start-date="calendarStartDate"
                :text-col-width-percentage="textColWidthPercentage"
                :assignments-selected-limiter="assignmentFilter"
                @showAssignment="emitShowAssignment"/>
        </b-collapse>
    </div>
</template>


<script>
import {timeUtils} from "@/components/mixins/TimeUtils";
import {restApi} from "@/components/mixins/RestApiMixin";
import TimeLineItem from "@/components/contractcalendar/TimeLineItem";
import {orderMixin} from "@/components/mixins/OrderMixin";
import OrderStatusBadge from "@/components/contractcalendar/OrderStatusBadge";
import {workAssignmentHelper} from "@/components/mixins/WorkAssignmentMixin";
import {workManagementHelper} from '@/components/mixins/WorkManagementMixin'
import AssignmentCalendarItem from "@/components/contractcalendar/AssignmentCalendarItem";
export default {
    name: 'OrderCalendarItem',
    components: {AssignmentCalendarItem, OrderStatusBadge, TimeLineItem},
    mixins: [timeUtils, restApi, orderMixin, workAssignmentHelper, workManagementHelper],
    data: function () {
        return {
            start: null,
            assignmentsVisible: false,
            loading: 0,
            orderObject: null,
            weeks: [],
            shown: false,
        }
    },
    props: {
        order: {
            type: Object,
            default: null
        },
        assignments: {
            type: Array,
            default() {
                return []
            }
        },
        textColWidthPercentage: {
            type: Number,
            default: 40
        },
        assignmentFilter: {
            type: Array,
            default: null
        },
        selectedLimiter: {
            type: Array,
            default: null
        },
        timeColWidth: {
            type: Number,
            default: 100  // px
        },
        showAll: {
            type: Boolean,
            default: false
        },
        visibleWeeksLimit: {
            type: Number,
            default: 20
        },
        calendarStartWeek: {
            type: Number,
            default: 20
        },
        calendarStartDate: {
            type: Date,
            default: null
        }
    },
    computed: {
        status() {
            return status
        },
        weekColWidth() {
            return Math.round(this.timeColWidth / 7) * 7
        }
    },
    watch: {
        calendarStartWeek: function () {
            this.initTable()
        },
        order: function () {
            this.orderObject = this.order
        },
        selectedLimiter: function () {
            this.toggleShown()
        },
    },
    created: function () {
        this.orderObject = this.order
        this.initTable();
        if(this.showAll) {
            this.toggleAssignments()
        }
        this.toggleShown()
    },
    methods: {
        initTable() {
            this.weeks = []
            let startWeek = this.calendarStartWeek
            for (let i = 0; i < this.visibleWeeksLimit; i++) {
                this.weeks.push(this.$t('calendar.wk_prefix') + (startWeek+i))
            }
        },
        toggleShown(){
            if (this.selectedLimiter === null || this.selectedLimiter.length === 0) this.shown = true
            else {
                for (let i = 0; i < this.selectedLimiter.length; i++) {
                    this.selectedLimiter.includes(this.orderObject.status) ? this.shown = true : this.shown = false
                }
            }
        },
        toggleAssignments() {
            this.assignmentsVisible = !this.assignmentsVisible
        },
        updateOrderDates(orderId, startDate, endDate) {
            const orderStartDate = startDate.toISOString()
            const orderEndDate = endDate.toISOString()
            this.orderObject.planned_start = orderStartDate
            this.orderObject.planned_ready = orderEndDate
            this.restUpdate(this.orderDateUrl, {id: orderId, planned_start: orderStartDate, planned_ready: orderEndDate},
                this.orderUpdated, this.orderUpdateFailed)
        },
        updateOrderStatus(orderId, status) {
            this.orderObject.status = status
            this.restUpdate(this.orderStatusUrl, {id: orderId, status: status}, this.orderUpdated, this.orderUpdateFailed)
            this.toggleShown()
        },
        orderUpdated() {
            // TODO - Indicate success??
        },
        orderUpdateFailed() {
            // TODO - Show error message
        },
        emitShowAssignment(assignment) {
            this.$emit('showAssignment', assignment)
        },
        emitAddAssignment() {
            this.$emit('addAssignment', this.orderObject ? this.orderObject : null)
        }
    }
}
</script>
<style scoped>
.week-item, .order-text, .fill-item {
    display: inline-block;
    background: #ffffff;
    border-top: 1px #BCBCBC solid;
    border-right: 1px #BCBCBC solid;
    margin: 0;
    padding: .1em;
    font-size: .9em;
    user-select: none;
    min-height: 2em;
}
.order-text {
    color: #404041;
    padding-left: 2.5em;
}
.sub-item {
    padding-left: 5em;
    color: #636364;
    font-size: .9em;
    font-weight: bold;
}
.week-item, .fill-item {
    color: #FFFFFF;
    user-select: none;
    padding: .1em;
}
.fill-item {
    border: none;
}
.single-line {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.order-text:hover, .single-line:hover {
    color: #007BFF;
    font-weight: bold;
}
.dropdown-adder {
    padding-top: 0;
    padding-bottom: 0;
    max-height: 1.4em;
}
.btn-sm {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.order-info {
    font-size: .9em;
    padding-left: 2.7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
