<template>
    <table class="table table-sm table-fixed">
        <tbody>
        <tr>
            <td class="item-detail-title">
                {{ $t('ins_report_details.owner') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.company.name">{{ vehicle.company.name }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_list.make') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.make">{{ vehicle.make }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_list.model') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.vehicle_model">{{ vehicle.vehicle_model }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('vehicle_list.type') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.vehicle_type">{{ getVehicleTypeName(vehicle.vehicle_type) }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        <tr>
            <td class="item-detail-title">
                {{ $t('ins_report.list_label.license_plate') }}
            </td>
            <td class="item-detail-text">
                <span v-if="vehicle.license_plate">{{ vehicle.license_plate }}</span>
                <span v-else>-</span>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import {vehicleHelper} from '../mixins/VehicleMixin'

export default {
    name: 'TargetInfoVehicles',
    mixins: [vehicleHelper],
    props: {
        vehicle: {
            type: Object,
            default: null
        }
    }
}
</script>
