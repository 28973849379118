<template>
    <div class="col-12 nopads">
        <transition name="fade">
            <fence-editor
                v-if="selectedFence != null"
                style="pointer-events: all"
                :item="selectedFence"
                :selected-contract="selectedContract"
                @close="selectedFence = null"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '@/components/mixins/TimeUtils'
import {mapHelper} from '@/components/mixins/MapMixin'
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {restApi} from '../mixins/RestApiMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import FenceEditor from "@/components/velho/FenceEditor";

export default {
    name: 'FenceMap',
    components: {FenceEditor},
    mixins: [timeUtils, mapHelper, restApi, geometryMixin, stormSewersMixin, roadSignHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        filters: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContract: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: 0,
            fences: [],
            bbox: null,
            visibilityZoomLevel: 11,
            selectedFence: null
        }
    },

    watch: {
      map() {
        this.$nextTick(function () {
          this.initView()
        })
      },
      boundingBox() {
         this.updateView()
      },
      filters() {
         this.updateView(true)
      },
      selectedContractIds() {
        this.updateView(true)
      }
    },
    beforeDestroy() {
        this.hideAll()
    },
    mounted() {
        this.initView()
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideAll()
            } else if (force || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.005
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchFences()
            }
        },

        closeAndUpdate() {
          this.hideFences()
          this.fetchFences()
        },

        hideFences: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.EXTERNAL_FENCES)
            }
        },

        fetchFences: function () {
            this.selectedFence = null
            let params = {}
            if(this.bbox && this.bbox.length > 0) {
                this.loading++
                params.bbox = this.bbox
                this.restFetchParams(this.extFencesUrl, params, this.handleFences, () => {
                    this.loading--
                })
            } else {
                this.hideFences()
            }
        },

        handleFences: function (response) {
            this.hideFences()
            if (response && response.data) {
                this.fences = response.data
                this.drawFences()
            }
            this.loading--
        },

        drawFences: function () {
            this.fences.forEach(function (fence, index) {
                fence.geometry.forEach(item => {
                    var points = item.points.map(point => {
                        return {x: point[0], y: point[1]}
                    })
                    this.map.drawPolyLine(index, this.EXTERNAL_FENCES, points, fence.status === 10 ? '#c50505' : '#7d8d5e', false, false, 3, 1, false, false, fence.external_system_id)
                })
            }, this)
        },

        hideAll() {
            this.hideFences()
        },

        editFence(id) {
            this.fetchFence(id)
        },

        fetchFence(id) {
            if (this.loading < 1) {
                this.loading++
                this.restFetch(this.extFencesUrl + '/' + id, this.handleFence)
            }
        },

        handleFence(response) {
            this.loading--
            if (response && response.data) {
                // Set Velho oid as contract id
                if (this.selectedContractId) {
                    response.data.contract = {
                        id: this.selectedContractId
                    }
                }
                this.selectedFence = response.data
            }
        },
    }
}
</script>
