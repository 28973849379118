<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                class="no-margin"
                @submit.prevent="onSubmit"
                @reset.prevent="onReset"
            >
                <observation-filter
                    ref="obsFilter"
                    :is-admin="isAdmin"
                    @companySet="setCompany"
                    @observationGroupSet="setObservationGroup"
                    @observationTypeSet="setObservationType"
                />
                <b-row class="nopads">
                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('ins_report.from_date') }}</span>
                        <datetime
                            v-model="fromDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                        />
                    </div>
                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('ins_report.to_date') }}</span>
                        <datetime
                            v-model="toDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('observation_list.status') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="status"
                        >
                            <b-form-select
                                id="status"
                                v-model="statusGroup"
                                :options="statusGroups"
                                size="sm"
                                class="no-margin"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-6">
                        <span class="search-title">{{ $t('observation_list.reporter') }}</span>
                        <multiselect
                            v-model="reporter"
                            class="multiselect_routa"
                            :placeholder="$t('work_time.select_person')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="true"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="reporters"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                <span class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </span>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <div class="option__desc">
                                    <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </div>
                </b-row>
                <b-row class="nopads pl-1 pr-1">
                    <div class="col-sm-3 pr-1">
                        <span class="span-title">{{ $t('contracts.contract_collection.title') }}</span>
                        <b-form-select
                            v-model="contractCollection"
                            size="sm"
                        >
                            <option :value="null">
                                {{ $t('contracts.select_contract_collection') }}
                            </option>
                            <option
                                v-for="option in contractCollectionOptions"
                                :key="option.id"
                                :value="option"
                            >
                                {{ option.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-3 pl-1 pr-1" style="margin-top: -4px">
                        <open-contract-filter
                            :to="toDate"
                            :from="fromDate"
                            :contract-collection="contractCollection"
                            :show-company="false"
                            :showOnlyMyContractsOption="true"
                            :show-title="true"
                            @loading="loading=true"
                            @loadingCompleted="loading=false"
                            @contractSelected="setContract"
                            @contractsFetched="setContractCollections"
                        />
                    </div>

                    <div class="col-sm-3">
                        <contract-order-multiselect
                            v-model="order"
                            :contractIds="contractIds"
                            :single-mode="true"
                            :show-executor-text="true"/>
                    </div>

                    <!-- Contractor -->
                    <div class="col-sm-3">
                        <span class="span-title">{{ $t('trip_list.search_with_company') }}</span>
                        <multiselect
                            v-model="reportingCompany"
                            class="multiselect_routa"
                            :placeholder="$t('trip_list.search_select_contract_first')"
                            :deselect-label="$t('common.remove_selection')"
                            :select-label="$t('common.select')"
                            :close-on-select="true"
                            :disabled="companyOptions.length < 0"
                            label="name"
                            track-by="name"
                            :searchable="true"
                            :internal-search="true"
                            :selected-label="$t('common.selected')"
                            :options="companyOptions"
                        >
                            <template
                                slot="singleLabel"
                                slot-scope="props"
                            >
                                <span class="option__desc">
                                  <span class="option__title">{{ props.option.name }}</span>
                                </span>
                            </template>
                            <template
                                slot="option"
                                slot-scope="props"
                            >
                                <div class="option__desc">
                                    <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                                </div>
                            </template>
                            <span slot="noResult">{{ $t('common.not_found') }}</span>
                            <span slot="noOptions">{{ $t('common.no_results') }}</span>
                        </multiselect>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-3">
                        <span class="search-title">{{ $t('observation_list.city') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="city"
                        >
                            <b-form-input
                                id="city"
                                v-model="city"
                                size="sm"
                                class="no-margin"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-3">
                        <span class="search-title">{{ $t('observation_list.street_name') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="streetName"
                        >
                            <b-form-input
                                id="streetName"
                                v-model="streetName"
                                size="sm"
                                class="no-margin"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-3">
                        <span class="search-title">{{ $t('observation_list.road_number') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="roadNumber"
                        >
                            <b-form-input
                                id="roadNumber"
                                v-model="roadNumber"
                                type="number"
                                size="sm"
                                class="no-margin"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-3">
                        <span class="search-title">{{ $t('observation_list.road_section_number') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="roadSectionNumber"
                        >
                            <b-form-input
                                id="roadSectionNumber"
                                v-model="roadSectionNumber"
                                type="number"
                                size="sm"
                                class="no-margin"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        size="sm"
                        class="result-button"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        size="sm"
                        class="result-button"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {contractHelper} from '@/components/mixins/ContractMixin';
import ObservationFilter from './ObservationFilter'
import Multiselect from 'vue-multiselect'
import {timeUtils} from '../mixins/TimeUtils'
import ContractOrderMultiselect from "@/components/order/ContractOrderMultiselect.vue";
import OpenContractFilter from "@/components/contract/OpenContractFilter";

export default {
    name: 'ObservationList',
    components: {
        OpenContractFilter,
        ContractOrderMultiselect,
        ObservationFilter, Multiselect},
    mixins: [restApi, timeUtils, contractHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            statusGroups: [
                {value: null, text: this.$t('observations.search_all')},
                {value: 1, text: this.$t('observations.open')},
                {value: 2, text: this.$t('observations.closed')}
            ],
            statusGroup: 1,
            roadNumber: null,
            roadSectionNumber: null,
            streetName: null,
            city: null,
            fromDate: '',
            toDate: '',
            contract: null,
            contractIds: [],
            contractCollection: null,
            order: null,
            orders: [],
            observationGroup: null,
            observationType: null,
            reporter: null,
            company: null,
            reportingCompany: null,
            companyOptions: [],
            contractCollectionOptions: [],
            reporters: [],
            companyPlaceholder: ''
        }
    },
    mounted: function () {
        if (!this.isAdmin) {
            this.fetchReporter()
        }
        if (!this.fromDate) {
            this.fromDate = this.getMonthStart()
        }
        this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
    },

    methods: {
        onReset: function () {
            this.statusGroup = null
            this.roadNumber = null
            this.roadSectionNumber = null
            this.streetName = null
            this.city = null
            this.toDate = ''
            this.contract = null
            this.contractCollection = null
            this.order = null
            this.customer = null
            this.contractor = null
            this.company = null
            this.reportingCompany = null
            this.observationGroup = null
            this.observationType = null
            this.reporter = null
            if (this.isAdmin) {
                this.reporters = []
            }
            this.$refs.obsFilter.resetData()
            this.$refs.contractFilter.resetData()
        },
        onSubmit: function () {
            var params = {}
            if (this.statusGroup) {
                params.status = this.statusGroup
            }
            if (this.roadNumber) {
                params.roadNumber = this.roadNumber
            }
            if (this.roadSectionNumber) {
                params.roadSectionNumber = this.roadSectionNumber
            }
            if (this.streetName) {
                params.streetName = this.streetName
            }
            if (this.fromDate) {
                params.from = this.fromDate
            }
            if (this.toDate) {
                params.to = this.toDate
            }
            if (this.customer > 0) {
                params.customer = this.customer
            }
            if (this.contractor > 0) {
                params.contractor = this.contractor
            }
            if (this.contract) {
                params.contract = this.contract.id
            }
            if(this.order) {
                params.order = this.order.id
            }
            if (this.observationGroup !== null) {
                params.observationGroup = this.observationGroup
            }
            if (this.observationType !== null) {
                params.observationType = this.observationType
            }
            if (this.reportingCompany) {
                params.reportingCompany = this.reportingCompany.value
            }
            if (this.reporter) {
                params.reporter = this.reporter.value
            }
            if (this.city) {
                params.city = this.city
            }
            this.$emit('search', params)
        },

        setContract: function (contract) {
            this.contract = contract
            this.updateContractIds()
            this.getContractCompanies()
        },
        updateContractIds() {
            this.contractIds = []
            if (this.contract) {
                this.contractIds.push(this.contract.id)
            }
        },

        getContractCompanies: function () {
            this.companyOptions = []
            this.companyParam = null
            this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
            if (this.contractIds && this.contractIds.length > 0) {
                this.restFetchParams(this.contractCompanyUrl, {contractIds: this.contractIds}, this.handleContractCompanies)
            }
        },

        handleContractCompanies: function (response) {
            if (response && response.data) {
                response.data.forEach(company => {
                    this.companyOptions.push({name: company.name, value: company.id})
                })
                this.companyPlaceholder = this.$t('users.select_company')
            }
            this.loading = false
        },


        setCompany: function (company) {
            this.company = company
            this.reporters = []
            if (this.company !== null) {
                this.fetchReporter()
            }
        },

        setObservationGroup: function (obsGroup) {
            this.observationGroup = obsGroup
        },

        setObservationType: function (obsType) {
            this.observationType = obsType
        },

        fetchReporter: function () {
            this.loading = true
            this.restFetchParams(this.reporterUrl, {company: this.company}, this.handleReporters)
        },

        handleReporters: function (response) {
            this.loading = false
            if (response && response.data) {
                response.data.forEach(function (user) {
                    this.reporters.push({
                        value: user.id,
                        name: user.last_name + ', ' + user.first_name,
                        company: user.company ? user.company.name : '',
                        email: user.email
                    })
                }, this)
            }
        }

    }
}
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
