<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="vehicle-list"
        class="col-sm-12 pl-3 pr-3 pb-4"
    >
        <div class="col-12 pt-3 text-right">
            <b-button
                variant="success"
                size="sm"
                class="form-button"
                @click.stop="addNew()"
            >
                {{ $t('common.add_new') }}
            </b-button>
        </div>
        <div
            class="col-sm-12 list-content result-table"
        >
            <b-table
                id="resultTable"
                ref="resultTable"
                :small="true"
                :responsive="true"
                :items="results"
                :fields="fields"
                @row-clicked="editItem"
            >
                <template v-slot:cell(company)="row">
                    {{ row.item.company.name }}
                </template>
                <template v-slot:cell(external_system)="row">
                    Velho
                </template>
                <template v-slot:cell(inactive)="row">
                    {{ row.item.inactive ? $t('common.no') : $t('common.yes') }}

                </template>
            </b-table>
        </div>
        <b-modal
            hide-footer
            hide-header
            body-class="nopads"
            class="no-padding"
            ref="editor">
            <external-contract-id-editor
                :record="this.selectedItem"
                @close="closeModal"
                @closeAndUpdate="fetchData"/>
        </b-modal>
    </div>
</template>

<script>
import {restApi} from "@/components/mixins/RestApiMixin";
import ExternalContractIdEditor from "@/components/contract/external/ExternalContractIdEditor";

export default {
    name: 'ExternalContractIds',
    components: {ExternalContractIdEditor},
    mixins: [restApi],
    data() {
        return {
            results: [],
            fields: [
                {key: 'id', label: 'Id', sortable: true},
                {key: 'company', label: this.$t('users.company'), sortable: true},
                {key: 'external_name', label: this.$t('contracts.name'), sortable: true},
                {key: 'external_id', label: this.$t('contracts.number'), sortable: true},
                {key: 'external_system', label: this.$t('contracts.customer'), sortable: true},
                {key: 'inactive', label: this.$t('common.in_use'), sortable: true},
            ],
            loading: false,
            selectedItem: null
        }
    },
    mounted: function () {
        this.fetchData()
    },
    watch: {
    },
    methods: {
        fetchData() {
            this.loading = true
            this.closeModal()
            this.restFetch(this.extContractIdUrl, this.handleResponse)
        },
        handleResponse(response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
            }
        },
        editItem(item) {
            this.selectedItem = item
            this.$refs.editor.show()
        },
        addNew() {
            this.selectedItem = {
                id: -1,
                company: null,
                external_id: null,
                external_system: 1,
                external_name: null,
                inactive: false
            }
            this.$refs.editor.show()
        },
        closeModal() {
            this.selectedItem = null
            this.$refs.editor.hide()
        }
    }
}
</script>
<style scoped>
.no-padding .modal-content,
.no-padding .modal-dialog,
.no-padding .modal-content .modal-body {
    padding: 0 !important;
    margin: 0 !important;
}
</style>
