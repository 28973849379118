<template>
    <div class="editor-form">
        <div class="col-sm-12 form-title editor-title">
            <h2
                class="form-title editor-title"
            >
                <span v-if="vehicle.id > 0" class="nopads">
                    {{ vehicle.license_plate }} - {{ vehicle.make }} {{ vehicle.vehicle_model }}
                </span>
                <span v-else>
                    {{ $t('vehicle.add')}}
                </span>
            </h2>
        </div>
        <b-tabs  v-model="tabIndex" fill>
            <!--vehicle editor-->
            <b-tab :title="$t('contracts.basic_info')" active :title-link-class="linkTabClass(0)">
                <template #title>
                    <b-row class="nopads text-center">
                        <div class="col-2 nopads" >
                        </div>
                        <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 0}">
                            {{ $t('contracts.basic_info') }}
                        </div>
                    </b-row>
                </template>
                <vehicle-statistic
                    v-if="vehicle.id > 0"
                    :vehicle="vehicle"
                />
                <hr class="nopads ml-2 mr-2"/>
                <vehicle-editor
                    :vehicle="vehicle"
                    :close-when-update="true"
                    @close="$emit('close')"
                    @added="$emit('closeAndUpdate')"
                />
            </b-tab>
            <!--vehicle inspections-->
            <b-tab :title="$t('contracts.basic_info')" :title-link-class="linkTabClass(1)" :disabled="vehicle.id < 1">
                <template #title>
                    <b-row class="nopads text-center">
                        <div class="col-2 nopads" >
                        </div>
                        <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 1}">
                            {{ $t('menu.inspections') }}
                        </div>
                    </b-row>
                </template>
                <vehicle-inspections
                    :vehicle="vehicle"/>
            </b-tab>
            <!--vehicle defects-->
            <b-tab :title="$t('contracts.basic_info')" :title-link-class="linkTabClass(2)" :disabled="vehicle.id < 1">
                <template #title>
                    <b-row class="nopads text-center">
                        <div class="col-2 nopads" >
                            <b-tooltip target="basicTitle" triggers="hover" placement="right">
                                {{ $t('contracts.warning_task_types') }}
                            </b-tooltip>
                        </div>
                        <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 2}">
                            {{ $t('menu.fleet_defects') }}
                        </div>
                    </b-row>
                </template>
                <vehicle-defect-summary
                    :vehicle-id="vehicle.id"
                    :defect-data="defectData"
                    />

                <vehicle-defects
                    :vehicle="vehicle"
                    @search-results="setDefectData"
                />
            </b-tab>
            <!--vehicle trips-->
            <b-tab :title="$t('contracts.basic_info')" :title-link-class="linkTabClass(3)" :disabled="vehicle.id < 1">
                <template #title>
                    <b-row class="nopads text-center !important">
                        <div class="col-2 nopads" >
                        </div>
                        <div class="col-10 tab-title-text" :class="{'tab-title-text-active' : tabIndex === 3}">
                            {{ $t('menu.fleet_trips') }}
                        </div>
                    </b-row>
                </template>
                    <trips
                        :vehicle-id="vehicle.id"
                    />
            </b-tab>
        </b-tabs>
    </div>
</template>


<script>
import {vehicleHelper} from '../../mixins/VehicleMixin'
import {restApi} from '../../mixins/RestApiMixin'
import Trips from "@/components/trip/Trips.vue";
import VehicleDefects from "@/components/vehicle/defect/VehicleDefects.vue";
import VehicleEditor from "@/components/vehicle/VehicleEditor.vue";
import VehicleStatistic from "@/components/vehicle/statistic/VehicleStatistic.vue";
import VehicleInspections from "@/components/vehicle/tabbed/VehicleInspections";
import vehicleDefectSummary from "@/components/vehicle/defect/VehicleDefectSummary.vue";

export default {
    name: 'VehicleTabbed',
    components: {
        VehicleInspections,
        VehicleStatistic,
        VehicleEditor,
        VehicleDefects,
        vehicleDefectSummary,
        Trips,
    },
    mixins: [vehicleHelper, restApi],
    props: {
        isAdmin: Boolean,
        vehicle: {
            type: Object,
            default: null
        },
        editMode: Boolean,
        isObserver: Boolean,
    },
    data: function () {
        return {
            tabIndex: 1,
            defect: null,
            lastSearchedParams: null,
            inspection: {},
            defects: [],
            vehicleID: -1,
            newVehicleUi: true,
            defectData: null,
        }
    },

    methods: {

        setDefectData: function (data) {
            this.defectData = data;
        },

        fetchDefects: function () {
            this.loading = true
            this.lastSearchedParams = {vehicle: this.vehicle.id}
            this.results = []
            let url = this.vehicleDefectUrl
            this.restFetchParams(url, this.lastSearchedParams, this.handleDefectResponse)
        },

        handleDefectResponse: function (response) {
            this.defects = response.data
            this.loading = false
        },

        closeAndUpdate: function () {
            this.showDefectList()
            this.fetchDefects(this.lastSearchedParams)
        },

        linkTabClass(index) {
            if (this.tabIndex === index) {
                return ['tab-title', 'tab-title-active']
            } else {
                return ['tab-title']
            }
        },

    }

}
</script>
