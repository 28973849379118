
export const logModes = {
    RESETTLE: 1,
    WORK: 3
}

export const vehicleTypes = {
    CAR: 1,
    TRUCK: 2,
    TRAILER: 3,
    SMALL_TRAILER: 4,
    BOBCAT: 5,
    TRACTOR: 6,
    SPREADER: 7,
    HARVESTER: 8,
    FORWARDER: 9,
    OTHER: 10,
    SNOWCAT: 11,
    HELICOPTER: 12,
    PAVER: 13,
    ROAD_FENCE: 14,
    SHOCK_ABSORBER: 15,
    DIGGER: 16
}

export const vehicleFuel = {
    UNKNOWN: 0,
    FUEL_95E10: 1,
    FUEL_98E: 2,
    DIESEL: 3,
    GAS_CBG: 4,
    GAS_CNG: 5,
    GAS_LNG: 6,
    HYBRID: 7,
    ELECTRIC: 8,
    OTHER: 9
}

export const vehicleHelper = {
    methods: {
        newVehicle: function () {
            return {
                id: -1,
                make: '',
                vehicle_model: '',
                vehicle_type: '',
                license_plate: '',
                vin: '',
                displacement: '',
                last_inspection: '',
                next_inspection: '',
                in_use: true
            }
        },

        getVehicleMarker: function (type, fresh, markerStore) {
            switch (type) {
                case vehicleTypes.CAR:
                    if (fresh) {
                        return markerStore.getActiveCarIcon()
                    }
                    return markerStore.getInactiveCarIcon()
                case vehicleTypes.TRUCK:
                    if (fresh) {
                        return markerStore.getActiveTruckIcon()
                    }
                    return markerStore.getInactiveTruckIcon()
                case vehicleTypes.TRAILER:
                case vehicleTypes.SMALL_TRAILER:
                    if (fresh) {
                        return markerStore.getActiveTrailerIcon()
                    }
                    return markerStore.getInactiveTrailerIcon()
                case vehicleTypes.TRACTOR:
                    if (fresh) {
                        return markerStore.getActiveTractorIcon()
                    }
                    return markerStore.getInactiveTractorIcon()
                case vehicleTypes.BOBCAT:
                case vehicleTypes.HARVESTER:
                case vehicleTypes.FORWARDER:
                    if (fresh) {
                        return markerStore.getActiveMonsterIcon()
                    }
                    return markerStore.getInactiveMonsterIcon()
                case vehicleTypes.SNOWCAT:
                    if (fresh) {
                        return markerStore.getActiveSnowplowIcon()
                    }
                    return markerStore.getInactiveSnowplowIcon()
                case vehicleTypes.HELICOPTER:
                    if (fresh) {
                        return markerStore.getActiveHelicopterIcon()
                    }
                    return markerStore.getInactiveHelicopterIcon()
                case vehicleTypes.PAVER:
                    if (fresh) {
                        return markerStore.getActivePaverIcon()
                    }
                    return markerStore.getInactivePaverIcon()
                case vehicleTypes.ROAD_FENCE:
                    return markerStore.getFenceIcon()
                case vehicleTypes.SHOCK_ABSORBER:
                    return markerStore.getAbsorberIcon()
                case vehicleTypes.DIGGER:
                    if (fresh) {
                        return markerStore.getActiveDiggerIcon()
                    }
                    return markerStore.getInactiveDiggerIcon()

            }

            if (fresh) {
                return markerStore.getActiveDefaultIcon()
            }
            return markerStore.getInactiveDefaultIcon()

        },

        jsonToVehicle: function (vehicle) {
            return JSON.parse(JSON.stringify(vehicle)) // deep cloning of the vehicle object
        },

        getVehicleTypeName: function (vehicleType) {
            switch (vehicleType) {
                case 1:
                    return this.$t('vehicle_list.vehicle_type.passenger_or_van')
                case 2:
                    return this.$t('vehicle_list.vehicle_type.truck')
                case 3:
                    return this.$t('vehicle_list.vehicle_type.trailer')
                case 4:
                    return this.$t('vehicle_list.vehicle_type.trailer1')
                case 5:
                    return this.$t('vehicle_list.vehicle_type.loader')
                case 6:
                    return this.$t('vehicle_list.vehicle_type.tractor')
                case 7:
                    return this.$t('vehicle_list.vehicle_type.spreader')
                case 8:
                    return this.$t('vehicle_list.vehicle_type.harvester')
                case 9:
                    return this.$t('vehicle_list.vehicle_type.forwarder')
                case 11:
                    return this.$t('vehicle_list.vehicle_type.snowplow')
                case 12:
                    return this.$t('vehicle_list.vehicle_type.helicopter')
                case 13:
                    return this.$t('vehicle_list.vehicle_type.paver')
                case 14:
                    return this.$t('vehicle_list.vehicle_type.road_fence')
                case 15:
                    return this.$t('vehicle_list.vehicle_type.shock_absorber')
                case 16:
                    return this.$t('vehicle_list.vehicle_type.digger')
            }
            return this.$t('vehicle_list.vehicle_type.other')
        },

        getFuelTypeName: function (fuelType) {
            switch (fuelType) {
                case vehicleFuel.DIESEL:
                    return this.$t('vehicle_list.fuel_type.diesel')
                case vehicleFuel.FUEL_95E10:
                    return this.$t('vehicle_list.fuel_type.fuel_95E10')
                case vehicleFuel.FUEL_98E:
                    return this.$t('vehicle_list.fuel_type.fuel_98E')
                case vehicleFuel.GAS_CBG:
                    return this.$t('vehicle_list.fuel_type.gas_cbg')
                case vehicleFuel.GAS_CNG:
                    return this.$t('vehicle_list.fuel_type.gas_cng')
                case vehicleFuel.GAS_LNG:
                    return this.$t('vehicle_list.fuel_type.gas_lng')
                case vehicleFuel.HYBRID:
                    return this.$t('vehicle_list.fuel_type.hybrid')
                case vehicleFuel.ELECTRIC:
                    return this.$t('vehicle_list.fuel_type.electric')
                case vehicleFuel.OTHER:
                    return this.$t('vehicle_list.fuel_type.other')
            }
            return this.$t('vehicle_list.fuel_type.unknown')
        },


        getTripModeString: function (mode) {
            switch (mode) {
                case logModes.WORK:
                    return this.$t('trip.mode_work')
                case logModes.RESETTLE:
                    return this.$t('trip.mode_resettle')
            }
            return this.$t('common.unknown')
        },

        newDefect: function () {
            return {
                id: -1,
                vehicle: {
                    id: -1
                },
                reporter: {
                    id: -1
                },
                inspectable_name: '',
                description: '',
                status: -1,
                created_date: '',
                fixed_date: '',
                fixer: {
                    id: -1
                }
            }
        },
        getIcon: function (type) {
            switch (type) {
                case 1:
                    return "car-side"
                case 2:
                    return "truck-moving"
                case 3:
                case 4:
                    return "trailer"
                case 6:
                    return "tractor"
                case 5:
                case 7:
                case 8:
                case 9:
                    return "truck-monster"
                case 11:
                case 16:
                case 13:
                    return "snowplow"
                case 12:
                    return "helicopter"
                case 14:
                    return "ruler-horizontal"
                case 15:
                    return "dolly-flatbed"
            }
            return "cogs"
        },
        getAverageSpeed: function (distanceKm, durationMin) {
            if (durationMin === 0) {
                return 0;
            }
            let durationHr = durationMin / 60
            let avgSpeedKmh = distanceKm / durationHr
            return avgSpeedKmh.toFixed(1)
        },
        calculateSpeed(x1, y1, x2, y2, time1, time2) {
            // Calculate distance between consecutive points using Euclidean distance formula
            let dx = x2 - x1;
            let dy = y2 - y1;
            let distance = Math.sqrt(dx * dx + dy * dy);

            // Calculate time difference between consecutive timestamps
            let timeDifference = (new Date(time2)).getTime() - (new Date(time1)).getTime(); // Convert timestamps to milliseconds

            // Calculate speed (distance / time) and convert to km/h
            let speed = (distance / timeDifference) * 3600 * 100000; // Convert to km/h
            return speed;
        },
    }
}
