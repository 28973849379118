<template>
    <div class="map-item-filter">
        <div
            class="col-sm-12 nopads vehicle-list"
        >
            <span class="vehicle-list-title" @click.stop="toggleList">
              {{ $t('map_tooltips.infra_data') }}
            </span>
            <div class="vehicle-list-caret" :class="{ caret_open: listOpen }" @click.stop="toggleList">
                <font-awesome-icon icon="caret-down"/>
            </div>
            <div
                class="col-sm-12 nopads map-accordion-content"
                v-bind:class="{ 'map-accordion-open': listOpen }"
            >
                <div v-bind:class="{ 'm-2': listOpen }">
                    <div
                        class="map-select-item pt-0"
                    >
                        <div class="row nopads pb-2">
                            <div
                                v-for="option in filters"
                                :key="option.value"
                                class="col-sm-6 nopads p-1">
                                <b-form-checkbox
                                    v-model="selectedTypes"
                                    :value="option.value"
                                    class="text-light"
                                >
                                    {{ option.text }}
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapHelper} from "../mixins/MapMixin";
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: "InfraMapFilter",
    mixins: [restApi, mapHelper],
    data() {
        return {
            isOpen: true,
            listOpen: false,
            allSelected: false,
            multiselectOpen: false,
            selectedTypes: [],
            filters: [],
        };
    },
    watch: {
        selectedTypes(val) {
            this.$emit('typesSelected', val)
        }
    },
    mounted() {
        this.initFilters()
    },
    methods: {
        toggleList: function () {
            this.listOpen = !this.listOpen
        },
        initFilters() {
            this.filters = [
                {
                    text: this.$t("storm_sewers.sewers"),
                    value: this.SEWERS,
                    selected: false
                },
                {
                    text: this.$t("storm_sewers.drains"),
                    value: this.DRAINS,
                    selected: false
                },
                {
                    text: this.$t("menu.street_lights"),
                    value: this.STREET_LIGHT,
                    selected: false
                },
                {
                    text: this.$t("menu.traffic_signs"),
                    value: this.TRAFFIC_SIGN,
                    selected: false
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.multiselect-expand {
    background: #FAA633;
    height: 300px;
}
</style>
