<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                class="no-margin"
                @submit.prevent="onSubmit"
                @reset.prevent="onReset"
            >
                <b-row class="nopads">
                    <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">
                          {{ $t('ins_report.from_date') }}
                        </span>
                        <datetime
                            v-model="fromDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>

                    <div class="col-sm-6 pl-2 pr-2">
                        <span class="span-title">
                          {{ $t('ins_report.to_date') }}
                        </span>
                        <datetime
                            v-model="toDate"
                            type="datetime"
                            format="dd.MM.yyyy HH:mm"
                            class="routa-input"
                            :placeholder="$t('observations_videos.search_input_format')"
                        />
                    </div>
                </b-row>
                <b-row class="nopads pl-1 pr-1">
                    <div class="col-sm-4 pl-1 pr-1" style="margin-top: -4px">
                        <open-contract-filter
                            :from="fromDate"
                            :to="toDate"
                            :contract="contract"
                            :showOnlyMyContractsOption="false"
                            :show-title="true"
                            :show-company="false"
                            @contractSelected="setContract"/>
                    </div>

                    <div class="col-sm-4 pl-1 pr-1">
                        <contract-order-filter
                            v-model="order"
                            :contract-id="contract ? contract.id : undefined"
                            :show-executor-text="true"
                        />
                    </div>
                </b-row>
                <b-row class="nopads">
                    <!-- Search area -->
                    <div class="col-sm-4 pl-2 pr-1">
                        <span class="span-title">{{ $t('trip_list.area') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="searchArea"
                        >
                            <b-form-select
                                id="searchAreaInput"
                                v-model="selectedAreaGroup"
                                :disabled="!areaGroupsLoaded"
                                :options="areaGroups"
                                size="sm"
                                class="no-margin"
                            >
                                <template slot="first">
                                    <option :value="null">
                                        {{ $t('trip_list.search_all') }}
                                    </option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 pl-2 pr-1">
                        <span class="span-title">{{ $t('trip_list.search_with_road_name') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="roadName"
                        >
                            <b-form-input
                                id="roadNameInput"
                                v-model="roadName"
                                type="text"
                                size="sm"
                                class="no-margin"
                                :placeholder="$t('trip_list.search_with_road_name')"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-4 pl-2 pr-1">
                        <span class="span-title">{{ $t('trip_list.search_with_road_num') }}</span>
                        <b-form-group
                            class="title nopads"
                        >
                            <b-form-input
                                id="roadInput"
                                v-model="roadNumber"
                                type="number"
                                size="sm"
                                class="no-margin"
                                :placeholder="$t('trip_list.search_with_road_num')"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-2 pl-2 pr-1">
                        <span class="span-title">{{ $t('trip_list.search_with_road_sec') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="roadSecFrom"
                        >
                            <b-form-input
                                id="roadSecFrom"
                                v-model="roadSecFrom"
                                type="number"
                                size="sm"
                                class="no-margin"
                                :placeholder="$t('trip_list.road_sec_from')"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-2">
                        <span class="span-title">{{ $t('trip_list.search_with_road_sec') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="roadSecTo"
                        >
                            <b-form-input
                                id="roadSecTo"
                                v-model="roadSecTo"
                                type="number"
                                size="sm"
                                class="no-margin"
                                :placeholder="$t('trip_list.road_sec_to')"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {orderMixin} from "../mixins/OrderMixin";
import ContractOrderFilter from "../order/ContractOrderFilter";
import OpenContractFilter from "../contract/OpenContractFilter";

export default {
    name: 'TripSearchMapViewer',
    components: {OpenContractFilter, ContractOrderFilter},
    mixins: [restApi, timeUtils, orderMixin],
    data: function () {
        return {
            areaGroupObjects: [],
            areaGroups: [],
            selectedAreaGroup: null,
            userParam: null,
            companyParam: null,
            fromDate: '',
            toDate: '',
            contract: null,
            customer: null,
            contractor: null,
            order: null,
            roadSecFrom: '',
            roadSecTo: '',
            roadNumber: '',
            roadName: '',
            companyOptions: [],
            orders: [],
            results: [],
            areaGroupsLoaded: false,
            loading: false
        }
    },
    watch: {
        selectedAreaGroup: function () {
            this.$emit('areaGroupSelected', this.getSelectedAreaGroup())
        }
    },
    mounted: function () {
        this.loading = true
        if (!this.fromDate) {
            this.fromDate = this.getMonthStart()
        }
        this.getAreaGroups()
        this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
    },
    methods: {

        getSelectedAreaGroup: function () {
            if (this.selectedAreaGroup) {
                return this.areaGroupObjects.find(item => item.id === this.selectedAreaGroup)
            }
            return null
        },

        setContract: function (contract) {
            this.contract = contract ? contract : null
            this.getContractCompanies()
        },

        onReset: function () {
            this.roadNumber = ''
            this.roadName = ''
            this.roadSecFrom = ''
            this.roadSecTo = ''
            this.contract = null
            this.pricingModel = null
            this.fromDate = ''
            this.toDate = ''
            this.order = null
            this.selectedAreaGroup = null
        },
        isLoading: function () {
            return !this.areaGroupsLoaded
        },

        getContractCompanies: function () {
            this.companyOptions = []
            this.companyParam = null
            this.companyPlaceholder = this.$t('trip_list.search_select_contract_first')
            if (this.contract) {
                this.restFetchParams(this.contractCompanyUrl, {contract: this.contract.id}, this.handleContractCompanies)
            }
        },

        handleContractCompanies: function (response) {
            if (response && response.data) {
                this.companyPlaceholder = this.$t('')
                response.data.forEach(company => {
                    this.companyOptions.push({name: company.name, value: company.id})
                })
                this.companyPlaceholder = this.$t('users.select_company')
            }
            this.loading = false
        },

        getAreaGroups: function () {
            this.loading = true
            this.restFetch(this.areaGroupUrl, this.handleAreaGroupFetch)
        },

        handleAreaGroupFetch: function (response) {
            if (response.data) {
                this.areaGroupObjects = response.data
                response.data.forEach(areaGroup => {
                    this.areaGroups.push({text: areaGroup.name, value: areaGroup.id})
                })
            }
            this.areaGroupsLoaded = true
            this.loading = this.isLoading()
        },

        onSubmit: function () {
            var params = {}
            if (this.selectedAreaGroup) {
                params.area_group = this.selectedAreaGroup
            }
            if (this.roadNumber > 0) {
                params.road_num = this.roadNumber
            }
            if (this.roadName.trim()) {
                params.road_name = this.roadName
            }
            if (this.roadSecFrom > 0) {
                params.road_sec_from = this.roadSecFrom
            }
            if (this.roadSecTo > 0) {
                params.road_sec_to = this.roadSecTo
            }
            if (this.contract) {
                params.contract = this.contract.id
                params.contractor = this.contract.contractor.id
                params.customer = this.contract.customer.id
            }
            if (this.order) {
                params.order = this.order.id
            }
            if (this.fromDate) {
                params.from = this.fromDate
            }
            if (this.toDate) {
                params.to = this.toDate
            }
            if (this.companyParam && this.companyParam.value) {
                params.company = this.companyParam.value
            }
            this.$emit('search', params)
        }
    }
}
</script>

<style scoped>
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
