<template>
    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {logModes} from "../mixins/VehicleMixin";

export default {
    name: 'MapWorkSnapshot',
    mixins: [restApi, timeUtils, mapHelper],
    props: {
        historyRoadOwner: null,
        historyHours: {
            type: Number,
            default: 6
        },
        withoutTimeLimit: {
            type: Boolean,
            default: false
        },
        selectedTaskType: {
            type: Number,
            default: null
        },
        selectedOrder: {
            type: Number,
            default: null
        },
        selectedContractId: {
            type: Number,
            default: null
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
        map: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            results: [],
            polyLines: [],
            timer: null,
            fetching: false,
            fromTime: null
        }
    },
    watch: {
       historyHours() {
           this.updateSnapshot()
       },
       map() {
           this.updateSnapshot()
       },
       historyRoadOwner() {
          this.updateSnapshot()
       },
       withoutTimeLimit() {
          this.updateSnapshot()
       },
       selectedContractId() {
          this.updateSnapshot()
       },
        selectedContractIds() {
            this.updateSnapshot()
        },
       selectedTaskType() {
           this.updateSnapshot()
       },
       selectedOrder() {
           this.updateSnapshot()
       }
    },
    mounted: function () {
        this.updateSnapshot()
    },
    beforeDestroy: function () {
        if (this.timer) {
            clearTimeout(this.timer)
        }
        this.hideSnapshot()
    },

    methods: {
        updateSnapshot() {
            if (!this.withoutTimeLimit || this.selectedContract || (this.selectedContractIds && this.selectedContractIds.length > 0)) {
                this.fromTime = new Date()
                this.fromTime = this.fromTime.setHours(this.fromTime.getHours() - this.historyHours);
                this.fetchRecords()
            }
        },

        fetchRecords: function () {
            if (!this.fetching) {
                this.fetching = true
                this.loading = true
                this.hideSnapshot()
                if (this.timer) {
                    clearTimeout(this.timer)
                } else {
                    this.loading = true
                }
                let from = this.localTimeToUtcTime(this.fromTime)
                let searchParams = {}
                if(!this.withoutTimeLimit) {
                    searchParams.from = from
                }
                if(this.selectedContractIds) {
                    searchParams.contract_id = this.selectedContractIds
                } else if(this.selectedContractId) {
                    searchParams.contract_id = this.selectedContractId
                }
                if (this.historyRoadOwner) {
                    searchParams.owner = this.historyRoadOwner
                }
                if(this.selectedTaskType) {
                    searchParams.task_type = this.selectedTaskType
                }
                if(this.selectedOrder) {
                    searchParams.order = this.selectedOrder
                }
                this.restFetchParams(this.roadWorkRecordsUrl, searchParams, this.handleResponse)
            }
        },

        handleResponse: function (response) {
            this.loading = false
            this.fetching = false
            if (response) {
                this.results = response.data
                this.showSnapshot()
            }
            this.timer = setTimeout(this.fetchVehicles, 10 * 60 * 1000); // Update every ten minutes
        },

        hideSnapshot: function () {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.polyLines.forEach(line => {
                this.map.removePolyline(line, this.WORK_TRACE)
            })
        },

        showSnapshot: function () {
            this.results.forEach(item => {
                item.streets.forEach((street) => {
                    this.drawWorkTrace(street.id, street.geometry, item.color, 3, this.withoutTimeLimit ? 0.85 : this.getTransparency(street.age)); // Default linewidth is 3
                    this.polyLines.push(street.id)
                })
            })
        },

        getTraceColor: function (mode, color) {
            if (mode === logModes.WORK) {
                return color
            }
            return '#bcbcbc'
        },

        drawWorkTrace: function (id, coords, color, width, transparency) {
            this.map.drawPolyLine(id, this.WORK_TRACE, coords, color, false, false, width, transparency)
        },

        getTransparency: function (age) {
            let maxAge = Math.round((Date.now() - this.fromTime) / 1000 / 60)
            if (age > maxAge) {
                return 0.1;
            }
            // Scale age to 0-1
            return 1 - (age / maxAge)
        },

        showLabel: function (data) {
            // Find hovered geometry
            let streetName = ''
            let linkId
            let foundGeometry

            // TODO - Use something else than forEach!!!

            for (let i=0; i < this.results.length; i++) {
                    let street = this.results[i].streets.find(street => street.id === data.id)
                    if (street) {
                        streetName = street.street_name
                        linkId = street.link_id
                        foundGeometry = street.geometry
                        break
                    }
            }
            // Find all task types from hovered road link
            if (linkId) {
                let label = ''
                if(streetName) {
                    label += `<div class="nopads pl-2" style="font-style: italic; color: #949494">${streetName}</div>`
                }
                let taskTypes = []
                this.results.forEach((item) => {
                    // Check if task type already exists
                    let exists = taskTypes.find(name => item.task_type === name)
                    if (!exists) {
                        let street = item.streets.find(street => street.link_id === linkId )
                        if (street) {
                            taskTypes.push({
                                    name: item.task_type,
                                    color: item.color,
                                    time: this.timestampToLocalTime(street.time*1000)
                            }
                            )
                        }
                    }

                })
                taskTypes.forEach(item => {
                    label += `<div class="task-type-circle" style="background-color: ${this.getTraceColor(logModes.WORK, item.color)}"></div>
                                  <span class="nopads">${item.name} - ${item.time}</span><br>`
                })
                let index = Math.floor(foundGeometry.length / 2)
                this.map.addGeoLabel(foundGeometry[index].y, foundGeometry[index].x, label, 'taskTypeLabel')
            }
        },

        hideLabel: function () {
            this.map.removeGeoLabels('taskTypeLabel')
        }
    }
}
</script>
