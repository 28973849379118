<script src="../mixins/RestApiMixin.js"></script>
<template>
    <div v-show="false">
        <div ref="userLocation">
            <div class="user-location"/>
        </div>

        <div ref="person">
            <div class="user-marker">
                <font-awesome-icon icon="user"/>
            </div>
        </div>

        <div ref="inactivePerson">
            <div class="user-marker inactive">
                <font-awesome-icon icon="user"/>
            </div>
        </div>

        <div ref="equipment">
            <div class="equipment-marker"/>
        </div>

        <div ref="storage">
            <div class="storage-marker">
                <font-awesome-icon icon="warehouse"/>
            </div>
        </div>

        <div ref="storageAvailable">
            <div class="storage-marker available">
                <font-awesome-icon icon="warehouse"/>
            </div>
        </div>

        <div ref="storageOrdered">
            <div class="storage-marker ordered">
                <font-awesome-icon icon="warehouse"/>
            </div>
        </div>

        <div ref="storageOutOfStock">
            <div class="storage-marker out-of-stock">
                <font-awesome-icon icon="warehouse"/>
            </div>
        </div>

        <div ref="station">
            <div class="station-marker">
                <font-awesome-icon icon="industry"/>
            </div>
        </div>

        <div ref="observation">
            <div class="observation-marker">
                <font-awesome-icon icon="map-marker"  class="print-icon-padding"/>
            </div>
        </div>

        <div ref="closedObservation">
            <div class="observation-marker closed-observation">
                <font-awesome-icon icon="map-marker"/>
            </div>
        </div>

        <div ref="publicObservation">
            <div class="public-marker">
                <font-awesome-icon icon="exclamation-triangle"/>
            </div>
        </div>

        <div ref="activeTruck">
            <div class="vehicle-marker">
                <font-awesome-icon icon="truck-moving"/>
            </div>
        </div>

        <div ref="inactiveTruck">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="truck-moving"/>
            </div>
        </div>

        <div ref="activeCar">
            <div class="vehicle-marker">
                <font-awesome-icon icon="car-side"/>
            </div>
        </div>

        <div ref="inactiveCar">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="car-side"/>
            </div>
        </div>

        <div ref="activeMonster">
            <div class="vehicle-marker">
                <font-awesome-icon icon="truck-monster"/>
            </div>
        </div>

        <div ref="inactiveMonster">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="truck-monster"/>
            </div>
        </div>

        <div ref="activeTractor">
            <div class="vehicle-marker">
                <font-awesome-icon icon="tractor"/>
            </div>
        </div>

        <div ref="inactiveTractor">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="tractor"/>
            </div>
        </div>

        <div ref="activeDigger">
            <div class="vehicle-marker">
                <font-awesome-icon icon="snowplow"/>
            </div>
        </div>

        <div ref="inactiveDigger">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="snowplow"/>
            </div>
        </div>

        <div ref="activeTrailer">
            <div class="vehicle-marker">
                <font-awesome-icon icon="trailer"/>
            </div>
        </div>

        <div ref="inactiveTrailer">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="trailer"/>
            </div>
        </div>

        <div ref="shockAbsorber">
            <div class="user-marker">
                <font-awesome-icon icon="dolly-flatbed"/>
            </div>
        </div>

        <div ref="roadFence">
            <div class="user-marker">
                <img src="../../assets/roadsign/I2.1_Sulkuaita.svg" style="height: 20px; margin-top: -3px;"/>
            </div>
        </div>

        <div ref="geoJsonPoint">
            <div class="geojson-point"/>
        </div>

        <div ref="activeSnowcat">
            <div class="vehicle-marker">
                <font-awesome-icon icon="snowplow"/>
            </div>
        </div>

        <div ref="inactiveSnowcat">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="snowplow"/>
            </div>
        </div>

        <div ref="activeHelicopter">
            <div class="vehicle-marker">
                <font-awesome-icon icon="helicopter"/>
            </div>
        </div>

        <div ref="inactiveHelicopter">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="helicopter"/>
            </div>
        </div>

        <div ref="activePaver">
            <div class="vehicle-marker">
                <font-awesome-icon icon="truck-moving"/>
            </div>
        </div>

        <div ref="inactivePaver">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="truck-moving"/>
            </div>
        </div>

        <div ref="activeDefault">
            <div class="vehicle-marker">
                <font-awesome-icon icon="cogs"/>
            </div>
        </div>

        <div ref="inactiveDefault">
            <div class="vehicle-marker inactive">
                <font-awesome-icon icon="cogs"/>
            </div>
        </div>

        <div ref="markerDefault">
            <div class="default-marker">
                <font-awesome-icon icon="map-marker"/>
            </div>
        </div>

        <div ref="dragAnchor">
            <div class="default-marker">
                <font-awesome-icon icon="dot-circle"/>
                <!--<font-awesome-icon icon="square"/>-->
            </div>
        </div>

        <div ref="dragAnchorHead">
            <div class="default-marker head-marker-color">
                <font-awesome-icon icon="dot-circle"/>
                <!--<font-awesome-icon icon="square"/>-->
            </div>
        </div>

        <div ref="polylinePoint">
            <div class="observation-marker">
                <font-awesome-icon icon="wave-square"/>
            </div>
        </div>

        <div ref="polylinePointClosed">
            <div class="observation-marker closed-observation">
                <font-awesome-icon icon="wave-square"/>
            </div>
        </div>

        <div ref="alert">
            <div class="alert-marker">
                <font-awesome-icon icon="exclamation"/>
            </div>
        </div>

        <div ref="bridge">
            <div class="image-marker">
                <img src="@/assets/icon_bridge.png" class="image-marker-image" alt="Bridge">
            </div>
        </div>

        <div ref="culvert">
            <div class="image-marker">
                <img src="@/assets/icon_culvert.png" class="image-marker-image" alt="Culvert">
            </div>
        </div>

        <div ref="drain">
            <div class="image-marker round-marker">
                <img src="@/assets/icon_drain.png" class="image-marker-image" alt="Drain">
            </div>
        </div>

        <div ref="drainDefect">
            <div class="image-marker round-marker">
                <img src="@/assets/icon_drain_defect.png" class="image-marker-image" alt="DrainDefect">
            </div>
        </div>

        <div ref="powerStation">
            <div class="image-marker transparent-bg ml-1">
                <img src="@/assets/power_center.png" class="image-marker-image" alt="Center">
            </div>
        </div>

        <div ref="weatherStation">
            <div class="image-marker">
                <font-awesome-icon icon="cloud-sun"/>
            </div>
        </div>

        <div ref="harjaNotification">
            <div class="image-marker">
                <font-awesome-icon icon="paint-brush"/>
            </div>
        </div>

        <div ref="powerStationDim">
            <div class="image-marker transparent-bg ml-1">
                <img src="@/assets/power_center_dim.png" class="image-marker-image" alt="Center">
            </div>
        </div>

        <div ref="powerStationClock">
            <div class="image-marker transparent-bg ml-1">
                <img src="@/assets/power_center_clock.png" class="image-marker-image" alt="Center">
            </div>
        </div>

        <div ref="powerStationControl">
            <div class="image-marker transparent-bg ml-1">
                <img src="@/assets/power_center_control.png" class="image-marker-image" alt="Center">
            </div>
        </div>

        <div ref="post">
            <div class="image-marker transparent-bg">
                <img src="@/assets/icon_post.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="portal">
            <div class="image-marker transparent-bg">
                <img src="@/assets/icon_portal.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="stairs">
            <div class="image-marker">
                <img src="@/assets/icon_stairs.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="gate">
            <div class="image-marker transparent-bg">
                <img src="@/assets/icon_gate.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="pile">
            <div class="image-marker transparent-bg">
                <img src="@/assets/icon_edge_pile.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="boom">
            <div class="image-marker transparent-bg">
                <img src="@/assets/icon_boom.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="equipment">
            <div class="image-marker transparent-bg">
                <img src="@/assets/icon_equipment.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="roadAd">
            <div class="image-marker transparent-bg">
                <img src="@/assets/icon_road_ad.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="streetLight">
            <div class="image-marker transparent-bg">
                <img src="@/assets/street_light.png" class="image-marker-image" alt="Light">
            </div>
        </div>

        <div ref="streetLightDefect">
            <div class="image-marker transparent-bg">
                <img src="@/assets/street_light_defect.png" class="image-marker-image" alt="LightDefect">
            </div>
        </div>

        <div ref="streetLightPlanned">
            <div class="image-marker transparent-bg">
                <img src="@/assets/street_light_planned.png" class="image-marker-image" alt="LightDefect">
            </div>
        </div>

        <div ref="streetLightRemoved">
            <div class="image-marker transparent-bg">
                <img src="@/assets/street_light_removed.png" class="image-marker-image" alt="LightDefect">
            </div>
        </div>

        <div ref="unknownMarker">
            <div class="image-marker transparent-bg">
                <img src="@/assets/roadsign/unknown_sign.png" class="image-marker-image" alt="LightDefect">
            </div>
        </div>

        <div ref="workAssignment">
            <div class="image-marker">
                <font-awesome-icon icon="tasks"/>
            </div>
        </div>

        <div ref="workAssignmentOpen">
            <div class="image-marker open-marker">
                <font-awesome-icon icon="tasks"/>
            </div>
        </div>

        <div ref="workAssignmentClosed">
            <div class="image-marker inactive-marker">
                <font-awesome-icon icon="tasks"/>
            </div>
        </div>

        <div ref="measureDistance">
            <div class="default-marker" v-bind:style="{ color: 'black'}">
                <font-awesome-icon icon="dot-circle"/>
            </div>
        </div>

        <div ref="measureDistanceLeaflet">
            <div class="default-marker-leaflet" v-bind:style="{ color: 'black', marginTop: '-3px'}">
                <font-awesome-icon icon="dot-circle"/>
            </div>
        </div>

        <div ref="circle">
            <div v-bind:style="{ color: 'black'}">
                <font-awesome-icon icon="dot-circle"/>
            </div>
        </div>
        <div ref="solidCircle">
            <div v-bind:style="{ color: '#c50505' }">
                <font-awesome-icon icon="circle"/>
            </div>
        </div>
        <div ref="neutralCircle">
            <div v-bind:style="{ color: '#85e7db' }">
                <font-awesome-icon icon="circle"/>
            </div>
        </div>
        <div ref="equipmentSimple">
            <div v-bind:style="{ color: '#32359d' }">
                <font-awesome-icon icon="flag"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapMarker',
    data() {
        return {
            leaflet: Boolean
        }
    },
    methods: {
        getActiveTruckIcon: function () {
            return this.$refs.activeTruck.innerHTML
        },
        getInactiveTruckIcon: function () {
            return this.$refs.inactiveTruck.innerHTML
        },
        getActiveCarIcon: function () {
            return this.$refs.activeCar.innerHTML
        },
        getInactiveCarIcon: function () {
            return this.$refs.inactiveCar.innerHTML
        },
        getActiveMonsterIcon: function () {
            return this.$refs.activeMonster.innerHTML
        },
        getInactiveMonsterIcon: function () {
            return this.$refs.inactiveMonster.innerHTML
        },
        getActiveTractorIcon: function () {
            return this.$refs.activeTractor.innerHTML
        },
        getInactiveTractorIcon: function () {
            return this.$refs.inactiveTractor.innerHTML
        },
        getActiveSnowplowIcon: function () {
            return this.$refs.activeSnowcat.innerHTML
        },
        getInactiveSnowplowIcon: function () {
            return this.$refs.inactiveSnowcat.innerHTML
        },
        getActiveHelicopterIcon: function () {
            return this.$refs.activeHelicopter.innerHTML
        },
        getInactiveHelicopterIcon: function () {
            return this.$refs.inactiveHelicopter.innerHTML
        },
        getActivePaverIcon: function () {
            return this.$refs.activeSnowcat.innerHTML
        },
        getInactivePaverIcon: function () {
            return this.$refs.inactiveSnowcat.innerHTML
        },
        getActiveTrailerIcon: function () {
            return this.$refs.activeTrailer.innerHTML
        },
        getInactiveTrailerIcon: function () {
            return this.$refs.inactiveTrailer.innerHTML
        },
        getActiveDiggerIcon: function () {
            return this.$refs.activeDigger.innerHTML
        },
        getInactiveDiggerIcon: function () {
            return this.$refs.inactiveDigger.innerHTML
        },
        getFenceIcon: function () {
            return this.$refs.roadFence.innerHTML
        },
        getAbsorberIcon: function () {
            return this.$refs.shockAbsorber.innerHTML
        },
        getActiveDefaultIcon: function () {
            return this.$refs.activeDefault.innerHTML
        },
        getInactiveDefaultIcon: function () {
            return this.$refs.inactiveDefault.innerHTML
        },
        getDefaultMarkerIcon: function () {
            return this.$refs.markerDefault.innerHTML
        },
        getUserMarkerIcon: function () {
            return this.$refs.userLocation.innerHTML
        },
        getEquipmentMarkerIcon: function () {
            return this.$refs.equipment.innerHTML
        },
        getStorageMarkerGenericIcon() {
            return this.$refs.storage.innerHTML
        },
        getStorageMarkerAvailableIcon() {
            return this.$refs.storageAvailable.innerHTML
        },
        getStorageMarkerOrderedIcon() {
            return this.$refs.storageOrdered.innerHTML
        },
        getStorageMarkerOutOfStockIcon() {
            return this.$refs.storageOutOfStock.innerHTML
        },
        getStationMarkerIcon() {
            return this.$refs.station.innerHTML
        },
        getWeatherStationMarkerIcon() {
            return this.$refs.weatherStation.innerHTML
        },
        getHarjaNotificationMarkerIcon() {
            return this.$refs.harjaNotification.innerHTML
        },
        getDragAnchorMarkerIcon: function () {
            return this.$refs.dragAnchor.innerHTML
        },
        getDragAnchorHeadMarkerIcon: function () {
            return this.$refs.dragAnchorHead.innerHTML
        },
        getObservationMarkerIcon: function () {
            return this.$refs.observation.innerHTML
        },
        getPolylinePointIcon: function () {
            return this.$refs.polylinePoint.innerHTML
        },
        getPolylinePointClosedIcon: function () {
            return this.$refs.polylinePointClosed.innerHTML
        },
        getClosedObservationMarkerIcon: function () {
            return this.$refs.closedObservation.innerHTML
        },
        getPublicObservationMarkerIcon: function () {
            return this.$refs.publicObservation.innerHTML
        },
        getPersonMarkerIcon: function () {
            return this.$refs.person.innerHTML
        },
        getInactivePersonMarkerIcon: function () {
            return this.$refs.inactivePerson.innerHTML
        },
        getAlertMarkerIcon: function () {
            return this.$refs.alert.innerHTML
        },
        getBridgeIcon: function () {
            return this.$refs.bridge.innerHTML
        },
        getCulvertIcon: function () {
            return this.$refs.culvert.innerHTML
        },
        getDrainIcon: function () {
            return this.$refs.drain.innerHTML
        },
        getDrainDefectIcon: function () {
            return this.$refs.drainDefect.innerHTML
        },
        getPowerStationIcon: function (mode) {
            switch (mode){
                case 1: return this.$refs.powerStationControl.innerHTML
                case 2: return this.$refs.powerStationClock.innerHTML
                case 3: return this.$refs.powerStationDim.innerHTML
            }
            return this.$refs.powerStation.innerHTML
        },
        getStreetLightIcon: function () {
            return this.$refs.streetLight.innerHTML
        },
        getStreetLightDefectIcon: function () {
            return this.$refs.streetLightDefect.innerHTML
        },
        getStreetLightPlannedIcon: function () {
            return this.$refs.streetLightPlanned.innerHTML
        },
        getStreetLightRemovedIcon: function () {
            return this.$refs.streetLightRemoved.innerHTML
        },
        getUnknownMarkerIcon: function () {
            return this.$refs.unknownMarker.innerHTML
        },
        getWorkAssignmentMarkerIcon: function (open = false) {
            return open ? this.$refs.workAssignmentOpen.innerHTML : this.$refs.workAssignment.innerHTML
        },
        getClosedWorkAssignmentMarkerIcon: function () {
            return this.$refs.workAssignmentClosed.innerHTML
        },
        getMeasureDistanceMarkerIcon: function (leaflet) {
            return leaflet ? this.$refs.measureDistanceLeaflet.innerHTML : this.$refs.measureDistance.innerHTML
        },
        getCircleIcon: function () {
            return this.$refs.circle.innerHTML
        },
        getSolidCircleIcon: function () {
            return this.$refs.solidCircle.innerHTML
        },
        getNeutralCircleIcon: function () {
            return this.$refs.neutralCircle.innerHTML
        },
        getGeoJsonPointIcon: function (color) {
            return this.$refs.geoJsonPoint.innerHTML
        },
        getAsDivElement: function(iconElement) {
            let tempDiv = document.createElement('div');
            tempDiv.innerHTML = iconElement;
            return tempDiv.querySelector('div')
        },
        setIconStyleProperty(iconDiv, property, value) {
            iconDiv.style[property] = value;
        },
        getPostIcon: function () {
            return this.$refs.post.innerHTML
        },
        getPortalIcon: function () {
            return this.$refs.portal.innerHTML
        },
        getStairsIcon: function () {
            return this.$refs.stairs.innerHTML
        },
        getGateIcon: function () {
            return this.$refs.gate.innerHTML
        },
        getEdgePileIcon: function () {
            return this.$refs.pile.innerHTML
        },
        getBoomIcon: function () {
            return this.$refs.boom.innerHTML
        },
        getEquipmentIcon: function () {
            return this.$refs.equipment.innerHTML
        },
        getRoadAdIcon: function () {
            return this.$refs.roadAd.innerHTML
        },
        getSimpleEquipmentIcon: function () {
            return this.$refs.equipmentSimple.innerHTML
        }
    }
}
</script>
