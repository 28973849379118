export const streetLightHelper = {
    data: function () {
        return {
            BULB_TYPE_LED: 1,
            BULB_TYPE_NATRIUM: 2,
            BULB_TYPE_MULTIMETAL: 3,
            BULB_TYPE_QUICKSILVER: 3,
            BULB_TYPE_OTHER: 10,

            LIGHT_TYPE_STREET: 1,
            LIGHT_TYPE_PARK: 2,
            LIGHT_TYPE_SPOT: 3,
            LIGHT_TYPE_TRACK: 4,
            LIGHT_TYPE_OTHER: 10,

            ARM_TYPE_VERTICAL: 1,
            ARM_TYPE_ONE_SIDED: 2,
            ARM_TYPE_TWO_SIDED: 3,
            ARM_TYPE_ROOST: 4,
            ARM_TYPE_ARMLESS: 5,

            POLE_TYPE_CONE: 1,
            POLE_TYPE_SHOULDER: 2,
            POLE_TYPE_MAST: 3,
            POLE_TYPE_SAFETY: 4,
            POLE_TYPE_WOOD: 5,
            POLE_TYPE_SPECIAL: 6,

            INSTALL_VERTICAL: 1,
            INSTALL_HORIZONTAL: 2,
            INSTALL_HANGING: 3,
            INSTALL_OTHER: 10,

            mainFuseTypeOptions: [
                {value: 1, text: '3x25A'},
                {value: 2, text: '3x35A'},
                {value: 3, text: '3x50A'},
                {value: 4, text: '3x63A'},
                {value: 5, text: '3x80A'},
                {value: 6, text: '3x100A'}
            ],

            lightingClassOptions: [
                { value: null, text: this.$t('street_lights.select_class') },
                { value: 1, text: 'M1' },
                { value: 2, text: 'M2' },
                { value: 3, text: 'M3a' },
                { value: 4, text: 'M3b' },
                { value: 5, text: 'M4' },
                { value: 6, text: 'M5' },
                { value: 7, text: 'M6' },
                { value: 8, text: 'P1' },
                { value: 9, text: 'P2' },
                { value: 10, text: 'P3' },
                { value: 11, text: 'P4' },
                { value: 12, text: 'P5' },
                { value: 13, text: 'P6' }
            ],

            CONTROL_TYPE_SYSTEM: 1,
            CONTROL_TYPE_CLOCK: 2,
            CONTROL_TYPE_TWILIGHT: 3,

            meteredOptions: [
                {value: 1, text: this.$t('power_stations.metered')},
                {value: 0, text: this.$t('power_stations.not_metered')}
            ],

            guaranteeOptions: [
                {value: null, text: this.$t('common.unknown')},
                {value: 1, text: 1},
                {value: 2, text: 2},
                {value: 3, text: 3},
                {value: 4, text: 4},
                {value: 5, text: 5},
                {value: 6, text: 6},
                {value: 7, text: 7},
                {value: 8, text: 8},
                {value: 9, text: 9},
                {value: 10, text: 10}
            ],

            STATION_TYPE_CABINET: 1,
            STATION_TYPE_POLE: 2,
            STATION_TYPE_SUBSTATION: 3,

            LOCK_TRI_KEY: 1,
            LOCK_KEY: 2,

            POLE_SUPPORT_STAY: 1,
            POLE_SUPPORT_PILAR: 2,
            POLE_SUPPORT_NONE: 10

        }
    },
    computed: {
        controlTypeOptions() {
            return[
                {value: null, text: this.$t('common.unknown')},
                {value: this.CONTROL_TYPE_SYSTEM, text: this.$t('power_stations.control_type_system')},
                {value: this.CONTROL_TYPE_CLOCK, text: this.$t('power_stations.control_type_clock')},
                {value: this.CONTROL_TYPE_TWILIGHT, text: this.$t('power_stations.control_type_twilight')},
            ]
        },

        earthingOptions() {
            return [
            {value: 1, text: this.$t('common.yes')},
            {value: 0, text: this.$t('common.no')}
        ]},

        cablingOptions() {
            return [
            {text: this.$t('street_lights.air'), value: 1},
            {text: this.$t('street_lights.ground'), value: 2},
        ]},

        statusOptions() {
            return [
            {value: 1, text: this.$t('street_lights.in_use')},
            {value: 2, text: this.$t('street_lights.failure')},
            {value: 3, text: this.$t('street_lights.not_in_use')},
            {value: 4, text: this.$t('street_lights.planned')},
        ]},

        stationStatusOptions() {
            return [
                {value: 1, text: this.$t('street_lights.in_use')},
                {value: 2, text: this.$t('street_lights.failure')},
                {value: 3, text: this.$t('power_stations.service_needed')},
                {value: 4, text: this.$t('street_lights.not_in_use')},
        ]},

        mainFuseOptions() {
            return [
                {value: null, text: this.$t('common.unknown')},
                {value: 1, text: this.$t("power_stations.handle_000")},
                {value: 2, text: this.$t("power_stations.handle_00")},
                {value: 3, text: this.$t("power_stations.handle_0")},
                {value: 4, text: this.$t("power_stations.handle_1")},
                {value: 5, text: this.$t("power_stations.handle_2")},
                {value: 6, text: this.$t("power_stations.plug")},
            ]},

        groupFuseTypeOptions() {
            return [
                {value: null, text: this.$t('common.unknown')},
                {value: 20, text: "3x20A"},
                {value: 16, text: "3x16A"},
                {value: 10, text: "3x10A"},
                {value: 6, text: "3x6A"},
            ]},

        breakCurveOptions() {
            return [
                {value: null, text: this.$t('common.unknown')},
                {value: "B", text: "B"},
                {value: "C", text: "C"},
                {value: "D", text: "D"},
            ]},

        currentValueOptions() {
            return [
                {text: this.$t('common.unknown'), value: null},
                {text: "6A", value: 6},
                {text: "10A", value: 10},
                {text: "16A", value: 16},
                {text: "20A", value: 20},
                {text: "25A", value: 25},
                {text: "32A", value: 32},
            ]
        },

        lockingOptions() {
            return [
                {text: this.$t('common.unknown'), value: null},
                {text: this.$t('power_stations.lock_tri_key'), value: this.LOCK_TRI_KEY},
                {text: this.$t('power_stations.lock_key'), value: this.LOCK_KEY}
            ]
        },
        bulbTypeOptions() {
            return [
            {value: this.BULB_TYPE_LED, text: this.$t('street_lights.bulb_type_led')},
            {value: this.BULB_TYPE_NATRIUM, text: this.$t('street_lights.bulb_type_natrium')},
            {value: this.BULB_TYPE_MULTIMETAL, text: this.$t('street_lights.bulb_type_multimetal')},
            {value: this.BULB_TYPE_QUICKSILVER, text: this.$t('street_lights.bulb_type_quicksilver')},
            {value: this.BULB_TYPE_OTHER, text: this.$t('street_lights.bulb_type_other')}
        ]},
        lightTypeOptions() {
            return [
            {value: this.LIGHT_TYPE_STREET, text: this.$t('street_lights.light_type_street')},
            {value: this.LIGHT_TYPE_PARK, text: this.$t('street_lights.light_type_park')},
            {value: this.LIGHT_TYPE_SPOT, text: this.$t('street_lights.light_type_spot')},
            {value: this.LIGHT_TYPE_TRACK, text: this.$t('street_lights.light_type_track')},
            {value: this.LIGHT_TYPE_OTHER, text: this.$t('street_lights.light_type_other')}
        ]},
        armTypeOptions() {
            return [
            {value: this.ARM_TYPE_VERTICAL, text: this.$t('street_lights.arm_type_vertical')},
            {value: this.ARM_TYPE_ONE_SIDED, text: this.$t('street_lights.arm_type_one_sided')},
            {value: this.ARM_TYPE_TWO_SIDED, text: this.$t('street_lights.arm_type_two_sided')},
            {value: this.ARM_TYPE_ROOST, text: this.$t('street_lights.arm_type_roost')},
            {value: this.ARM_TYPE_ARMLESS, text: this.$t('street_lights.arm_type_armless')}
        ]},
        poleTypeOptions() {
            return [
            {value: this.POLE_TYPE_CONE, text: this.$t('street_lights.pole_type_cone')},
            {value: this.POLE_TYPE_SHOULDER, text: this.$t('street_lights.pole_type_shoulder')},
            {value: this.POLE_TYPE_MAST, text: this.$t('street_lights.pole_type_mast')},
            {value: this.POLE_TYPE_SAFETY, text: this.$t('street_lights.pole_type_safety')},
            {value: this.POLE_TYPE_WOOD, text: this.$t('street_lights.pole_type_wood')},
            {value: this.POLE_TYPE_SPECIAL, text: this.$t('street_lights.pole_type_special')}
        ]},
        stationTypeOptions() {
            return [
                {value: null, text: this.$t('common.unknown')},
                {value: this.STATION_TYPE_CABINET, text: this.$t('power_stations.type_cabinet')},
                {value: this.STATION_TYPE_POLE, text: this.$t('power_stations.type_pole')},
                {value: this.STATION_TYPE_SUBSTATION, text: this.$t('power_stations.type_substation')},
            ]
        },
        installOptions() {
            return [
                {value: null, text: this.$t('common.unknown')},
                {value: this.INSTALL_HORIZONTAL, text: this.$t('street_lights.installation_horizontal')},
                {value: this.INSTALL_VERTICAL, text: this.$t('street_lights.installation_vertical')},
                {value: this.INSTALL_HANGING, text: this.$t('street_lights.installation_hanging')},
                {value: this.INSTALL_OTHER, text: this.$t('street_lights.installation_other')},
            ]
        },
        poleSupportOptions() {
            return [
                {value: null, text: this.$t('common.unknown')},
                {value: this.POLE_SUPPORT_STAY, text: this.$t('street_lights.pole_support_stay')},
                {value: this.POLE_SUPPORT_PILAR, text: this.$t('street_lights.pole_support_pilar')},
                {value: this.POLE_SUPPORT_NONE, text: this.$t('street_lights.pole_support_none')},
            ]
        }
    }
}
