<template>
    <div class="content-container">
        <b-navbar
            variant="faded"
            class="header-background"
            type="dark"
        >
            <div
                v-if="!editMode && !detailsMode"
                class="header"
            >
                <span class="title">{{ $t('area_report.title') }}</span>
            </div>
            <div
                v-else
                class="header col-sm-12 nopads"
                @click="close"
            >
        <span class="pointer">
          <i class="fa fa-arrow-left"/>
        </span>
                <span class="pointer"> {{ $t('common.back') }}</span>
                <font-awesome-icon
                    v-if="detailsMode && areaReport.status!== completed && areaReport.reporter.id===userId && isNearBy"
                    icon="edit"
                    class="print-icon"
                    @click.stop="editAreaReportWizard"
                />
                <font-awesome-icon
                    v-if="detailsMode && areaReport.status!== completed && areaReport.reporter.id===userId"
                    icon="trash"
                    class="print-icon"
                    @click.stop="deleteReport"
                />
                <font-awesome-icon
                    v-if="detailsMode && areaReport.status === completed"
                    icon="print"
                    class="print-icon"
                    @click.stop="print"
                />
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <keep-alive>
                    <area-report-filter
                        v-if="!editMode && !detailsMode"
                        :is-admin="isAdmin"
                        @search="fetchAreaReports"
                    />
                </keep-alive>
            </transition>


            <transition name="fade">
                <area-report-list
                    v-if="!editMode && !detailsMode"
                    :results="results"
                    @details="showReportDetails"
                    @add="startAreaReportWizard"
                />
            </transition>

            <transition name="fade">
                <area-report-editor
                    v-if="editMode && !detailsMode"
                    :area-report="areaReport"
                    @closeAndUpdate="closeAndFetchUpdate"
                />
            </transition>
            <transition name="fade">
                <area-report-details
                    v-if="detailsMode"
                    id="details"
                    ref="details"
                    :report-details="areaReport"
                    @closeAndUpdate="closeAndFetchUpdate"
                />
            </transition>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {areaReportHelper} from '../../mixins/AreaReportMixin'
import AreaReportEditor from './AreaReportEditor'
import AreaReportList from './AreaReportList'
import AreaReportDetails from './AreaReportDetails'
import AreaReportFilter from './AreaReportFilter'
import {isPointInPolygon} from "geolib";

export default {
    name: 'AreaReports',
    components: {AreaReportFilter, AreaReportDetails, AreaReportList, AreaReportEditor},
    mixins: [restApi, areaReportHelper],
    props: {
        isAdmin: Boolean,
        userId: {
            type: Number,
            default: -1
        }
    },

    data: function () {
        return {
            results: [],
            isNearBy: false,
            userPosition: null,
            areas: [],
            nearbyAreas: [],
            areaReport: null,
            editMode: false,
            detailsMode: false,
            loading: false,
            completed: 2,
            lastSearchParams: null,
            watchID: null
        }
    },

    mounted: function () {
        this.initPosition()
        this.watchUserPosition()
        this.fetchAreas()
    },

    beforeDestroy() {
        if (this.watchID) {
            navigator.geolocation.clearWatch(this.watchID);
        }
    },

    methods: {
        initPosition: function () {
            navigator.geolocation.getCurrentPosition(
                function (pos) {
                    if (pos) {
                        this.setPosition(pos)
                    }
                }
            )
        },

        fetchAreas: function () {
            this.loading = true
            this.restFetchParams(this.areaUrl, {no_geom: 1}, this.handleAreaResponse)
        },

        handleAreaResponse: function (response) {
            this.loading = false
            this.areas = response.data
            this.filterNearbyAreas()
        },

        filterNearbyAreas: function () {
            // TODO - user's distance to area can also be checked and then results sorted by distance
            this.nearbyAreas = []
            if (this.userPosition) {
                this.areas.forEach(area => {
                    if (isPointInPolygon(
                        {latitude: this.userPosition.coords.latitude, longitude: this.userPosition.coords.longitude},
                        this.getAreaPolygon(area))) {
                        this.nearbyAreas.push(area)
                    }
                }, this)
            }
        },

        watchUserPosition: function () {
            if (navigator.geolocation) {
                this.watchID = navigator.geolocation.watchPosition(position => {
                    this.setPosition(position)
                }, () => {
                }, {timeout: 30000, enableHighAccuracy: true})
            }
        },

        setPosition: function (position) {
            this.userPosition = position
            this.filterNearbyAreas()
        },

        fetchAreaReports: function (params) {
            if (params !== undefined && params !== null) {
                this.lastSearchParams = params
            }
            this.loading = true
            this.restFetchParams(this.areaReportUrl, this.lastSearchParams, this.handleResponse)
        },

        handleResponse: function (response) {
            this.loading = false
            this.results = response.data
        },

        editAreaReportWizard: function () {
            this.detailsMode = false
            this.editMode = true
        },

        startAreaReportWizard: function () {
            this.areaReport = null
            this.detailsMode = false
            this.editMode = true
        },

        showReportDetails: function (details) {
            this.areaReport = details
            this.detailsMode = true
            this.checkNearBy()
        },

        print: function () {
            this.$refs.details.print()
        },

        deleteReport: function () {
            this.$refs.details.confirmDeleteReport()
        },

        close: function () {
            this.editMode = false
            this.detailsMode = false
            this.areaReportWizardMode = false
            this.selectedArea = null
        },

        closeAndFetchUpdate: function () {
            this.close()
            this.fetchAreaReports(this.lastSearchParams)
        },

        checkNearBy: function () {
            if (this.areaReport) {
                this.isNearBy = this.nearbyAreas.find(area => area.id === this.areaReport.area.id) !== undefined
            } else {
                this.isNearBy = false
            }
        }
    }
}
</script>
