<template>
    <div class="col-sm-12 editor pb-3">
        <transition name="fade">
            <b-form v-if="inspectionTemplate!=null"
                    class="col-sm-12 editor-form p-3">
                <h3>{{ inspectionTemplate.name }}</h3>
                <!-- TODO - Add here information about the inspection target -->
                <!--
                <span class="form-sub-title">{{ vehicle.make }} {{ vehicle.vehicle_model }} - {{ vehicle.license_plate }}</span>
                -->
                <!--        Generic info field-->
                <b-col class="col-12 nopads mt-2 mb-2">
                    <b-form-textarea
                        id="info"
                        class="nopads p-1"
                        v-model="info"
                        :placeholder="$t('ins_report.info')"
                        size="sm"
                        :rows="3"
                        :max-rows="6"/>
                </b-col>

                <div class="col-sm-12 form-data nopads pb-3 mb-2">
                    <b-row class="inspection-row"
                           v-for="(inspectable, index) of inspectables"
                           :key="inspectable.id">
                        <b-col class="col-sm-8">
                            <span class="item-detail-title">{{ inspectable.name }}</span>
                        </b-col>
                        <b-col class="col-12 nopads mt-2 mb-2 pl-3 pr-3">
                            <template v-if="inspectableTypes[inspectable.type] === 'number'">
                                <b-form-input
                                    type="number"
                                    :min="inspectable.range && inspectable.range.min ? inspectable.range.min : undefined"
                                    :max="inspectable.range && inspectable.range.max ? inspectable.range.max : undefined"
                                    v-model="inspectableTypeResults[index].number"
                                    :state="isValidInput(inspectable, inspectableTypeResults[index].number)"
                                    :placeholder="getValueRange(inspectable)"
                                    @change="val => {inspectableTypeResults[index].number = getValidNumber(inspectable, val)}"
                                    @blur="checkIfReady()"
                                />
                            </template>
                            <template v-else-if="inspectableTypes[inspectable.type] === 'text'">
                                <b-form-input
                                    type="text"
                                    @change="checkIfReady()"
                                    v-model="inspectableTypeResults[index].text"
                                />
                            </template>
                            <template v-else-if="inspectableTypes[inspectable.type] === 'select_single'">
                                <b-form-radio-group
                                    v-model="inspectableTypeResults[index].select_single"
                                    @change="checkIfReady()"
                                    :options="inspectable.selectable_items.map(v => ({value: v, text: v.name}))"
                                />
                            </template>
                            <template v-else-if="inspectableTypes[inspectable.type] === 'select_multi'">
                                <b-form-checkbox-group
                                    v-model="inspectableTypeResults[index].select_multi"
                                    :options="inspectable.selectable_items.map(v => ({value: v, text: v.name}))"
                                    class="mb-3"
                                    value-field="value"
                                    text-field="text"
                                    disabled-field="notEnabled"
                                    @change="checkIfReady"
                                />
                            </template>
                            <template v-else-if="inspectableTypes[inspectable.type] === 'boolean'">
                                <b-form-group>
                                    <b-form-radio
                                        v-model="inspectableTypeResults[index].boolean"
                                        :value="true"
                                        @change="checkIfReady"
                                    >
                                        {{ $t('common.yes') }}
                                    </b-form-radio>
                                    <b-form-radio
                                        v-model="inspectableTypeResults[index].boolean"
                                        :value="false"
                                        @change="checkIfReady"
                                    >
                                        {{ $t('common.no') }}
                                    </b-form-radio>
                                </b-form-group>
                            </template>
                            <template v-else>
                                <b-form-select
                                    v-model="inspectableValues[index]"
                                    @change="checkIfReady"
                                    :options="inspectionOptions"/>
                            </template>
                        </b-col>
                        <b-col class="col-12 nopads mt-2 mb-2 pl-3 pr-3">
                            <b-form-textarea
                                id="info"
                                class="nopads p-1"
                                v-model="inspectableInfo[index]"
                                :placeholder="$t('inspection.additional_info_placeholder')"
                                :rows="3"
                                :max-rows="6"/>
                            <span class="span-title">{{ $t('equipment.photo') }}</span>
                            <div
                                v-for="(path, counter) in photos[inspectable.id]"
                                :key="counter"
                                class="col-sm-12 nopads"
                            >
                                <b-form-group
                                    class="title"
                                    label-for="counter"
                                    v-if="counter === 0 || isAttachmentSet(photos, counter-1)"
                                >
                                    <b-form-file
                                        v-model="photos[inspectable.id][counter]"
                                        accept="image/jpeg, image/jpg, image/png"
                                        size="sm"
                                        :placeholder="$t('equipment.photo_placeholder')"
                                    />
                                </b-form-group>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="!canSubmit||loading"
                        @click.stop="askForSubmission"
                    >
                        <i class="fa fa-share-square"/>
                        <span class="button-text">{{ $t('common.send') }}</span>
                    </b-button>
                </div>
            </b-form>
        </transition>
        <div
            v-if="inspectionTemplate==null"
            class="col-sm-12"
        >
            {{ $t('inspection.no_template_found') }}
        </div>
        <b-modal
            id="submit_modal"
            ref="submit_modal"
            :hide-header=true
            :hide-footer=true
            @ok="submitInspection">
            <div class="col-sm-12 pb-1">
                <span class="pb-2 title">{{ $t('ins_wizard.submit_confirm') }}</span>
                <span>{{ $t('ins_wizard.submit_confirm_info') }}</span>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="cancelSubmit">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="submitInspection">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {attachmentHelper} from '../../mixins/AttachmentMixin'
import {types as inspectableTypes} from "../../../modules/Inspectable";

export default {
    name: 'InspectionForm',
    mixins: [attachmentHelper],
    props: {
        inspectionTemplate: {
            type: Object,
            default: null
        },
        allowSkipping: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data: function () {
        return {
            canSubmit: false,
            inspectionOptions: [
                {value: 1, text: this.$t('inspection.options.not_tested')},
                {value: 2, text: this.$t('inspection.options.okay')},
                {value: 3, text: this.$t('inspection.options.defects')},
                {value: 4, text: this.$t('inspection.options.ban')}
            ],
            inspectableTypes,
            inspectionType: this.inspectionTemplate.type,
            inspectionName: this.inspectionTemplate.name,
            inspectables: this.inspectionTemplate.inspectables,
            inspectableValues: [],
            inspectableInfo: [],
            inspectableTypeResults: [],
            info: null,
            photos: this.initPhotos()
        }
    },
    created() {
        this.inspectables = this.inspectionTemplate.inspectables
        this.inspectionType = this.inspectionTemplate.name
        this.inspectableTypeResults = this.inspectionTemplate.inspectables.map(i => ({
            none: undefined,
            number: { number: null },
            text: { text: "" },
            select_single: { select_single: null },
            select_multi: { select_multi: [] },
            boolean: { boolean: null },
        }[inspectableTypes[i.type]]))
        for (let i = 0; i < this.inspectionTemplate.inspectables.length; i++) {
            const inspectable = this.inspectionTemplate.inspectables[i];
            if (inspectableTypes[inspectable.type] === 'none') continue;
            this.inspectableValues[i] = 2;
        }
    },
    methods: {
        getValueRange(inspectable) {
            if (inspectable.range) {
                let text = inspectable.range.min === undefined ? '' : (inspectable.range.min + ' - ')
                if (inspectable.range.max !== undefined) {
                    text += inspectable.range.max
                }
                return text
            }
            return ''
        },
        initPhotos: function () {
            let photos = {}
            this.inspectionTemplate.inspectables.forEach(inspectable => {
                photos[inspectable.id] = Array(5)
            })
            return photos
        },
        getTitle: function (type) {
            switch (type) {
                case 1:
                    return this.$t('inspection_template.type.weekly')
                case 2:
                    return this.$t('inspection_template.type.monthly')
                case 3:
                    return this.$t('inspection_template.type.annually')
                case 4:
                    return this.$t('inspection_template.type.deployment')
                case 5:
                    return this.$t('inspection_template.type.daily')
            }
        },
        getInspectionResultsFromForm: function () {
            // Loop through inspectables to generate results
            var results = []
            for (var i = 0; i < this.inspectables.length; i++) {
                var inspectable = this.inspectables[i]
                var value = this.inspectableValues[i]
                if (value === undefined) {
                    value = 1 // Skipped
                }
                var info = this.inspectableInfo[i]
                if (info === undefined) {
                    info = ''
                }
                let typeResult = this.inspectableTypeResults[i]
                results.push({
                    id: '',
                    inspectable: inspectable.name,
                    inspectable_id: inspectable.id,
                    result: value,
                    info: info,
                    ...typeResult,
                })
            }
            var d = new Date()
            var createTime = d.toISOString()
            return {
                created: createTime,
                results: results,
                info: this.info
            }
        },

        askForSubmission() {
            this.$refs['submit_modal'].show()
        },

        cancelSubmit() {
            this.$refs['submit_modal'].hide()
        },

        submitInspection: function () {
            this.$refs['submit_modal'].hide()
            this.$emit('onSubmitResults', this.getInspectionResultsFromForm(), this.photos);
        },

        infoVisible: function (index) {
            return this.inspectableValues[index] === 3 || this.inspectableValues[index] === 4
        },

        isValidInput(inspectable, value) {
            if (value === undefined || value === null || value.length < 1) {
                return null
            }
            return this.getValidNumber(inspectable, value) !== undefined
        },

        getValidNumber(inspectable, value) {
            if (inspectable.range && value) {
                if (inspectable.range.max !== undefined && inspectable.range.min !== undefined) {
                    if (value <= inspectable.range.max && value >= inspectable.range.min) {
                        return value
                    }
                    return undefined
                } else if (inspectable.range.max !== undefined && value <= inspectable.range.max) {
                    return value
                } else if (inspectable.range.min !== undefined && value >= inspectable.range.min) {
                    return value
                }
                return undefined
            }
            return value
        },

        checkIfReady() {
            for (let i = 0; i < this.inspectionTemplate.inspectables.length; i++) {
                const type = inspectableTypes[this.inspectionTemplate.inspectables[i].type];
                switch (type) {
                    case "number":
                        if (this.inspectableTypeResults[i].number == null) {
                            this.canSubmit = false;
                            return;
                        }
                        break;
                    case "boolean":
                        if (this.inspectableTypeResults[i].boolean == null) {
                            this.canSubmit = false;
                            return;
                        }
                        break;
                    case "select_single":
                        if (this.inspectableTypeResults[i].select_single == null) {
                            this.canSubmit = false;
                            return;
                        }
                        break;
                    case "select_multi":
                        if (this.inspectableTypeResults[i].select_multi == null) {
                            this.canSubmit = false;
                            return;
                        }
                        break;
                    case "text":
                        if (this.inspectableTypeResults[i].text === null || this.inspectableTypeResults[i].text.length < 1) {
                            this.canSubmit = false;
                            return;
                        }
                        break;
                }
            }
            this.canSubmit = this.inspectableValues.length === this.inspectables.length;
        }
    }
}
</script>

<style>
p {
    padding-left: .5em;
    padding-right: .5em;
    font-size: 1.2em;
}

div.info {
    margin-left: 30px;
}
</style>
