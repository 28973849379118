<template>
<base-modal :min-width="true" @cancel="$emit('close')" style="z-index: 50;">
    <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
    <div class="col-12">
        <h3>{{ $t('velho_equipment.add_new') }}</h3>
    </div>
    <div class="col-sm-12 nopads">
        <b-form class="col-sm-12 editor-form">
            <div class="col-sm-12 nopads pl-1 pr-1">
                <span class="span-title">{{ $t('velho_equipment.select_equipment_type') }}</span>
                <b-form-group label-for="value">
                    <b-form-select size="sm" v-model="selectedEquipment" :disabled="this.loading">
                        <template slot="first">
                            <option :value="null">
                                {{ $t('velho_equipment.select_equipment_type') }}
                            </option>
                        </template>
                        <option
                            v-for="option in velhoEquipmentsSelectors"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.text }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
            <hr>
            <div class="col-sm-12">
                <transition name="fade">
                    <culvert-editor
                        v-if="selectedEquipment === this.EXTERNAL_CULVERTS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <drain-editor
                        v-if="selectedEquipment === this.EXTERNAL_DRAINS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <portal-editor
                        v-if="selectedEquipment === this.EXTERNAL_PORTALS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <gate-editor
                        v-if="selectedEquipment === this.EXTERNAL_GATES"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <roadside-advertisement-editor
                        v-if="selectedEquipment === this.EXTERNAL_ROADSIDE_ADVERTISEMENTS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <post-editor
                        v-if="selectedEquipment === this.EXTERNAL_POSTS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <stair-editor
                        v-if="selectedEquipment === this.EXTERNAL_STAIRS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <roadside-furniture-editor
                        v-if="selectedEquipment === this.EXTERNAL_ROADSIDE_FURNITURE"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <!-- <railing-editor
                        v-if="selectedEquipment === this.EXTERNAL_RAILINGS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    /> -->
                    <booms-barriers-bollards-editor
                        v-if="selectedEquipment === this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <fence-editor
                        v-if="selectedEquipment === this.EXTERNAL_FENCES"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <edge-support-editor
                        v-if="selectedEquipment === this.EXTERNAL_EDGE_SUPPORTS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <!-- <edge-pile-editor
                        v-if="selectedEquipment === this.EXTERNAL_EDGE_PILES"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    /> -->
                    <lighting-editor
                        v-if="selectedEquipment === this.EXTERNAL_LIGHTING"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <pipes-wires-cables-editor
                        v-if="selectedEquipment === this.EXTERNAL_PIPES_WIRES_AND_CABLES"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    />
                    <!-- <road-sign-editor
                        v-if="selectedEquipment === this.EXTERNAL_TRAFFIC_SIGNS"
                        style="pointer-events: all"
                        :addEquipmentEditor="true"
                        :item="equipment"
                    /> -->
                </transition>
            </div>
            <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                <b-button
                    variant="secondary"
                    class="result-button"
                    @click.stop="$emit('close')"
                >
                    {{ $t('common.cancel') }}
                </b-button>
                <b-button
                    variant="success"
                    class="result-button"
                    @click.stop="submit"
                    :disabled="this.loading || this.selectedEquipment === null"
                >
                    {{ $t('common.save') }}
                </b-button>
            </div>
        </b-form>
    </div>
    <div v-if="loading > 0" id="loader" class="spinner"></div>
</base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal'
import {mapHelper} from "@/components/mixins/MapMixin";
import {restApi} from '@/components/mixins/RestApiMixin'
import CulvertEditor from "@/components/velho/CulvertEditor";
import DrainEditor from "@/components/velho/DrainEditor";
import PortalEditor from "@/components/velho/PortalEditor";
import GateEditor from "@/components/velho/GateEditor";
import RoadsideAdvertisementEditor from "@/components/velho/RoadsideAdvertisementEditor";
import PostEditor from "@/components/velho/PostEditor";
import StairEditor from "@/components/velho/StairEditor";
import RoadsideFurnitureEditor from "@/components/velho/RoadsideFurnitureEditor";
// import RailingEditor from "@/components/velho/RailingEditor";
import BoomsBarriersBollardsEditor from "@/components/velho/BoomsBarriersBollardsEditor";
import FenceEditor from "@/components/velho/FenceEditor";
import EdgeSupportEditor from "@/components/velho/EdgeSupportEditor";
// import EdgePileEditor from "@/components/velho/EdgePileEditor";
import LightingEditor from "@/components/velho/LightingEditor";
import PipesWiresCablesEditor from "@/components/velho/PipesWiresCablesEditor";
// import RoadSignEditor from "@/components/velho/RoadSignEditor";

export default {
    name: 'velho-equipment-wizard',
    components: {
        BaseModal,
        CulvertEditor,
        DrainEditor,
        PortalEditor,
        GateEditor,
        RoadsideAdvertisementEditor,
        PostEditor,
        StairEditor,
        RoadsideFurnitureEditor,
        // RailingEditor,
        BoomsBarriersBollardsEditor,
        FenceEditor,
        EdgeSupportEditor,
        // EdgePileEditor,
        LightingEditor,
        PipesWiresCablesEditor,
        // RoadSignEditor
    },
    mixins: [mapHelper, restApi],
    props: {
        coordinate: {
            type: Object
        },
    },
    data: function () {
        return {
            loading: false,
            roadData: {
                roadNum: null,
                roadSec: null,
            },
            velhoCoordinate: this.coordinate,
            selectedEquipment: null,
            equipment: {},
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            variant: 'danger',
            alertMessage: ''
        }
    },
    watch: {
        selectedEquipment(equipmentId) {
            const roadData = {road_num: this.roadData.roadNum, road_sec: this.roadData.roadSec, distance: null}
            const lineGeometryRoadData = {
                location_specification_starting: {
                    location_specification: {
                        lane: null,
                        side: null,
                        tracks: null
                    },
                    ...roadData
                },
                location_specification_ending: {
                    location_specification: {
                        lane: null,
                        side: null,
                        tracks: null
                    },
                    road_num: this.roadData.roadNum,
                    road_sec: this.roadData.roadSec,
                    distance: null
                },
            }
            const equipmentInitData = {
                position: {
                    y: this.velhoCoordinate.lat,
                    x: this.velhoCoordinate.lng
                },
                location_specification: {
                    lane: null,
                    side: null,
                    tracks: null
                },
                equipment_damages: [],
                ...roadData
            }
            // Initialize equipment model
            switch (equipmentId) {
                case this.EXTERNAL_CULVERTS:
                    this.equipment = {
                        ...equipmentInitData,
                        material: null,
                        type: null,
                        external_system_id: "1.2.246.578.4.3.6._",
                    }
                    break;
                case this.EXTERNAL_DRAINS:
                    this.equipment = {
                        ...equipmentInitData,
                        type: null,
                        material: null,
                        cover_type: null,
                        cover_material: null,
                        cover_adjustment_mechanism: null,
                        external_system_id: "1.2.246.578.4.3.12._",
                    }
                    break;
                case this.EXTERNAL_PORTALS:
                    this.equipment = {
                        ...equipmentInitData,
                        type: null,
                        additional_type: null,
                        foundation: null,
                        structure: null,

                        external_system_id: "1.2.246.578.4.3.5._",
                    }
                    break;
                case this.EXTERNAL_GATES:
                    this.equipment = {
                        ...equipmentInitData,
                        material: null,
                        opening_mechanism: null,
                        external_system_id: "1.2.246.578.4.3.4._",
                    }
                    break;
                case this.EXTERNAL_ROADSIDE_ADVERTISEMENTS:
                    this.equipment = {
                        ...equipmentInitData,
                        material: null,
                        lighting: null,
                        external_system_id: "1.2.246.578.4.3.16._",
                    }
                    break;
                case this.EXTERNAL_POSTS:
                    this.equipment = {
                        ...equipmentInitData,
                        material: null,
                        type: null,
                        stand_type: null,
                        stand_material: null,
                        thickness: null,
                        external_system_id: "1.2.246.578.4.3.14._",
                    }
                    break;
                case this.EXTERNAL_STAIRS:
                    this.equipment = {
                        ...equipmentInitData,
                        railing_type: null,
                        material: null,
                        external_system_id: "1.2.246.578.4.3.9._",
                    }
                    break;
                case this.EXTERNAL_ROADSIDE_FURNITURE:
                    this.equipment = {
                        ...equipmentInitData,
                        type: null,
                        material: null,
                        wc_drainage: null,
                        external_system_id: "1.2.246.578.4.3.11._",
                    }
                    break;
                case this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS:
                    this.equipment = {
                        ...equipmentInitData,
                        type: null,
                        material: null,
                        refined_type: null,
                        technique: null,
                        control_type: null,
                        railway_level_crossing_boom_additional_type: null,
                        external_system_id: "1.2.246.578.4.3.10._",
                    }
                    break;
                case this.EXTERNAL_FENCES:
                    this.equipment = {
                        ...equipmentInitData,
                        ...lineGeometryRoadData,
                        type: null,
                        post_material: null,
                        material: null,
                        external_system_id: "1.2.246.578.4.3.2._"
                    }
                    break;
                case this.EXTERNAL_EDGE_SUPPORTS:
                    this.equipment = {
                        ...equipmentInitData,
                        ...lineGeometryRoadData,
                        type: null,
                        additional_type: null,
                        material: null,
                        external_system_id: "1.2.246.578.4.3.7._"
                    }
                    break;
                case this.EXTERNAL_LIGHTING:
                    this.equipment = {
                        ...equipmentInitData,
                        ...lineGeometryRoadData,
                        type: null,
                        post_material: null,
                        material: null,
                        lamp_type: null,
                        cable_installation_method: null,
                        post_type: null,
                        post_safety_class: null,
                        external_system_id: "1.2.246.578.4.3.8._"
                    }
                    break;
                case this.EXTERNAL_PIPES_WIRES_AND_CABLES:
                    this.equipment = {
                        ...equipmentInitData,
                        ...lineGeometryRoadData,
                        type: null,
                        material: null,
                        refined_material: null,

                        external_system_id: "1.2.246.578.4.3.13._"
                    }
                    break;
                /*
                case this.EXTERNAL_TRAFFIC_SIGNS:
                    this.equipment = {
                        ...equipmentInitData,
                        external_system_id: "1.2.246.578.4.3.15._",
                    }
                    break;
                case this.EXTERNAL_EDGE_PILES:
                    this.equipment = {
                        ...equipmentInitData,
                        external_system_id: "1.2.246.578.4.3.3._",
                        location_specification_starting: {
                            location_specification: {
                                lane: null,
                                side: null,
                                tracks: null
                            },
                            ...roadData
                        },
                        location_specification_ending: {
                            location_specification: {
                                lane: null,
                                side: null,
                                tracks: null
                            },
                            ...roadData
                        },
                    }
                    break;
                case this.EXTERNAL_RAILINGS:
                    this.equipment = {
                        external_system_id: "1.2.246.578.4.3.1._",
                        position: {
                            y: this.velhoCoordinate.lat,
                            x: this.velhoCoordinate.lng
                        },
                        location_specification_starting: {
                            ...roadData
                        },
                        location_specification_ending: {
                            ...roadData
                        },
                        ...roadData
                    }
                    break; */
                default:
                    break;
            }
        }
    },
    computed: {
        velhoEquipmentsSelectors() {
            return [
                /* {
                    text: this.$t("external_road_signs.road_signs"),
                    value: this.EXTERNAL_TRAFFIC_SIGNS
                }, */
                {
                    text: this.$t("infra.posts"),
                    value: this.EXTERNAL_POSTS
                },
                {
                    text: this.$t("culvert.title"),
                    value: this.EXTERNAL_CULVERTS,
                },
                /* {
                    text: this.$t("railing.title"),
                    value: this.EXTERNAL_RAILINGS,
                }, */
                {
                    text: this.$t("portal.title"),
                    value: this.EXTERNAL_PORTALS,
                },
                {
                    text: this.$t("drain.title"),
                    value: this.EXTERNAL_DRAINS,
                },
                {
                    text: this.$t("fence.title"),
                    value: this.EXTERNAL_FENCES,
                },
                {
                    text: this.$t("stair.title"),
                    value: this.EXTERNAL_STAIRS,
                },
                {
                    text: this.$t("gate.title"),
                    value: this.EXTERNAL_GATES,
                },
                {
                    text: this.$t("edge_support.title"),
                    value: this.EXTERNAL_EDGE_SUPPORTS,
                },
                /* {
                    text: this.$t("edge_pile.title"),
                    value: this.EXTERNAL_EDGE_PILES,
                }, */
                {
                    text: this.$t("roadside_furniture.title"),
                    value: this.EXTERNAL_ROADSIDE_FURNITURE,
                },
                {
                    text: this.$t("roadside_advertisement.title"),
                    value: this.EXTERNAL_ROADSIDE_ADVERTISEMENTS,
                },
                {
                    text: this.$t("boom_barrier_bollard.title"),
                    value: this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS,
                },
                {
                    text: this.$t("pipe_wire_cable.title"),
                    value: this.EXTERNAL_PIPES_WIRES_AND_CABLES,
                },
                {
                    text: this.$t("lighting.title"),
                    value: this.EXTERNAL_LIGHTING,
                }
            ]
        }
    },
    mounted() {
        this.fetchRoadDataByCoordinates(this.velhoCoordinate)
    },
    methods: {
        fetchRoadDataByCoordinates(coord) {
            try {
                this.loading = true
                this.restFetchParams(this.roadGeometryLocationUrl, { lat: coord.lat, lon: coord.lng}, (response) => {
                    this.loading = false
                    if(response.data) {
                        this.roadData = {
                            roadNum: response.data.m_road_num,
                            roadSec: response.data.m_road_sec_num
                        }
                    }
                }, () => { this.loading = false })
            } catch (error) {
                this.loading = false
            }
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        submit() {
            let data = {}
            if([this.EXTERNAL_RAILINGS, this.EXTERNAL_FENCES, this.EXTERNAL_PIPES_WIRES_AND_CABLES, this.EXTERNAL_EDGE_SUPPORTS, this.EXTERNAL_EDGE_PILES, this.EXTERNAL_LIGHTING].includes(this.selectedEquipment)) {
                data = {
                    ...this.equipment,
                    location_specification: {
                        starting: {
                            ...this.equipment.location_specification_starting,
                            location_specification: {
                                ...this.equipment.location_specification_starting.location_specification,
                                tracks: this.equipment.location_specification_starting.location_specification.tracks !== null ? [this.equipment.location_specification_starting.location_specification.tracks] : []
                            }
                        },
                        ending: {
                            ...this.equipment.location_specification_ending,
                            location_specification: {
                                ...this.equipment.location_specification_ending.location_specification,
                                tracks: this.equipment.location_specification_ending.location_specification.tracks !== null ? [this.equipment.location_specification_ending.location_specification.tracks] : []
                            }
                            
                        }
                    }
                }
                delete data.location_specification_starting
                delete data.location_specification_ending
            } else {
                data = {
                    ...this.equipment,
                    location_specification: {
                        ...this.equipment.location_specification,
                        tracks: this.equipment.location_specification.tracks !== null ? [this.equipment.location_specification.tracks] : []
                    }
                }
                if(this.selectedEquipment === this.EXTERNAL_TRAFFIC_SIGNS) {
                    data.sign = this.equipment.sign ? this.equipment.sign.value : null
                    data.sec_num = this.equipment.road_sec
                    delete this.equipment.road_sec
                }
            }
            this.loading = true
            try {
                this.restAdd(this.extEquipmentUrl, data, () => {
                    this.loading = false
                    this.$emit('close')
                }, () => {
                    this.loading = false
                    this.showAlert(this.$t('common.alert_add_failed'))
                })
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>
<style>
 .modal-container {
    min-width: 70%;
}
@import "../../styles/modal.css";
</style>