<template>
    <div class="col-sm-12 nopads">
        <div class="search-form">
            <div class="col-sm-12 search-form-title">
                <font-awesome-icon icon="search"/>
                {{ $t('common.search') }}
            </div>
            <b-form
                class="no-margin"
                @submit.prevent="onSubmit"
                @reset.prevent="onReset"
            >
                <b-row class="nopads p-2">
                    <area-filter
                        ref="areaFilter"
                        :is-admin="isAdmin"
                        @areaSet="setArea"
                        @companySet="setCompany"
                    />
                </b-row>

                <b-row class="nopads p-2">
                    <div class="col-sm-6 nopads">
                        <span class="span-title">{{ $t('equipment.name') }}</span>
                        <b-form-group
                            class="title nopads"
                            label-for="equipment_name"
                        >
                            <b-form-input
                                id="equipment_name"
                                v-model="name"
                                type="text"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                </b-row>

                <div class="col-sm-12 button-container">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="result-button"
                        :disabled="(isAdmin && !company)"
                    >
                        {{ $t('ins_report.search') }}
                    </b-button>
                    <b-button
                        type="reset"
                        variant="danger"
                        class="result-button"
                    >
                        {{ $t('ins_report.clear') }}
                    </b-button>
                </div>
            </b-form>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import AreaFilter from '../AreaFilter'

export default {
    name: 'AreaEquipmentFilter',
    components: {AreaFilter},
    mixins: [restApi],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            name: null,
            area: null,
            company: null
        }
    },

    mounted: function () {
    },

    methods: {
        onReset: function () {
            this.name = null
            this.area = null
            this.company = null
            this.$refs.areaFilter.resetData()
        },
        onSubmit: function () {
            var params = {}
            if (this.area) {
                params.area = this.area.id
            }
            if (this.name) {
                params.name = this.name
            }
            if (this.company) {
                params.company = this.company
            }
            this.$emit('search', params)
        },

        setArea: function (area) {
            this.area = area
        },

        setCompany: function (company) {
            this.company = company
        }
    }
}
</script>

<style scoped>

</style>
