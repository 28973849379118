<template>
    <div class="d-flex flex-column w-100">
        <div class="map-item-filter">
            <!-- Info box -->
            <div
                class="col-sm-12 nopads vehicle-list"
                v-bind:class="{ list_closed: !listOpen }"
            >
                <span
                    class="vehicle-list-title"
                    @click.stop="toggleList">
                {{ $t('sports.skiing_tracks') }}
                </span>
                <div
                    class="vehicle-list-caret"
                    v-bind:class="{ caret_open: listOpen }"
                    @click.stop="toggleList">
                    <font-awesome-icon icon="caret-down"/>
                </div>
                <div class="col-sm-12 nopads vehicle-list-container p-2" v-if="listOpen">
                    <div class="badge-filters__wrapper">
                        <div class="badge-filters">
                            <b-badge
                                v-for="item in routeTypeOptions"
                                :key="item.value"
                                @click="routeType = item.value"
                                :variant="routeType === item.value ? 'primary' : 'light'"
                                style="padding: .5em 1em"
                            >
                                {{ item.text }}
                            </b-badge>
                        </div>
                    </div>
                    <b-form-select
                        @change="setSelectedCity"
                        :value="selectedCity"
                        :options="cityOptions"
                        size="sm"
                    />
                </div>
                <!-- Work records -->
            </div>
        </div>
        <map-ski-track-work-history
            class="nopads"
            style="pointer-events: all"
            v-if="selectedCity"
            ref="workHistory"
            @onAddWork="addWork"
            @trackClicked="trackClicked"
        />
        <ski-track-work-editor
            class="map-item-filter"
            v-if="showWorkEditor"
            :user="user"
            :track="selectedTrack"
            :city="selectedCity"
            @close="showWorkEditor = false"
            @closeAndUpdate="updateWorkRecords"
        />
        <!-- Spinner -->
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from '../mixins/MapMixin'
import {sportHelper} from '../mixins/SportMixin'
import {timeUtils} from '../mixins/TimeUtils'
import MapSkiTrackWorkHistory from "./MapSkiTrackWorkHistory"
import SkiTrackWorkEditor from "./SkiTrackWorkEditor"
import {EventBus} from "../../event-bus"

export default {
    name: 'MapSkiing',
    components: {SkiTrackWorkEditor, MapSkiTrackWorkHistory},
    mixins: [restApi, mapHelper, sportHelper, timeUtils],
    props: {
        map: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            fetching: false,
            showWorkEditor: false,
            fromTime: null,
            tracks: [],
            polyLines: [],
            listOpen: true,
            selectedCity: undefined,
            cityOptions: [
                { value: null, text: this.$t('sports.select_city') },
                { value: 5, text: 'Alajärvi' },
                { value: 139, text: 'Ii' },
                { value: 179, text: 'Jyväskylä' },
                { value: 249, text: 'Keuruu' },
                { value: 241, text: 'Keminmaa' },
                { value: 244, text: 'Kempele' },
                { value: 257, text: 'Kirkkonummi' },
                { value: 410, text: 'Laukaa'},
                { value: 416, text: 'Lemi'},
                { value: 441, text: 'Luumäki'},
                { value: 498, text: 'Muonio' },
                { value: 577, text: 'Paimio' },
                { value: 859, text: 'Tyrnävä' },
                { value: 992, text: 'Äänekoski' }
            ],
            routeType: null,
            selectedTrack: null
        }
    },
    watch: {
        selectedCity() {
            this.onParamsChanged()
        },
        routeType() {
          this.onParamsChanged()
        }
    },

    computed: {
        routeTypeOptions() {
            return [
                { text: this.$t('sports.tracks_all'), value: null },
                { text: this.$t('sports.skiing_tracks'), value: this.TRACK_SKIING },
                //{ text: this.$t('sport.track_cycling'), value: this.TRACK_SKIING },
                { text: this.$t('sports.tracks_offroad_cycling'), value: this.TRACK_CYCLING },
                { text: this.$t('sports.tracks_walking'), value: this.TRACK_WALKING }
                //{ text: this.$t('sports.tracks_snowmobile'), value: this.TRACK_SNOWMOBILE },
            ]
        }
    },

    async mounted() {
        if (this.selectedCity === undefined) {
            this.selectedCity = await this.getMapSettingsIn(['buttons','skiing','city'], null);
        }
        this.fromTime = new Date()
        // TODO - Allow user to choose time range
        this.fromTime = this.fromTime.setHours(this.fromTime.getHours() - 48)
        this.routeType = this.TRACK_SKIING
    },

    beforeDestroy: function () {
        this.hideTrackInfo()
        this.hideTracks()
        this.hideSnapshot()
    },

    methods: {
        onParamsChanged()  {
            if (this.selectedCity != null) {
                this.fetchTracks()
                this.fetchRecords()
                this.selectedTrack = null
            } else {
                this.hideTracks()
                this.hideSnapshot()
            }
        },

        setSelectedCity(value) {
            this.selectedCity = value;
            this.updateMapSettings({ buttons: { skiing: { city: value } } });
            this.saveMapSettings();
        },

        fetchTracks: function () {
            this.loading = true
            this.hideTracks()
            this.restFetchParams(this.sportTrackUrl, {
                city: this.selectedCity,
                type: this.routeType
            }, this.handleResponse);
        },

        fetchTracksById(trackId) {
            this.loading = true
            this.hideTracks()
            this.restFetch(this.skiingTrackUrl + '/' + trackId, this.handleResponseAndFocus, this.handleError)
        },

        handleResponse: function (response) {
            if (response && response.data) {
                this.tracks = response.data
                this.showTracks(response.data)
            }
            this.loading = false
        },

        handleResponseAndFocus: function (response) {
            if (response && response.data) {
                this.tracks = response.data
                this.showTracks(response.data)
                this.map.zoomToPosition(response.data[0].geometry[0]['y'], response.data[0].geometry[0]['x'])
            }
            this.loading = false
        },

        hideTracks: function () {
            this.polyLines.forEach(line => {
                this.map.removePolyline(line, this.SKI_TRACK)
            })
        },

        showTracks: function (tracks) {
            tracks.forEach(item => {
                if (item.geometry && item.geometry.length > 1) {
                    this.drawSkiingTrack(item.id, item.typeName, item.geometry)
                    this.polyLines.push(item.id)
                }
            })
        },

        drawSkiingTrack: function (id, type, coords) {
            let color = this.getTypeColor(type)
            this.map.drawPolyLine(id, this.SKI_TRACK, coords, color, false, true, 3)
        },

        onPolylineTap: function (data) {
           this.showTrackInfo(data.id)
        },

        showTrackInfo: function (id) {
            this.hideTrackInfo()
            let track = this.tracks.find(item => item.id === id)
            if (track) {
                let data = [
                    {name: this.$t('sports.route'), value: track.routeName}
                ]
                this.map.showPolylineBubble(track.id, this.SKI_TRACK, track.geometry[0].y, track.geometry[0].x, this.$t('sports.skiing_track'), data)
                this.visibleBubble = id
                this.selectedTrack = track
            }
        },

        hideTrackInfo: function () {
            if (this.visibleBubble) {
                this.map.hidePolylineBubble(this.visibleBubble, this.SKI_TRACK)
                this.selectedTrack = null
            }
        },

        getTypeColor: function (type) {
            switch (parseInt(type)) {
                case this.SKI_FREESTYLE:
                    return '#52f0ff'
                case this.SKI_CLASSIC:
                    return '#5148ff'
                case this.SKI_LIGHTS:
                    return '#ffbd13'
                case this.SKI_COMPETITION:
                    return '#e454ff'
            }
            return '#2d3b38'
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        // Work history
        fetchRecords: function () {
            if (!this.fetching) {
                this.fetching = true
                this.loading = true
                this.hideSnapshot()
                this.restFetchParams(this.skiTrackRecordsUrl, {
                    city: this.selectedCity,
                    from: this.localTimeToUtcTime(this.fromTime)
                }, this.handleWorkResponse)
            }
        },

        handleWorkResponse: function (response) {
            this.loading = false
            this.fetching = false
            if (response) {
                this.results = response.data
                this.showSnapshot()
            }
        },

        hideSnapshot: function () {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.polyLines.forEach(line => {
                this.map.removePolyline(line, this.WORK_TRACE)
            })
        },

        showSnapshot: function () {
            this.results.forEach(item => {
                let workCount = item.work_records.length;
                item.work_records.forEach(work => {
                    this.drawWorkTrace(work.id, item.geometry, work.color, 6 + workCount, this.getTransparency(work.age)); // Default linewidth is 3
                    this.polyLines.push(work.id)
                    workCount--
                })
            })
        },

        drawWorkTrace: function (id, coords, color, width, transparency) {
            this.map.drawPolyLine(id, this.WORK_TRACE, coords, color, false, false, width, transparency)
        },

        getTransparency: function (age) {
            let maxAge = Math.round((Date.now() - this.fromTime) / 1000 / 60)
            if (age > maxAge) {
                return 0.1;
            }
            // Scale age to 0-1
            return 1 - (age / maxAge)
        },

        addWork: function () {
            if (this.selectedTrack) {
                this.showWorkEditor = true
            } else {
                EventBus.$emit('show-alert', this.$t('sports.select_track_first'))
            }
        },

        trackClicked(item) {
            this.fetchTracksById(item.track_id)
        },

        updateWorkRecords() {
            this.$refs.workHistory.refreshHistory()
            this.fetchRecords()
            this.showWorkEditor = false
        }

    }
}
</script>
