<template>
    <div
        class="col-sm-12 nopads vehicle-list"
        v-bind:class="{ list_closed: !listOpen }"
    >
        <span
            class="vehicle-list-title"
            @click.stop="toggleList">
            {{ $t('areas.work_history') }}
        </span>
        <div
            class="vehicle-list-caret"
            v-bind:class="{ caret_open: listOpen }"
            @click.stop="toggleList">
            <font-awesome-icon icon="caret-down"/>
        </div>
        <div class="col-sm-12 nopads vehicle-list-container" v-if="listOpen">

            <div class="col-12 align-right">
                <b-button
                    class="m-1"
                    variant="success"
                    size="md"
                    @click.stop="addWork"
                >
                    <span class="map-button-text">{{ $t('common.add_new') }}</span>
                </b-button>
            </div>


            <div
                class="map-select-item"
            >
                <div v-if="results.length > 0">
                    <div
                        v-for="item of results"
                        :key="item.id"
                        class="vehicle-list-item"
                        style="cursor: default;"
                        @click="$emit('trackClicked', item)"
                    >
                        <b-row class="nopads">
                            <div class="vehicle-list-icon">
                                <font-awesome-icon
                                    :icon="item.added_by ? 'user' : 'route'"
                                />
                            </div>
                            <div class="vehicle-list-header">{{ item.track_name ? item.track_name : 'N/A' }}</div>
                        </b-row>
                        <div class="item-detail-text" style="font-weight: bold">{{ toLocalTime(item.time) }}</div>
                        <div class="item-detail-text">{{ item.task_type_name }}</div>
                    </div>
                </div>
                <div v-else>
                    <span class="text-light m-2" v-if="loading">
                        {{  $t('areas.loading_history') }}
                    </span>
                    <span class="text-light m-2" v-else>
                        {{  $t('areas.no_history') }}
                    </span>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import {restApi} from '../mixins/RestApiMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'MapSkiTrackWorkHistory',
    mixins: [restApi, timeUtils],
    data: function () {
        return {
            loading: false,
            results: [],
            listOpen: false
        }
    },
    mounted: function () {
        this.fetchWorkRecords()
    },
    methods: {
        fetchWorkRecords() {
            this.results = []
            this.loading = true
            // TODO - Allow user to choose time range like in vehicle work
            let fromTime = new Date()
            fromTime.setDate(fromTime.getDate() - 30)
            this.restFetchParams(this.skiingTrackWorkUrl, {
                from: this.localTimeToUtcTime(fromTime)
            }, this.success, this.fail)
        },

        success: function (response) {
            this.loading = false
            this.results = response.data
        },

        fail: function () {
            this.loading = false
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        refreshHistory() {
            this.fetchWorkRecords()
        },

        addWork() {
            this.$emit('onAddWork')
        }

    }
}
</script>
