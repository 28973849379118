<template>
    <div class="content-container" ref="container">
        <h2 class="p-2 pl-3">{{ $t('menu.new_inspection') }}</h2>
        <div class="col-sm-12 nopads">
            <!-- Step one - select inspection type -->
            <transition name="fade">
                <div v-if="phase === START" class="col-10 mx-auto nopads">
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads">
                            <div class="inspection-wizard-selector" @click.stop="setTypeAndProceed(WORK_INSPECTION)">
                                <font-awesome-icon icon="hard-hat" class="inspection-wizard-selector-icon"/>
                                <span
                                    class="title inspection-wizard-selector-text">{{
                                        $t('ins_wizard.work_inspection')
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6 nopads">
                            <div class="inspection-wizard-selector" @click.stop="setTypeAndProceed(TASK_INSPECTION)">
                                <font-awesome-icon icon="clipboard-list" class="inspection-wizard-selector-icon"/>
                                <span
                                    class="title inspection-wizard-selector-text">{{
                                        $t('ins_wizard.task_inspection')
                                    }}</span>
                            </div>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads">
                            <div class="inspection-wizard-selector" @click.stop="setTypeAndProceed(VEHICLE_INSPECTION)">
                                <font-awesome-icon icon="car" class="inspection-wizard-selector-icon"/>
                                <span
                                    class="title inspection-wizard-selector-text">{{
                                        $t('ins_wizard.vehicle_inspection')
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6 nopads" @click.stop="setTypeAndProceed(AREA_INSPECTION)">
                            <div class="inspection-wizard-selector">
                                <font-awesome-icon icon="draw-polygon" class="inspection-wizard-selector-icon"/>
                                <span
                                    class="title inspection-wizard-selector-text">{{
                                        $t('ins_wizard.area_inspection')
                                    }}</span>
                            </div>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads">
                            <div class="inspection-wizard-selector" @click.stop="setTypeAndProceed(BRIDGE_INSPECTION)">
                                <font-awesome-icon icon="road" class="inspection-wizard-selector-icon"/>
                                <span
                                    class="title inspection-wizard-selector-text">{{
                                        $t('ins_wizard.bridge_inspection')
                                    }}</span>
                            </div>
                        </div>
                        <div class="col-sm-6 nopads">
                            <div class="inspection-wizard-selector" @click.stop="setTypeAndProceed(CULVERT_INSPECTION)">
                                <font-awesome-icon icon="archway" class="inspection-wizard-selector-icon"/>
                                <span
                                    class="title inspection-wizard-selector-text">{{
                                        $t('ins_wizard.culvert_inspection')
                                    }}</span>
                            </div>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads">
                            <div class="inspection-wizard-selector" @click.stop="setTypeAndProceed(COMPANY_INSPECTION)">
                                <font-awesome-icon icon="building" class="inspection-wizard-selector-icon"/>
                                <span
                                    class="title inspection-wizard-selector-text">{{
                                        $t('ins_wizard.company_inspection')
                                    }}</span>
                            </div>
                        </div>
                    </b-row>
                </div>
            </transition>

            <!-- Work inspection selector -->
            <transition name="fade">
                <inspection-selector v-if="phase === INSPECTION_SELECTION"
                                     :inspection-type="reportType"
                                     :vehicle-type="vehicle?vehicle.vehicle_type:null"
                                     @onInspectionSelected="setInspectionTemplate"/>
            </transition>
            <!-- Task inspection selector -->
            <transition name="fade">
                <inspection-order-selector v-if="phase === TARGET_SELECTION && inspectionType === TASK_INSPECTION"
                                           @onOrderSelected="setOrder"/>
            </transition>
            <!-- Vehicle selector -->
            <transition name="fade">
                <vehicle-selector v-if="phase === VEHICLE_SELECTION && inspectionType === VEHICLE_INSPECTION"
                                  @onVehicleSelected="setVehicle"/>
            </transition>
            <!-- Customer selector -->
            <transition name="fade">
                <contractor-selector v-if="phase === TARGET_SELECTION && inspectionType === COMPANY_INSPECTION"
                                     @onCompanySelected="setInspectedCompany"/>
            </transition>
            <!-- Contract selector -->
            <transition name="fade">
                <inspection-contract-selector v-if="phase === TARGET_SELECTION &&
        (inspectionType === WORK_INSPECTION || inspectionType === VEHICLE_INSPECTION || inspectionType === BRIDGE_INSPECTION || inspectionType === CULVERT_INSPECTION)"
                                              :user="user"
                                              :is-observer="isObserver"
                                              @onContractSelected="setContract"/>
            </transition>
            <!-- Bridges selector -->
            <transition name="fade">
                <inspection-bridge-selector v-if="phase === BRIDGE_SELECTION && inspectionType === BRIDGE_INSPECTION"
                                            @onBridgeSelected="setInspectedBridge"/>
            </transition>
            <!-- Culvert selector -->
            <transition name="fade">
                <inspection-culvert-selector v-if="phase === CULVERT_SELECTION && inspectionType === CULVERT_INSPECTION"
                                             @onCulvertSelected="setInspectedCulvert"/>
            </transition>
            <!-- Inspection form -->
            <transition name="fade">
                <inspection-form v-if="phase === INSPECTION"
                                 :inspection-template="inspectionTemplate"
                                 @onSubmitResults="submitResults"
                                 :loading="loading"/>
            </transition>
            <transition name="fade">
                <area-report-editor
                    v-if="phase===AREA_INSPECTION_WIZARD"
                    @closeAndUpdate="closeAndUpdate"
                />
            </transition>

            <div class="inspection-back-btn">
                <!-- Back button -->
                <b-button
                    class="inspection-back-btn-shadow"
                    v-if="phase !== START"
                    variant="primary"
                    @click.stop="goBack"
                >
                    <font-awesome-icon icon="arrow-left"/>
                </b-button>
                <!-- Next button -->
                <b-button
                    class="inspection-back-btn-shadow"
                    v-if="phase === TARGET_SELECTION && (inspectionType === VEHICLE_INSPECTION || inspectionType === BRIDGE_INSPECTION || inspectionType === CULVERT_INSPECTION)"
                    variant="primary"
                    @click.stop="setContract(null)"
                >
                    <font-awesome-icon icon="arrow-right"/>
                </b-button>
            </div>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
        <b-alert
            class="alert"
            :show="noteDismissCountDown"
            dismissible
            fade
            variant="success"
            @dismissed="noteDismissCountDown=0"
            @dismiss-count-down="noteCountDownChanged"
        >
            {{ message }}
        </b-alert>
    </div>
</template>

<script>

import InspectionSelector from "./InspectionSelector"
import InspectionContractSelector from "./InspectionContractSelector"
import VehicleSelector from "./VehicleSelector"
import InspectionForm from "./InspectionForm"
import ContractorSelector from "./ContractorSelector"
import {EventBus} from "@/event-bus"
import {restApi} from '../../mixins/RestApiMixin'
import InspectionOrderSelector from "./InspectionOrderSelector";
import AreaReportEditor from "../../area/areareport/AreaReportEditor";
import {attachmentHelper} from '../../mixins/AttachmentMixin'
import InspectionBridgeSelector from "@/components/inspections/wizard/InspectionBridgeSelector";
import InspectionCulvertSelector from "@/components/inspections/wizard/InspectionCulvertSelector";

export default {
    name: 'InspectionWizard',
    components: {
        InspectionCulvertSelector,
        InspectionBridgeSelector,
        InspectionOrderSelector,
        InspectionForm,
        InspectionContractSelector,
        InspectionSelector,
        VehicleSelector,
        AreaReportEditor,
        ContractorSelector
    },
    mixins: [restApi, attachmentHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isObserver: {
            type: Boolean,
            default: false
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        inspectionItem: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            START: 0,
            INSPECTION_SELECTION: 1,
            TARGET_SELECTION: 2,
            VEHICLE_SELECTION: 4,
            INSPECTION: 3,
            AREA_INSPECTION_WIZARD: 5,
            BRIDGE_SELECTION: 6,
            CULVERT_SELECTION: 7,
            phase: this.START,
            inspectionType: null,
            reportType: null,
            inspectionTemplate: null,
            contract: null,
            vehicle: null,
            order: null,
            area: null,
            inspectedCompany: null,
            bridge: null,
            culvert: null,
            results: [],
            noteDismissSecs: 5,
            noteDismissCountDown: 0,
            loading: false,
            message: null,
            VEHICLE_INSPECTION: 1,
            WORK_INSPECTION: 2,
            TASK_INSPECTION: 3,
            COMPANY_INSPECTION: 4,
            BRIDGE_INSPECTION: 5,
            CULVERT_INSPECTION: 6,
            AREA_INSPECTION: 7,
            photos: null,
            uploadedResults: null
        }
    },
    mounted() {
        this.phase = this.START
        if (this.inspectionItem) {
            if (this.inspectionItem.inspectionType === "bridge") {
                this.setTypeAndProceed(this.BRIDGE_INSPECTION)
                this.setInspectedBridge(this.inspectionItem)
            } else if (this.inspectionItem.inspectionType === "culvert") {
                this.setTypeAndProceed(this.CULVERT_INSPECTION)
                this.setInspectedCulvert(this.inspectionItem)
            }
        }
    },
    methods: {
        setTypeAndProceed(type) {
            this.photos = null
            this.uploadedResults = null
            this.inspectionType = type
            if (type === this.TASK_INSPECTION) {
                this.reportType = this.WORK_INSPECTION
            } else {
                this.reportType = this.inspectionType
            }
            if (type === this.VEHICLE_INSPECTION) {
                this.phase = this.VEHICLE_SELECTION
            } else if (type === this.BRIDGE_INSPECTION) {
                this.phase = this.BRIDGE_SELECTION
            } else if (type === this.CULVERT_INSPECTION) {
                this.phase = this.CULVERT_SELECTION
            } else if (type === this.AREA_INSPECTION) {
                this.phase = this.AREA_INSPECTION_WIZARD
            } else {
                this.phase = this.INSPECTION_SELECTION
            }
            this.scrollTop()
        },
        setInspectionTemplate(template) {
            this.inspectionTemplate = template
            this.phase = this.TARGET_SELECTION
            this.scrollTop()
        },
        setContract(contract) {
            this.contract = contract
            this.phase = this.INSPECTION
            this.scrollTop()
        },
        setInspectedCompany(company) {
            this.inspectedCompany = {id: company.id, name: company.name}
            this.phase = this.INSPECTION
        },
        setInspectedBridge(bridge) {
            this.bridge = bridge
            this.phase = this.INSPECTION_SELECTION
            this.scrollTop()
        },
        setInspectedCulvert(culvert) {
            this.culvert = culvert
            this.phase = this.INSPECTION_SELECTION
            this.scrollTop()
        },
        setVehicle(vehicle) {
            this.vehicle = vehicle
            this.phase = this.INSPECTION_SELECTION
            this.scrollTop()
        },
        setOrder(order) {
            this.order = order
            this.phase = this.INSPECTION
            this.scrollTop()
        },
        goBack() {
            this.scrollTop()
            this.loading = false
            if (this.phase === this.INSPECTION_SELECTION) {
                this.inspectionTemplate = null
                if (this.inspectionType === this.VEHICLE_INSPECTION) {
                    this.phase = this.VEHICLE_SELECTION
                } else if (this.inspectionType === this.BRIDGE_INSPECTION) {
                    this.phase = this.BRIDGE_SELECTION
                } else if (this.inspectionType === this.CULVERT_INSPECTION) {
                    this.phase = this.CULVERT_SELECTION
                } else {
                    this.inspectionType = null
                    this.phase = this.START
                }
            } else if (this.phase === this.TARGET_SELECTION) {
                this.phase = this.INSPECTION_SELECTION
                this.order = null
                this.contract = null
            } else if (this.phase === this.INSPECTION) {
                this.phase = this.TARGET_SELECTION
            } else if (this.phase === this.VEHICLE_SELECTION || this.phase === this.BRIDGE_SELECTION || this.phase === this.CULVERT_SELECTION) {
                this.inspectionType = null
                this.phase = this.START
                this.vehicle = null
                this.bridge = null
                this.culvert = null
            } else if (this.phase === this.AREA_INSPECTION_WIZARD) {
                this.inspectionType = null
                this.phase = this.START
            }
        },
        submitResults(results, photos) {
            this.loading = true
            this.photos = photos
            if (this.contract) {
                results.contract = this.contract
            }
            if (this.order) {
                results.order = this.order
            }
            if (this.vehicle) {
                results.vehicle = this.vehicle
            }
            if (this.area) {
                results.area = this.area
            }
            if (this.bridge) {
                results.bridge_id = this.bridge.digiroad_id
            }
            if (this.culvert) {
                results.culvert_id = this.culvert.digiroad_id
            }
            if (this.inspectedCompany) {
                results.inspected_company = this.inspectedCompany
            }
            results.type = this.inspectionTemplate.type
            results.frequency = this.inspectionTemplate.frequency
            results.name = this.inspectionTemplate.name
            results.template = this.inspectionTemplate.id
            this.restAdd(this.inspectionReportGenericUrl, results, this.submitResultsSuccess, this.submitResultsFail)
        },
        submitResultsSuccess: function (data) {
            this.uploadedResults = data.results
            this.uploadPhoto()
        },
        uploadPhoto: function () {
            if (this.uploadedResults.length > 0) {
                let result = this.uploadedResults[0]
                this.uploadedResults.splice(0, 1)
                let inspectablesPhotos = this.getValidAttachments(this.photos[result.inspectable_id])
                if (inspectablesPhotos.length > 0) {
                    let formData = new FormData()
                    let type = this.inspectionTemplate.type
                    if (type === this.WORK_INSPECTION && this.order) {
                        type = this.TASK_INSPECTION // If work inspection has order, it saved as task inspection in the backend
                    }
                    formData.append('inspection_type', type)
                    inspectablesPhotos.forEach(photoFile => {
                        formData.append('photos[]', photoFile)
                    })
                    this.restPostWithFile(this.inspectionResult + result.id + '/photo',
                        {}, formData, this.uploadPhoto, this.uploadPhotoFail
                    )
                } else {
                    this.uploadPhoto()
                }
            } else {
                this.closeAndUpdate()
            }
        },
        uploadPhotoFail: function () {
            this.closeAndUpdate()
            this.showMessage(this.$t('ins_wizard.photo_upload_failed'))
        },
        closeAndUpdate: function () {
            this.$emit('closeAndUpdate')
            this.order = null
            this.contract = null
            this.vehicle = null
            this.inspectionTemplate = null
            this.inspectionType = null
            this.loading = false
            this.showMessage(this.$t('ins_wizard.submitted'))
            this.phase = this.START
        },
        submitResultsFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
            this.loading = false
        },
        showMessage: function (message) {
            this.noteDismissCountDown = this.noteDismissSecs
            this.message = message
        },
        noteCountDownChanged: function (dismissCountDown) {
            this.noteDismissCountDown = dismissCountDown
        },
        scrollTop: function () {
            this.$nextTick(function () {
                this.$refs.container.scrollTop = 0
            })
        }
    }
}
</script>

