<template>
    <b-form-group className="title">
        <div>
            <span className="span-title">
            </span>
            <span v-if="hint" className="col-12 note p-1">{{ hint}}</span>
            <b-form-file
                ref="file-input"
                :value="value"
                :disabled="disabled"
                v-on:input="updateValue($event)"
                :accept="extension"
                :placeholder="label +' '+ $t('import_file_wizard.max_shapefile_size')"
            />
        </div>
        <div>
            <span v-if="invalidFile"
                  className="subtitle"
                  style="color: red">
                {{ $t('import_file_wizard.shape_file_too_big') }}
            </span>
        </div>
    </b-form-group>
</template>
<script>
export default {
    name: 'geojson-file-picker',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: File,
            default: null
        },
        extension: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            required: false,
            default: null
        }
    },
    data: function () {
        return {
            invalidFile: false
        }
    },
    methods: {
        updateValue: function (value) {
            if (value !== null) {
                let maxFileSize = 4 * 1024 * 1024 // 2 MB
                this.invalidFile = value.size > maxFileSize
                if (this.invalidFile) {
                    value = null
                    this.$refs['file-input'].reset()
                }
            }
            this.$emit('input', value)
        }
    }
}
</script>
