<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div
        id="vehicle-list"
        class="col-sm-12 pl-3 pr-3 pb-4"
    >
        <transition name="fade">
            <div
                class="col-sm-12 list-content result-table"
            >
                <b-table
                    id="resultTable"
                    ref="resultTable"
                    :small="true"
                    :responsive="true"
                    :items="tableItems"
                    :fields="fields"
                    @row-clicked="emitEdit"
                >
                    <template slot="empty">
                        <div class="text-center my-2">
                            {{ $t('area_report.no_results') }}
                        </div>
                    </template>
                    <template v-slot:cell(task_types)="row">
                        <div v-if="row.item.is_contract_party" class="nopads">
                            <div v-if="!row.item.task_types || row.item.task_types.length < 1">
                                <b-tooltip :target="'contract'+row.item.id" triggers="hover"  placement="right">
                                    {{ $t('contracts.no_task_types') }}
                                </b-tooltip>
                                <font-awesome-icon
                                    :id="'contract'+row.item.id"
                                    icon="exclamation-circle"
                                    class="ban"
                                />
                            </div>
                            <div v-else-if="checkOrders(row.item)">
                                <b-tooltip :target="'contract'+row.item.id" triggers="hover" placement="right">
                                    {{ $t('contracts.no_ongoing_orders') }}
                                </b-tooltip>
                                <font-awesome-icon
                                    :id="'contract'+row.item.id"
                                    icon="exclamation-circle"
                                    class="defect"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell(id)="row">
                        {{ row.item.id }}
                    </template>
                    <template v-slot:cell(name)="row">
                        {{ row.item.name }}
                    </template>
                    <template v-slot:cell(customer)="row">
                        {{ row.item.customer.name }}
                    </template>
                    <template v-slot:cell(contractor)="row">
                        {{ row.item.contractor.name }}
                    </template>
                    <template v-slot:cell(start_date)="row">
                        {{ getShortDateWithoutTimezone(row.item.start_date) }}
                    </template>
                    <template v-slot:cell(end_date)="row">
                        {{ getShortDateWithoutTimezone(row.item.end_date) }}
                    </template>
                </b-table>
            </div>
        </transition>
    </div>
</template>

<script>
import {workManagementHelper} from '../mixins/WorkManagementMixin'
import {timeUtils} from '../mixins/TimeUtils'

export default {
    name: 'ContractList',
    mixins: [timeUtils, workManagementHelper],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            sortBy: 'tasktype',
            sortDesc: false,
            tableItems: [],
            fields: [
                {key: 'task_types', label: "", sortable: true},
                {key: 'id', label: 'Id', sortable: true},
                {key: 'name', label: this.$t('contracts.name'), sortable: true},
                {key: 'contract_number', label: this.$t('contracts.number'), sortable: true},
                {key: 'customer', label: this.$t('contracts.customer'), sortable: true},
                {key: 'contractor', label: this.$t('orders.contractor'), sortable: true},
                {key: 'start_date', label: this.$t('contracts.start'), sortable: true},
                {key: 'end_date', label: this.$t('contracts.end'), sortable: true},
                {key: 'harja_contract_id', label: this.$t('contracts.harja_contract_id'), sortable: true},
            ],
            loading: false
        }
    },
    mounted: function () {
        this.initTableItems()
    },
    watch: {
        results: function () {
            this.initTableItems()
        }
    },
    methods: {
        initTableItems() {
            this.tableItems = []
            if (this.results) {
                this.results.forEach(item => {
                    item._rowVariant = Date.parse(item.end_date) < Date.now() ? 'secondary' : ''
                    this.tableItems.push(item)
                })
            }
        },
        checkOrders(item) {
            if(!item.task_types || item.task_types.length < 1) {
                return false
            } else if(item.task_types) {
                let noOngoingOrderTaskTypes = 0;
                let orders = 0
                item.task_types.forEach(tt => {
                    orders += tt.orders.length
                    noOngoingOrderTaskTypes += tt.orders.filter(order => order.status === 10 || order.status === 20).length
                })
                return noOngoingOrderTaskTypes === orders
            }
        },
        emitEdit: function (result) {
            let item = this.results.find(item => result.id === item.id)
            if (item) {
                this.$emit('edit', item)
            }
        }
    }
}
</script>
