<template>
    <div
        v-bind:class="{ 'details-container': !this.isAddEquipmentMode }"
        @click.stop="close"
    >
        <div
            v-bind:class="{ 'details dynamic_width': !this.isAddEquipmentMode }"
            @click.stop=""
        >
            <h3 v-if="!this.isAddEquipmentMode" class="pl-1">
                {{ $t('edge_support.editor_title')  }}
            </h3>
            <location-deviation v-if="!this.isAddEquipmentMode" :location-deviation="item.location_deviation"/>
            <div class="pl-2 pr-2">
                <b-row v-if="!this.isAddEquipmentMode">
                    <div class="col-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('common.id') }}</span>
                        <span class="item-detail-text">{{ item.external_system_id }}</span>
                    </div>
                    <div class="col-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('culvert.location') }}</span>
                        <span class="item-detail-text">{{ getLocationString(item) }}</span>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('post.type') }}</span>
                        <b-form-select
                            v-model="item.type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('post.select_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getEdgeSupportTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <span class="span-title">{{ $t('edge_support.additional_types.title') }}</span>
                        <b-form-select
                            v-model="item.additional_type"
                            size="sm"
                        >
                            <template slot="first">
                                <option
                                    :value="null"
                                    disabled
                                >
                                    {{ $t('railing.post_type.select_type') }}
                                </option>
                            </template>
                            <option
                                v-for="item in getEdgeSupportAdditionalTypes()"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 nopads pr-1 pl-1">
                        <condition-selector v-model="item.condition"/>
                    </div>
                </b-row>
                <location-details
                    v-if="!this.isAddEquipmentMode"
                    :lane-data="this.item.location_specification.lane"
                    :side-data="this.item.location_specification.side"
                    :tracks-data="this.item.location_specification.tracks"
                />
                <b-row class="nopads">
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('railing.material') }}</span>
                        <b-form-group
                            class="title"
                            label-for="material"
                        >
                            <b-form-select
                                v-model="item.material"
                                size="sm"
                            >
                                <option
                                    v-for="item in getEdgeSupportMaterials()"
                                    :key="item.value"
                                    :value="item.value"
                                >
                                    {{ item.name }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('post.height') }}</span>
                        <b-form-group
                            class="title"
                            label-for="height"
                        >
                            <b-form-input
                                id="height"
                                min="1"
                                v-model="item.height"
                                type="number"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('post.code') }}</span>
                        <b-form-group
                            class="title"
                            label-for="code"
                        >
                            <b-form-input
                                id="code"
                                v-model="item.code"
                                type="text"
                                size="sm"
                            />
                        </b-form-group>
                    </div>
                </b-row>
                <b-row class="nopads mt-2" v-if="this.isAddEquipmentMode">
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('velho_equipment.starting_position') }}</span>
                    </div>
                    <div class="col-sm-12 nopads">
                        <velho-equipment-location-details-editor v-if="this.isAddEquipmentMode" :item="item.location_specification_starting" />
                    </div>
                </b-row>
                <b-row class="nopads mt-2" v-if="this.isAddEquipmentMode">
                    <div class="col-sm-4 nopads pr-1">
                        <span class="span-title">{{ $t('velho_equipment.ending_position') }}</span>
                    </div>
                    <div class="col-sm-12 nopads">
                        <velho-equipment-location-details-editor v-if="this.isAddEquipmentMode" :item="item.location_specification_ending" :hideSideSelector="true" :hideTracksSelector="true" />
                    </div>
                </b-row>
                <!-- TODO - Railings don't have point based actions(?)
                    <equipment-actions ref="actions" v-model="item.actions" />
                 -->
                <linked-equipment
                    v-if="item.linked_equipment && item.linked_equipment.length > 0"
                    :linked-equipments="item.linked_equipment"
                />
            </div>
            <velho-editor-buttons
                v-if="!this.isAddEquipmentMode"
                :url="exteEdgeSupportsUrl"
                :selected-contract="selectedContract"
                :item="item"
                :disabled="loading"
                @close="close"
                @submit="handleSubmit"
                @removalConfirmed="removalConfirmed"
            />
        </div>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from "@/components/mixins/MapMixin";
import {edgeSupportHelper} from "@/components/mixins/EdgeSupportMixin";
import {conversionModelHelper} from "@/components/mixins/ConversionMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import ConditionSelector from "@/components/velho/ConditionSelector";
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import VelhoEditorButtons from "@/components/velho/VelhoEditorButtons";
import LinkedEquipment from "@/components/velho/LinkedEquipment";
import LocationDetails from "@/components/velho/LocationDetails.vue";
import LocationDeviation from "@/components/velho/LocationDeviation";
import VelhoEquipmentLocationDetailsEditor from "@/components/velho/VelhoEquipmentLocationDetailsEditor";

export default {
    name: 'EdgeSupportEditor',
    components: {LocationDeviation, LocationDetails, VelhoEditorButtons, ConditionSelector, LinkedEquipment, VelhoEquipmentLocationDetailsEditor},
    mixins: [velhoHelper, mapHelper, edgeSupportHelper, conversionModelHelper, restApi],
    props: {
        item: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        },
        addEquipmentEditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAddEquipmentMode() {
            return this.addEquipmentMode
        }
    },
    data() {
        return {
            addEquipmentMode: this.addEquipmentEditor,
            map: null,
            loading: false,
        }
    },
    methods: {
        handleSubmit: function(url, item, selectedContract, replace = false) {
            if(replace) {
                this.submitEquipmentsreplace(this.extEquipmentsReplaceUrl, [item], selectedContract)
            } else {
                this.submitEquipment(url, item, selectedContract);
            }
        },
        close: function () {
            this.$emit('close')
        },

    }
}
</script>

<style scoped>
.map-container {
    height: 30em;
    position: relative;
}

</style>

