import {material} from "@/components/mixins/ConversionMixin";

export const roadSignHelper = {
    methods: {
        getInstallationString: function (code) {
            switch (code) {
                case installation.OWN_POLE:
                    return this.$t('road_sign.installation_own_pole')
                case installation.TRAFFIC_LIGHT:
                    return this.$t('road_sign.installation_traffic_light')
                case installation.STREET_LIGHT:
                    return this.$t('road_sign.installation_street_light')
                case installation.FRAME:
                    return this.$t('road_sign.installation_frame')
                case installation.OTHER:
                    return this.$t('common.other')
                default:
                    return ""
            }
        },
        getInstallations: function () {
            return [
                {name: this.$t('road_sign.installation_own_pole'), value: installation.OWN_POLE},
                {name: this.$t('road_sign.installation_traffic_light'), value: installation.TRAFFIC_LIGHT},
                {name: this.$t('road_sign.installation_street_light'), value: installation.STREET_LIGHT},
                {name: this.$t('common.other'), value: installation.OTHER},
            ]
        },
        //If you are working with Velho data use VelhoMixin
        getSideString: function (code) {
            switch (code) {
                case side.RIGHT:
                    return this.$t('road_sign.side_right')
                case side.LEFT:
                    return this.$t('road_sign.side_left')
                case side.ABOVE:
                    return this.$t('road_sign.side_above')
                default:
                    return ""
            }
        },
        //If you are working with Velho data use VelhoMixin
        getSides: function () {
            return [
                {name: this.$t('road_sign.side_right'), value: side.RIGHT},
                {name: this.$t('road_sign.side_left'), value: side.LEFT},
                {name: this.$t('road_sign.side_above'), value: side.ABOVE}
            ]
        },
        getMaterials: function () {
            return [
                {name: this.$t('road_sign.material_aluminium'), value: material.ALUMINIUM},
                {name: this.$t('road_sign.material_wood'), value: material.WOOD},
                {name: this.$t('common.other'), value: material.OTHER}
            ]
        },
        getConditionString: function (code) {
            switch (code) {
                case condition.POOR:
                    return this.$t('road_sign.condition_poor')
                case condition.MEDIOCRE:
                    return this.$t('road_sign.condition_mediocre')
                case condition.SATISFACTORY:
                    return this.$t('road_sign.condition_satisfactory')
                case condition.GOOD:
                    return this.$t('road_sign.condition_good')
                case condition.EXCELLENT:
                    return this.$t('road_sign.condition_excellent')
                case condition.MISSING:
                    return this.$t('road_sign.condition_missing')
                default:
                    return ""
            }
        },
        getConditions: function () {
            return [
                {name: this.$t('road_sign.condition_poor'), value: condition.POOR},
                {name: this.$t('road_sign.condition_mediocre'), value: condition.MEDIOCRE},
                {name: this.$t('road_sign.condition_satisfactory'), value: condition.SATISFACTORY},
                {name: this.$t('road_sign.condition_good'), value: condition.GOOD},
                {name: this.$t('road_sign.condition_excellent'), value: condition.EXCELLENT},
                {name: this.$t('road_sign.condition_missing'), value: condition.MISSING}
            ]
        },
        getRoadSignTypes: function () {
            return [
                {name: this.$t('road_sign.warnings.title'), value: roadSignTypes.WARNINGS},
                {name: this.$t('road_sign.restrictions.title'), value: roadSignTypes.RESTRICTIONS},
                {name: this.$t('road_sign.rules.title'), value: roadSignTypes.RULES},
                {name: this.$t('road_sign.commands.title'), value: roadSignTypes.COMMANDS},
                {name: this.$t('road_sign.priorities.title'), value: roadSignTypes.PRIORITIES},
                {name: this.$t('road_sign.guides.title'), value: roadSignTypes.GUIDES},
                {name: this.$t('road_sign.services.title'), value: roadSignTypes.SERVICES},
                {name: this.$t('road_sign.additional_plates.title'), value: roadSignTypes.ADDITIONAL_PLATES},
                {name: this.$t('road_sign.others.title'), value: roadSignTypes.OTHERS},
            ]
        },
        getWarningSigns: function () {
            return [
                {name: this.$t('road_sign.warnings.a1_1'), value: warnings.A1_1, icon: 'A1.1_Mutka.svg'},
                {name: this.$t('road_sign.warnings.a1_2'), value: warnings.A1_2, icon: 'A1.2_Mutka.svg'},
                {name: this.$t('road_sign.warnings.a2_1'), value: warnings.A2_1, icon: 'A2.1_Mutkia.svg'},
                {name: this.$t('road_sign.warnings.a2_2'), value: warnings.A2_2, icon: 'A2.2_Mutkia.svg'},
                {name: this.$t('road_sign.warnings.a3_1'), value: warnings.A3_1, icon: 'A3.1_Jyrkkä_mäki.svg'},
                {name: this.$t('road_sign.warnings.a3_2'), value: warnings.A3_2, icon: 'A3.2_Jyrkkä_mäki.svg'},
                {name: this.$t('road_sign.warnings.a4'), value: warnings.A4, icon: 'A4_Kapeneva_tie.svg'},
                {name: this.$t('road_sign.warnings.a5'), value: warnings.A5, icon: 'A5_Kaksisuuntainen_liikenne.svg'},
                {name: this.$t('road_sign.warnings.a6'), value: warnings.A6, icon: 'A6_Avattava_silta.svg'},
                {name: this.$t('road_sign.warnings.a7'), value: warnings.A7, icon: 'A7_Lautta_laituri_tai_ranta.svg'},
                {name: this.$t('road_sign.warnings.a8'), value: warnings.A8, icon: 'A8_Liikenneruuhka.svg'},
                {name: this.$t('road_sign.warnings.a9'), value: warnings.A9, icon: 'A9_Epätasainen_tie.svg'},
                {name: this.$t('road_sign.warnings.a10'), value: warnings.A10, icon: 'A10_Töyssyjä.svg'},
                {name: this.$t('road_sign.warnings.a11'), value: warnings.A11, icon: 'A11_Tietyö.svg'},
                {name: this.$t('road_sign.warnings.a12'), value: warnings.A12, icon: 'A12_Irtokiviä.svg'},
                {name: this.$t('road_sign.warnings.a13'), value: warnings.A13, icon: 'A13_Liukas_ajorata.svg'},
                {name: this.$t('road_sign.warnings.a14'), value: warnings.A14, icon: 'A14_Vaarallinen_tien_reuna.svg'},
                {
                    name: this.$t('road_sign.warnings.a15'),
                    value: warnings.A15,
                    icon: 'A15_Suojatien_ennakkovaroitus.svg'
                },
                {name: this.$t('road_sign.warnings.a16'), value: warnings.A16, icon: 'A16_Jalankulkijoita.svg'},
                {name: this.$t('road_sign.warnings.a17'), value: warnings.A17, icon: 'A17_Lapsia.svg'},
                {name: this.$t('road_sign.warnings.a18'), value: warnings.A18, icon: 'A18_Pyöräilijoitä.svg'},
                {name: this.$t('road_sign.warnings.a19'), value: warnings.A19, icon: 'A19_Hiihtolatu.svg'},
                {name: this.$t('road_sign.warnings.a20_1'), value: warnings.A20_1, icon: 'A20.1_Hirvi.svg'},
                {name: this.$t('road_sign.warnings.a20_2'), value: warnings.A20_2, icon: 'A20.2_Poro.svg'},
                {name: this.$t('road_sign.warnings.a20_3'), value: warnings.A20_3, icon: 'A20.3_Kauriseläin.svg'},
                {name: this.$t('road_sign.warnings.a21'), value: warnings.A21, icon: 'A21_Tienristeys.svg'},
                {name: this.$t('road_sign.warnings.a22_1'), value: warnings.A22_1, icon: 'A22.1_Sivutien_risteys.svg'},
                {name: this.$t('road_sign.warnings.a22_2'), value: warnings.A22_2, icon: 'A22.2_Sivutien_risteys.svg'},
                {name: this.$t('road_sign.warnings.a22_3'), value: warnings.A22_3, icon: 'A22.3_Sivutien_risteys.svg'},
                {name: this.$t('road_sign.warnings.a22_4'), value: warnings.A22, icon: 'A22.4_Sivutien_risteys.svg'},
                {name: this.$t('road_sign.warnings.a23'), value: warnings.A23, icon: 'A23_Liikennevalot.svg'},
                {name: this.$t('road_sign.warnings.a24'), value: warnings.A24, icon: 'A24_Liikenneympyrä.svg'},
                {name: this.$t('road_sign.warnings.a25'), value: warnings.A25, icon: 'A25_Raitiovaunu.svg'},
                {
                    name: this.$t('road_sign.warnings.a26'),
                    value: warnings.A26,
                    icon: 'A26_Rautatien_tasoristeys_ilman_puomeja.svg'
                },
                {
                    name: this.$t('road_sign.warnings.a27'),
                    value: warnings.A27,
                    icon: 'A27_Rautatien_tasoristeys_jossa_on_puomit.svg'
                },
                {
                    name: this.$t('road_sign.warnings.a28_1'),
                    value: warnings.A28_1,
                    icon: 'A28.1_Rautatien_tasoristeyksen_lähestymismerkit.svg'
                },
                {
                    name: this.$t('road_sign.warnings.a28_2'),
                    value: warnings.A28_2,
                    icon: 'A28.2_Rautatien_tasoristeyksen_lähestymismerkit.svg'
                },
                {
                    name: this.$t('road_sign.warnings.a28_3'),
                    value: warnings.A28_3,
                    icon: 'A28.3_Rautatien_tasoristeyksen_lähestymismerkit.svg'
                },
                {name: this.$t('road_sign.warnings.a29_1'), value: warnings.A29_1, icon: 'A29.1_Tasoristeys.svg'},
                {name: this.$t('road_sign.warnings.a29_2'), value: warnings.A29_2, icon: 'A29.2_Tasoristeys.svg'},
                {name: this.$t('road_sign.warnings.a30'), value: warnings.A30, icon: 'A30_Putoavia_kiviä.svg'},
                {
                    name: this.$t('road_sign.warnings.a31'),
                    value: warnings.A31,
                    icon: 'A31_Matalalla_lentäviä_lentokoneita.svg'
                },
                {name: this.$t('road_sign.warnings.a32'), value: warnings.A32, icon: 'A32_Sivutuuli.svg'},
                {name: this.$t('road_sign.warnings.a33'), value: warnings.A33, icon: 'A33_Muu_vaara.svg'}
            ]
        },
        getRestrictionSigns: function () {
            return [
                {
                    name: this.$t('road_sign.restrictions.c1'),
                    value: restrictions.C1,
                    icon: 'C1_Ajoneuvolla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c2'),
                    value: restrictions.C2,
                    icon: 'C2_Moottorikäyttöisellä_ajoneuvolla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c3'),
                    value: restrictions.C3,
                    icon: 'C3_Kuorma-_ja_pakettiautolla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c4'),
                    value: restrictions.C4,
                    icon: 'C4_Ajoneuvoyhdistelmällä_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c5'),
                    value: restrictions.C5,
                    icon: 'C5_Traktorilla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c6'),
                    value: restrictions.C6,
                    icon: 'C6_Moottoripyörälla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c7'),
                    value: restrictions.C7,
                    icon: 'C7_Moottorikelkalla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c8'),
                    value: restrictions.C8,
                    icon: 'C8_Vaarallisten_aineiden_kuljetus_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c9'),
                    value: restrictions.C9,
                    icon: 'C9_Linja-autolla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c10'),
                    value: restrictions.C10,
                    icon: 'C10_Mopolla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c11'),
                    value: restrictions.C11,
                    icon: 'C11_Polkupyörällä_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c12'),
                    value: restrictions.C12,
                    icon: 'C12_Polkupyörällä_ja_mopolla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c13'),
                    value: restrictions.C13,
                    icon: 'C13_Jalankulku_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c14'),
                    value: restrictions.C14,
                    icon: 'C14_Jalankulku_ja_polkupyorällä_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c15'),
                    value: restrictions.C15,
                    icon: 'C15_Jalankulku_ja_polkupyorällä_ja_mopolla_ajo_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c16'),
                    value: restrictions.C16,
                    icon: 'C16_Ratsastus_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c17'),
                    value: restrictions.C17,
                    icon: 'C17_Kielletty_ajosuunta.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c18'),
                    value: restrictions.C18,
                    icon: 'C18_Vasemmalle_kääntyminen_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c19'),
                    value: restrictions.C19,
                    icon: 'C19_Oikealle_kääntyminen_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c20'),
                    value: restrictions.C20,
                    icon: 'C20_U-käännos_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c21'),
                    value: restrictions.C21,
                    icon: 'C21_Ajoneuvon_suurin_sallittu_leveys.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c22'),
                    value: restrictions.C22,
                    icon: 'C22_Ajoneuvon_suurin_sallittu_korkeus.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c23'),
                    value: restrictions.C23,
                    icon: 'C23_Ajoneuvon_tai_ajoneuvoyhdistelmän_suurin_sallittu_Pituus.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c24'),
                    value: restrictions.C24,
                    icon: 'C24_Ajoneuvon_suurin_sallittu_massa.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c25'),
                    value: restrictions.C25,
                    icon: 'C25_Ajoneuvoyhdistelmän_suurin_sallittu_massa.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c26'),
                    value: restrictions.C26,
                    icon: 'C26_Ajoneuvon_suurin_sallittu_akselille_kohdistuva_massa.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c27'),
                    value: restrictions.C27,
                    icon: 'C27_Ajoneuvon_suurin_sallittu_telille_kohdistuva_massa.svg'
                },
                {name: this.$t('road_sign.restrictions.c28'), value: restrictions.C28, icon: 'C28_Ohituskielto.svg'},
                {
                    name: this.$t('road_sign.restrictions.c29'),
                    value: restrictions.C29,
                    icon: 'C29_Ohituskielto_päättyy.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c30'),
                    value: restrictions.C30,
                    icon: 'C30_Ohituskielto_kuorma-autolla.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c31'),
                    value: restrictions.C31,
                    icon: 'C31_Ohituskielto_kuorma-autolla_päättyy.svg'
                },
                {name: this.$t('road_sign.restrictions.c32'), value: restrictions.C32, icon: 'C32_Nopeusrajoitus.svg'},
                {
                    name: this.$t('road_sign.restrictions.c33'),
                    value: restrictions.C33,
                    icon: 'C33_Nopeusrajoitus_päättyy.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c34'),
                    value: restrictions.C34,
                    icon: 'C34_Nopeusrajoitusalue.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c35'),
                    value: restrictions.C35,
                    icon: 'C35_Nopeusrajoitusalue_päättyy.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c36'),
                    value: restrictions.C36,
                    icon: 'C36_Ajokaistakohtainen_kielto_rajoitus_tai_määräys.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c37'),
                    value: restrictions.C37,
                    icon: 'C37_Pysäyttäminen_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c38'),
                    value: restrictions.C38,
                    icon: 'C38_Pysäköinti_kielletty.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c39'),
                    value: restrictions.C39,
                    icon: 'C39_Pysäköintikieltoalue.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c40'),
                    value: restrictions.C40,
                    icon: 'C40_Pysäköintikieltoalue_päättyy.svg'
                },
                {name: this.$t('road_sign.restrictions.c41'), value: restrictions.C41, icon: 'C41_Taksiasema-alue.svg'},
                {
                    name: this.$t('road_sign.restrictions.c42'),
                    value: restrictions.C42,
                    icon: 'C42_Taksin_pysäyttämispaikka.svg'
                },
                {name: this.$t('road_sign.restrictions.c43'), value: restrictions.C43, icon: 'C43_Kuormauspaikka.svg'},
                {
                    name: this.$t('road_sign.restrictions.c44_1'),
                    value: restrictions.C44_1,
                    icon: 'C44.1_Vuoropysäköinti.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c44_2'),
                    value: restrictions.C44_2,
                    icon: 'C44.2_Vuoropysäköinti.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c45'),
                    value: restrictions.C45,
                    icon: 'C45_Pakollinen_pysäyttäminen_tullitarkastusta_varten.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c46'),
                    value: restrictions.C46,
                    icon: 'C46_Pakollinen_pysäyttäminen_tarkastusta_varten.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c47'),
                    value: restrictions.C47,
                    icon: 'C47_Moottorikäyttöisten_ajoneuvojen_vähimmäisetäisyys.svg'
                },
                {
                    name: this.$t('road_sign.restrictions.c48'),
                    value: restrictions.C48,
                    icon: 'C48_Nastarenkailla_varustetulla_moottorikäyttöisellä_ajoneuvolla_ajo_kielletty.svg'
                }
            ]
        },
        getCommandSigns: function () {
            return [
                {name: this.$t('road_sign.commands.d1_1'), value: commands.D1_1, icon: 'D1.1_Pakollinen_ajosuunta.svg'},
                {name: this.$t('road_sign.commands.d1_2'), value: commands.D1_2, icon: 'D1.2_Pakollinen_ajosuunta.svg'},
                {name: this.$t('road_sign.commands.d1_3'), value: commands.D1_3, icon: 'D1.3_Pakollinen_ajosuunta.svg'},
                {name: this.$t('road_sign.commands.d1_4'), value: commands.D1_4, icon: 'D1.4_Pakollinen_ajosuunta.svg'},
                {name: this.$t('road_sign.commands.d1_5'), value: commands.D1_5, icon: 'D1.5_Pakollinen_ajosuunta.svg'},
                {name: this.$t('road_sign.commands.d1_6'), value: commands.D1_6, icon: 'D1.6_Pakollinen_ajosuunta.svg'},
                {name: this.$t('road_sign.commands.d1_7'), value: commands.D1_7, icon: 'D1.7_Pakollinen_ajosuunta.svg'},
                {name: this.$t('road_sign.commands.d1_8'), value: commands.D1_8, icon: 'D1.8_Pakollinen_ajosuunta.svg'},
                {name: this.$t('road_sign.commands.d2'), value: commands.D2, icon: 'D2_Pakollinen_kiertosuunta.svg'},
                {name: this.$t('road_sign.commands.d3_1'), value: commands.D3_1, icon: 'D3.1_Liikenteenjakaja.svg'},
                {name: this.$t('road_sign.commands.d3_2'), value: commands.D3_2, icon: 'D3.2_Liikenteenjakaja.svg'},
                {name: this.$t('road_sign.commands.d3_3'), value: commands.D3_3, icon: 'D3.3_Liikenteenjakaja.svg'},
                {name: this.$t('road_sign.commands.d4'), value: commands.D4, icon: 'D4_Jalkakäytävä.svg'},
                {name: this.$t('road_sign.commands.d5'), value: commands.D5, icon: 'D5_Pyörätie.svg'},
                {name: this.$t('road_sign.commands.d6'), value: commands.D6, icon: 'D6_Yhdistetty_pyörätie_ja_jalkakäytavä.svg'},
                {name: this.$t('road_sign.commands.d7_1'), value: commands.D7_1, icon: 'D7.1_Pyörätie_ja_jalkakäytavä_rinnakkain.svg'},
                {name: this.$t('road_sign.commands.d7_2'), value: commands.D7_2, icon: 'D7.2_Pyorätie_ja_jalkakaytävä_rinnakkain.svg'},
                {name: this.$t('road_sign.commands.d8'), value: commands.D8, icon: 'D8_Moottorikelkkailureitti.svg'},
                {name: this.$t('road_sign.commands.d9'), value: commands.D9, icon: 'D9_Ratsastustie.svg'},
                {name: this.$t('road_sign.commands.d10'), value: commands.D10, icon: 'D10_Vähimmäisnopeus.svg'},
                {name: this.$t('road_sign.commands.d11'), value: commands.D11, icon: 'D11_Vähimmäisnopeus_päättyy.svg'}
            ]
        },
        getRuleSigns: function () {
            return [
                {name: this.$t('road_sign.rules.e1'), value: rules.E1, icon: 'E1_Suojatie.svg'},
                {name: this.$t('road_sign.rules.e2'), value: rules.E2, icon: 'E2_Pysäköintipaikka.svg'},
                {name: this.$t('road_sign.rules.e3_1'), value: rules.E3_1, icon: 'E3.1_Liityntäpysäköintipaikka.svg'},
                {name: this.$t('road_sign.rules.e3_2'), value: rules.E3_2, icon: 'E3.2_Liityntäpysäköintipaikka.svg'},
                {name: this.$t('road_sign.rules.e3_3'), value: rules.E3_3, icon: 'E3.3_Liityntäpysäköintipaikka.svg'},
                {name: this.$t('road_sign.rules.e3_4'), value: rules.E3_4, icon: 'E3.4_Liityntäpysäköintipaikka.svg'},
                {name: this.$t('road_sign.rules.e3_5'), value: rules.E3_5, icon: 'E3.5_Liityntäpysäköintipaikka.svg'},
                {
                    name: this.$t('road_sign.rules.e4_1'),
                    value: rules.E4_1,
                    icon: 'E4.1_Ajoneuvojen_sijoitus_pysäköintipaikalla.svg'
                },
                {
                    name: this.$t('road_sign.rules.e4_2'),
                    value: rules.E4_2,
                    icon: 'E4.2_Ajoneuvojen_sijoitus_pysäköintipaikalla.svg'
                },
                {
                    name: this.$t('road_sign.rules.e4_3'),
                    value: rules.E4_3,
                    icon: 'E4.3_Ajoneuvojen_sijoitus_pysäköintipaikalla.svg'
                },
                {name: this.$t('road_sign.rules.e5'), value: rules.E5, icon: 'E5_Kohtaamispaikka.svg'},
                {name: this.$t('road_sign.rules.e6'), value: rules.E6, icon: 'E6_Linja-autopysäkki.svg'},
                {name: this.$t('road_sign.rules.e7'), value: rules.E7, icon: 'E7_Raitiovaunupysäkki.svg'},
                {name: this.$t('road_sign.rules.e8'), value: rules.E8, icon: 'E8_Taksiasema.svg'},
                {name: this.$t('road_sign.rules.e9_1'), value: rules.E9_1, icon: 'E9.1_Linja-autokaista.svg'},
                {name: this.$t('road_sign.rules.e9_2'), value: rules.E9_2, icon: 'E9.2_Linja-autokaista.svg'},
                {
                    name: this.$t('road_sign.rules.e10_1'),
                    value: rules.E10_1,
                    icon: 'E10.1_Linja-autokaista_päättyy.svg'
                },
                {
                    name: this.$t('road_sign.rules.e10_2'),
                    value: rules.E10_2,
                    icon: 'E10.2_Linja-autokaista_päättyy.svg'
                },
                {name: this.$t('road_sign.rules.e11_1'), value: rules.E11_1, icon: 'E11.1_Raitiovaunukaista.svg'},
                {name: this.$t('road_sign.rules.e11_2'), value: rules.E11_2, icon: 'E11.2_Raitiovaunukaista.svg'},
                {
                    name: this.$t('road_sign.rules.e12_1'),
                    value: rules.E12_1,
                    icon: 'E12.1_Raitiovaunukaista_päättyy.svg'
                },
                {
                    name: this.$t('road_sign.rules.e12_2'),
                    value: rules.E12_2,
                    icon: 'E12.2_Raitiovaunukaista_päättyy.svg'
                },
                {name: this.$t('road_sign.rules.e13_1'), value: rules.E13_1, icon: 'E13.1_Pyöräkaista.svg'},
                {name: this.$t('road_sign.rules.e13_2'), value: rules.E13_2, icon: 'E13.2_Pyöräkaista.svg'},
                {name: this.$t('road_sign.rules.e14_1'), value: rules.E14_1, icon: 'E14.1_Yksisuuntainen_tie.svg'},
                {name: this.$t('road_sign.rules.e14_2'), value: rules.E14_2, icon: 'E14.2_Yksisuuntainen_tie.svg'},
                {name: this.$t('road_sign.rules.e15'), value: rules.E15, icon: 'E15_Moottoritie.svg'},
                {name: this.$t('road_sign.rules.e16'), value: rules.E16, icon: 'E16_Moottoritie_päättyy.svg'},
                {name: this.$t('road_sign.rules.e17'), value: rules.E17, icon: 'E17_Moottoriliikennetie.svg'},
                {name: this.$t('road_sign.rules.e18'), value: rules.E18, icon: 'E18_Moottoriliikennetie_päättyy.svg'},
                {name: this.$t('road_sign.rules.e19'), value: rules.E19, icon: 'E19_Tunneli.svg'},
                {name: this.$t('road_sign.rules.e20'), value: rules.E20, icon: 'E20_Tunneli_päättyy.svg'},
                {name: this.$t('road_sign.rules.e21'), value: rules.E21, icon: 'E21_Hätäpysäyttämispaikka.svg'},
                {name: this.$t('road_sign.rules.e22'), value: rules.E22, icon: 'E22_Taajama.svg'},
                {name: this.$t('road_sign.rules.e23'), value: rules.E23, icon: 'E23_Taajama_päättyy.svg'},
                {name: this.$t('road_sign.rules.e24'), value: rules.E24, icon: 'E24_Pihakatu.svg'},
                {name: this.$t('road_sign.rules.e25'), value: rules.E25, icon: 'E25_Pihakatu_päättyy.svg'},
                {name: this.$t('road_sign.rules.e26'), value: rules.E26, icon: 'E26_Kävelykatu.svg'},
                {name: this.$t('road_sign.rules.e27'), value: rules.E27, icon: 'E27_Kävelykatu_Päättyy.svg'},
                {name: this.$t('road_sign.rules.e28'), value: rules.E28, icon: 'E28_Pyöräkatu.svg'},
                {name: this.$t('road_sign.rules.e29'), value: rules.E29, icon: 'E29_Pyöräkatu_päättyy.svg'},
                {name: this.$t('road_sign.rules.e30'), value: rules.E30, icon: 'E30_Ajokaistojen_yhdistyminen.svg'}
            ]
        },
        getPrioritySigns: function () {
            return [
                {name: this.$t('road_sign.priorities.b1'), value: priorities.B1, icon: 'B1_Etuajo-oikeutettu_tie.svg'},
                {
                    name: this.$t('road_sign.priorities.b2'),
                    value: priorities.B2,
                    icon: 'B2_Etuajo-oikeuden_päättyminen.svg'
                },
                {
                    name: this.$t('road_sign.priorities.b3'),
                    value: priorities.B3,
                    icon: 'B3_Etuajo-oikeus_kohdattaessa.svg'
                },
                {
                    name: this.$t('road_sign.priorities.b4'),
                    value: priorities.B4,
                    icon: 'B4_Väistamisvelvollisuus_kohdattaessa.svg'
                },
                {
                    name: this.$t('road_sign.priorities.b5'),
                    value: priorities.B5,
                    icon: 'B5_Väistämisvelvollisuus_risteyksessä.svg'
                },
                {
                    name: this.$t('road_sign.priorities.b6'),
                    value: priorities.B6,
                    icon: 'B6_Pakollinen_pysäyttäminen.svg'
                },
                {
                    name: this.$t('road_sign.priorities.b7'),
                    value: priorities.B7,
                    icon: 'B7_Väistämisvelvollisuus_pyöräilijän_tienylityspaikassa.svg'
                },
            ]
        },
        getGuideSigns: function () {
            return [
                {name: this.$t('road_sign.guides.f1_1'), value: guides.F1_1, icon: 'F1.1_Suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f1_2'), value: guides.F1_2, icon: 'F1.2_Suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f1_3'), value: guides.F1_3, icon: 'F1.3_Suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f2_1'), value: guides.F2_1, icon: 'F2.1_Suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f2_2'), value: guides.F2_2, icon: 'F2.2_Suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f2_3'), value: guides.F2_3, icon: 'F2.3_Suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f3'), value: guides.F3, icon: 'F3_Ajokaistakohtainen_suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f4_1'), value: guides.F4_1, icon: 'F4.1_Kiertotien_suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f4_2'), value: guides.F4_2, icon: 'F4.2_Kiertotien_suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f5'), value: guides.F5, icon: 'F5_Kiertotieopastus.svg'},
                {name: this.$t('road_sign.guides.f6'), value: guides.F6, icon: 'F6_Ajoreittiopastus.svg'},
                {name: this.$t('road_sign.guides.f7_1'), value: guides.F7_1, icon: 'F7.1_Ajokaistaopastus.svg'},
                {name: this.$t('road_sign.guides.f7_2'), value: guides.F7_2, icon: 'F7.2_Ajokaistaopastus.svg'},
                {name: this.$t('road_sign.guides.f7_3'), value: guides.F7_3, icon: 'F7.3_Ajokaistaopastus.svg'},
                {name: this.$t('road_sign.guides.f7_4'), value: guides.F7_4, icon: 'F7.4_Ajokaistaopastus.svg'},
                {name: this.$t('road_sign.guides.f7_5'), value: guides.F7_5, icon: 'F7.5_Ajokaistaopastus.svg'},
                {name: this.$t('road_sign.guides.f7_6'), value: guides.F7_6, icon: 'F7.6_Ajokaistaopastus.svg'},
                {name: this.$t('road_sign.guides.f8_1'), value: guides.F8_1, icon: 'F8.1_Ajokaistan_päättyminen.svg'},
                {name: this.$t('road_sign.guides.f8_2'), value: guides.F8_2, icon: 'F8.2_Ajokaistan_päättyminen.svg'},
                {name: this.$t('road_sign.guides.f9'), value: guides.F9, icon: 'F9_Viitoituksen_koontimerkki.svg'},
                {name: this.$t('road_sign.guides.f10'), value: guides.F10, icon: 'F10_Ajokaistan_yläpuolinen_viitta.svg'},
                {name: this.$t('road_sign.guides.f11'), value: guides.F11, icon: 'F11_Ajokaistan_yläpuolinen_viitta.svg'},
                {name: this.$t('road_sign.guides.f12'), value: guides.F12, icon: 'F12_Ajokaistan_yläpuolinen_erkanemisviitta.svg'},
                {name: this.$t('road_sign.guides.f13'), value: guides.F13, icon: 'F13_Tienviitta.svg'},
                {name: this.$t('road_sign.guides.f14'), value: guides.F14, icon: 'F14_Erkanemisviitta.svg'},
                {name: this.$t('road_sign.guides.f15'), value: guides.F15, icon: 'F15_Kiertotien_viitta.svg'},
                {name: this.$t('road_sign.guides.f16'), value: guides.F16, icon: 'F16_Osoiteviitta.svg'},
                {name: this.$t('road_sign.guides.f17'), value: guides.F17, icon: 'F17_Osoiteviitan_ennakkomerkki.svg'},
                {name: this.$t('road_sign.guides.f18_1'), value: guides.F18_1, icon: 'F18.1_Liityntäpysäköintiviitta.svg'},
                {name: this.$t('road_sign.guides.f18_2'), value: guides.F18_2, icon: 'F18.2_Liityntäpysäköintiviitta.svg'},
                {name: this.$t('road_sign.guides.f18_3'), value: guides.F18_3, icon: 'F18.3_Liityntäpysäköintiviitta.svg'},
                {name: this.$t('road_sign.guides.f18_4'), value: guides.F18_4, icon: 'F18.4_Liityntäpysäköintiviitta.svg'},
                {name: this.$t('road_sign.guides.f18_5'), value: guides.F18_5, icon: 'F18.5_Liityntäpysäköintiviitta.svg'},
                {name: this.$t('road_sign.guides.f19'), value: guides.F19, icon: 'F19_Jalankulun_viitta.svg'},
                {name: this.$t('road_sign.guides.f20_1'), value: guides.F20_1, icon: 'F20.1_Pyöräilyn_viitta.svg'},
                {name: this.$t('road_sign.guides.f20_2'), value: guides.F20_2, icon: 'F20.2_Pyöräilyn_viitta.svg'},
                {name: this.$t('road_sign.guides.f21_1'), value: guides.F21_1, icon: 'F21.1_Pyöräilyn_suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f21_2'), value: guides.F21_2, icon: 'F21.2_Pyöräilyn_suunnistustaulu.svg'},
                {name: this.$t('road_sign.guides.f22'), value: guides.F22, icon: 'F22_Pyöräilyn_etäisyystaulu.svg'},
                {name: this.$t('road_sign.guides.f23'), value: guides.F23, icon: 'F23_Pyöräilyn_paikannimi.svg'},
                {name: this.$t('road_sign.guides.f24_1'), value: guides.F24_1, icon: 'F24.1_Umpitie.svg'},
                {name: this.$t('road_sign.guides.f24_2'), value: guides.F24_2, icon: 'F24.2_Umpitie.svg'},
                {name: this.$t('road_sign.guides.f24_3'), value: guides.F24_3, icon: 'F24.3_Umpitie.svg'},
                {name: this.$t('road_sign.guides.f25'), value: guides.F25, icon: 'F25_Enimmäisnopeussuositus.svg'},
                {name: this.$t('road_sign.guides.f26'), value: guides.F26, icon: 'F26_etäisyystaulu.svg'},
                {name: this.$t('road_sign.guides.f27_1'), value: guides.F27_1, icon: 'F27.1_Paikannimi.svg'},
                {name: this.$t('road_sign.guides.f27_2'), value: guides.F27_2, icon: 'F27.2_Paikannimi.svg'},
                {name: this.$t('road_sign.guides.f28'), value: guides.F28, icon: 'F28_Kansainvalisen_pääliikenneväylän_numero.svg'},
                {name: this.$t('road_sign.guides.f29'), value: guides.F29, icon: 'F29_Valtatien_numero.svg'},
                {name: this.$t('road_sign.guides.f30'), value: guides.F30, icon: 'F30_Kantatien_numero.svg'},
                {name: this.$t('road_sign.guides.f31'), value: guides.F31, icon: 'F31_Seututien_numero.svg'},
                {name: this.$t('road_sign.guides.f32'), value: guides.F32, icon: 'F32_Muun_maantien_numero.svg'},
                {name: this.$t('road_sign.guides.f33'), value: guides.F33, icon: 'F33_Kehätien_numero.svg'},
                {name: this.$t('road_sign.guides.f34'), value: guides.F34, icon: 'F34_eritasoliittymän_numero.svg'},
                {name: this.$t('road_sign.guides.f35'), value: guides.F35, icon: 'F35_Opastus_numeron_tarkoittamalle_tielle.svg'},
                {name: this.$t('road_sign.guides.f36'), value: guides.F36, icon: 'F36_Varareitti.svg'},
                {name: this.$t('road_sign.guides.f37'), value: guides.F37, icon: 'F37_Moottoritien_tunnus.svg'},
                {name: this.$t('road_sign.guides.f38'), value: guides.F38, icon: 'F38_Moottoriliikennetien_tunnus.svg'},
                {name: this.$t('road_sign.guides.f39'), value: guides.F39, icon: 'F39_Lentoasema.svg'},
                {name: this.$t('road_sign.guides.f40'), value: guides.F40, icon: 'F40_Autolautta.svg'},
                {name: this.$t('road_sign.guides.f41'), value: guides.F41, icon: 'F41_Matkustajasatama.svg'},
                {name: this.$t('road_sign.guides.f42'), value: guides.F42, icon: 'F42_Tavarasatama.svg'},
                {name: this.$t('road_sign.guides.f43'), value: guides.F43, icon: 'F43_Tavaraterminaali.svg'},
                {name: this.$t('road_sign.guides.f44'), value: guides.F44, icon: 'F44_Teollisuusalue_tai_yritysalue.svg'},
                {name: this.$t('road_sign.guides.f45'), value: guides.F45, icon: 'F45_Vähittäiskaupan_suuryksikkö.svg'},
                {name: this.$t('road_sign.guides.f46_1'), value: guides.F46_1, icon: 'F46.1_Pysäköinti.svg'},
                {name: this.$t('road_sign.guides.f46_2'), value: guides.F46_2, icon: 'F46.2_Pysäköinti.svg'},
                {name: this.$t('road_sign.guides.f47'), value: guides.F47, icon: 'F47_Rautatieasema.svg'},
                {name: this.$t('road_sign.guides.f48'), value: guides.F48, icon: 'F48_Linja-autoasema.svg'},
                {name: this.$t('road_sign.guides.f49'), value: guides.F49, icon: 'F49_Keskusta.svg'},
                {name: this.$t('road_sign.guides.f50'), value: guides.F50, icon: 'F50_Tietylle_ajoneuvolle_tarkoitettu_reitti.svg'},  // TODO - Is this right?
                {name: this.$t('road_sign.guides.f50_7'), value: guides.F50_7, icon: 'F50_Tietylle_ajoneuvolle_tarkoitettu_reitti.svg'}, // TODO - Is this right?
                {name: this.$t('road_sign.guides.f50_8'), value: guides.F50_8, icon: 'F50_Tietylle_ajoneuvolle_tarkoitettu_reitti.svg'},
                {name: this.$t('road_sign.guides.f51'), value: guides.F51, icon: 'F51_Vaarallisten_aineiden_kuljetukselle_tarkoitettu_reitti.svg'},
                {name: this.$t('road_sign.guides.f52'), value: guides.F52, icon: 'F52_Jalankulkijalle_tarkoitettu_reitti.svg'},
                {name: this.$t('road_sign.guides.f53'), value: guides.F53, icon: 'F53_Esteetön_reitti.svg'},
                {name: this.$t('road_sign.guides.f54_1'), value: guides.F54_1, icon: 'F54.1_Reitti_jolla_on_portaat.svg'},
                {name: this.$t('road_sign.guides.f54_2'), value: guides.F54_2, icon: 'F54.2_Reitti_jolla_on_portaat.svg'},
                {name: this.$t('road_sign.guides.f55_1'), value: guides.F55_1, icon: 'F55.1_Reitti_ilman_portaita.svg'},
                {name: this.$t('road_sign.guides.f55_2'), value: guides.F55_2, icon: 'F55.2_Reitti_ilman_portaita.svg'},
                {name: this.$t('road_sign.guides.f55_3'), value: guides.F55_3, icon: 'F55.3_Reitti_ilman_portaita.svg'},
                {name: this.$t('road_sign.guides.f55_4'), value: guides.F55_4, icon: 'F55.4_Reitti_ilman_portaita.svg'},
                {name: this.$t('road_sign.guides.f56_1'), value: guides.F56_1, icon: 'F56.1_Hätäuloskäynti.svg'},
                {name: this.$t('road_sign.guides.f56_2'), value: guides.F56_2, icon: 'F56.2_Hätäuloskäynti.svg'},
                {name: this.$t('road_sign.guides.f57_1'), value: guides.F57_1, icon: 'F57.1_Poistumisreitti.svg'},
                {name: this.$t('road_sign.guides.f57_2'), value: guides.F57_2, icon: 'F57.2_Poistumisreitti.svg'}
            ]
        },
        getServiceSigns: function () {
            return [
                {name: this.$t('road_sign.services.g1'), value: services.G1, icon: 'G1_Palvelukohteen_opastustaulu.svg'},
                {name: this.$t('road_sign.services.g2'), value: services.G2, icon: 'G2_Palvelukohteen_opastustaulu.svg'},
                {name: this.$t('road_sign.services.g3'), value: services.G3, icon: 'G3_Palvelukohteen_erkanemisviitta.svg'},
                {name: this.$t('road_sign.services.g4'), value: services.G4, icon: 'G4_Palvelukohteen_osoiteviitta.svg'},
                {name: this.$t('road_sign.services.g5'), value: services.G5, icon: 'G5_Palvelukohteen_osoiteviitan_ennakkomerkki.svg'},
                {name: this.$t('road_sign.services.g6'), value: services.G6, icon: 'G6_Radioaseman_taajuus.svg'},
                {name: this.$t('road_sign.services.g7'), value: services.G7, icon: 'G7_Opastuspiste.svg'},
                {name: this.$t('road_sign.services.g8'), value: services.G8, icon: 'G8_Opastustoimisto.svg'},
                {name: this.$t('road_sign.services.g9'), value: services.G9, icon: 'G9_Ensiapu.svg'},
                {name: this.$t('road_sign.services.g10'), value: services.G10, icon: 'G10_Autokorjaamo.svg'},
                {name: this.$t('road_sign.services.g11_1'), value: services.G11_1, icon: 'G11.1_Polttoaineen_jakelu.svg'},
                {name: this.$t('road_sign.services.g11_2'), value: services.G11_2, icon: 'G11.2_Polttoaineen_jakelu.svg'},
                {name: this.$t('road_sign.services.g11_3'), value: services.G11_3, icon: 'G11.3_Polttoaineen_jakelu.svg'},
                {name: this.$t('road_sign.services.g11_4'), value: services.G11_4, icon: 'G11.4_Polttoaineen_jakelu.svg'},
                {name: this.$t('road_sign.services.g12'), value: services.G12, icon: 'G12_Hotelli_tai_motelli.svg'},
                {name: this.$t('road_sign.services.g13'), value: services.G13, icon: 'G13_Ruokailupaikka.svg'},
                {name: this.$t('road_sign.services.g14'), value: services.G14, icon: 'G14_Kahvila_tai_pikaruokapaikka.svg'},
                {name: this.$t('road_sign.services.g15'), value: services.G15, icon: 'G15_Käymälä.svg'},
                {name: this.$t('road_sign.services.g16'), value: services.G16, icon: 'G16_Retkeilymaja.svg'},
                {name: this.$t('road_sign.services.g17'), value: services.G17, icon: 'G17_Leirintäalue.svg'},
                {name: this.$t('road_sign.services.g18'), value: services.G18, icon: 'G18_Matkailuajoneuvoalue.svg'},
                {name: this.$t('road_sign.services.g19'), value: services.G19, icon: 'G19_Levähdysalue.svg'},
                {name: this.$t('road_sign.services.g20'), value: services.G20, icon: 'G20_Ulkoilualue.svg'},
                {name: this.$t('road_sign.services.g21'), value: services.G21, icon: 'G21_Hätäpuhelin.svg'},
                {name: this.$t('road_sign.services.g22'), value: services.G22, icon: 'G22_Sammutin.svg'},
                {name: this.$t('road_sign.services.g23'), value: services.G23, icon: 'G23_Museo_tai_historiallinen_rakennus.svg'},
                {name: this.$t('road_sign.services.g24'), value: services.G24, icon: 'G24_Maailmanperintökohde.svg'},
                {name: this.$t('road_sign.services.g25'), value: services.G25, icon: 'G25_Luontokohde.svg'},
                {name: this.$t('road_sign.services.g26'), value: services.G26, icon: 'G26_Naköalapaikka.svg'},
                {name: this.$t('road_sign.services.g27'), value: services.G27, icon: 'G27_Eläintarha_tai_puisto.svg'},
                {name: this.$t('road_sign.services.g28'), value: services.G28, icon: 'G28_Muu_nähtävyys.svg'},
                {name: this.$t('road_sign.services.g29'), value: services.G29, icon: 'G29_Uintipaikka.svg'},
                {name: this.$t('road_sign.services.g30'), value: services.G30, icon: 'G30_Kalastuspaikka.svg'},
                {name: this.$t('road_sign.services.g31'), value: services.G31, icon: 'G31_Hiihtohissi.svg'},
                {name: this.$t('road_sign.services.g32'), value: services.G32, icon: 'G32_Maastohiihtokeskus.svg'},
                {name: this.$t('road_sign.services.g33'), value: services.G33, icon: 'G33_Golfkenttä.svg'},
                {name: this.$t('road_sign.services.g34'), value: services.G34, icon: 'G34_Huvi_ja_teemapuisto.svg'},
                {name: this.$t('road_sign.services.g35'), value: services.G35, icon: 'G35_Mökkimajoitus.svg'},
                {name: this.$t('road_sign.services.g36'), value: services.G36, icon: 'G36_Aamiaismajoitus.svg'},
                {name: this.$t('road_sign.services.g37'), value: services.G37, icon: 'G37_Suoramyyntipaikka.svg'},
                {name: this.$t('road_sign.services.g38'), value: services.G38, icon: 'G38_Käsityöpaja.svg'},
                {name: this.$t('road_sign.services.g39'), value: services.G39, icon: 'G39_Kotieläinpiha.svg'},
                {name: this.$t('road_sign.services.g40'), value: services.G40, icon: 'G40_Ratsastuspaikka.svg'},
                {name: this.$t('road_sign.services.g41_1'), value: services.G41_1, icon: 'G41.1_Matkailutie.svg'},
                {name: this.$t('road_sign.services.g41_2'), value: services.G41_2, icon: 'G41.2_Matkailutie.svg'},
                {name: this.$t('road_sign.services.g42'), value: services.G42, icon: 'G42_Tilapäinen_opastusmerkki.svg'},
            ]
        },
        getAdditionalPlateSigns: function () {
            return [
                {name: this.$t('road_sign.additional_plates.h1'),value: additionalPlates.H1,icon: 'H1_Kohde_risteävässä_suunnassa.svg'},
                {name: this.$t('road_sign.additional_plates.h2_1'),value: additionalPlates.H2_1,icon: 'H2.1_Kohde_nuolen_suunnassa.svg'},
                {name: this.$t('road_sign.additional_plates.h2_2'),value: additionalPlates.H2_2,icon: 'H2.2_Kohde_nuolen_suunnassa.svg'},
                {name: this.$t('road_sign.additional_plates.h2_3'),value: additionalPlates.H2_3,icon: 'H2.3_Kohde_nuolen_suunnassa.svg'},
                {name: this.$t('road_sign.additional_plates.h3'),value: additionalPlates.H3,icon: 'H3_Vaikutusalueen_pituus.svg'},
                {name: this.$t('road_sign.additional_plates.h4'),value: additionalPlates.H4,icon: 'H4_Etäisyys_kohteeseen.svg'},
                {name: this.$t('road_sign.additional_plates.h5'),value: additionalPlates.H5,icon: 'H5_Etäisyys_pakolliseen_pysäyttämiseen.svg'},
                {name: this.$t('road_sign.additional_plates.h6'),value: additionalPlates.H6,icon: 'H6_Vapaa_leveys.svg'},
                {name: this.$t('road_sign.additional_plates.h7'),value: additionalPlates.H7,icon: 'H7_Vapaa_korkeus.svg'},
                {name: this.$t('road_sign.additional_plates.h8'),value: additionalPlates.H8,icon: 'H8_Sähköjohdon_korkeus.svg'},
                {name: this.$t('road_sign.additional_plates.h9_1'),value: additionalPlates.H9_1,icon: 'H9.1_Vaikutusalue_molempiin_suuntiin.svg'},
                {name: this.$t('road_sign.additional_plates.h9_2'),value: additionalPlates.H9_2,icon: 'H9.2_Vaikutusalue_molempiin_suuntiin.svg'},
                {name: this.$t('road_sign.additional_plates.h10'),value: additionalPlates.H10,icon: 'H10_Vaikutusalue_nuolen_suuntaan.svg'},
                {name: this.$t('road_sign.additional_plates.h11'),value: additionalPlates.H11,icon: 'H11_Vaikutusalue_päättyy.svg'},
                {name: this.$t('road_sign.additional_plates.h12_1'),value: additionalPlates.H12_1,icon: 'H12.1_Henkilöauto.svg'},
                {name: this.$t('road_sign.additional_plates.h12_2'),value: additionalPlates.H12_2,icon: 'H12.2_Linja-auto.svg'},
                {name: this.$t('road_sign.additional_plates.h12_3'),value: additionalPlates.H12_3,icon: 'H12.3_Kuorma-auto.svg'},
                {name: this.$t('road_sign.additional_plates.h12_4'),value: additionalPlates.H12_4,icon: 'H12.4_Pakettiauto.svg'},
                {name: this.$t('road_sign.additional_plates.h12_5'),value: additionalPlates.H12_5,icon: 'H12.5_Matkailuperävaunu.svg'},
                {name: this.$t('road_sign.additional_plates.h12_6'),value: additionalPlates.H12_6,icon: 'H12.6_Matkailuauto.svg'},
                {name: this.$t('road_sign.additional_plates.h12_7'),value: additionalPlates.H12_7,icon: 'H12.7_Invalidin_ajoneuvo.svg'},
                {name: this.$t('road_sign.additional_plates.h12_8'),value: additionalPlates.H12_8,icon: 'H12.8_Moottoripyörä.svg'},
                {name: this.$t('road_sign.additional_plates.h12_9'),value: additionalPlates.H12_9,icon: 'H12.9_Mopo.svg'},
                {name: this.$t('road_sign.additional_plates.h12_10'),value: additionalPlates.H12_10,icon: 'H12.10_Polkupyörä.svg'},
                {name: this.$t('road_sign.additional_plates.h12_11'),value: additionalPlates.H12_11,icon: 'H12.11_Moottorikelkka.svg'},
                {name: this.$t('road_sign.additional_plates.h12_12'),value: additionalPlates.H12_12,icon: 'H12.12_Traktori.svg'},
                {name: this.$t('road_sign.additional_plates.h12_13'),value: additionalPlates.H12_13,icon: 'H12.13_Vähäpäästöinen_ajoneuvo.svg'},
                {name: this.$t('road_sign.additional_plates.h13_1'),value: additionalPlates.H13_1,icon: 'H13.1_Pysäköintitapa.svg'},
                {name: this.$t('road_sign.additional_plates.h13_2'),value: additionalPlates.H13_2,icon: 'H13.2_Pysäköintitapa.svg'},
                {name: this.$t('road_sign.additional_plates.h14'),value: additionalPlates.H14,icon: 'H14_Kielto_ryhmän_A_vaarallisten_aineiden_kuljetukselle.svg'},
                {name: this.$t('road_sign.additional_plates.h15'),value: additionalPlates.H15,icon: 'H15_Kielto_ryhmän_B_vaarallisten_aineiden_kuljetukselle.svg'},
                {name: this.$t('road_sign.additional_plates.h16'),value: additionalPlates.H16,icon: 'H16_Tunneliluokka.svg'},
                {name: this.$t('road_sign.additional_plates.h17_1'),value: additionalPlates.H17_1,icon: 'H17.1_Voimassaoloaika.svg'},
                {name: this.$t('road_sign.additional_plates.h17_2'),value: additionalPlates.H17_2,icon: 'H17.2_Voimassaoloaika.svg'},
                {name: this.$t('road_sign.additional_plates.h17_3'),value: additionalPlates.H17_3,icon: 'H17.3_Voimassaoloaika.svg'},
                {name: this.$t('road_sign.additional_plates.h18'),value: additionalPlates.H18,icon: 'H18_Aikarajoitus.svg'},
                {name: this.$t('road_sign.additional_plates.h19_1'),value: additionalPlates.H19_1,icon: 'H19.1_Pysäköintiajan_alkamisen_osoittamisvelvollisuus.svg'},
                {name: this.$t('road_sign.additional_plates.h19_2'),value: additionalPlates.H19_2,icon: 'H19.2_Pysäköintiajan_alkamisen_osoittamisvelvollisuus.svg'},
                {name: this.$t('road_sign.additional_plates.h20'),value: additionalPlates.H20,icon: 'H20_Maksullinen_pysäköinti.svg'},
                {name: this.$t('road_sign.additional_plates.h21'),value: additionalPlates.H21,icon: 'H21_Latauspaikka.svg'},
                {name: this.$t('road_sign.additional_plates.h22_1'),value: additionalPlates.H22_1,icon: 'H22.1_Etuajo-oikeutetun_liikenteen_suunta.svg'},
                {name: this.$t('road_sign.additional_plates.h22_2'),value: additionalPlates.H22_2,icon: 'H22.2_Etuajo-oikeutetun_liikenteen_suunta.svg'},
                {name: this.$t('road_sign.additional_plates.h23_1'),value: additionalPlates.H23_1,icon: 'H23.1_Kaksisuuntainen_pyorätie.svg'},
                {name: this.$t('road_sign.additional_plates.h23_2'),value: additionalPlates.H23_2,icon: 'H23.2_Kaksisuuntainen_pyorätie.svg'},
                {name: this.$t('road_sign.additional_plates.h24'),value: additionalPlates.H24,icon: 'H24_Tekstillinen_lisäkilpi.svg'},
                {name: this.$t('road_sign.additional_plates.h25'),value: additionalPlates.H25,icon: 'H25_Huoltoajo_sallittu.svg'},
                {name: this.$t('road_sign.additional_plates.h26'),value: additionalPlates.H26,icon: 'H26_Hätäpuhelin_ja_sammutin.svg'}
            ]
        },
        getOtherSigns: function () {
            return [
                {name: this.$t('road_sign.others.x1'), value: others.X1, icon: 'X1_Tienimikilpi.png'},
                {name: this.$t('road_sign.others.i1'), value: others.I1, icon: 'I1_Sulkupuomi.svg'},
                {name: this.$t('road_sign.others.i2_1'), value: others.I2_1, icon: 'I2.1_Sulkuaita.svg'},
                {name: this.$t('road_sign.others.i2_2'), value: others.I2_2, icon: 'I2.2_Sulkuaita.svg'},
                {name: this.$t('road_sign.others.i3_1'), value: others.I3_1, icon: 'I3.1_Sulkupylväs.svg'},
                {name: this.$t('road_sign.others.i3_2'), value: others.I3_2, icon: 'I3.2_Sulkupylväs.svg'},
                {name: this.$t('road_sign.others.i3_3'), value: others.I3_3, icon: 'I3.3_Sulkupylväs.svg'},
                {name: this.$t('road_sign.others.i4'), value: others.I4, icon: 'I4_Sulkukartio.svg'},
                {name: this.$t('road_sign.others.i5'), value: others.I5, icon: 'I5_Taustamerkki.svg'},
                {name: this.$t('road_sign.others.i6'), value: others.I6, icon: 'I6_Kaarteen_suuntamerkki.svg'},
                {name: this.$t('road_sign.others.i7_1'), value: others.I7_1, icon: 'I7.1_Reunamerkki.svg'},
                {name: this.$t('road_sign.others.i7_2'), value: others.I7_2, icon: 'I7.2_Reunamerkki.svg'},
                {name: this.$t('road_sign.others.i8'), value: others.I8, icon: 'I8_Korkeusmerkki_Normaali_koko.svg'},
                {name: this.$t('road_sign.others.i9'), value: others.I9, icon: 'I9_Alikulun_korkeusmitta.svg'},
                {name: this.$t('road_sign.others.i10_1'), value: others.I10_1, icon: 'I10.1_Liikennemerkkipylvaan_tehostamismerkki.svg'},
                {name: this.$t('road_sign.others.i10_2'),value: others.I10_2,icon: 'I10.2_Liikennemerkkipylvaan_tehostamismerkki.svg'},
                {name: this.$t('road_sign.others.i11'), value: others.I11, icon: 'I11_Erkanemismerkki.svg'},
                {name: this.$t('road_sign.others.i12_1'), value: others.I12_1, icon: 'I12.1_Reunapaalu.svg'},
                {name: this.$t('road_sign.others.i12_2'), value: others.I12_2, icon: 'I12.2_Reunapaalu.svg'},
                {name: this.$t('road_sign.others.i13'), value: others.I13, icon: 'I13_Siirtokehotus.svg'},
                {name: this.$t('road_sign.others.i14'), value: others.I14, icon: 'I14_Paikannusmerkki.svg'},
                {name: this.$t('road_sign.others.i15'),value: others.I15,icon: 'I15_Automaattinen_liikennevalvonta.svg'},
                {name: this.$t('road_sign.others.i16'), value: others.I16, icon: 'I16_Tekninen_valvonta.svg'},
                {name: this.$t('road_sign.others.i17_1'), value: others.I17_1, icon: 'I17.1_Poronhoitoalue.svg'},
                {name: this.$t('road_sign.others.i17_2'), value: others.I17_2, icon: 'I17.2_Poronhoitoalue.svg'},
                {name: this.$t('road_sign.others.i18'), value: others.I18, icon: 'I18_Yleinen_nopeusrajoitus.svg'},
                {name: this.$t('road_sign.others.i19'), value: others.I19, icon: 'I19_Valtion_raja.svg'}
            ]
        },
        getSigns: function (type) {
            switch (type) {
                case roadSignTypes.WARNINGS:
                    return this.getWarningSigns()
                case roadSignTypes.RESTRICTIONS:
                    return this.getRestrictionSigns()
                case roadSignTypes.RULES:
                    return this.getRuleSigns()
                case roadSignTypes.COMMANDS:
                    return this.getCommandSigns()
                case roadSignTypes.PRIORITIES:
                    return this.getPrioritySigns()
                case roadSignTypes.GUIDES:
                    return this.getGuideSigns()
                case roadSignTypes.SERVICES:
                    return this.getServiceSigns()
                case roadSignTypes.ADDITIONAL_PLATES:
                    return this.getAdditionalPlateSigns()
                case roadSignTypes.OTHERS:
                    return this.getOtherSigns()
                default:
                    return []
            }
        },
        getSignType(code) {
            let sign = this.getWarningSigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.WARNINGS
            }
            sign = this.getRestrictionSigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.RESTRICTIONS
            }
            sign = this.getCommandSigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.COMMANDS
            }
            sign = this.getRuleSigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.RULES
            }
            sign = this.getPrioritySigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.PRIORITIES
            }
            sign = this.getGuideSigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.GUIDES
            }
            sign = this.getServiceSigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.SERVICES
            }
            sign = this.getOtherSigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.OTHERS
            }
            sign = this.getAdditionalPlateSigns().find(item => item.value === code)
            if (sign) {
                return roadSignTypes.ADDITIONAL_PLATES
            }
            return null
        },
        getSignIcon(code) {
            let sign = this.getWarningSigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            sign = this.getRestrictionSigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            sign = this.getCommandSigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            sign = this.getRuleSigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            sign = this.getPrioritySigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            sign = this.getGuideSigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            sign = this.getServiceSigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            sign = this.getOtherSigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            sign = this.getAdditionalPlateSigns().find(item => item.value === code)
            if (sign) {
                return sign.icon
            }
            return 'unknown_sign.png'
        }
    }
}
export const installation = {
    OWN_POLE: 1,
    TRAFFIC_LIGHT: 2,
    STREET_LIGHT: 3,
    OTHER: 4,
    FRAME: 5
}

export const side = {
    RIGHT: 1,
    LEFT: 2,
    ABOVE: 3
}

export const foil = {
    R0: 1,
    R1: 2,
    R2: 4,
    R3: 3
}

export const condition = {
    POOR: 1,
    MEDIOCRE: 2,
    SATISFACTORY: 3,
    GOOD: 4,
    EXCELLENT: 5,
    MISSING: 10
}

export const roadSignTypes = {
    WARNINGS: "WARNINGS",
    RESTRICTIONS: "RESTRICTIONS",
    RULES: "RULES",
    COMMANDS: "COMMANDS",
    PRIORITIES: "PRIORITIES",
    GUIDES: "GUIDES",
    ADDITIONAL_PLATES: "ADDITIONAL_PLATES",
    OTHERS: "OTHERS",
    SERVICES: "SERVICES"
}

export const warnings = {
    A1_1: "A1.1",
    A1_2: "A1.2",
    A2_1: "A2.1",
    A2_2: "A2.2",
    A3_1: "A3.1",
    A3_2: "A3.2",
    A4: "A4",
    A5: "A5",
    A6: "A6",
    A7: "A7",
    A8: "A8",
    A9: "A9",
    A10: "A10",
    A11: "A11",
    A12: "A12",
    A13: "A13",
    A14: "A14",
    A15: "A15",
    A16: "A16",
    A17: "A17",
    A18: "A18",
    A19: "A19",
    A20_1: "A20.1",
    A20_2: "A20.2",
    A20_3: "A20.3",
    A21: "A21",
    A22_1: "A22.1",
    A22_2: "A22.2",
    A22_3: "A22.3",
    A22: "A22",
    A23: "A23",
    A24: "A24",
    A25: "A25",
    A26: "A26",
    A27: "A27",
    A28_1: "A28.1",
    A28_2: "A28.2",
    A28_3: "A28.3",
    A29_1: "A29.1",
    A29_2: "A29.2",
    A30: "A30",
    A31: "A31",
    A32: "A32",
    A33: "A33"
}

export const restrictions = {
    C1: "C1",
    C2: "C2",
    C3: "C3",
    C4: "C4",
    C5: "C5",
    C6: "C6",
    C7: "C7",
    C8: "C8",
    C9: "C9",
    C10: "C10",
    C11: "C11",
    C12: "C12",
    C13: "C13",
    C14: "C14",
    C15: "C15",
    C16: "C16",
    C17: "C17",
    C18: "C18",
    C19: "C19",
    C20: "C20",
    C21: "C21",
    C22: "C22",
    C23: "C23",
    C24: "C24",
    C25: "C25",
    C26: "C26",
    C27: "C27",
    C28: "C28",
    C29: "C29",
    C30: "C30",
    C31: "C31",
    C32: "C32",
    C33: "C33",
    C34: "C34",
    C35: "C35",
    C36: "C36",
    C37: "C37",
    C38: "C38",
    C39: "C39",
    C40: "C40",
    C41: "C41",
    C42: "C42",
    C43: "C43",
    C44_1: "C44.1",
    C44_2: "C44.2",
    C45: "C45",
    C46: "C46",
    C47: "C47",
    C48: "C48"
}

export const rules = {
    E1: "E1",
    E2: "E2",
    E3_1: "E3.1",
    E3_2: "E3.2",
    E3_3: "E3.3",
    E3_4: "E3.4",
    E3_5: "E3.5",
    E4_1: "E4.1",
    E4_2: "E4.2",
    E4_3: "E4.3",
    E5: "E5",
    E6: "E6",
    E7: "E7",
    E8: "E8",
    E9_1: "E9.1",
    E9_2: "E9.2",
    E10_1: "E10.1",
    E10_2: "E10.2",
    E11_1: "E11.1",
    E11_2: "E11.2",
    E12_1: "E12.1",
    E12_2: "E12.2",
    E13_1: "E13.1",
    E13_2: "E13.2",
    E14_1: "E14.1",
    E14_2: "E14.2",
    E15: "E15",
    E16: "E16",
    E17: "E17",
    E18: "E18",
    E19: "E19",
    E20: "E20",
    E21: "E21",
    E22: "E22",
    E23: "E23",
    E24: "E24",
    E25: "E25",
    E26: "E26",
    E27: "E27",
    E28: "E28",
    E29: "E29",
    E30: "E30"
}

export const commands = {
    D1_1: "D1.1",
    D1_2: "D1.2",
    D1_3: "D1.3",
    D1_4: "D1.4",
    D1_5: "D1.5",
    D1_6: "D1.6",
    D1_7: "D1.7",
    D1_8: "D1.8",
    D1_9: "D1.9",
    D2: "D2",
    D3_1: "D3.1",
    D3_2: "D3.2",
    D3_3: "D3.3",
    D4: "D4",
    D5: "D5",
    D6: "D6",
    D7_1: "D7.1",
    D7_2: "D7.2",
    D8: "D8",
    D9: "D9",
    D10: "D10",
    D11: "D11"
}


export const priorities = {
    B1: "B1",
    B2: "B2",
    B3: "B3",
    B4: "B4",
    B5: "B5",
    B6: "B6",
    B7: "B7"
}

export const guides = {
    F1_1: "F1.1",
    F1_2: "F1.2",
    F1_3: "F1.3",
    F2_1: "F2.1",
    F2_2: "F2.2",
    F2_3: "F2.3",
    F3: "F3",
    F4_1: "F4.1",
    F4_2: "F4.2",
    F5: "F5",
    F6: "F6",
    F7_1: "F7.1",
    F7_2: "F7.2",
    F7_3: "F7.3",
    F7_4: "F7.4",
    F7_5: "F7.5",
    F7_6: "F7.6",
    F8_1: "F8.1",
    F8_2: "F8.2",
    F9: "F9",
    F10: "F10",
    F11: "F11",
    F12: "F12",
    F13: "F13",
    F14: "F14",
    F15: "F15",
    F16: "F16",
    F17: "F17",
    F18_1: "F18.1",
    F18_2: "F18.2",
    F18_3: "F18.3",
    F18_4: "F18.4",
    F18_5: "F18.5",
    F19: "F19",
    F20_1: "F20.1",
    F20_2: "F20.2",
    F21_1: "F21.1",
    F21_2: "F21.2",
    F22: "F22",
    F23: "F23",
    F24_1: "F24.1",
    F24_2: "F24.2",
    F24_3: "F24.3",
    F25: "F25",
    F26: "F26",
    F27_1: "F27.1",
    F27_2: "F27.2",
    F28: "F28",
    F29: "F29",
    F30: "F30",
    F31: "F31",
    F32: "F32",
    F33: "F33",
    F34: "F34",
    F35: "F35",
    F36: "F36",
    F37: "F37",
    F38: "F38",
    F39: "F39",
    F40: "F40",
    F41: "F41",
    F42: "F42",
    F43: "F43",
    F44: "F44",
    F45: "F45",
    F46_1: "F46.1",
    F46_2: "F46.2",
    F47: "F47",
    F48: "F48",
    F49: "F49",
    F50: "F50",
    F50_7: "F50.7",
    F50_8: "F50.8",
    F51: "F51",
    F52: "F52",
    F53: "F53",
    F54_1: "F54.1",
    F54_2: "F54.2",
    F55_1: "F55.1",
    F55_2: "F55.2",
    F55_3: "F55.3",
    F55_4: "F55.4",
    F56_1: "F56.1",
    F56_2: "F56.2",
    F57_1: "F57.1",
    F57_2: "F57.2"
}

export const services = {
    G1: "G1",
    G2: "G2",
    G3: "G3",
    G4: "G4",
    G5: "G5",
    G6: "G6",
    G7: "G7",
    G8: "G8",
    G9: "G9",
    G10: "G10",
    G11_1: "G11.1",
    G11_2: "G11.2",
    G11_3: "G11.3",
    G11_4: "G11.4",
    G12: "G12",
    G13: "G13",
    G14: "G14",
    G15: "G15",
    G16: "G16",
    G17: "G17",
    G18: "G18",
    G19: "G19",
    G20: "G20",
    G21: "G21",
    G22: "G22",
    G23: "G23",
    G24: "G24",
    G25: "G25",
    G26: "G26",
    G27: "G27",
    G28: "G28",
    G29: "G29",
    G30: "G30",
    G31: "G31",
    G32: "G32",
    G33: "G33",
    G34: "G34",
    G35: "G35",
    G36: "G36",
    G37: "G37",
    G38: "G38",
    G39: "G39",
    G40: "G40",
    G41_1: "G41.1",
    G41_2: "G41.2",
    G42: "G42",
}

export const additionalPlates = {
    H1: "H1",
    H2_1: "H2.1",
    H2_2: "H2.2",
    H2_3: "H2.3",
    H3: "H3",
    H4: "H4",
    H5: "H5",
    H6: "H6",
    H7: "H7",
    H8: "H8",
    H9_1: "H9.1",
    H9_2: "H9.2",
    H10: "H10",
    H11: "H11",
    H12_1: "H12.1",
    H12_2: "H12.2",
    H12_3: "H12.3",
    H12_4: "H12.4",
    H12_5: "H12.5",
    H12_6: "H12.6",
    H12_7: "H12.7",
    H12_8: "H12.8",
    H12_9: "H12.9",
    H12_10: "H12.10",
    H12_11: "H12.11",
    H12_12: "H12.12",
    H12_13: "H12.13",
    H13_1: "H13.1",
    H13_2: "H13.2",
    H14: "H14",
    H15: "H15",
    H16: "H16",
    H17_1: "H17.1",
    H17_2: "H17.2",
    H17_3: "H17.3",
    H18: "H18",
    H19_1: "H19.1",
    H19_2: "H19.2",
    H20: "H20",
    H21: "H21",
    H22_1: "H22.1",
    H22_2: "H22.2",
    H23_1: "H23.1",
    H23_2: "H23.2",
    H24: "H24",
    H25: "H25",
    H26: "H26"
}

export const others = {
    X1: "X1",
    I1: "I1",
    I2_1: "I2.1",
    I2_2: "I2.2",
    I3_1: "I3.1",
    I3_2: "I3.2",
    I3_3: "I3.3",
    I4: "I4",
    I5: "I5",
    I6: "I6",
    I7_1: "I7.1",
    I7_2: "I7.2",
    I8: "I8",
    I9: "I9",
    I10_1: "I10.1",
    I10_2: "I10.2",
    I11: "I11",
    I12_1: "I12.1",
    I12_2: "I12.2",
    I13: "I13",
    I14: "I14",
    I15: "I15",
    I16: "I16",
    I17_1: "I17.1",
    I17_2: "I17.2",
    I18: "I18",
    I19: "I19"
}
