<template>
    <div class="col-sm-12 nopads">
        <b-row class="nopads">
            <!-- MAP -->
            <div class="col-sm-6 nopads pl-1 pr-1 pt-1">
                <div
                    class="col-sm-12 stat-map-container nopads"
                    style="overflow: hidden; height: 15em;"
                >
                    <marker-editor-map
                        v-if="observation"
                        :id="observation.id"
                        :lat="getLatitude(observation)"
                        :lon="getLongitude(observation)"
                        :points="getPoints(observation)"
                        :type="OBSERVATION"
                        :draggable="false"
                    />
                </div>
            </div>
            <!-- PHOTOS -->
            <div class="col-sm-6 nopads p-1">
                <Flicking :options="options" :plugins="plugins" v-if="observation.photos.length > 0" class="pointer nopads text-center"
                          style="height: 15em;">
                    <div v-for="photo in observation.photos" :key="photo.id" class="relative nopads stat-map-container slide-img-container">
                        <ImageView
                            :url="getPhotoUrl(observationPhotoUrl, photo)"
                        />
                    </div>
                    <div slot="viewport" class="flicking-pagination"></div>
                </Flicking>
                <div v-else style="padding-top: 6em; text-align: center">{{ $t('power_stations.no_photos') }}</div>
            </div>
        </b-row>
        <!-- Details -->
        <b-row class="nopads mt-4 pt-2">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('observations.observation_group') }}</span>
                <span
                    v-if="observation.type && observation.type.observation_group"
                    class="item-detail-text"
                >
            {{ observation.type.observation_group.name }}
          </span>
                <span
                    v-else
                    class="item-detail-text"
                >
            -
          </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('observations.type') }}</span>
                <span class="item-detail-text">{{ observation.type.name }}</span>
            </div>
        </b-row>

        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('contracts.customer') }}</span>
                <span
                    v-if="observation.contract"
                    class="item-detail-text"
                >
            {{ observation.contract.customer.name }}
          </span>
                <span
                    v-else-if="observation.work_order && observation.work_order.contract_task_type"
                    class="item-detail-text"
                >
            {{ observation.work_order.contract_task_type.contract.customer.name }}
          </span>
                <span
                    v-else
                    class="item-detail-text"
                >
            -
          </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('observations.contract') }}</span>
                <span
                    v-if="observation.contract"
                    class="item-detail-text"
                >
            {{ observation.contract.name }}
          </span>
                <span
                    v-else-if="observation.work_order && observation.work_order.contract_task_type"
                    class="item-detail-text"
                >
            {{ observation.work_order.contract_task_type.contract.name }}
          </span>
                <span
                    v-else
                    class="item-detail-text"
                >
            -
          </span>
            </div>
        </b-row>

        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('observations.order') }}</span>
                <span
                    v-if="observation.work_order && observation.work_order.contract_task_type"
                    class="item-detail-text"
                >
            {{ observation.work_order.contract_task_type.contract.name }}
          </span>
                <span
                    v-else
                    class="item-detail-text"
                >
            -
          </span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('observations.city') }}</span>
                <span
                    v-if="observation.city"
                    class="item-detail-text"
                >
            {{ observation.city.name }}
          </span>
                <span
                    v-else
                    class="item-detail-text"
                >
            -
          </span>
            </div>
        </b-row>

        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('observations.location') }}</span>
                <span class="item-detail-text">{{ getStreetAddressString(observation) }}</span>
            </div>
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('observations.status') }}</span>
                <span class="item-detail-text">{{
                        observation.closed_time ? this.$t('observations.closed') : this.$t('observations.open')
                    }}</span>
            </div>
        </b-row>

        <b-row class="nopads">
            <div class="col-sm-6 nopads">
                <span class="item-detail-title">{{ $t('work_report.info') }}</span>
                <span class="item-detail-text">{{ observation.info ? observation.info : '-' }}</span>
            </div>
        </b-row>
    </div>
</template>

<script>
import {observationHelper} from '../mixins/ObservationMixin'
import MarkerEditorMap from '../map/MarkerEditorMap'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import {Pagination} from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/vue-flicking/dist/flicking.css";
import {Flicking} from "@egjs/vue-flicking";
import ImageView from "@/components/view/ImageView.vue";

export default {
    name: 'WorkAssignmentObservation',
    mixins: [observationHelper, mapHelper, restApi],
    components: {MarkerEditorMap, Flicking, ImageView},
    props: {
        observation: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            plugins: [new Pagination({type: 'bullet'})],
            options: {
                preventDefaultOnDrag: true,
                bound: true,
            },
        }
    },
}
</script>

<style scoped>
.map-container {
    height: 250px;
    margin-bottom: -0.2em;
}
</style>


