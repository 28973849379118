import deepmerge from "deepmerge";

const overwriteMerge = (destinationArray, sourceArray) => sourceArray

export const mapHelper = {
    data: function () {
        return {
            VEHICLE: 1,
            OBSERVATION: 2,
            EQUIPMENT: 3,
            OTHER: 4,
            USER: 5,
            PERSON: 6,
            ROAD: 7,
            TRACE: 8,
            SKI_TRACK: 9,
            MATERIAL_STORAGE: 10,
            USER_ALERT: 11,
            WORK_TRACE: 12,
            TRAFFIC_SIGN: 13,
            MATERIAL_STATION: 14,
            BRIDGE: 15,
            CULVERT: 16,
            WORK_ASSIGNMENT: 17,
            MEASURE_DISTANCE: 18,
            IMPORTED_SHAPE: 19,
            MEASURE_ROAD: 20,
            AREA: 21,
            USER_LOCATION: 22,
            POWER_CENTER: 23,
            STREET_LIGHT: 24,
            SEWERS: 25,
            DRAINS: 26,
            SEWERS_AND_DRAINS: 27,
            TRAFFIC_CONTROL_ITEM: 28,
            EXTERNAL_TRAFFIC_SIGNS: 29,
            EXTERNAL_POSTS: 30,
            EXTERNAL_CULVERTS: 31,
            EXTERNAL_RAILINGS: 32,
            SPREADER_DATA: 33,
            EXTERNAL_PORTALS: 34,
            EXTERNAL_DRAINS: 35,
            EXTERNAL_FENCES: 36,
            EXTERNAL_STAIRS: 37,
            EXTERNAL_GATES: 38,
            EXTERNAL_EDGE_SUPPORTS: 39,
            EXTERNAL_EDGE_PILES: 40,
            EXTERNAL_ROADSIDE_FURNITURE: 41,
            EXTERNAL_ROADSIDE_ADVERTISEMENTS: 42,
            EXTERNAL_LIGHTING: 43,
            EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS: 44,
            EXTERNAL_PIPES_WIRES_AND_CABLES: 45,
            HARJA_MESSAGES: 46,
            WEATHER_STATION: 47,
            mouseCoordinate: null,
            roadNames: ['roadName', 'name', 'nimi', 'tie_nimi', 'MT_TIENIMI', 'TIENIMI_SU'],
            excludedFields: ['id', 'importedItemIndex', 'selected', 'index', 'objectIndex'],
            leafletScreenshotOptions: {
                cropImageByInnerWH: true,
                hidden: true,
                preventDownload: false,
                domtoimageOptions: {},
                position: 'topleft',
                screenName: 'screen',
                iconUrl: 'noicon',
                hideElementsWithSelectors: ['.leaflet-control-container'],
                mimeType: 'image/png',
                caption: null,
                captionFontSize: 15,
                captionFont: 'Arial',
                captionColor: 'black',
                captionBgColor: 'white',
                captionOffset: 5,
                // eslint-disable-next-line
                onPixelDataFail: async function({ node, plugin, error, mapPane, domtoimageOptions }) {
                    return plugin._getPixelDataOfNormalMap(domtoimageOptions)
                }
            }
        }
    },
    computed: {
        mapSettings: {
            get() {
                const settings = this.$store && this.$store.state.mapSettings || {};
                return this.convertMapSettings(settings);
            },
            set(value) {
                this.$store.commit(
                    'storeMapSettings',
                    value
                )
            }
        }
    },
    methods: {
        async saveMapSettings() {
            return await this.upsertUserPreferences('mapSettings', this.cleanSettings(this.mapSettings));
        },
        // When vue supports it, replace calls to this with optional chaining
        // eg. this.mapSettings?.my?.path ?? myDefaultValue
        getMapSettingsIn(path, defaultValue = undefined) {
            let settings = this.mapSettings;
            for (const index in path) {
                settings = settings[path[index]];
                if (settings == null) {
                    return defaultValue;
                }
            }
            return settings
        },
        cleanSettings(settings) {
            return {
                "baseMap": settings.baseMap,
                "buttons": settings.buttons,
                "center": settings.center,
                "leaflet": settings.leaflet,
                "here": settings.here
            }
        },
        convertMapSettings(settings) {
            const mapToggles = settings.mapToggles;
            if (mapToggles) {
                delete settings.mapToggles;
                if (settings.buttons === undefined) {
                    settings.buttons = {};
                }
                settings.buttons.vehicles = mapToggles.vehiclesEnabled;
                settings.buttons.observations = mapToggles.observationsEnabled;
                settings.buttons.users = mapToggles.usersEnabled;
                settings.buttons.skiing =
                    mapToggles.skiingSelectedCity ? {city: mapToggles.skiingSelectedCity}
                        : mapToggles.skiingEnabled ? {}
                            : undefined;
                settings.buttons.storages = mapToggles.storagesEnabled;
                settings.buttons.stations = mapToggles.stationsEnabled;
                settings.buttons.roadSigns = mapToggles.roadSignsEnabled;
                settings.buttons.workAssignments = mapToggles.workAssignmentsEnabled;
                setTimeout(this.saveMapSettings, 500);
            }
            return settings;
        },
        updateMapSettings(value) {
            this.mapSettings = deepmerge(this.mapSettings, value, { arrayMerge: overwriteMerge });
        },
        getMarkerDiv(markerStore, type) {
            switch (type) {
                case this.OBSERVATION: return markerStore.getObservationMarkerIcon()
                case this.WORK_ASSIGNMENT: return markerStore.getWorkAssignmentMarkerIcon()
                case this.MATERIAL_STORAGE: return markerStore.getStorageMarkerGenericIcon()
                case this.USER: return markerStore.getPersonMarkerIcon()
                case this.STREET_LIGHT: return markerStore.getStreetLightIcon()
                case this.POWER_CENTER: return markerStore.getPowerStationIcon()
                case this.DRAINS: return markerStore.getDrainIcon()
                case this.VEHICLE: return markerStore.getActiveCarIcon()
                case this.USER_ALERT: return markerStore.getAlertMarkerIcon()
                case this.TRAFFIC_CONTROL_ITEM: return markerStore.getUnknownMarkerIcon()
            }
            return null
        },
        getMarkerTypeName(type) {
            switch (type) {
                case this.OBSERVATION: return this.$t('map.create_observation')
                case this.WORK_ASSIGNMENT: return this.$t('map.create_work_assignment')
                case this.MATERIAL_STORAGE: return this.$t('materialstorage.title')
                case this.STREET_LIGHT: return this.$t('map.add_street_light')
                case this.POWER_CENTER: return this.$t('map.add_power_center')
                case this.DRAINS: return this.$t('map.add_sewer_drain')
                case this.VEHICLE: return this.$t('tracking_devices.vehicle')
                case this.USER: return this.$t('work_time.person')
                case this.USER_ALERT: return this.$t('menu.user_alerts')
                case this.TRAFFIC_CONTROL_ITEM: return this.$t('traffic_control.modal_title')
            }
            return null
        }
    }
}

export const userTracking = {
    props: {
        selectedMap: {
            type: String,
            default: ""
        }
    },

    data() {
        return {
            watchUserPositionId: null,
            isLoadingPosition: false
        }
    },

    methods: {
        userPinOnMap() {
            return this.USER_LOCATION in this.markers && this.markers[this.USER_LOCATION][this.userMarkerId] !== undefined
        },

        watchUserPosition: function () {
            if (this.watchUserPositionId) {
                return this.showUserMarkerPosition()
            }
            this.isLoadingPosition = true;
            if (navigator.geolocation) {
                if (!this.watchUserPositionId) {
                    this.isLoadingPosition = true
                }
                this.watchUserPositionId = navigator.geolocation.watchPosition(
                    (pos) => this.successPosition(pos),
                    () => this.failurePosition(),
                    { timeout: Infinity, enableHighAccuracy: true, maximumAge: Infinity }
                )
            }
        },

        successPosition: function (pos) {
            if (pos.message || pos.code) return
            this.showPosition(pos, true, this.watchUserPositionId != null)
            if (this.isLoadingPosition) this.isLoadingPosition = false
        },

        failurePosition: function () {
            this.isLoadingPosition = false;
        },

        clearWatchUserPosition: function () {
            if (navigator.geolocation && this.watchUserPositionId) {
                navigator.geolocation.clearWatch(this.watchUserPositionId)
                this.watchUserPositionId = null
            }
        },

        showUserMarkerPosition: function () {
            if (this.userPinOnMap()) {
                const userMarker = this.markers[this.USER_LOCATION][this.userMarkerId];
                if (userMarker) {
                    if (this.selectedMap === "HERE") {
                        const { lat, lng } = userMarker.getGeometry()
                        this.showPosition({ coords: { latitude: lat, longitude: lng } }, false, this.watchUserPositionId != null)
                    }
                    if (this.selectedMap === "LEAFLET") {
                        const { lat, lng } = userMarker.getLatLng()
                        this.showPosition({ coords: { latitude: lat, longitude: lng } }, false, this.watchUserPositionId != null)
                    }
                }
            }
        },
    },

    computed: {
        currentMapCenter() {
            return this.getMapCenter()
        },
    },

    mounted() {
        this.clearWatchUserPosition()
    },
    beforeDestroy() {
        navigator.geolocation.clearWatch(this.watchUserPositionId)
        this.watchUserPositionId = null
    }
}
