<template>
	<div :class="['color-swatch', {dark: dark}]">
		<button class="color-swatch__button button--nostyle" @click.stop.prevent="toggleColorPicker">
			<div class="color-swatch__selected-color-indicator" :style="{ backgroundColor: selectedColor }">
			</div>
		</button>
		<transition name="fade">
			<div v-if="showPicker" class="color-swatch__picker">
				<button v-for="color in colors" class="color-swatch__picker-item button--nostyle" @click.stop.prevent="selectColor(color)" :key="color">
					<div class="color-swatch__selected-color-indicator" :style="{ backgroundColor: color }"></div>
				</button>
			</div>
		</transition>
	</div>
</template>
<script>
export default {
	name: 'color-picker',
	props: {
		dark: {
			type: Boolean,
			default: false
		},
		customSwatch: {
			type: Array,
			default: () => []
		},
		defaultColor: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			showPicker: false,
			selectedColor: "#B200FF",
            colors: [
                "#007bff",
                "#6610f2",
                "#e83e8c",
                "#753703",
                "#fd7e14",
                "#ffc107",
                "#28a745",
                "#20c997",
                "#17a2b8",
                "#949494",
                "#000000"
            ]
		}
	},
    watch: {
        defaultColor(color) {
            this.selectedColor = color
        }
    },
	methods: {
		selectColor(color) {
			this.selectedColor = color;
			this.$emit('selectColor', color);
			this.showPicker = false;
		},
		toggleColorPicker() {
			this.showPicker = !this.showPicker;
		}
	},

	created() {
		if (Array.isArray(this.customSwatch) && this.customSwatch.length) {
			this.colors = this.customSwatch;
		}
		if (this.defaultColor) {
			this.selectedColor = this.defaultColor;
		}
	}

}
</script>
<style lang="scss" scoped>
.color-swatch {
	--size: 30px;
	--border: 1px solid rgba(0,0,0, .15);
	--background-color: hsl(0, 0%, 95%);
	display: inline-block;
	position: relative;

	&.dark {
		--border: 1px solid rgba(255,255,255, .15);
		--background-color: hsl(0, 0%, 10%);
	}

	&__button {
		width: var(--size);
		height: var(--size);
		border-radius: 50%;
		padding: 2px;
		border: var(--border);
		transition: border .25s ease-out;

		&:hover {
			border-color: #8c9095;
		}
	}

	&__selected-color-indicator {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	&__picker {
		background-color: var(--background-color);
		padding: 8px 10px;
		border-radius: 4px;
		position: absolute;
		bottom: calc(100% + 6px);
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		gap: .25em;
		border: var(--border);
		z-index: 10;

		&::after {
			content: "";
			display: block;
			position: absolute;
			width: 6px;
			height: 6px;
			bottom: -4px;
			left: 50%;
			transform: translateX(-50%) rotate(45deg);
			background-color: inherit;
			border-right: var(--border);
			border-bottom: var(--border);

		}

		&-item {
			@extend .color-swatch__button;
			--size: 22px;
			padding: 2px;
		}
	}
}
</style>
