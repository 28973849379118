<template>
    <div class="col-sm-12 nopads">
        <!-- Equipment report -->
        <b-form
            v-for="(equipmentReport, index) in equipmentReports"
            :key="equipmentReport.equipment.id"
            :value="equipmentReport.equipment.id"
            class="col-sm-12 editor-form"
        >
            <div class="col-sm-12 form-title editor-title">
                <h2 class="form-title editor-title">
                    {{ getEquipmentName(equipmentReport.equipment) }}
                </h2>
                <div class="col-sm-12 title-desc">
                    {{ equipmentReport.equipment.description }}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8">
                    <div class="col-sm-12 nopads form-data">
            <span class="span-title">
              {{ $t('equipment_report.result') }}
            </span>
                        <b-form-group
                            class="title"
                            label-for="equipment_result"
                        >
                            <b-form-select
                                id="equipment_result"
                                v-model="equipmentReport.result"
                                size="sm"
                                class="sm-3"
                            >
                                <option :value="null">
                                    {{ $t('equipment_report.select_equipment_condition') }}
                                </option>
                                <option
                                    v-for="result in equipmentResults"
                                    :key="result.id"
                                    :value="result"
                                >
                                    {{ getEquipmentResult(result) }}
                                </option>
                            </b-form-select>
                        </b-form-group>
                        <span class="span-title">
              {{ $t('equipment_report.info') }}
            </span>
                        <b-form-group
                            class="title"
                            label-for="info"
                        >
                            <b-form-input
                                id="info"
                                v-model="equipmentReport.info"
                                type="text"
                                size="sm"
                            />
                        </b-form-group>
                        <span class="span-title">
              {{ $t('equipment_report.value') }}
            </span>
                        <b-form-group
                            class="title"
                            label-for="value"
                        >
                            <b-form-input
                                id="value"
                                v-model="equipmentReport.value"
                                type="text"
                                size="sm"
                            />
                        </b-form-group>

                        <span class="span-title">
              {{ $t('equipment.photo') }}
            </span>
                        <div
                            v-for="(path, counter) in equipmentReport.photos"
                            :key="counter"
                            class="col-sm-12 nopads"
                        >
                            <b-form-group
                                class="title"
                                label-for="counter"
                                v-if="counter === 0 || isAttachmentSet(equipmentReport.photos, counter-1)"
                            >
                                <b-form-file
                                    v-model="equipmentReport.photos[counter]"
                                    accept="image/jpeg, image/jpg, image/png"
                                    size="sm"
                                    :placeholder="$t('equipment.photo_placeholder')"
                                />
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 img-container mt-4">
                    <ImageView
                        v-if="getPhotoUrl(equipmentReport.equipment)"
                        :url="getPhotoUrl(equipmentReport.equipment)"
                    />
                    <b-row
                        v-else
                        class="img-placeholder"
                        align-h="center"
                        align-v="center"
                    >
                        {{ $t('equipment.no_photo') }}
                    </b-row>
                </div>
                <div class="col-sm-12 button-container">
                    <b-button
                        variant="success"
                        class="routa-button"
                        :disabled="loading || equipmentReport.result == null"
                        @click.stop="submitEquipmentReport(index)"
                    >
                        <i class="fa fa-save"/>
                        <span class="button-text">
              {{ $t('common.save') }}
            </span>
                    </b-button>
                </div>
            </div>
        </b-form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../../mixins/RestApiMixin'
import {areaReportHelper, results} from '../../mixins/AreaReportMixin.js'
import {areaHelper} from '../../mixins/AreaMixin.js'
import {attachmentHelper} from '../../mixins/AttachmentMixin.js'
import ImageView from '../../view/ImageView'

export default {
    name: 'AreaEquipmentReportEditor',
    components: {ImageView},
    mixins: [areaHelper, areaReportHelper, results, restApi, attachmentHelper],
    props: {
        areaReport: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: false,
            equipmentReports: [],
            equipmentResults: []
        }
    },
    mounted: function () {
        this.areaReport.area.equipment.forEach(equipment => {
            if (!this.checkInspected(equipment, this.areaReport.equipment_inspection_results)) {
                let equipmentReport = this.newAreaEquipmentReport()
                equipmentReport.equipment = equipment
                this.equipmentReports.push(equipmentReport)
            }
        })
        this.equipmentResults = Object.values(results)
        this.checkEditAreaReport()
    },
    methods: {
        checkInspected: function (equipment, equipmentInspectionResults) {
            var isInspected = false
            if (equipmentInspectionResults) {
                equipmentInspectionResults.forEach(insResult => {
                    if (insResult.equipment.id === equipment.id) {
                        isInspected = true
                    }
                })
            }
            return isInspected
        },
        getEquipmentName: function (equipment) {
            return equipment.manufacturer ? equipment.name + ' - ' + equipment.manufacturer : equipment.name
        },

        checkValues: function (equipmentReport) {
            if (equipmentReport.result == null) {
                EventBus.$emit('show-alert', this.$t('equipment_report.err_result'))
                return
            }
            equipmentReport.equipment = {id: equipmentReport.equipment.id}
            return equipmentReport
        },

        submitEquipmentReport: function (equipmentReportIdx) {
            const jsonEquipmentReport = this.checkValues(this.equipmentReports[equipmentReportIdx])
            if (jsonEquipmentReport !== undefined) {
                this.addEquipmentInsResult(jsonEquipmentReport)
            }
        },
        addEquipmentInsResult: function (inspectionResult) {
            this.loading = true
            let formData = new FormData()
            inspectionResult.photos.forEach(photoFile => {
                if (photoFile !== undefined && photoFile !== null) {
                    formData.append('photos[]', photoFile)
                }
            })
            inspectionResult.photos = null
            this.restPostWithFile(this.equipInsResultUrl + this.areaReport.id, inspectionResult, formData, this.success, this.fail)
        },
        success: function (responseData) {
            this.loading = false
            for (let i = 0; i < this.equipmentReports.length; i++) {
                if (this.equipmentReports[i].equipment.id === responseData.equipment.id) {
                    this.equipmentReports.splice(i, 1)
                }
            }
            this.checkEditAreaReport()
        },
        fail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
            this.loading = false
        },
        // Check if equipment report is done and ready to submit area report
        checkEditAreaReport: function () {
            if (this.equipmentReports.length === 0) {
                this.$emit('areaEquipmentReport', this.areaReport)
            }
        }
    }
}
</script>

<style scoped>
.editor-form:not(:first-child) {
    margin-top: 10px;
}
</style>
