// eslint-disable-next-line
import * as turf from '@turf/turf';

export const spatialMixin = {
    methods: {
        getLineParts(polygon, feature) {
            let partsInsidePolygon = []
            let lineString = JSON.parse(JSON.stringify(feature))
            // Ensure coordinates are numbers and not string format!
            lineString.geometry.coordinates = JSON.parse(JSON.stringify(lineString.geometry.coordinates).replaceAll("\"", ""))
            let lineStringCoords = lineString.geometry.coordinates
            const intersect = turf.lineIntersect(polygon, lineString);
            if (intersect.features.length === 0 && turf.booleanContains(polygon, lineString)) {
                // Line is fully inside, return the full feature
                partsInsidePolygon.push(feature.geometry.coordinates)
            } else if (intersect.features.length > 0) {
                let inside = false;
                let part = [];
                // Go through each line string coordinates
                for (let i = 0; i < lineStringCoords.length - 1; i++) {
                    const currentPoint = lineStringCoords[i];
                    const nextPoint = lineStringCoords[i + 1];
                    const segment = turf.lineString([currentPoint, nextPoint]);
                    const intersection = turf.lineIntersect(segment, polygon);
                    // First check if current point is inside polygon
                    if (turf.booleanPointInPolygon(turf.point(currentPoint), polygon)) {
                        inside = true;
                        part.push(currentPoint);
                    } else {
                        inside = false
                        if (part.length > 1) {
                            partsInsidePolygon.push(part);
                            part = [];
                        }
                    }

                    // Now handle intersections
                    if (intersection.features.length === 1) {
                        // Either way, the intersection point can be taken in
                        inside = true
                        part.push(intersection.features[0].geometry.coordinates)
                    } else if (intersection.features.length > 1) {
                        // Sort intersection points along the line
                        const intersectionDistances = intersection.features.map(feature => {
                            const pt = turf.point(feature.geometry.coordinates);
                            return {
                                point: pt,
                                distance: turf.distance(turf.point(currentPoint), pt) // Distance from start point of line
                            };
                        });
                        intersectionDistances.sort((a, b) => a.distance - b.distance);
                        let lastIntersect = null
                        //  Go through intersection points and add add inside parts
                        intersectionDistances.forEach(item => {
                            if (!lastIntersect) {
                                // First intersect always can go in
                                part.push(item.point.geometry.coordinates)
                                lastIntersect = item
                            } else {
                                // Resolve center point between end points and if that is inside polygon, create result
                                const startPoint = lastIntersect.point
                                const endPoint = item.point
                                const centerPoint = turf.midpoint(startPoint, endPoint);
                                if(turf.booleanPointInPolygon(centerPoint, polygon)) {
                                    inside = true
                                    part.push(lastIntersect.point.geometry.coordinates)
                                    part.push(item.point.geometry.coordinates)
                                } else {
                                    inside = false
                                    if (part.length > 1) {
                                        partsInsidePolygon.push(part);
                                        part = [];
                                    }
                                }
                                lastIntersect = item
                            }
                        });
                    }

                    // And now the next point on the last round
                    if (i === lineStringCoords.length-2 && inside && turf.booleanPointInPolygon(turf.point(nextPoint), polygon)) {
                        // Next point is inside the Polygon
                        part.push(nextPoint);
                    }
                }
                // Add the last part if it's inside the Polygon
                if (inside && part.length > 0) {
                    partsInsidePolygon.push(part);
                }
            }
            return partsInsidePolygon
        },

        // eslint-disable-next-line
        getInsidePolygon(polygon, geoJson ) {
            let insideFeats = []
            polygon = turf.polygon([polygon])
            geoJson.features.forEach(feature => {
                if (feature.geometry.type === 'LineString') {
                    // Generate features from parts
                    const partsInsidePolygon = this.getLineParts(polygon, feature)
                    partsInsidePolygon.forEach(part => {
                        let partialFeature = JSON.parse(JSON.stringify(feature))
                        partialFeature.geometry.coordinates = part
                        insideFeats.push(partialFeature)
                    })
                } else if (feature.geometry.type === 'Point' && turf.booleanPointInPolygon(turf.point(feature.geometry.coordinates), polygon)) {
                    insideFeats.push(feature)
                }

                // TODO - Multi-linestring, multi-point and area!!!!

            })

            if (insideFeats.length > 0) {
                let result = JSON.parse(JSON.stringify(geoJson))
                result.features = insideFeats
                return result
            }

            return null
        },


        getLinePartsOutside(polygon, feature) {
            const partsOutside = []
            let lineString = JSON.parse(JSON.stringify(feature))
            // Ensure coordinates are numbers and not string format!
            lineString.geometry.coordinates = JSON.parse(JSON.stringify(lineString.geometry.coordinates).replaceAll("\"", ""))
            if (turf.booleanDisjoint(lineString, polygon)) {
                // First check if given feature is completely outside
                partsOutside.push(feature.geometry.coordinates)
            } else {
                const intersectingPoints = turf.lineIntersect(lineString, polygon)
                if (intersectingPoints.features.length > 0) {
                    const coordinates = lineString.geometry.coordinates
                    // Sort intersecting points along the line
                    const pointsWithDistances = intersectingPoints.features.map(feature => {
                        const point = turf.point(feature.geometry.coordinates)
                        const pointOnLine = turf.nearestPointOnLine(lineString, point)
                        const distance = turf.length(turf.lineString([lineString.geometry.coordinates[0], pointOnLine.geometry.coordinates]), { units: 'kilometers' });
                        return {
                            point: point,
                            distance: distance
                        };
                    });
                    const sortedIntersectingPoints = pointsWithDistances.sort((a, b) => a.distance - b.distance).map(item => item.point);
                    let lastSplitIndex = -1
                    let lastIntersect = null
                    // Iterate through the intersecting points to split the line
                    for (const intersect of sortedIntersectingPoints) {
                        const splitIndex = turf.pointOnLine(lineString, intersect).properties.index;
                        // Check if the segment is outside the polygon
                        const partCoordinates = coordinates.slice(lastSplitIndex + 1, splitIndex +1)
                        if (partCoordinates.length > 1) {
                            // Check if one of the line ends is outside polyon and if so, add to results
                            const start = partCoordinates[0]
                            const end = partCoordinates[partCoordinates.length-1]
                            if (!turf.booleanPointInPolygon(turf.point(start), polygon) ||
                                !turf.booleanPointInPolygon(turf.point(end), polygon)) {
                                // Add intersection point to line
                                if (splitIndex > 0) {
                                    partCoordinates.push(intersect.geometry.coordinates)
                                } else {
                                    partCoordinates.unshift(intersect.geometry.coordinates)
                                }
                                if (lastIntersect != null) {
                                    // Start with last intersection point
                                    partCoordinates.unshift(lastIntersect.geometry.coordinates)
                                }
                                partsOutside.push(partCoordinates);
                            }
                        } else if (partCoordinates.length === 1) {
                            // One point only, check if the point is outside polygon and create a line with intersection point
                            if (!turf.booleanPointInPolygon(turf.point(partCoordinates[0]), polygon)) {
                                partCoordinates.push(intersect.geometry.coordinates)
                                if (lastIntersect) {
                                    partCoordinates.unshift(lastIntersect.geometry.coordinates)
                                }
                                partsOutside.push(partCoordinates);
                            }
                        } else if (lastIntersect) {
                            // No points between intersection points, but the line could still go through then
                            // Resolve center point between end points and if that is outside polygon, create result
                            const startPoint = turf.point(intersect.geometry.coordinates)
                            const endPoint = turf.point(lastIntersect.geometry.coordinates)
                            const centerPoint = turf.midpoint(startPoint, endPoint);
                            if(!turf.booleanPointInPolygon(centerPoint, polygon)) {
                                partCoordinates.push(intersect.geometry.coordinates)
                                partCoordinates.push(centerPoint.geometry.coordinates)
                                partCoordinates.push(lastIntersect.geometry.coordinates)
                                partsOutside.push(partCoordinates);
                            }
                        }
                        lastSplitIndex = splitIndex;
                        lastIntersect = intersect
                    }
                    // Add the portion of the line after the last intersection
                    const remainingCoordinates = coordinates.slice(lastSplitIndex+1);
                    // Special case check - with few points we need to check the remaining part does not intersect with polygon
                    const remainingOutsideCoordinates = []
                    remainingCoordinates.forEach(point => {
                        if (!turf.booleanPointInPolygon(point, polygon)) {
                            remainingOutsideCoordinates.push(point)
                        }
                    });
                    if (remainingOutsideCoordinates.length > 0) {
                        remainingOutsideCoordinates.unshift(lastIntersect.geometry.coordinates)
                        partsOutside.push(remainingOutsideCoordinates);
                    } else if (remainingOutsideCoordinates.length === 1) {
                        if (!turf.booleanPointInPolygon(turf.point(remainingOutsideCoordinates[0]), polygon) && lastIntersect !== null) {
                            remainingOutsideCoordinates.push(lastIntersect.geometry.coordinates)
                            partsOutside.push(remainingOutsideCoordinates);
                        }
                    }
                }
            }
            return partsOutside
        },

        getOutsideParts(polygon, geoJson) {
            polygon = turf.polygon([polygon])
            let outsideFeats = []
            geoJson.features.forEach(feature => {
                if (feature.geometry.type === 'LineString') {
                    const partsOutsidePolygon = this.getLinePartsOutside(polygon, feature)
                    partsOutsidePolygon.forEach(part => {
                        let partialFeature = JSON.parse(JSON.stringify(feature))
                        partialFeature.geometry.coordinates = part
                        outsideFeats.push(partialFeature)
                    })
                } else if (feature.geometry.type === 'Point' && !turf.booleanPointInPolygon(turf.point(feature.geometry.coordinates), polygon)) {
                    outsideFeats.push(feature)
                }
            })
            if (outsideFeats.length > 0) {
                let result = JSON.parse(JSON.stringify(geoJson))
                result.features = outsideFeats
                return result
            }
            return null
        }

    }
}
