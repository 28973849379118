<template>
    <div
        v-bind:class="{ 'details-container': !this.isAddEquipmentMode }"
        @click.stop="close"
    >
        <div
            v-bind:class="{ 'details dynamic_width': !this.isAddEquipmentMode }"
            @click.stop=""
        >
            <h3 v-if="!this.isAddEquipmentMode" class="pl-1">
                {{ $t('culvert.culvert')  }}
            </h3>
            <location-deviation v-if="!this.isAddEquipmentMode" :location-deviation="item.location_deviation"/>
            <marker-editor-map
                v-if="item && !this.isAddEquipmentMode"
                :id="item.id"
                class="item-map"
                :lat="parseFloat(item.position.y)"
                :lon="parseFloat(item.position.x)"
                :type="this.CULVERT"
                :draggable="false"
            />

            <div class="pl-2 pr-2">
                <b-row v-if="!this.isAddEquipmentMode">
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('common.id') }}</span>
                        <span class="item-detail-text">{{ item.external_system_id }}</span>
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('culvert.location') }}</span>
                        <span class="item-detail-text">{{ getLocationString(item) }}</span>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('culvert.material') }}</span>
                        <b-form-select
                            v-model="item.material"
                            size="sm"
                            :options="culvertMaterials"
                        />
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('culvert.type') }}</span>
                        <b-form-select
                            v-model="item.type"
                            size="sm"
                            :options="culvertTypes"
                        />
                    </div>
                </b-row>
                <location-details
                    v-if="!this.isAddEquipmentMode"
                    :lane-data="this.item.location_specification.lane"
                    :side-data="this.item.location_specification.side"
                    :track-data="this.item.location_specification.tracks"
                    />
                <b-row>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('culvert.size') }}</span>
                        <b-form-input
                            v-model="item.size"
                            type="number"
                            min="0"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <span class="span-title">{{ $t('culvert.length') }}</span>
                        <b-form-input
                            v-model="item.length"
                            type="number"
                            min="0"
                            size="sm"
                        />
                    </div>
                </b-row>
                <b-row>
                    <div class="col-sm-6 nopads pl-1 pr-1">
                        <condition-selector v-model="item.condition"/>
                    </div>
                </b-row>
                <b-row class="nopads" v-if="this.isAddEquipmentMode">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-location-details-editor :item="item"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-12 nopads mt-2">
                        <velho-equipment-damages :items="item.equipment_damages" :isPercentageVisible="true"/>
                    </div>
                </b-row>
                <equipment-actions v-if="!this.isAddEquipmentMode" ref="actions" v-model="item.actions" />
                <linked-equipment
                    v-if="item.linked_equipment && item.linked_equipment.length > 0"
                    :linked-equipments="item.linked_equipment"
                />
            </div>
            <velho-editor-buttons
                v-if="!this.isAddEquipmentMode"
                :url="extCulvertsUrl"
                :selected-contract="selectedContract"
                :item="item"
                :disabled="loading"
                @close="close"
                @submit="handleSubmit"
                @removalConfirmed="removalConfirmed"
            />
        </div>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import MarkerEditorMap from '../map/MarkerEditorMap'
import {mapHelper} from "@/components/mixins/MapMixin";
import {conversionModelHelper, material} from "@/components/mixins/ConversionMixin";
import {restApi} from "@/components/mixins/RestApiMixin";
import ConditionSelector from "@/components/velho/ConditionSelector";
import {velhoHelper} from "@/components/mixins/VelhoMixin";
import EquipmentActions from "@/components/velho/EquipmentActions";
import VelhoEditorButtons from "@/components/velho/VelhoEditorButtons";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import LinkedEquipment from "@/components/velho/LinkedEquipment";
import LocationDetails from "@/components/velho/LocationDetails.vue";
import LocationDeviation from "@/components/velho/LocationDeviation";
import VelhoEquipmentLocationDetailsEditor from "@/components/velho/VelhoEquipmentLocationDetailsEditor";
import VelhoEquipmentDamages from "@/components/velho/VelhoEquipmentDamages";

export default {
    name: 'CulvertEditor',
    mixins: [velhoHelper, mapHelper, conversionModelHelper, restApi, roadSignHelper],
    components: {
        LocationDeviation, VelhoEquipmentLocationDetailsEditor, VelhoEquipmentDamages,
        VelhoEditorButtons, EquipmentActions, ConditionSelector, MarkerEditorMap, LocationDetails, LinkedEquipment},
    props: {
        item: {
            type: Object,
            default: null
        },
        selectedContract: {
            type: Object,
            default: null
        },
        setInspectionParams: {
            type: Function,
            default: null
        },
        addEquipmentEditor: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isAddEquipmentMode() {
            return this.addEquipmentMode
        }
    },
    data() {
        return {
            addEquipmentMode: this.addEquipmentEditor,
            map: null,
            loading: false,
            culvertMaterials: [
                { value: null, text: this.$t('culvert.select_material')},
                { value: material.CONCRETE, text: this.getMaterialString(material.CONCRETE)},
                { value: material.METAL, text: this.getMaterialString(material.METAL)},
                { value: material.PLASTIC, text: this.getMaterialString(material.PLASTIC)},
                { value: material.OTHER, text: this.getMaterialString(material.OTHER)},
            ],
            culvertTypes: [
                { value: null, text: this.$t('culvert.select_type')},
                { value: 1, text: this.$t('culvert.type_highway')},
                { value: 2, text: this.$t('culvert.type_street')},
                { value: 3, text: this.$t('culvert.type_private_road')},
                { value: 4, text: this.$t('culvert.type_side_ditch')},
                { value: 5, text: this.$t('culvert.type_animal')},
            ]
        }
    },
    methods: {
        handleSubmit: function(url, item, selectedContract, replace = false) {
            if(replace) {
                this.submitEquipmentsreplace(this.extEquipmentsReplaceUrl, [item], selectedContract)
            } else {
                this.submitEquipment(url, item, selectedContract);
            }
        },
        close: function () {
            this.$emit('close')
        },
        changeView: function () {
            if (this.setInspectionParams) {
                this.setInspectionParams('inspectionWizard', 'culvert', this.item);
            }
            this.$emit('close')
        },
    }
}
</script>

<style scoped>
.map-container {
    height: 30em;
    position: relative;
}

</style>
