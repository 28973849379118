<template>
    <base-modal @cancel="$emit('close')" style="z-index: 50">
        <div class="col-12">
            <b-tabs>
                <b-tab
                    v-if="!showOnlyGeoJsonTab"
                    id="shape-file-import"
                    :title="$t('import_file_wizard.import_shape_file')"
                    active
                >
                    <div>
                        <b-form-group>
                            <shape-import-form @filePicked="shapeFileSelected"/>
                        </b-form-group>
                    </div>
                </b-tab>
                <b-tab
                    :title="$t('import_file_wizard.import_geojson_file')"
                    id="geojson-file-import"
                >
                    <div>
                        <span class="item-title pl-3 pt-2">{{ $t('import_file_wizard.choose_file_to_import') }}</span>
                        <b-form-group class="title">
                            <geojson-file-picker
                                :label="$t('import_file_wizard.GeoJson_file_attachment_placeholder')"
                                :extension="getGeoJsonFileExtension()"
                                @input="geojsonFilePicked">
                            </geojson-file-picker>
                        </b-form-group>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div class="col-sm-12 nopads">
            <b-form class="col-sm-12 editor-form">
                <div class="col-sm-12 form-data">
                    <div>
                        <div v-if="crs_selection_visible">
                            <span class="span-title">{{ $t('import_file_wizard.choose_file_CRS') }}</span>
                            <b-form-group
                                label-for="value"
                                class="p-1"
                            >
                                <b-form-select
                                    v-model="crs"
                                    size="sm"
                                >
                                    <template slot="first">
                                        <option
                                            :value="null"
                                            disabled
                                        >
                                            {{ $t('import_file_wizard.choose_file_CRS') }}
                                        </option>
                                    </template>
                                    <option
                                        v-for="item in getCRSTypes()"
                                        :key="item.value"
                                        :value="item.value"
                                    >
                                        {{ item.name }}
                                    </option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div v-else>
                            <span class="span-title pl-2">{{ $t('import_file_wizard.use_coordinate_system') }}: ETRS-TM35FIN (epsg: 3067)</span>
                        </div>
                    </div>
                    <div class="col-sm-12 button-container pt-1 pb-0 mb-0">
                        <b-button
                            variant="success"
                            class="result-button"
                            :disabled="allFilesGiven===false"
                            @click.stop="processGeoFileToGeoJson"
                        >
                            {{ $t('import_file_wizard.import_file_to_preview') }}
                        </b-button>
                    </div>
                    <div class="col-sm-12 button-container pt-2 pb-0 mb-0">
                        <file-import-preview
                            ref="shapePreviewControl"
                            v-model="coordinate"
                            :point-type="0"
                            :coordinate="coordinate"
                        />
                    </div>
                    <div class="col-sm-12 button-container pt-2 pb-0 mb-0">
                        <b-button
                            variant="secondary"
                            class="result-button"
                            @click.stop="$emit('close')"
                        >
                            {{ $t('common.cancel') }}
                        </b-button>
                        <b-button
                            variant="success"
                            class="result-button"
                            :disabled="loading || !allFilesGiven || !fileReady"
                            @click.stop="submit"
                        >
                            {{ $t('import_file_wizard.import_file') }}
                        </b-button>
                    </div>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '../BaseModal'
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import FileImportPreview from "./FileImportPreview";
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from "../../event-bus";
import GeojsonFilePicker from "./GeojsonFilePicker";
import ShapeImportForm from "./ShapeImportForm";

// From: https://epsg.io/?q=Finland
export const CRSTypes = {
    Epsg3067: {EpsgCode: 3067, title: 'ETRS89 / TM35FIN(E,N)'},
    Epsg4326: {EpsgCode: 4326, title: 'WGS 84 - WGS84'},
}

export default {
    name: 'import-file-wizard' ,
    components: {ShapeImportForm, GeojsonFilePicker, BaseModal, FileImportPreview},
    mixins: [
        attachmentHelper,
        mapHelper,
        restApi
    ],
    props: {
        coordinate: {
            type: Object,
            default: null
        },
        showOnlyGeoJsonTab: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            allFilesGiven: false,
            fileReady: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            variant: 'danger',
            shpFile: null,
            shxFile: null,
            dbfFile: null,
            geojsonFile: null,
            geojson: null,
            crs: '',
            crs_selection_visible: false
        }
    },
    watch: {
        'shpFile': {
            handler: function (newValue) {
                this.allFilesGiven = (newValue !== null && this.dbfFile !== null && this.shxFile !== null)
           }
        },
        'shxFile': {
            handler: function (newValue) {
                this.allFilesGiven = (this.shpFile !== null && this.dbfFile !== null && newValue !== null)
            }
        },
        'dbfFile': {
            handler: function (newValue) {
                this.allFilesGiven = (this.shpFile !== null && newValue !== null && this.shxFile !== null)
            }
        },
        'geojsonFile': {
            handler: function (newValue) {
                this.allFilesGiven = (this.geojsonFile !== null && newValue !== null)
            }
        }
    },
    methods: {
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        getFileName() {
            return this.geojsonFile ? this.geojsonFile.name.split('.')[0] : null
        },
        submit: function () {
            // Load shape on preview map to main map
            this.$emit('addLayer', this.$refs.shapePreviewControl.getGeoJson(), this.getFileName() )
        },
        getCRSTypes: function () {
            return [
                {name: CRSTypes.Epsg3067.title, value: CRSTypes.Epsg3067.EpsgCode},
                {name: CRSTypes.Epsg4326.title, value: CRSTypes.Epsg4326.EpsgCode}
            ]
        },
        getShpFileExtension:function (){
            return ".shp"
        },
        getShxFileExtension: function (){
            return ".shx"
        },
        getDbfFileExtension: function (){
            return ".dbf"
        },
        getGeoJsonFileExtension: function (){
            return ".GeoJson"
        },
        processGeoFileToGeoJson: function () {
            let geojsonClasses = document.getElementById('geojson-file-import').classList;
            let shapeClasses = !this.showOnlyGeoJsonTab ? document.getElementById('shape-file-import').classList : null;
            let formData = new FormData()
            if (shapeClasses && shapeClasses.contains('active')) {
                // Have to give files in this order
                let files = [this.shpFile, this.shxFile, this.dbfFile]
                files.forEach(file => {
                    if (file !== undefined && file !== null) {
                        formData.append('files[]', file)
                    }
                })
                this.loading = true
                this.restPostWithFile(this.convertShapeToGeoJsonUrl, {}, formData, this.handleGeoJson, this.onFailedRequest)
            } else if (geojsonClasses.contains('active')) {
                this.loading = true;
                this.readGeoJsonFile()
            }
        },
        fileImportFail: function () {
            EventBus.$emit('show-alert', this.$t('common.alert_add_failed'))
            this.loading = false
            this.fileReady = false
        },
        readGeoJsonFile: function(){
            let file = this.geojsonFile
            let read = new FileReader();
            read.onload = function() {
                this.geojson = read.result
                try {
                    JSON.parse(this.geojson)
                } catch (e) {
                    this.fileImportFail()
                    return;
                }
                this.handleGeoJson()
            }.bind(this)
            read.readAsText(file);
        },
        handleGeoJson: function(file){
            if(!file){
                file = this.geojson
            }
            this.$refs.shapePreviewControl.renderGeoJson(file)
            this.loading = false
            this.fileReady = true
        },
        onFailedRequest: function(){
            this.loading = false
            this.fileReady = false
            EventBus.$emit('show-alert', this.$t('import_file_wizard.import_failed'))
        },
        shapeFileSelected: function (shpFile, shxFile, dbfFile) {
            this.shpFile = shpFile
            this.shxFile = shxFile
            this.dbfFile = dbfFile
            this.allFilesGiven = (this.shpFile !== null && this.shxFile !== null && this.dbfFile !== null)
        },
        geojsonFilePicked: function(value){
            this.geojsonFile = value;
            this.allFilesGiven = true
        },
    }
}
</script>
<style>
@import "../../styles/modal.css";
</style>
