<template>
    <div class="content-container">
        <map-container
            ref="map"
            :find-user="true"
            :is-editing="isEditing"
            :center="mapCenter"
            @onMapClicked="mapClicked"
            @editAreaEvent="editAreaEvent"
            @onGeoJsonClicked="onGeoJsonClicked"
            :menuItems="menuItems"
            :menu-enabled="true"
        />
        <div class="map-button-container">
                <b-button
                    v-if="!polygonDrawing && !importing && results.length === 0"
                    class="m-1"
                    variant="info"
                    size="lg"
                    @click.stop="showCompanyAreaSelectionModal"
                >
                    <span class="map-button-text">{{ $t('areas.search_areas') }}</span>
                </b-button>
                <b-button
                    v-if="!polygonDrawing && !importing && !isEditing"
                    class="m-1"
                    variant="info"
                    size="lg"
                    @click.stop="enablePolygonDrawing"
                >
                    <span class="map-button-text">{{ $t('areas.draw_area') }}</span>
                </b-button>
                <b-button
                    v-if="!polygonDrawing && editingAreaEventFlag && !importing && !isEditing"
                    class="m-1"
                    variant="info"
                    size="lg"
                    @click.stop="editingPolygon"
                >
                    <span class="map-button-text">{{ $t('areas.edit_area') }}</span>
                </b-button>
                <b-button
                    v-if="!polygonDrawing && !importing && !isEditing && searchAreas.length > 0"
                    class="m-1"
                    variant="info"
                    size="lg"
                    @click.stop="showAreaImportModal"
                >
                    <span class="map-button-text">{{ $t('areas.import_from_searchAreas') }} ködöö</span>
                </b-button>
                <b-button
                    v-if="polygonDrawing && !importing && !isEditing"
                    class="m-1"
                    variant="primary"
                    size="lg"
                    @click.stop="undo"
                >
                    <span class="map-button-text">{{ $t('areas.undo') }}</span>
                </b-button>
                <b-button
                    v-if="polygonDrawing || importing || isEditing"
                    class="m-1"
                    variant="success"
                    size="lg"
                    :disabled="newPolygonId < 0"
                    @click.stop="savePolygon"
                >
                    <span class="map-button-text">{{ $t('areas.save_area') }}</span>
                </b-button>
                <b-button
                    v-if="polygonDrawing || importing || isEditing"
                    class="m-1"
                    variant="danger"
                    size="lg"
                    @click.stop="cancel"
                >
                    <span class="map-button-text">{{ $t('common.cancel') }}</span>
                </b-button>
            </div>
        <!-- Map overlays -->
        <div class="map-controls">
            <div class="map-controls__list col-12 col-lg-4 nopads">
                <map-area-selector
                    style="float: right; z-index: 2"
                    v-if="!isEditing"
                    ref="areas"
                    :area-results="results"
                    :hide-badges="true"
                    :hide-name-toggle="true"
                    @areaSelectionChanged="updateAreaTypeSelection"
                />
                <map-area-work-history
                    style="float: right; z-index: 2; margin-top: 1px"
                    v-if="newPolygonId > 0"
                    ref="workHistory"
                    :area="newPolygonId"
                    @onAddWork="showAreaWorkEditor"/>

                <map-imported-objects
                    style="float: right; z-index: 2"
                    v-if="showImportedObjects"
                    @hideImportedObjects="hideImportedObjects"/>
            </div>
        </div>
        <!-- Area editor dialog -->
        <b-modal
            ref="saveModal"
            centered
            hide-footer
            :title="$t('areas.save_area')"
            @hidden="resetAreaName"
        >
            <span class="span-title">{{ $t('areas.area_name') }}</span>
            <b-form-group
                class="title"
                label-for="areaName"
            >
                <b-form-input
                    id="areaName"
                    v-model="newAreaName"
                    type="text"
                    autofocus
                    :placeholder="$t('areas.area_name')"
                    size="md"
                />
            </b-form-group>
            <span class="span-title">{{ $t('areas.type') }}</span>
            <b-form-group
                class="title"
                label-for="areaType"
            >
                <b-form-select
                    v-model="selectedAreaType"
                    :options="areaTypes"
                    size="md"
                />
            </b-form-group>
            <span class="span-title">{{ $t('areas.square_meters') }}</span>
            <b-form-group
                class="title"
                label-for="areaName"
            >
                <b-form-input
                    id="areaName"
                    v-model="newAreaSqm"
                    type="number"
                    size="md"
                />
            </b-form-group>
            <span class="span-title">{{ $t('tasktypes.color') }}</span>
            <color-picker
                @selectColor="(color) => setColor(color)"
                :default-color="selectedColor"
            />
            <span
                v-if="isEditing"
                class="span-title"
            >{{ $t('areas.inactivate_area') }}</span>
            <b-form-group
                v-if="isEditing"
                class="title"
                label-for="isInactive"
            >
                <b-form-checkbox
                    id="isInactive"
                    v-model="isInactive"
                    size="mb"
                />
            </b-form-group>
            <div class="button-container top-margin">
                <b-button
                    variant="success"
                    size="lg"
                    :disabled="!newAreaName || !selectedAreaType"
                    @click.stop="submitArea"
                >
                    <span class="map-button-text">{{ $t('areas.save_area') }}</span>
                </b-button>
            </div>
        </b-modal>

        <!-- Shape file importer -->
        <import-file-wizard
            ref="importfilewizardref"
            v-if="showImportShapeFileWizardOnCoord !== null"
            :coordinate="showImportShapeFileWizardOnCoord"
            @close="closeImportShapeFileWizard"
            @addLayer="addGeoJsonMapObjects"
        />

        <!-- Area work editor -->
        <area-work-editor
            v-if="editAreaId"
            :user="user"
            :area-id="editAreaId"
            @close="editAreaId = undefined"
            @closeAndUpdate="refreshWorkHistory"/>

        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from '@/event-bus'
import MapAreaSelector from "./MapAreaSelector";
import MapContainer from "../map/MapContainer";
import MapImportedObjects from "../shapeimport/MapImportedObjects";
import ImportFileWizard from "../shapeimport/ImportFileWizard";
import MapAreaWorkHistory from "./areawork/MapAreaWorkHistory";
import AreaWorkEditor from "./areawork/AreaWorkEditor";
import {mapHelper} from "@/components/mixins/MapMixin";
import ColorPicker from "@/components/ColorPicker";

export default {
    name: 'Areas',
    components: {
        ColorPicker,
        AreaWorkEditor,
        MapAreaWorkHistory,
        ImportFileWizard,
        MapImportedObjects,
        MapContainer,
        MapAreaSelector
    },
    mixins: [restApi, mapHelper],
    data: function () {
        return {
            polygonDrawing: false,
            isEditing: false,
            importing: false,
            newPolygonId: -1,
            newAreaName: null,
            newAreaSqm: null,
            isInactive: false,
            company: null,
            loading: false,
            results: [],
            drawnAreas: [],
            areaNames: [],
            areaTypes: [],
            selectedAreaType: null,
            selectedAreaTypes: [],
            companies: [],
            selectedColor: "#007bff",
            selectedCompany: null,
            companyArea: null,
            editingAreaEventFlag: false, //This flag tells you that an area is selected
            searchAreas: [],
            selectedSearchArea: null,
            menuItems: [
                {
                    text: this.$t('map.import_geo_file'),
                    onClick: this.openImportShapeFileWizard
                }
            ],
            showImportShapeFileWizardOnCoord: null,
            showImportedObjects: false,
            editAreaId: null,
            mapCenter: null,
        }
    },
    mounted: function () {
        if(this.getMapSettingsIn(['center'])){
            this.mapCenter = this.getMapSettingsIn(['center'])
        } else if (this.user && this.user.company.location) {
            this.mapCenter = {
                lon: this.user.company.location.x,
                lat: this.user.company.location.y,
            }
        } else if (this.center) {
            this.mapCenter = this.center
        }
        this.fetchAreaTypes()
    },
    methods: {
        getMapSettingsIn(path, defaultValue = undefined) {
            let settings = this.mapSettings;
            for (const index in path) {
                settings = settings[path[index]];
                if (settings == null) {
                    return defaultValue;
                }
            }
            return settings;
        },

        openImportShapeFileWizard(coord) {
            this.showImportShapeFileWizardOnCoord = coord;
        },

        closeImportShapeFileWizard() {
            this.showImportShapeFileWizardOnCoord = null
        },

        addGeoJsonMapObjects(geoJson) {
            this.$refs.map.addGeoJsonObjects(geoJson)
            this.showImportedObjects = true
            this.showImportShapeFileWizardOnCoord = null
        },

        hideImportedObjects: function () {
            this.showImportedObjects = false
            this.$refs.map.hideImportedObjects()
        },


        fetchAreaTypes: function () {
            this.loading = true
            this.restFetch(this.areaTypeUrl, this.handleAreaTypesResponse)
        },

        handleAreaTypesResponse: function (response) {
            this.loading = false
            this.areaTypes.push({value: null, text: this.$t('areas.select_area_type')})
            response.data.forEach(type => {
                this.areaTypes.push({value: type, text: type.name})
            })
            // Next fetch areas and show according to filter
            this.fetchAreas()
        },

        fetchAreas: function () {
            this.loading = true
            this.restFetch(this.areaUrl + '?equipment=true', this.handleAreaResults)
        },

        handleAreaResults: function (response) {
            this.loading = false
            if (response && response.data) {
                this.results = response.data
                this.drawAreas(this.results)
            }
        },

        drawAreas: function (areas) {
            if (areas.length) {
                let equipment = []
                this.drawnAreas = [];
                areas.forEach(function (area) {
                    if (this.selectedAreaTypes && this.selectedAreaTypes.includes(area.type.id)) {
                        this.drawnAreas.push(area)
                        if (area.color) {
                            this.$refs.map.getMap().drawPolygon(area.id,area.geometry.rings[0], this.AREA,  area.color, area.color, 0.4)
                        } else {
                            this.$refs.map.getMap().drawPolygon(area.id,area.geometry.rings[0])
                        }
                        this.$refs.map.getMap().addAreaLabel(area.id, area.name, area.geometry.rings[0][0][1], area.geometry.rings[0][0][0])
                        if (area.equipment) {
                            equipment.push(...area.equipment)
                        }
                    }
                }, this)
                if (equipment.length > 0) {
                    this.addAreaEquipment(equipment)
                }
                this.$refs.map.getMap().attachEventListenersToAreas()
            }
        },

        clearDrawnAreas: function () {
            this.results.forEach(function (area) {
                this.$refs.map.getMap().removePolygon(area.id)
            }, this)
            this.clearEquipment()
        },

        updateAreaTypeSelection: function (types) {
            //Update the selectedAreas
            this.selectedAreaTypes = types
            //Redraw appropriate areas
            this.clearDrawnAreas()
            this.drawAreas(this.results)
            //If there was an area in focus, remove the focus
            this.editingAreaEventFlag = false
            this.newPolygonId = -1
        },

        mapClicked: function (coord) {
            if (this.polygonDrawing && !this.isEditing) {
                this.addPointToPolygon(coord)
            }
        },

        addPointToPolygon: function (point) {
            // If not exist yet, create one and add to map
            if (this.newPolygonId < 0) {
                this.newPolygonId = Date.now()
                this.$refs.map.getMap().newPolygon(this.newPolygonId, point.lat, point.lng)
            } else {
                this.$refs.map.getMap().addPointToPolygon(this.newPolygonId, point.lat, point.lng)
            }
        },

        enablePolygonDrawing: function () {
            this.newPolygonId = -1
            this.newAreaName = null
            this.newAreaSqm = null
            this.selectedAreaType = null
            this.polygonDrawing = true
            this.$refs.map.getMap().removeAreaEventListeners()
        },

        editAreaEvent: function (id) {
            //This allows us to store the id of the polygon which is in focus
            //i.e. it was clicked on by the user
            if (!this.polygonDrawing) {
                this.editingAreaEventFlag = true
                this.newPolygonId = id
            }
        },

        editingPolygon: function () {
            this.$refs.map.getMap().editArea(this.newPolygonId)
            this.isEditing = true
            this.polygonDrawing = false
        },

        disablePolygonDrawing: function () {
            this.polygonDrawing = false
        },

        redrawAreas() {
            this.clearDrawnAreas()
            this.drawAreas(this.results)
        },

        cancel() {
            if (this.isEditing) {
                this.$refs.map.getMap().disableAreaEditing(this.newPolygonId)
                this.redrawAreas()
                this.isEditing = false
                this.editingAreaEventFlag = false
            } else {
                this.clearPolygon()
            }
        },

        clearPolygon: function () {
            if (this.newPolygonId > 0) {
                if (this.importing) {
                    this.$refs.map.getMap().disableAreaEditing(this.newPolygonId)
                    this.$refs.map.getMap().removePolygon(this.newPolygonId)
                    this.editingAreaEventFlag = false
                } else if (this.isEditing) {
                    this.$refs.map.getMap().disableAreaEditing(this.newPolygonId)
                    this.editingAreaEventFlag = false
                } else {
                    this.$refs.map.getMap().removePolygon(this.newPolygonId)
                }
            }
            this.newPolygonId = -1
            this.polygonDrawing = false
            this.isEditing = false
            this.importing = false
            this.$refs.map.getMap().attachEventListenersToAreas()
        },

        savePolygon: function () {
            if ((this.newPolygonId > 0)) {
                // Ask name for area
                this.showAreaSaveModal()
            }
        },

        undo: function () {
            if (this.newPolygonId > 0) {
                this.$refs.map.getMap().removeLastPointFromPolygon(this.newPolygonId)
            }
        },

        resetAreaName: function () {
            this.newAreaName = null
        },

        showAreaSaveModal() {
            if (this.isEditing) {
                this.initArea()
            }
            this.$refs.saveModal.show()
        },

        initArea: function () {
            if (this.newPolygonId > 0) {
                let polygon = this.results.find(polygon => polygon.id === this.newPolygonId)
                if (polygon !== undefined) {
                    this.newAreaName = polygon.name
                    this.newAreaSqm = polygon.square_meters
                    this.selectedAreaType = polygon.type
                    this.selectedColor = polygon.color ? polygon.color : this.selectedColor
                    this.isInactive = false
                }
            }
        },

        hideAreaSaveModal() {
            this.$refs.saveModal.hide()
        },

        showCompanyAreaSelectionModal() {
            this.$refs.companyAreaSelectionModal.show()
        },

        hideCompanyAreaSelectionModal() {
            this.$refs.companyAreaSelectionModal.hide()
        },

        getPolygon: function () {
            let original = this.$refs.map.getMap().getPolygonBoundaries(this.newPolygonId)
            return original.map(item => [item['lng'], item['lat']])
        },

        clearResults: function () {
            this.clearDrawnAreas()
            this.results = []
        },

        setDefaultValues: function (area) {
            area.type = this.selectedAreaType
            area.square_meters = this.newAreaSqm
            // Create closed polygon
            area.geometry.points.push(area.geometry.points[0])
            area.is_inactive = this.isInactive
            area.color = this.selectedColor
            return area
        },

        submitArea: function () {
            if (this.isEditing) {
                let polygon = this.results.find(polygon => polygon.id === this.newPolygonId)
                polygon.name = this.newAreaName
                polygon.geometry = {'points': this.getPolygon()}
                const jsonArea = this.setDefaultValues(polygon)
                if (jsonArea !== undefined) {
                    this.loading = true
                    this.restUpdate(this.areaUrl, jsonArea, this.areaSubmitSuccess, this.areaSubmitFail)
                }
            } else {
                const jsonArea = this.setDefaultValues({
                    'name': this.newAreaName,
                    'geometry': {'points': this.getPolygon()},
                    'created_on': new Date()
                })
                if (jsonArea !== undefined) {
                    this.loading = true
                    this.restAdd(this.areaUrl, jsonArea, this.areaSubmitSuccess, this.areaSubmitFail)
                }
            }
        },

        areaSubmitSuccess: function () {
            this.isInactive = false
            this.hideAreaSaveModal()
            this.resetAreaName()
            this.disablePolygonDrawing()
            this.loading = false
            this.clearPolygon()
            //There is no results when user creates first area
            this.clearResults()
            //MapAreaSelector is not rendered during editing area.
            this.$nextTick(() => this.$refs.areas.fetchAreaTypes())
            this.fetchAreaTypes()
        },

        areaSubmitFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.alert_update_failed'))
        },

        addAreaEquipment: function (equipmentList) {
            this.$refs.map.getMap().addEquipmentMarkers(equipmentList)
        },

        clearEquipment: function () {
            this.$refs.map.getMap().removeEquipmentGroup()
        },

        showAreaImportModal: function () {
            self.selectedSearchArea = null
            this.$refs.areaImportModal.show()
        },

        hideAreaImportModal: function () {
            this.$refs.areaImportModal.hide()
        },

        fetchSearchAreas: function () {
            this.restFetch(this.searchAreaUrl, this.searchAreaFetchSuccess)
        },

        searchAreaFetchSuccess: function (response) {
            if (response.data) {
                this.searchAreas = []
                response.data.forEach(searchArea => {
                    this.searchAreas.push({value: searchArea, text: searchArea.name})
                })
            }
        },

        importSearchArea: function () {
            this.newAreaName = this.selectedSearchArea.name
            this.newPolygonId = Date.now()
            this.$refs.map.getMap().drawPolygon(this.newPolygonId, this.makeLoop(this.selectedSearchArea.boundaries.points))
            this.$refs.map.getMap().attachEventListenersToAreas()
            this.hideAreaImportModal()
            this.$refs.map.getMap().editArea(this.newPolygonId)
            this.isEditing = false
            this.polygonDrawing = false
            this.importing = true
        },

        makeLoop: function (arr) {
            let cpy = arr
            cpy.push(arr[0])
            return cpy
        },

        showAreaWorkEditor: function (areaId) {
            this.editAreaId = areaId
        },

        refreshWorkHistory() {
            this.editAreaId = undefined
            this.$refs.workHistory.refreshHistory()
        },

        onGeoJsonClicked(feature) {
            if (feature.geometry && (feature.geometry.type.localeCompare("Polygon") === 0 || feature.geometry.type.localeCompare("MultiPolygon") === 0)) {
                this.$nextTick(() => {
                    this.clearPolygon()
                    this.enablePolygonDrawing()
                    // NOTE - supports only outer line for now!
                    let coordinates = feature.geometry.coordinates[0];
                    coordinates.forEach(coordinate => {
                        if (coordinate[0] instanceof Array) {
                            coordinates.forEach(coord => {
                                this.addPointToPolygon({lat: coord[1], lng: coord[0]})
                            })
                        } else {
                            this.addPointToPolygon({lat: coordinate[1], lng: coordinate[0]})
                        }
                    })
                })
            }
        },

        setColor(color) {
            this.selectedColor = color
        }
    }
}
</script>

<style scoped>

</style>
