<template>
    <div class="login-container">
        <form
            class="form-signin"
            @submit.prevent="changePassword"
        >
            <b-row>
                <img src="../../assets/Routa_logo.png" style="display: block; margin-left: auto; margin-right: auto; width: 8em; height: 8em; margin-bottom: 1em" alt="logo"/>
            </b-row>
            <h2 class="form-signin-heading">
                Routa - {{ $t('users.change_password') }}
            </h2>
            <b-row>
                <b-input-group>
                    <b-form-input
                        id="inputPassword"
                        v-model="password"
                        :type="showFirstPassword ? 'text' : 'password'"
                        :placeholder="$t('users.new_password')"
                        required
                        autocomplete="none"
                        @input="validatePassword"
                        class="mb-2"
                    />
                    <b-input-group-append>
                        <b-button
                            size="sm"
                            variant="light"
                            @mousedown="showFirstPassword = true"
                            @mouseup="showFirstPassword = false"
                            @mouseout="showFirstPassword = false"
                            style="max-height: 3.3em; border: #BCBCBC 1px solid">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-row>
            <b-row>
                <b-input-group>
                    <b-form-input
                        id="confirmPassword"
                        v-model="confirmPassword"
                        :disabled="!passwordOk"
                        :type="showConfPassword ? 'text' : 'password'"
                        :placeholder="$t('users.password_again')"
                        required
                        autocomplete="none"
                        @input="validateConfirmPassword"
                        class="mb-2"
                    />
                    <b-input-group-append>
                        <b-button
                            size="sm"
                            variant="light"
                            @mousedown="showConfPassword = true"
                            @mouseup="showConfPassword = false"
                            @mouseout="showConfPassword = false"
                            style="max-height: 3.3em; border: #BCBCBC 1px solid">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-row>
            <b-row>
                <b-button
                    type="submit"
                    size="lg"
                    variant="primary col-sm-12"
                    :disabled="  !confirmPasswordOk"
                >
                    {{ $t('users.change_password') }}
                </b-button>
            </b-row>
            <b-row>
        <span
            v-if="!passwordOk && !passwordChanged"
            class="tip"
        >
          {{ $t('users.password_tip') }}
        </span>
                <span
                    v-else-if="!confirmPasswordOk && !passwordChanged"
                    class="tip"
                >
          {{ $t('users.confirm_tip') }}
        </span>
                <div
                    v-if="passwordChanged"
                    class="forgot"
                    @click.stop="goToLogin"
                >
                    {{ $t('login.title') }}
                </div>
            </b-row>

            <span
                v-if="expireMessage"
                class="tip"
            >
                {{ $t('login.expired_password_link') }}
            </span>

            <b-alert
                class="alert"
                :show="alertDismissCountDown"
                dismissible
                fade
                variant="danger"
                @dismissed="alertDismissCountDown=0"
                @dismiss-count-down="alertCountDownChanged"
            >
                {{ alertMessage }}
            </b-alert>
            <b-alert
                class="alert"
                :show="noteDismissCountDown"
                dismissible
                fade
                variant="info"
                @dismissed="noteDismissCountDown=0"
                @dismiss-count-down="noteCountDownChanged"
            >
                {{ message }}
            </b-alert>
        </form>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'ResetPassword',
    mixins: [restApi],
    data() {
        return {
            token: '',
            password: '',
            confirmPassword: '',
            passwordOk: false,
            confirmPasswordOk: false,
            passwordChanged: false,
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            noteDismissCountDown: 0,
            alertMessage: '',
            message: '',
            showFirstPassword: false,
            showConfPassword: false
        }
    },

    mounted: function () {
        this.token = this.$route.query.token
        this.showFirstPassword = false
        this.showConfPassword = false
    },

    methods: {
        changePassword: function () {
            this.loading = true
            this.restUpdate(this.resetPasswordUrl, {
                token: this.token,
                password: this.password
            }, this.success, this.fail)
        },

        success: function () {
            this.loading = false
            this.password = ''
            this.confirmPassword = ''
            this.passwordChanged = true
            this.showMessage(this.$t('users.password_set_ok'))
        },

        fail: function (response) {
            this.loading = false
            this.showAlert(response.data)
        },

        showAlert: function (message) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
        },

        alertCountDownChanged: function (dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },

        showMessage: function (message) {
            this.noteDismissCountDown = this.alertDismissSecs
            this.message = message
        },

        noteCountDownChanged: function (dismissCountDown) {
            this.noteDismissCountDown = dismissCountDown
        },

        validatePassword: function () {
            this.passwordOk = this.password.length >= 8
            this.confirmPasswordOk = (this.password === this.confirmPassword)
        },

        validateConfirmPassword: function () {
            this.confirmPasswordOk = (
                this.confirmPassword.length >= 8 &&
                this.password === this.confirmPassword
            )
        },

        goToLogin: function () {
            this.$router.push('/')
        }
    }
}
</script>
<style>
@import "../../styles/login.css";
</style>
