<template>
    <div>
        <b-row class="nopads">
            <div class="col-sm-6 nopads pr-1">
                <b-form-select
                    id="area"
                    v-model="area"
                    size="sm"
                    class="sm-3"
                    :disabled="areas.length < 1"
                >
                    <template slot="first">
                        <option :value="null">
                            {{ $t('areas.select_area') }}
                        </option>
                    </template>
                    <option
                        v-for="option in areas"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </b-form-select>
            </div>
            <div class="col-sm-6 nopads">
                <b-form-select
                    size="sm"
                    :value="value && value.id || null"
                    :disabled="areaEquipments.length < 1"
                    @change="(val) => $emit('input', equipmentOptions.find(e => e.id === val))"
                >
                    <template slot="first">
                        <option :value="null">
                            {{ $t('areas.select_area_equipment') }}
                        </option>
                    </template>
                    <option
                        v-for="option in equipmentOptions"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </b-form-select>
            </div>
        </b-row>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from "../../mixins/RestApiMixin";

export default {
    name: "AreaEquipmentSelector",
    mixins: [restApi],
    props: {
        value: {
            type: Object,
            default: () => null
        },
        areaId: null
    },
    data: function () {
      return {
          area: null,
          areaEquipments: [],
          areas: [],
          loading: false
      }
    },
    watch: {
        area: function () {
            if (this.area) {
                this.fetchAreaEquipment()
            } else {
                this.areaEquipments = []
                this.$emit('input', null)
            }
        }
    },
    mounted() {
        this.fetchAreas()
    },
    computed: {
        equipmentOptions() {
            return [...this.areaEquipments]
                .sort((a, b) => {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                });
        }
    },
    methods: {
        fetchAreas: function () {
            this.loading = true
            this.restFetch(this.areaUrl, this.handleAreaResults)
        },

        handleAreaResults: function (response) {
            this.loading = false
            this.areas = response && response.data ? response.data : []
            if (this.areaId && this.areas.find(item => item.id === this.areaId)) {
                this.area = this.areaId
            }
        },

        setArea: function (area) {
            this.area = area
            if (this.area) {
                this.fetchAreaEquipment()
            } else {
                this.areaEquipments = []
            }
        },

        async fetchAreaEquipment() {
            this.loading = true
            const { data: equipment } = await this.axios.get(this.areaEquipmentUrl, { params: { area: this.area } });
            this.areaEquipments = equipment;
            this.loading = false
        }
    }
}
</script>
