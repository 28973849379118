<template>
    <div class="col-sm-12 p-1" style="max-width: 80em">
        <h3 class="text-center">{{ editable ? $t('materialstorage.title') : storage.name }}</h3>
        <span class="span-title">{{ storage.company.name }}</span>
        <b-form class="col-sm-12 editor-form">
            <div v-if="editable">
                <b-row class="nopads">
                    <div class="col-sm-6 nopads">
                        <span class="span-title p-1">{{ $t('materialstorage.name') }}</span>
                        <b-form-input
                            v-model="storage.name"
                            :disabled="!editable"
                            type="text"
                            size="sm"
                        />
                    </div>
                    <div class="col-sm-6 nopads pt-1">
                        <coordinate-editor
                            v-model="storage.location"
                            :point-type="MATERIAL_STORAGE"
                            size="sm"
                            :title="$t('materialstorage.location')"
                        />
                    </div>
                </b-row>
                <b-row class="mt-2 ml-2">
                    <b-form-checkbox
                        v-model="storage.active"
                        size="sm"
                    >
                        {{ $t('materialstorage.active') }}
                    </b-form-checkbox>
                </b-row>
            </div>
            <h4 class="form-header">{{ $t('materialstorage.records') }}</h4>
            <material-record-list
                v-model="storage.material_records"
                :editable="editable"
            />
            <hr/>
            <h4 class="form-header">{{ $t('materialstorage.share_contracts') }}</h4>
            <span class="hint-text"> {{ $t('materialstorage.share_hint')}}</span>
            <material-storage-contracts
                class="pb-4"
                v-model="storage.contracts"
                :editable="editable"
            />

            <!-- Attachments-->
            <hr/>
            <h4 class="form-header">{{ $t('materialstorage.attachments')}}</h4>
            <div v-if="storage.attachments && storage.attachments.length" class="mb-2">
                <span class="span-title">{{ $t('common.attachments') }}</span>
                <div class="pointer link mb-2"
                     v-for="item in storage.attachments"
                     v-on:click="downloadAttachment(item)"
                     :key="item.id"
                >
                    {{ item.file_original_name }}
                    <b-button
                        variant="danger"
                        size="sm"
                        class="ml-3"
                        @click.stop="removeAttachment(item)"
                    >
                        <i class="fa fa-trash"/>
                    </b-button>
                </div>
            </div>

            <!-- Attachment upload-->
            <div>
                <span class="span-title">{{ $t('work_assignment.add_attachment') }}</span>
                <div
                    v-for="(path, counter) in attachments"
                    :key="counter"
                    class="col-sm-12 nopads"
                >
                    <b-form-group
                        class="title"
                        label-for="counter"
                        v-if="counter === 0 || isAttachmentSet(attachments, counter-1)"
                    >
                        <b-form-file
                            v-model="attachments[counter]"
                            :accept="getValidAttachmentTypes()"
                            :placeholder="$t('common.attachment_placeholder')"
                        />
                    </b-form-group>
                </div>
            </div>

            <div class="col-sm-12 button-container pt-3">
                <b-button
                    variant="primary"
                    class="routa-button"
                    @click.stop="$emit('cancel')"
                >
                    <i class="fa fa-times"/>
                    <span class="button-text">{{ $t('common.close') }}</span>
                </b-button>
                <b-button
                    variant="success"
                    class="routa-button"
                    :disabled="!editable || !isFormValid || loading > 0"
                    @click.stop="submitStorage(storage)"
                >
                    <i class="fa fa-save"/>
                    <span class="button-text">{{ $t('common.save') }}</span>
                </b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
import CoordinateEditor from '../CoordinateEditor'
import MaterialRecordList from './MaterialRecordList'
import {mapHelper} from '../mixins/MapMixin'
import {EventBus} from '@/event-bus'
import MaterialStorageContracts from "./MaterialStorageContracts";
import {userHelper} from "@/components/mixins/UserMixin";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'MaterialStorageEditor',
    components: {MaterialStorageContracts, MaterialRecordList, CoordinateEditor},
    mixins: [mapHelper, userHelper, attachmentHelper, restApi],
    props: {
        storage: {
            type: Object
        },
        disabled: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object
        }
    },
    data: function () {
        return {
            loading: false,
            attachments: new Array(5),
        }
    },
    computed: {
        editable: function () {
            return this.user && this.user.company.id === this.storage.company.id && this.hasObserver(this.user.roles)
        },
        isFormValid() {
            const objectsWithEmptyFields = this.storage.material_records.filter(item => item.active && (!item.name || !item.unit));
            return objectsWithEmptyFields.length === 0;
        }
    },
    created() {
        // Handle new storages by adding users company as storage company
        if (!this.storage.id || this.storage.id < 1) {
            this.storage.company = this.user.company
        }
    },
    methods: {
        downloadAttachment: function (item) {
            this.loading = true
            this.restFetch(this.materialStorageAttachmentUrl + this.storage.id + '/' + item.id,
                response => {
                    this.saveFile(item.file_original_name, response.data)
                    this.loading = false
                }, () => {
                    this.loading = false
                })
        },
        removeAttachment: function(item) {
            if(this.storage.attachments && this.storage.attachments.length > 0) {
                var index = this.storage.attachments.findIndex(attachment => attachment.id === item.id)
                if(index >= 0) {
                    this.storage.attachments.splice(index, 1)
                }
            }
        },
        async submitStorage(storage) {
            if(!this.isFormValid) {
                return
            }
            this.loading++
            let response = null
            try {
                if (isNaN(storage.id)) {
                    response = await new Promise((resolve, reject) => this.restAdd(this.materialStorageUrl, storage, resolve, reject))
                } else {
                    response = await new Promise((resolve, reject) => this.restUpdate(this.materialStorageUrl, storage, resolve, reject))
                }
                this.uploadAttachment(response)
            } catch (e) {
                EventBus.$emit('show-alert', this.$t('materialstorage.update_failed'))
            }
            this.loading--
        },
        uploadAttachment: function (response) {
            let attachments = this.getValidAttachments(this.attachments)
            if (attachments.length > 0) {
                let formData = new FormData()
                attachments.forEach(file => {
                    if (file !== undefined && file !== null) {
                        formData.append('attachments[]', file)
                    }
                })
                this.restPostWithFile(this.materialStorageAttachmentUrl + response.id, {}, formData,
                    () => {
                        this.loading = false
                        this.$emit('closeAndUpdate')
                    }, this.fail
                )
            } else {
                this.loading = false
                this.$emit('closeAndUpdate')
            }
        },
    }
}
</script>

<style scoped>

</style>
