export const timeUtils = {
    data: function () {
        return {
            DAY_IN_MILLIS: (24 * 60 * 60 * 1000) - 1
        }
    },

    methods: {
        getYearList: function () {
            const start = 2018
            const currentYear = new Date().getFullYear()
            const result = []
            let year = start
            while (year <= currentYear) {
                result.push(year)
                year++
            }
            return result
        },
        getDateFromString: function (dateStr) {
            if (dateStr) {
                return new Date(this.setTime(dateStr))
            }
            return null
        },
        getDate: function (dateStr) {
            if (dateStr) {
                return this.$d(new Date(dateStr), 'long')
            }
            return ''
        },
        getDateString: function (dateStr) {
            if (dateStr) {
                return this.$d(new Date(this.setTime(dateStr)), 'long')
            }
            return ''
        },
        getShortDateString: function (dateStr) {
            if (dateStr) {
                return this.$d(new Date(this.setTime(dateStr)), 'shortDate')
            }
            return ''
        },
        getShortDateWithoutTimezone: function (dateStr) {
            if (dateStr) {
                return this.$d(new Date(dateStr), 'shortDate')
            }
            return ''
        },
        getWeekDay: function (dateStr) {
            if (dateStr) {
                return this.$d(new Date(this.setTime(dateStr)), 'weekDay')
            }
            return ''
        },
        setTime: function (timeStr) {
            // This handles only timezones + UTC - that is enough for now
            // Server usually return with timezone
            if (timeStr.indexOf('+') < 0) {
                timeStr += '+00:00'
            }
            return timeStr
        },
        toLocalTime: function (timeStr) {
            return this.getDateString(timeStr)
        },
        getWorkRowVariant: function (mode) {
            if (mode) {
                if (mode.work_type) {
                    return 'success'
                }
                if (mode.absent_type) {
                    return 'secondary'
                }
                return 'info'
            }
            return ''
        },

        getDuration: function (start, end) {
            const startDate = new Date(start)
            const endDate = new Date(end)
            const dif = endDate - startDate
            let mins = Math.round(dif / 60 / 1000)
            const hrs = Math.floor(mins / 60)
            mins -= hrs * 60
            if (mins < 10) {
                return hrs + ':0' + mins
            }
            return hrs + ':' + mins
        },
        getWorkTime: function (mins) {
            if (mins) {
                const hrs = Math.floor(mins / 60)
                mins -= hrs * 60
                if (mins < 10) {
                    return hrs + ':0' + Math.round(mins)
                }
                return hrs + ':' + Math.round(mins)
            } else {
                return 0
            }
        },
        getWorkDays: function (min){
            let d = Math.floor(min/1440); // 60*24
            let h = Math.floor((min-(d*1440))/60);
            let m = Math.round(min%60);

            if(d>0){
                return(d + " d " + h + " h");
            }else{
                return(h + " h "+m+" m");
            }
        },

        getFormattedDateTimeForExcelExport: function (dateStr) {
                    if (dateStr) {
                        const date = new Date(dateStr)
                        return ('0' + date.getDate()).slice(-2) + ':' +
                            ('0' + (date.getMonth() + 1)).slice(-2) + ':' +
                            date.getFullYear() + ' ' +
                            ('0' + date.getHours()).slice(-2) + ':' +
                            ('0' + date.getMinutes()).slice(-2)
                    }
                    return ''
                },
        getDurationInMinutes: function (start, end) {
            const startDate = new Date(start)
            const endDate = new Date(end)
            const dif = endDate - startDate
            return Math.round(dif / 60 / 1000)
        },
        timestampToDateInput: function (timestamp) {
            if (timestamp < 1) {
                return null
            }
            var date = new Date(timestamp)
            var year = date.getFullYear()
            var month = date.getMonth() + 1
            var day = date.getDate()
            var result = year + '-'
            if (month < 10) {
                result += '0'
            }
            result += month + '-'
            if (day < 10) {
                result += '0'
            }
            result += day
            return result
        },
        timestampToLocalTime: function (timestamp) {
            return this.$d(new Date(timestamp), 'long')
        },
        localTimeToUtcTime: function (datetime) {
            const date = new Date(datetime)
            date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
            return date
        },
        getEndOfTheDay: function (date) {
            let startOfDay = new Date(date)
            startOfDay.setHours(0, 0, 0, 0)
            let endOfDay = startOfDay.getTime() + this.DAY_IN_MILLIS // add a whole day to get the values for the day
            return this.localTimeToUtcTime(endOfDay)
        },
        getStartOfTheDay: function (date) {
            let startOfDay = new Date(date)
            startOfDay.setHours(0, 0, 0, 0)
            return this.localTimeToUtcTime(startOfDay.getTime())
        },
        getStartOfWeek: function (date) {
           let dateTyped = new Date(date)
           let weekDayNum = dateTyped.getDay()
           if (weekDayNum === 0) {
               // Sunday
               weekDayNum = 7
           }
           let diffFromFirstDay = weekDayNum - 1;
           dateTyped.setTime(dateTyped.getTime() - (diffFromFirstDay * this.DAY_IN_MILLIS))
           dateTyped.setHours(0,0,0,0)
           return dateTyped
        },
        getCurrentDay: function () {
            return new Date().toISOString().substring(0, 10)
        },
        getToday: function () {
            let d = new Date();
            d.setDate(d.getDate())
            return this.getDateIsoString(d)
        },
        getTwoYearsAgo: function () {
            let d = new Date();
            d.setFullYear(d.getFullYear() -1)
            d.setMonth(0, 1)
            return this.getDateIsoString(d)
        },
        getYesterday: function () {
            let d = new Date();
            d.setDate(d.getDate() - 1)
            return this.getDateAsString(d)
        },
        getMonthStart: function () {
            return this.getDateIsoString(this.getMonthStartAsDate())
        },
        getDateAsString: function (date) {
            let month = date.getMonth() + 1
            if (month < 10) {
                month = '0' + month;
            }
            let day = date.getDate()
            if (day < 10) {
                day = '0' + day;
            }
            return date.getFullYear() + '-' + month + '-' + day
        },
        getMonthStartAsDate: function () {
            let date = new Date()
            return new Date(date.getFullYear(), date.getMonth(), 1)
        },
        getMonthEnd: function () {
            return this.getDateIsoString(this.getMonthEndAsDate());
        },
        getMonthEndAsDate: function () {
            let date = new Date();
            return new Date(date.getFullYear(), date.getMonth() + 1, 0);
        },
        getDateIsoString: function (d) {
            let month = d.getMonth() + 1
            if (month < 10) {
                month = '0' + month;
            }
            let day = d.getDate()
            if (day < 10) {
                day = '0' + day;
            }
            return d.getFullYear() + '-' + month + '-' + day + 'T00:00'
        },
        isInPast: function (dateStr) {
            if (dateStr) {
                let d = new Date(this.setTime(dateStr))
                let now = new Date()
                return now.getTime() > d.getTime()
            }
            return true
        },
        withinMonth: function (dateStr)  {
            if (dateStr) {
                let d = new Date(this.setTime(dateStr))
                let now = new Date()
                const month = 30 * 24 * 60 * 60 * 1000 // 30 days
                return (now.getTime() + month) > d.getTime()
            }
            return false
        },
        withinYear: function (dateStr)  {
            if (dateStr) {
                let d = new Date(this.setTime(dateStr))
                let now = new Date()
                const year = 365 * 24 * 60 * 60 * 1000 // 365 days
                return (now.getTime() + year) > d.getTime()
            }
            return false
        },
        getWeekNumber: function (date) {
            let modDate = new Date(date)
            var dayNum = modDate.getUTCDay() || 7;
            modDate.setUTCDate(modDate.getUTCDate() + 4 - dayNum);
            var yearStart = new Date(Date.UTC(modDate.getUTCFullYear(),0,1));
            return Math.ceil((((modDate - yearStart) / 86400000) + 1)/7)

        },
        timestampToISOString: function (timestamp) {
            if (!timestamp || timestamp < 1) {
                return null
            }
            var date = new Date(timestamp)
            var year = date.getFullYear()
            var month = date.getMonth() + 1
            var day = date.getDate()
            var hour = date.getHours()
            var min = date.getMinutes()
            var result = year + '-'
            if (month < 10) {
                result += '0'
            }
            result += month + '-'
            if (day < 10) {
                result += '0'
            }
            result += day
            result += 'T' + (hour > 9 ? hour : '0' + hour) + ':' + (min > 9 ? min : '0'+min)
            return result
        },

        getMinutesThisWeek() {
            const currentDate = new Date()
            const currentDayOfWeek = currentDate.getDay()
            const millisecondsInDay = 24 * 60 * 60 * 1000
            const millisecondsToStartOfWeek = currentDayOfWeek * millisecondsInDay
            const startOfWeek = new Date(currentDate.getTime() - millisecondsToStartOfWeek)
            return (currentDate - startOfWeek) / (60 * 1000)
        },

        getMinutesThisMonth() {
            const currentDate = new Date()
            return (currentDate.getDate() - 1) * 24 * 60 + currentDate.getHours() * 60 + currentDate.getMinutes()
        },

        isToday(timeStr) {
            const givenTime = new Date(timeStr)
            const currentDate = new Date();
            const givenYear = givenTime.getFullYear();
            const givenMonth = givenTime.getMonth();
            const givenDay = givenTime.getDate();
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth();
            const currentDay = currentDate.getDate();
            return givenYear === currentYear && givenMonth === currentMonth && givenDay === currentDay;
        },

        isThisWeek(timeStr) {
            const givenTime = new Date(timeStr)
            const currentDate = new Date();
            const givenWeek = this.getISOWeek(givenTime);
            const currentWeek = this.getISOWeek(currentDate);
            return givenWeek === currentWeek;  //Year not start always on monday
        },

        isThisMonth(date) {
            const d = new Date(date)
            const cD = new Date()
            return d.getMonth() === cD.getMonth()
        },

        getISOWeek(date) {
            const d = new Date(date);
            d.setHours(0, 0, 0, 0);
            d.setDate(d.getDate() + 4 - (d.getDay() || 7));
            const yearStart = new Date(d.getFullYear(), 0, 1);
            return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        }

    }
}
