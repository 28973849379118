<template>
    <div class="col-12 stat-container pt-3 pb-2">
        <b-row class="nopads">
            <div class="col-2">
                <font-awesome-icon icon="chart-line" class="stat-icon"/>
            </div>
            <div class="col-2">
                <span class="stat-title">{{ $t('contract_reports.assignment.total') }}</span>
                <span class="stat-text"> {{ total }} </span>
            </div>
            <div class="col-2">
                <span class="stat-title">{{ $t('contract_reports.assignment.new') }}</span>
                <span class="stat-text"> {{ newAs }} </span>
            </div>
            <div class="col-2">
                <span class="stat-title">{{ $t('contract_reports.assignment.closed') }}</span>
                <span class="stat-text"> {{ closed }} </span>
            </div>
            <div class="col-2">
                <span class="stat-title">{{ $t('contract_reports.assignment.open') }}</span>
                <span class="stat-text"> {{ open }} </span>
            </div>
        </b-row>
        <b-row class="nopads pt-2">
            <div class="col-2">
                <font-awesome-icon icon="clock" class="stat-icon"/>
            </div>
            <div class="col-2">
                <span class="stat-title">{{ $t('defects.average_age') }} </span>
                <span class="stat-text">{{ this.getWorkDays(average) }} </span>
            </div>
        </b-row>
    </div>
</template>

<script>
import {timeUtils} from "@/components/mixins/TimeUtils";

export default {
    name: 'WorkAssignmentSummaryValues',
    mixins: [timeUtils],
    props: {
        total: Number,
        newAs: Number,
        open: Number,
        closed: Number,
        average: Number

    },

}

</script>

<style>
</style>
