<template>
    <div ref="container" class="content-container">
        <b-navbar variant="faded">
            <div class="header">
                <span class="title">{{ $t('power_stations.title') }}</span>
            </div>
        </b-navbar>

        <div class="col-sm-12 nopads">
            <transition name="fade">
                <keep-alive>
                    <power-station-search
                        :user="user"
                        @search="fetchStations"
                    />
                </keep-alive>
            </transition>
            <transition name="fade">
                <div class="col-sm-12">
                    <div
                        class="button-container col-sm-12 nopads pb-2"
                    >
                        <b-button
                            size="sm"
                            variant="outline-primary"
                            class="result-button"
                            :disabled="loading"
                            @click="importFromFile"
                        >
                            {{ $t('common.import_from_file') }}
                        </b-button>
                        <b-button
                            v-if="results.length > 0"
                            variant="outline-success"
                            :disabled="loading || results.length < 1"
                            class="result-button"
                            @click="exportExcel"
                        >
                            {{ $t('trip.download_excel') }}
                        </b-button>
                    </div>
                    <power-station-list
                        :power-stations="results"
                        @rowClicked="editCenter"
                    />
                </div>
            </transition>

            <power-station-wizard
                v-if="powerStation != null"
                :power-station="powerStation"
                :user="user"
                @close="powerStation=null"
                @closeAndUpdate="closeAndUpdate"
            />

            <power-stations-importer
                v-if="showFileImporter"
                @close="showFileImporter = false"
                @closeAndUpdate="hideFileImporter"/>

            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
    </div>
</template>


<script>
import {restApi} from '../mixins/RestApiMixin'
import {EventBus} from "@/event-bus";
import {downloadHelper} from "../mixins/DownloadMixin";
import PowerStationSearch from "@/components/streetlights/PowerStationSearch";
import PowerStationList from "@/components/streetlights/PowerStationList";
import PowerStationWizard from "@/components/streetlights/PowerStationWizard";
import PowerStationsImporter from "@/components/streetlights/PowerStationImporter";

export default {
    name: 'PowerStations',
    components: {PowerStationsImporter, PowerStationWizard, PowerStationList, PowerStationSearch},
    mixins: [restApi, downloadHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
    },
    data: function () {
        return {
            results: [],
            powerStation: null,
            loading: false,
            lastSearchedParams: null,
            showFileImporter: false
        }
    },

    methods: {
        fetchStations: function (params) {
            this.loading = true
            this.lastSearchedParams = params
            this.results = []
            this.restFetchParams(this.powerStationUrl, this.lastSearchedParams, this.handleSearch, () => {
                this.loading = false
            })
        },
        handleSearch: function (response) {
            this.results = response.data
            this.loading = false
        },
        editCenter: function (center) {
            this.powerStation = center
        },
        closeAndUpdate: function () {
            this.powerStation = null
            this.fetchStations(this.lastSearchedParams)
        },
        exportExcel: function () {
            this.loading = true
            this.restGetDownloadFile(this.powerStationXlsxUrl, this.getCommonReportParams(this.lastSearchedParams), this.excelDownloadSuccess, this.fileDownloadFail)
        },
        excelDownloadSuccess: function (response) {
            this.loading = false
            this.downloadFile(response, this.$t('power_stations.report_file_name'))
        },
        fileDownloadFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('common.report_generation_failed'))
        },
        importFromFile() {
            this.showFileImporter = true
        },
        hideFileImporter() {
            this.showFileImporter = false
            this.fetchStations()
        }
    }
}
</script>
