<template>
    <div
        v-if="loading"
        id="loader"
        class="spinner"
    />
</template>

<script>

import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from "../mixins/MapMixin";

export default {
    name: 'SearchAreas',
    mixins: [restApi, mapHelper],
    props: {
        company: {
            type: Number
        },
        map: {
            type: Object,
            default: null
        },
        showLabels: {
            type: Boolean,
            default: false
        },
        drawAll: {
            type: Boolean,
            default: false
        },
        selectedAreaTypes: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
        areaCollection: {
            type: String,
            default() {
                return 'own'
            }
        }
    },
    data: function () {
        return {
            loading: false,
            results: [],
            areaNames: [],
        }
    },

    watch: {
        selectedAreaTypes: function () {
            if(this.results && this.map) {
                this.drawAreas(this.results)
            }
        },
        map() {
            this.$nextTick(function () {
                if (this.results && this.map) {
                    this.drawAreas(this.results)
                }
            })
        },
        showLabels: function () {
            if(this.results && this.map) {
                this.drawAreas(this.results)
            }
        },
        areaCollection: function () {
            this.clearAreas()
            this.fetchAreas()
        }
    },

    mounted() {
        this.fetchAreas()
    },

    methods: {

        fetchAreas() {
            let type
            if(this.selectedContractIds && this.selectedContractIds.length > 0) {
                type = 'contract'
            } else {
                type = this.areaCollection
            }
            if (type) {
                if (type === "own") {
                    this.fetchOwnAreas();
                }
                else if (type === "contract") {
                    this.fetchContractAreas();
                }
            }
        },

        fetchOwnAreas: function () {
            this.loading = true
            let params = {}
            if(this.company) {
                params.company = this.company
            }
            if(this.selectedContractIds) {
                params.contract = this.selectedContractIds
            }
            if(this.selectedContractIds && this.selectedContractIds.length > 0) {
                // fetch by area-group when contract is selected
                this.restFetchParams(this.areaGroupUrl, params, this.handleGroupResults)
            } else {
                this.restFetchParams(this.areaUrl, params, this.handleResults)
            }
        },

        handleResults: function (response) {
            if (response && response.data) {
                this.results = response.data
                this.drawAreas(this.results)
                // Send the areas to the parent for selection purposes
                this.$emit('areasAvailable', this.results)
            }
            this.loading = false
        },

        drawAreas: function (areas) {
            this.hideAreas()
            if (areas.length) {
                    areas.forEach(item => {
                        if (this.selectedAreaTypes && this.selectedAreaTypes.includes(item.type.id) || this.drawAll) {
                            let rings = item.geometry.rings
                            if (item.color) {
                                this.map.drawPolygon(item.id, rings[0], this.AREA, item.color, item.color, 0.4)
                            } else {
                                this.map.drawPolygon(item.id, rings[0])
                            }

                            if (this.showLabels) {
                                this.map.addAreaLabel(item.id, item.name, rings[0][0][1], rings[0][0][0])
                            }
                        }
                    })
                this.map.attachEventListenersToAreas()
                this.editingAreaEventFlag = false
                this.newPolygonId = -1
            }

        },

        hideAreas: function () {
            if (this.map) {
                this.results.forEach((polygon) => {
                    this.map.removePolygon(polygon.id)
                })
            }
        },

        clearAreas: function () {
            this.hideAreas()
            this.results = []
        },

        fetchContractAreas: function () {
            let params = {}
            this.loading = true
            if(this.company) {
                params.company = this.company
            }
            if(this.selectedContractIds && this.selectedContractIds.length > 0) {
                params.contract = this.selectedContractIds
            }
            this.restFetchParams(this.areaGroupUrl, params, this.handleGroupResults)
        },

        handleGroupResults: function (response) {
            if (response && response.data) {
                // Loop through area groups areas and add the to results
                let areaGroups = response.data
                areaGroups.forEach(item => {
                    item.areas.forEach(area => {
                        if (!this.results.find(item => item.id === area.id)) {
                            this.results.push(area)
                        }
                    })
                });
                this.drawAreas(this.results)
                // Send the areas to the parent for selection purposes
                this.$emit('areasAvailable', this.results)
            }
            this.loading = false
        }
    }
}
</script>
