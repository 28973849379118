export const priceHelper = {
    methods: {
        unitToText: function(unit) {
            switch (parseInt(unit)) {
                case 1:
                case 103:
                    return this.$t('pricing_models.unit_km')
                case 2: return this.$t('pricing_models.unit_h')
                case 3: return this.$t('pricing_models.unit_pcs')
                case 4: return this.$t('pricing_models.unit_road_km')
                case 5: return this.$t('pricing_models.unit_kg')
                case 6: return this.$t('pricing_models.unit_tons')
                case 7: return this.$t('pricing_models.unit_square_meters')
                case 8: return this.$t('pricing_models.unit_cubic_meters')
                case 9: return this.$t('pricing_models.unit_meters')
                case 10: return this.$t('pricing_models.unit_road_meters')
                case 11: return this.$t('pricing_models.unit_material_report')
                case 101: return this.$t('pricing_models.unit_duration')
                case 102: return this.$t('pricing_models.unit_reserve')
                case 104: return this.$t('pricing_models.unit_area')
                case 105: return this.$t('pricing_models.unit_vehicle_plow')
                case 106: return this.$t('pricing_models.unit_vehicle_spread')
                case 107: return this.$t('pricing_models.unit_work_distance')
                case 108: return this.$t('pricing_models.unit_order')
            }
            return ''
        },
        pricingModelToText(pricingModel, priceList) {
            if(!pricingModel && !priceList) {
                return this.$t('pricing_models.no_model')
            }
            if (priceList) {
                return this.$t('pricing_model_conversion.price_list')
            }
            return "€ / " + this.unitToText(pricingModel.unit1) +
                (pricingModel.unit2 ? " / " + this.unitToText(pricingModel.unit2) : "")
        },
        newPriceList() {
            let prices = [
                {id: undefined, price_list: undefined, distance_from: 0, distance_to: 0.5, price: 0},
                {id: undefined, price_list: undefined, distance_from: 0.5, distance_to: 1, price: 0},
            ]
            // Set default range setting
            const limit = 30;
            for (let i = 1; i < limit; i++) {
                prices.push(
                    {id: undefined, price_list: undefined, distance_from: i, distance_to: i+1, price: 0}
                )
            }
            prices.push(
                {id: undefined, price_list: undefined, distance_from: limit, distance_to: undefined, price: 0}
            )
            return {
                id: undefined,
                name: '',
                distance_unit: 'km',
                weight_unit: 't',
                prices: prices,
                active: true
            }
        },
        newAreaPriceList() {
            return {
                id: undefined,
                name: '',
                prices: [ { area:  {id: null}, task_type: {id: null}, price: 0.0 }],
                active: true
            }
        }
    }
}
