<template>
    <div class="col-12 nopads">
        <transition name="fade">
            <railing-editor
                v-if="selectedRailing != null"
                style="pointer-events: all"
                :item="selectedRailing"
                :selected-contract="selectedContract"
                @close="selectedRailing = null"
                @closeAndUpdate="closeAndUpdate"
            />
        </transition>
        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {timeUtils} from '@/components/mixins/TimeUtils'
import {mapHelper} from '@/components/mixins/MapMixin'
import {stormSewersMixin} from "@/components/mixins/StormSewersMixin";
import {roadSignHelper} from "@/components/mixins/RoadSignMixin";
import {restApi} from '../mixins/RestApiMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import RailingEditor from "@/components/velho/RailingEditor";

export default {
    name: 'RailingMap',
    components: {RailingEditor},
    mixins: [timeUtils, mapHelper, restApi, geometryMixin, stormSewersMixin, roadSignHelper],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        filters: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContract: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: 0,
            railings: [],
            bbox: null,
            visibilityZoomLevel: 13,
            selectedRailing: null
        }
    },

    watch: {
      map() {
        this.$nextTick(function () {
          this.initView()
        })
      },
      boundingBox() {
         this.updateView()
      },
      filters() {
         this.updateView(true)
      },
      selectedContractIds() {
        this.updateView(true)
      }
    },
    beforeDestroy() {
        this.hideAll()
    },
    mounted() {
        this.initView()
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideAll()
            } else if (force || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.005
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchRailings()
            }
        },

        closeAndUpdate() {
          this.hideRailings()
          this.fetchRailings()
        },

        hideRailings: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.EXTERNAL_RAILINGS)
            }
        },

        fetchRailings: function () {
            this.selectedRailing = null
            let params = {}
            if(this.bbox && this.bbox.length > 0) {
                this.loading++
                params.bbox = this.bbox
                this.restFetchParams(this.extRailingsUrl, params, this.handleRailings, () => {
                    this.loading--
                })
            } else {
                this.hideRailings()
            }
        },

        handleRailings: function (response) {
            this.hideRailings()
            if (response && response.data) {
                this.railings = response.data
                this.drawRailings()
            }
            this.loading--
        },

        drawRailings: function () {
            this.railings.forEach(function (railing, index) {
                railing.geometry.forEach(item => {
                    var points = item.points.map(point => {
                        return {x: point[0], y: point[1]}
                    })
                    this.map.drawPolyLine(index, this.EXTERNAL_RAILINGS, points, railing.status === 10 ? '#c50505' : '#1a6fb9', false, false, 3, 1, false, false, railing.external_system_id)
                })
            }, this)
        },

        hideAll() {
            this.hideRailings()
        },

        editRailing(id) {
            this.fetchRailing(id)
        },

        fetchRailing(id) {
            if (this.loading < 1) {
                this.loading++
                this.restFetch(this.extRailingsUrl + '/' + id, this.handleRailing)
            }
        },

        handleRailing(response) {
            this.loading--
            if (response && response.data) {
                // Set Velho oid as contract id
                if (this.selectedContractId) {
                    response.data.contract = {
                        id: this.selectedContractId
                    }
                }
                this.selectedRailing = response.data
            }
        },
    }
}
</script>
