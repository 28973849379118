<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div v-if="results && results.length"
         id="materials"
         class="nopads">
        <b-table
            show-empty
            small
            striped
            stacked="sm"
            :items="results"
            :fields="fields"
            :sort-by.sync="diarySortBy"
            :sort-desc.sync="sortDesc"
        >
            <template v-slot:cell(user)="row">
                <div>
                    {{ row.item.user.first_name }} {{ row.item.user.last_name}}
                </div>
            </template>
            <template v-slot:cell(record_date)="row">
                <div>
                    {{ getShortDateString(row.value) }}
                </div>
            </template>
            <template v-slot:cell(duty_officer)="row">
                <div>
                    {{ row.item.duty_officer ? row.item.duty_officer.first_name + ' ' + row.item.duty_officer.last_name : $t('contract_diary.not_defined')}}
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import {timeUtils} from '../mixins/TimeUtils'
import {printHelper} from '../mixins/PrintMixin'
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'ContractDiaryDetails',
    mixins: [timeUtils, printHelper, restApi],
    props: {
        results: {
            type: Array,
            default() {
                return []
            }
        },
        elyFields: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
            attachment: null,
            sortDesc: false,
            diarySortBy: 'report_date',
            fields: [
            ],
        }
    },
    created() {
        if (this.elyFields) {
            this.fields = [
                {key: 'record_date', label: this.$t('contract_diary.date'), sortable: false},
                {key: 'user', label: this.$t('observations.reporter'), sortable: false},
                {key: 'events', label: this.$t('contract_diary.events'), sortable: false},
                {key: 'duty_officer', label: this.$t('contract_diary.duty_officer'), sortable: false},
                {key: 'media_contacts_info', label: this.$t('contract_diary.media_contacts_info_title'), sortable: false},
                {key: 'customer_contacts_info', label: this.$t('contract_diary.customer_contacts_info_title'), sortable: false},
                {key: 'citizen_contacts_info', label: this.$t('contract_diary.citizen_contacts_info_title'), sortable: false},
                {key: 'authorities_assistance_info', label: this.$t('contract_diary.citizen_contacts_info_title'), sortable: false},
                {key: 'exceptional_weather_info', label: this.$t('contract_diary.citizen_contacts_info_title'), sortable: false}
            ]
        } else {
            this.fields = [
                {key: 'record_date', label: this.$t('contract_diary.date'), sortable: false},
                {key: 'user', label: this.$t('observations.reporter'), sortable: false},
                {key: 'events', label: this.$t('contract_diary.events'), sortable: false},
            ]
        }
    }
}
</script>
