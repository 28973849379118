<template>
    <div class="col-12 nopads">
        <transition name="fade">
            <culvert-editor
                v-if="selectedItem"
                style="pointer-events: all"
                :item="selectedItem"
                :selected-contract="selectedContract"
                @close="selectedItem = null"
                @closeAndUpdate="fetchItems"
            />
        </transition>        <div
            v-if="loading > 0"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import {geometryMixin} from "@/components/mixins/GeometryMixin";
import CulvertEditor from "@/components/velho/CulvertEditor";

export default {
    name: 'CulvertMap',
    components: {CulvertEditor},
    mixins: [mapHelper, restApi, geometryMixin],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default() {
                return []
            }
        },
        selectedContract: {
            type: Object,
            default: null
        }
    },
    data: function () {
        return {
            loading: 0,
            results: [],
            bbox: null,
            visibilityZoomLevel: 13,
            selectedItem: null
        }
    },

    watch: {
      map() {
        this.$nextTick(function () {
          this.initView()
        })
      },
      boundingBox() {
         this.updateView()
      }
    },
    beforeDestroy() {
        this.hideItems()
    },
    mounted() {
        this.initView()
    },
    methods: {
        initView: function () {
            if (this.map) {
                this.markerStore = this.map.getMarkerStore()
                this.updateView()
            }
        },

        updateView(force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideItems()
            } else if (force || (this.boundingBox && (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.005
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchItems()
            }
        },

        hideItems: function () {
            if (this.map) {
                this.map.removeMapItemsByType(this.EXTERNAL_CULVERTS)
            }
            this.results = []
        },

        fetchItems: function () {
            this.loading++
            this.results = []
            this.selectedItem = null
            let params = {}
            if(this.bbox && this.bbox.length > 0) {
                params.bbox = this.bbox
                this.restFetchParams(this.extCulvertsUrl, params, this.handleSearch, () => {
                    this.loading--
                })
            }
        },

        handleSearch: function (response) {
            this.hideItems()
            if (response && response.data && response.data.length > 0) {
                this.results = response.data
                this.drawItems()
            }
            this.loading--
        },

        drawItems: function () {
            this.results.forEach(function (item, index) {
                let icon = this.markerStore.getCulvertIcon()
                this.map.showMapMarker(index, this.EXTERNAL_CULVERTS, item.position.y,
                    item.position.x, icon, false, 5, false, item.external_system_id)
            }, this)
        },

        editCulvert(id) {
            this.fetchSign(id)
        },

        fetchSign: function (id) {
            this.loading++
            this.selectedItem = null
            this.restFetch(this.extCulvertsUrl + '/' + id, this.handleResult)
        },

        handleResult: function (response) {
            if (response && response.data) {
                // Set Velho oid as contract id
                if (this.selectedContractId) {
                    response.data.contract = {
                        id: this.selectedContractId
                    }
                }
                this.selectedItem = response.data
            }
            this.loading--
        }
    }
}
</script>
