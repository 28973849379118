<!--Provides options to select company, observationGroup and observationType-->
<template>
    <div class="nopads">
        <!--Company selection options for admin-->
        <b-row
            v-if="isAdmin"
            class="nopads"
        >
            <b-form-group
                class="nopads"
                label-for="companies"
            >
                <span class="span-title">{{ $t('observation_filter.company') }}</span>
                <b-form-select
                    id="companies"
                    v-model="company"
                    size="sm"
                    class="sm-3"
                    :disabled="companies.length < 1"
                >
                    <template slot="first">
                        <option :value="null">
                            {{ $t('observation_filter.select_company') }}
                        </option>
                    </template>
                    <option
                        v-for="option in companies"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </option>
                </b-form-select>
            </b-form-group>
        </b-row>

        <b-row class="nopads">
            <!--Observation group selection-->
            <div class="col-sm-6">
                <b-form-group
                    class="nopads"
                    label-for="observationGroup"
                >
                    <span class="span-title">{{ $t('observation_filter.observation_group') }}</span>
                    <b-form-select
                        id="observationGroup"
                        v-model="observationGroup"
                        size="sm"
                        class="sm-3"
                        :disabled="observationGroups.length < 1"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('observation_filter.select_observation_group') }}
                            </option>
                        </template>
                        <option
                            v-for="option in observationGroups"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>

            <!--Observation type selection-->
            <div class="col-sm-6">
                <b-form-group
                    class="nopads"
                    label-for="observationType"
                >
                    <span class="span-title">{{ $t('observation_filter.observation_type') }}</span>
                    <b-form-select
                        id="observationType"
                        v-model="observationType"
                        size="sm"
                        class="sm-3"
                        :disabled="observationTypes.length < 1"
                    >
                        <template slot="first">
                            <option :value="null">
                                {{ $t('observation_filter.select_observation_type') }}
                            </option>
                        </template>
                        <option
                            v-for="option in observationTypes"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </b-form-select>
                </b-form-group>
            </div>
        </b-row>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'ObservationFilter',
    mixins: [restApi],
    props: {
        isAdmin: Boolean
    },
    data: function () {
        return {
            loading: false,
            companies: [],
            observationGroups: [],
            observationTypes: [],
            company: null,
            observationGroup: null,
            observationType: null
        }
    },
    watch: {
        company: function () {
            this.$nextTick(() => {
                this.$emit('companySet', this.company)
            })
        },
        observationGroup: function () {
            this.$nextTick(() => {
                this.observationTypes = []
                this.observationType = null
                this.$emit('observationGroupSet', this.observationGroup)
                if (this.observationGroup !== null) {
                    this.fetchObservationType()
                }
            })
        },
        observationType: function () {
            this.$nextTick(() => {
                this.$emit('observationTypeSet', this.observationType)
            })
        }
    },
    mounted: function () {
        if (this.isAdmin) {
            this.fetchCompanies()
        }
        this.fetchObservationGroup()
    },
    methods: {
        resetData: function () {
            this.company = null
            this.observationGroup = null
            this.observationType = null
            this.observationTypes = []
        },

        fetchCompanies: function () {
            this.loading = true
            this.restFetch(this.companyUrl, this.handleCompanies)
        },

        handleCompanies: function (response) {
            this.loading = false
            this.companies = response.data
        },

        fetchObservationGroup: function () {
            this.loading = true
            this.restFetchParams(this.observationGroupUrl, {company: this.company}, this.handleObservationGroup)
        },

        handleObservationGroup: function (response) {
            this.loading = false
            this.observationGroups = response.data
        },

        fetchObservationType: function () {
            this.loading = true
            this.restFetchParams(this.observationTypeUrl, {
                company: this.company,
                group: this.observationGroup
            }, this.handleObservationType)
        },

        handleObservationType: function (response) {
            this.loading = false
            this.observationTypes = response.data
        }
    }
}
</script>
