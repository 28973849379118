<template>
    <base-modal style="z-index: 50" :min-width="true">
        <div class="col-12" style="min-width: 70vw">
            <h3 v-if="!station || !station.id">{{ $t('power_stations.add') }}</h3>
            <h3 v-else>{{ $t('power_stations.edit') }}</h3>
        </div>

        <b-alert
            class="alert"
            :show="alertDismissCountDown"
            dismissible
            fade
            :variant="variant"
            @dismissed="alertDismissCountDown=0"
            @dismiss-count-down="alertCountDownChanged"
        >
            {{ alertMessage }}
        </b-alert>
        <div class="col-sm-12">
            <b-form v-if="station" class="col-sm-12 editor-form">
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.photos')}} ({{station && station.photos ? station.photos.length : 0}})</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : photosVisible}"
                            v-b-toggle.photoSection
                            @click.stop="photosVisible = !photosVisible"/>
                    </b-col>
                </b-row>
                <b-collapse visible id="photoSection">
                    <div class="col-12 carousel-container">
                        <Flicking :options="options" :plugins="plugins" v-if="station.photos && station.photos.length > 0">
                            <div v-for="photo in station.photos" :key="photo.id" class="pointer p-1">
                                <ImageView
                                    :url="getPhotoUrl(powerStationPhotoUrl, photo)"
                                    @click="openImage"
                                />
                            </div>
                            <span slot="viewport" class="flicking-arrow-prev"></span>
                            <span slot="viewport" class="flicking-arrow-next"></span>
                        </Flicking>
                        <span v-else-if="loading" class="title">{{ $t('common.loading')}}</span>
                        <span v-else class="title">{{ $t('power_stations.no_photos')}}</span>
                    </div>
                    <!--   Photo editor-->
                    <b-row class="nopads pt-2">
                        <span class="span-title">{{ $t('power_stations.add_photo') }}</span>
                        <div
                            v-for="(path, counter) in photos"
                            :key="counter"
                            class="col-sm-12 nopads"
                        >
                            <b-form-group
                                class="title"
                                label-for="counter"
                                v-if="counter === 0 || isAttachmentSet(photos, counter-1)"
                            >
                                <b-form-file
                                    v-model="photos[counter]"
                                    accept="image/jpeg, image/jpg, image/png"
                                    :placeholder="$t('equipment.photo_placeholder')"
                                />
                            </b-form-group>
                        </div>
                    </b-row>
                </b-collapse>
                <hr/>
                <h4 class="form-header">{{ $t('power_stations.basic_info')}}</h4>
                <div class="col-12 p-1">
                    <span class="span-title">{{ $t('power_stations.location') }}</span>
                    <span v-if="station && station.street">{{ station.street }} {{ station.city }}</span>
                    <span v-else style="font-size: .85em">{{ coordinate.lat.toFixed(6) + ' ' + coordinate.lng.toFixed(6)}}</span>
                </div>
                <b-row class="nopads">
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('street_lights.status') }}</span>
                        <b-form-select id="lightStatus" size="sm" v-model="station.status" :options="stationStatusOptions">
                            <template #first>
                                <option :value="null">{{ $t('street_lights.select_status') }}</option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('power_stations.location_name') }}</span>
                        <b-form-input
                            v-model="station.location_name"
                            type="text"
                            size="sm"/>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('power_stations.location_code') }}</span>
                        <b-form-input
                            v-model="station.metering_point_number"
                            type="text"
                            size="sm"/>
                    </div>
                </b-row>
                <b-row class="nopads">
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('power_stations.type') }}</span>
                        <b-form-select id="stationType" size="sm" v-model="station.station_type" :options="stationTypeOptions"></b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('power_stations.locking') }}</span>
                        <b-form-select id="stationLocking"  size="sm" v-model="station.locking" :options="lockingOptions"></b-form-select>
                    </div>
                    <div class="col-sm-4 p-1">
                        <span class="span-title">{{ $t('power_stations.plate_value') }}</span>
                        <b-form-input
                            v-model="station.plate_value"
                            type="text"
                            size="sm"/>
                    </div>
                </b-row>
                <hr/>
                <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.contract')}}</h4>
                <span class="tip-light">{{ $t('power_stations.contract_tip')}}</span>
                <open-contract-filter
                    :to="toDate"
                    :from="fromDate"
                    :contract="preselectedContract"
                    :show-company="false"
                    :show-title="false"
                    @loading="loading=true"
                    @loadingCompleted="loading=false"
                    @contractSelected="setContract"
                    style="margin-left: -2px; margin-right: -3px;"/>
                <hr/>
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.electric_and_controls')}}</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : electricsVisible}"
                            v-b-toggle.electricSection
                            @click.stop="electricsVisible = !electricsVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="electricSection">
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.main_fuse') }}</span>
                            <b-form-select id="stationType" size="sm" v-model="station.main_fuse" :options="mainFuseTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.main_fuse_type') }}</span>
                            <b-form-select id="stationLocking"  size="sm" v-model="station.main_fuse_type" :options="mainFuseOptions"></b-form-select>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.meter_number') }}</span>
                            <b-form-input
                                v-model="station.meter_number"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.night_mode') }}</span>
                            <b-form-checkbox
                                size="lg"
                                v-model="station.shutdown_at_night">
                            </b-form-checkbox>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.energy_company') }}</span>
                            <b-form-input
                                v-model="station.energy_company"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.energy_company_number') }}</span>
                            <b-form-input
                                v-model="station.energy_company_number"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.metering') }}</span>
                            <b-form-select id="stationType" size="sm" v-model="station.metering" :options="meteredOptions"></b-form-select>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.power_source') }}</span>
                            <b-form-input
                                v-model="station.power_source_cable"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.control_method') }}</span>
                            <b-form-select id="controlMethod" size="sm" v-model="station.control_method" :options="controlTypeOptions"/>
                        </div>
                        <div class="col-sm-6 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.control_code') }}</span>
                            <b-form-input
                                v-model="station.control_code"
                                type="text"
                                size="sm"/>
                        </div>
                    </b-row>
                    <b-row class="nopads">
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.max_outputs') }}</span>
                            <b-form-input
                                v-model="station.max_outputs"
                                type="number"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.short_circuit_current') }}</span>
                            <b-form-input
                                v-model="station.short_circuit_current"
                                type="number"
                                size="sm"/>
                        </div>
                        <div class="col-sm-4 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.short_circuit_current_measure_date') }}</span>
                            <b-form-input
                                v-model="station.short_circuit_current_measure_date"
                                type="date"
                                format="dd.MM.yyyy"
                                size="sm"/>
                        </div>
                    </b-row>
                    <span class="span-title upper">{{ $t('power_stations.group_fuses') }}</span>
                    <b-row
                        v-for="(item, index) in groupFuses"
                        :key="index"
                        class="nopads align-items-end group-fuse pb-1">
                        <div class="col-sm-2 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.group_fuse_type') }}</span>
                            <b-form-select id="stationType" size="sm" v-model="item.type" :options="groupFuseTypeOptions"></b-form-select>
                        </div>
                        <div class="col-sm-2 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.circuit_break_curve') }}</span>
                            <b-form-select id="stationType" size="sm" v-model="item.circuit_break_curve" :options="breakCurveOptions"></b-form-select>
                        </div>
                        <div class="col-sm-2 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.current_value') }}</span>
                            <b-form-select id="stationType" size="sm" v-model="item.current_value" :options="currentValueOptions"></b-form-select>
                        </div>
                        <div class="col-sm-2 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.cable_protection_auto') }}</span>
                            <b-form-checkbox
                                size="lg"
                                v-model="item.cable_protection_auto">
                            </b-form-checkbox>
                        </div>
                        <div class="col-sm-3 nopads p-1">
                            <span class="span-title">{{ $t('power_stations.group_cable_type') }}</span>
                            <b-form-input
                                v-model="item.cable_type"
                                type="text"
                                size="sm"/>
                        </div>
                        <div class="col-1 nopads text-center pb-1">
                            <b-button
                                variant="danger"
                                size="sm"
                                @click.stop="removeGroupFuse(index)"
                            >
                                <i class="fa fa-trash"/>
                            </b-button>
                        </div>
                    </b-row>
                    <div class="col-12 text-right pt-2">
                        <b-button
                            size="sm"
                            variant="success"
                            class="mr-1"
                            @click.stop="addGroupFuse"
                            :disabled="this.groupFuses.length > 3"
                        >
                            <i class="fa fa-plus"/>
                        </b-button>
                    </div>
                </b-collapse>
                <hr/>
                <b-row>
                    <b-col class="nopads report-header" cols="10">
                        <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.inspections')}}</h4>
                    </b-col>
                    <b-col class="nopads">
                        <font-awesome-icon
                            icon="arrow-down"
                            class="pointer right no-focus arrow-icon mt-0"
                            v-bind:class="{rotated : inspectionsVisible}"
                            v-b-toggle.inspectionSection
                            @click.stop="inspectionsVisible = !inspectionsVisible"/>
                    </b-col>
                </b-row>
                <b-collapse id="inspectionSection">
                    <b-row>
                            <span class="span-title">{{ $t('power_stations.renewal_need') }}</span>
                            <b-form-textarea
                                id="renewal_need"
                                v-model="station.renewal_need"
                                :rows="4"
                                :max-rows="8"
                            />
                    </b-row>
                    <b-row>
                            <span class="span-title">{{ $t('power_stations.next_inspection_year') }}</span>
                            <b-form-input
                                v-model="station.next_inspection_year"
                                type="number"
                                min="2020"
                                max="2100"
                                size="sm"/>
                    </b-row>
                    <div v-if="inspections && inspections.length > 0">
                        <!-- TODO - Inspection lists here -->
                    </div>
                    <span v-else-if="loading" class="text-left">{{ $t('common.loading')}}</span>
                    <span v-else class="text-left">{{ $t('power_stations.no_inspections')}}</span>


                </b-collapse>
                <hr/>
                <h4 class="form-header mt-0 pt-0">{{ $t('power_stations.info') }}</h4>
                <b-form-textarea
                    id="info"
                    v-model="station.info"
                    :placeholder="$t('power_stations.info')"
                    :rows="4"
                    :max-rows="8"
                />
                <div class="col-sm-12 button-container pt-4 pb-0 mb-0">
                    <b-button
                        variant="secondary"
                        class="result-button"
                        @click.stop="$emit('close')"
                    >
                        {{ $t('common.cancel') }}
                    </b-button>
                    <b-button
                        variant="success"
                        class="result-button"
                        :disabled="loading||this.station===null"
                        @click.stop="submit"
                    >
                        {{ $t('common.save') }}
                    </b-button>
                </div>
            </b-form>
            <div
                v-if="loading"
                id="loader"
                class="spinner"
            />
        </div>
        <!-- Fullscreen photo -->
        <transition name="fade">
            <fullscreen-image
                v-if="fullScreenPhotoData"
                :data="fullScreenPhotoData"
                @close="closePhoto"/>
        </transition>
    </base-modal>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'
import BaseModal from '../BaseModal'
import OpenContractFilter from "@/components/contract/OpenContractFilter";
import {timeUtils} from "@/components/mixins/TimeUtils";
import ImageView from "@/components/view/ImageView";
import FullscreenImage from "@/components/view/FullscreenImage";
import {attachmentHelper} from "@/components/mixins/AttachmentMixin";
import {streetLightHelper} from "@/components/mixins/StreetLightMixin";
import {Arrow} from "@egjs/flicking-plugins";
import {Flicking} from "@egjs/vue-flicking";

export default {
    name: 'PowerStationWizard',
    components: {FullscreenImage, OpenContractFilter, BaseModal, Flicking, ImageView},
    mixins: [restApi, timeUtils, attachmentHelper, streetLightHelper],
    props: {
        selectedContract: {
            type: Object,
            default: null
        },
        coordinate: {
            type: Object,
            default: null
        },
        powerStation: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            loading: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            variant: 'danger',
            station: null,
            contract: null,
            fullScreenPhotoData: null,
            photosVisible: true,
            electricsVisible: false,
            inspectionsVisible: false,
            inspectionsDownloaded: false,
            inspections: [],
            photos: new Array(5),
            typeOptions: [
                {text: 'Type 1', value: 1},
                {text: 'Type 2', value: 2},
                {text: 'Type 4', value: 3}
            ],
            groupFuses: [],
            plugins: [new Arrow()],
            options: {
                preventDefaultOnDrag: true,
                bound: true,
                panelsPerView: 3,
            },
        }
    },
    computed: {
        fromDate() {
            if (this.powerStation) {
                return this.powerStation.created_at
            }
            let d = new Date()
            return d.toISOString()
        },
        toDate() {
            let d = new Date()
            return d.toISOString()
        },
        preselectedContract() {
            return this.station.contract ? this.station.contract : null
        }
    },
    watch: {
        inspectionsVisible(value) {
            if (value && !this.inspectionsDownloaded) {
                // TODO - Fetch and show station's inspections
            }
        }
    },
    mounted() {
        if (!this.powerStation) {
            this.station = {
                id: null,
                street: null,
                city: null,
                location_name: null,
                metering_point_number: null,
                station_type: null,
                locking: null,
                plate_value: null,
                main_fuse: null,
                main_fuse_type: null,
                meter_number: null,
                energy_company: null,
                energy_company_number: null,
                metering: null,
                control_method: null,
                control_code: null,
                shutdown_at_night: false,
                renewal_need: '',
                power_source_cable: null,
                next_inspection_year: null,
                info: '',
                max_outputs: null,
                short_circuit_current: null,
                short_circuit_current_measure_date: null,
                position: this.coordinate,
                group_fuses: []
            }
        } else {
            this.station = this.powerStation
            this.groupFuses = this.station.group_fuses ? this.station.group_fuses : []
            this.contract = this.station.contract ? this.station.contract : null
            this.station.short_circuit_current_measure_date = this.timestampToDateInput(this.station.short_circuit_current_measure_date)
        }
    },
    methods: {
        addGroupFuse() {
            if (this.groupFuses.length < 4) {
                this.groupFuses.push({
                    type: null,
                    circuit_break_curve: null,
                    current_value: null,
                    cable_protection_auto: false,
                    cable_type: ""
                })
            }
        },
        removeGroupFuse(index) {
          if (this.groupFuses.length > index && index >= 0) {
              this.groupFuses.splice(index, 1)
          }
        },
        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },
        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },
        submit: function () {
            if (this.contract) {
                this.station.contract = this.contract
            }
            if (!this.station.position) {
                this.showAlert(this.$t('power_stations.error_position'))
                return;
            } else if (this.coordinate) {
                // Coordinates given as props for new station
                this.station.position = {
                    lat: this.coordinate.lat,
                    lon: this.coordinate.lng
                }
            }
            if (!this.station.location_name) {
                this.showAlert(this.$t('power_stations.error_location'))
                return;
            }
            this.station.group_fuses=this.groupFuses
            if (this.station.id && this.station.id > 0) {
                this.loading = true
                this.restUpdate(this.powerStationUrl, this.station, this.success, this.fail)
            } else {
                this.loading = true
                this.restAdd(this.powerStationUrl, this.station, this.success, this.fail)
            }
        },
        success: function (response) {
            let photos = this.getValidAttachments(this.photos)
            if (photos.length > 0) {
                this.uploadPhoto(photos, response.id)
            } else {
                this.uploadCompleted()
            }
        },
        uploadPhoto: function (photos, id) {
            if (photos.length > 0) {
                this.loading = true
                let formData = new FormData()
                photos.forEach(photoFile => {
                    if (photoFile !== undefined && photoFile !== null) {
                        formData.append('photos[]', photoFile)
                    }
                })
                this.restPostWithFile(this.powerStationPhotoUrl + '/' + id, {}, formData, this.uploadCompleted, this.fail)
            }
        },
        uploadCompleted: function () {
            this.loading = false
            this.showAlert(this.$t('road_sign.added'), 'success')
            this.$emit('closeAndUpdate')
        },
        fail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_add_failed'))
        },
        setContract: function (contract) {
            this.contract = contract
        },
        getPhotoUrl: function (baseUrl, photo) {
            return baseUrl + '/' + this.station.id + '/' + photo.id
        },
        openImage: function (url) {
            this.fullScreenPhotoData = url
        },
        closePhoto() {
            this.fullScreenPhotoData = undefined
        }
    }
}
</script>
<style>
@import "../../styles/modal.css";

.upper {
    text-transform: uppercase;
    margin-top: .75em;
    margin-bottom: 0;
    font-size: .9em;
}

.group-fuse {
    border: 1px #d9d9d9 solid;
    border-radius: .25em;
    margin-bottom: .3em;
}

</style>
