<template>
    <div class="col-12 nopads">
        <div class="col-sm-12 nopads">
            <h4 class="form-header pl-3">{{ $t('menu.orders')}}</h4>
            <b-row class="nopasd pb-3">
                <div class="col-sm-3 nopads">
                    <span class="span-title pl-3">{{ $t('contracts.order_filter') }} </span>
                    <b-row class="nopads">
                        <b-col cols="11">
                            <multiselect
                                id="orderStatusSelector"
                                :multiple="true"
                                :options="orderStatusValues"
                                :custom-label="value => getStatusString(value)"
                                v-model="updateOrderFilter"
                                :searchable="true"
                                :close-on-select="false"
                                :placeholder="$t('orders.select_status')"
                                :select-label="$t('orders.select_state')"
                                :selected-label="$t('common.selected')"
                                :deselect-label="$t('common.remove_selection')"
                            />
                        </b-col>
                        <b-col cols="1" class="nopads pt-1">
                            <font-awesome-icon icon="trash" style="color: #636364" class="pointer" @click.stop="clearOrderStatusSelection"/>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-3 nopads">
                    <span class="span-title pl-3">{{ $t('contracts.order_task_type_filter') }} </span>
                    <b-row class="nopads">
                        <b-col cols="11">
                            <multiselect
                                id="taskTypeSelector"
                                class="col-12"
                                :multiple="true"
                                :options="taskTypeValues"
                                v-model="updateTaskTypeFilter"
                                track-by="name"
                                label="name"
                                :searchable="true"
                                :close-on-select="false"
                                :placeholder="$t('orders.select_status')"
                                :select-label="$t('orders.select_task_type')"
                                :selected-label="$t('common.selected')"
                                :deselect-label="$t('common.remove_selection')"
                            >
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                          <span class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                          </span>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                >
                                    <div class="option__desc">
                                        <span class="option__title multiselect_detail_block">{{ props.option.name }}</span>
                                    </div>
                                </template>
                                <span slot="noResult">{{ $t('common.not_found') }}</span>
                                <span slot="noOptions">{{ $t('common.no_results') }}</span>
                            </multiselect>
                        </b-col>
                        <b-col cols="1" class="nopads pt-1">
                            <font-awesome-icon icon="trash" style="color: #636364" class="pointer" @click.stop="clearOrderTaskTypeSelection"/>
                        </b-col>
                    </b-row>
                </div>
                <div v-if="contract.is_contract_party" class="col-sm-6 nopads button-container pr-3 pt-4">
                    <b-button
                        size="sm"
                        v-if="orders && orders.length > 0"
                        variant="secondary"
                        class="form-button"
                        :disabled="loading"
                        @click.stop="closeOrders"
                    >
                        <span>{{ $t('contracts.close_orders') }}</span>
                    </b-button>
                    <b-button
                        size="sm"
                        variant="outline-primary"
                        class="form-button"
                        :disabled="loading"
                        @click.stop="addOrderGeometry"
                    >
                        {{ $t('orders.add_geometry_to_all_orders') }}
                    </b-button>
                    <b-button
                        size="sm"
                        v-if="!isContractClosed()"
                        variant="primary"
                        class="form-button"
                        :disabled="loading || contract.task_types.length === 0"
                        @click.stop="addOpenOrders"
                    >
                        <span>{{ $t('contracts.add_open_orders') }}</span>
                    </b-button>
                    <b-button
                        size="sm"
                        v-if="!isContractClosed()"
                        variant="success"
                        class="form-button"
                        :disabled="loading || contract.task_types.length === 0"
                        @click.stop="addOrder"
                    >
                        <span>{{ $t('contracts.add_order') }}</span>
                    </b-button>
                </div>
            </b-row>
            <div v-if="!loading && contract.task_types.length === 0 && contract.is_contract_party" class="no-task-types-text col-sm-7 col-md-6 col-lg-4 col-7 right">
                <font-awesome-icon icon="exclamation-triangle" />
                <span class="p-2 text-danger">{{ $t('contracts.warning_task_types' )}}</span>
            </div>
            <div class="col-sm-12">
                <order-list
                    v-if="orders && orders.length > 0"
                    :results="orders"
                    :user="user"
                    @edit="editOrder"
                />
                <span v-else class="p-2">{{ $t('contracts.no_orders') }}</span>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
        <!-- Geometry importer -->
        <order-geometry
            v-if="showGeometryForm"
            @geometrySelected="setOrderGeometries"
            @close="hideOrderGeometry"
        />
        <b-modal
            id="close_orders_modal"
            ref="close_orders_modal"
            :hide-header=true
            :hide-footer=true
            @ok="doCloseOrders">
            <div class="col-sm-12 pb-1">
                <span class="pb-2 title">{{ $t('contracts.close_confirm') }}</span>
                <span>{{ $t('contracts.close_confirm_info') }}</span>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="cancelClosing">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="doCloseOrders">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>
        <b-modal
            id="add_orders_modal"
            ref="add_orders_modal"
            :hide-header=true
            :hide-footer=true
        >
            <div class="col-sm-12 pb-1">
                <span class="pb-2 title">{{ addMessage }}</span>
                <span>{{ $t('contracts.new_add_confirm_info') }}</span>
            </div>
            <div class="col-12 button-container">
                <b-button size="sm" class="form-button" variant="secondary" @click="cancelOpening">
                    {{ $t('common.no') }}
                </b-button>
                <b-button size="sm" class="form-button" variant="success" @click="doAddOpenOrders">
                    {{ $t('common.yes') }}
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<style scoped>
.clear-selection-button {
    margin-top: 22px;
}

.clear-icon {
    margin-right: 5px;
    font-weight: bold;
    color: red;
}
</style>

<script>
import {EventBus} from '@/event-bus'
import {restApi} from '../mixins/RestApiMixin'
import {orderStatus, workManagementHelper} from '../mixins/WorkManagementMixin'
import OrderList from '../order/OrderList'
import OrderGeometry from '../order/OrderGeometry'

export default {
    name: 'ContractOrders',
    components: {OrderList, OrderGeometry},
    mixins: [workManagementHelper, restApi],
    props: {
        contract: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        orderFilter: {
            type: Array,
            default() {
                return []
            }
        },
        taskTypeFilter: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data: function () {
        return {
            loading: true,
            orders: [],
            ordersToAdd: [],
            showGeometryForm: false,
            allOrders: [],
            order: null,
            closeOnCancel: false,
            contractUpdate: JSON.parse(JSON.stringify(this.contract)),
            addMessage: '',
            updateOrderFilter: [],
            updateTaskTypeFilter: [],
            taskTypeValues: []
        }
    },
    created: function () {
        if (this.contract.id > 0) {
            this.fetchContractOrders()
        }
        this.updateOrderFilter = this.orderFilter
        this.updateTaskTypeFilter = this.taskTypeFilter
    },
    computed: {
        orderStatusValues() {
            return Object.values(orderStatus)
        }
    },
    watch: {
        contract(val) {
            if (this.contract.id > 0) {
                this.contractUpdate = val
                this.fetchContractOrders()
            }
        },
        updateOrderFilter() {
            this.$emit('setOrderFilter', this.updateOrderFilter)
            this.filterVisibleOrders()
        },
        updateTaskTypeFilter() {
            this.$emit('setTaskTypeFilter', this.updateTaskTypeFilter)
            this.filterVisibleOrders()
        }
    },
    methods: {
        fetchContractOrders: function () {
            this.orders = []
            this.loading = true
            this.restFetchParams(this.orderUrl, {contract: this.contractUpdate.id}, this.handleOrders)
        },
        handleOrders: function (response) {
            this.loading = false
            if (response != null) {
                this.allOrders = response.data
                this.getOrderTaskTypes()
                this.filterVisibleOrders()
            }
        },
        getOrderTaskTypes() {
            this.taskTypeValues = this.allOrders
                .filter((item, index, self) => {
                    return index === self.findIndex((t) => t.contract_task_type.task_type.id === item.contract_task_type.task_type.id);
                })
                .map((item) => item.contract_task_type.task_type);
        },
        clearOrderStatusSelection() {
            this.updateOrderFilter = [];
        },
        clearOrderTaskTypeSelection() {
            this.updateTaskTypeFilter = [];
        },
        addOrderGeometry: function () {
            this.showGeometryForm = true
        },
        hideOrderGeometry: function () {
            this.showGeometryForm = false
        },
        setOrderGeometries(geometries) {
            let orderIds = []
            this.orders.forEach(item => {
                orderIds.push(item.id)
            })
            if (geometries && geometries.length > 0) {
                geometries.forEach(item => {
                    item.geometry = typeof item.geometry === 'object' && item.geometry !== null ? JSON.stringify(item.geometry) : item.geometry
                })
                this.restUpdate(this.orderGeometriesUrl, {orders: orderIds, geometries: geometries}, this.handleSetOrderGeometries, this.handleSetOrderGeometriesFail)
            } else {
                this.showGeometryForm = false
            }
        },

        handleSetOrderGeometries() {
            this.loading = false
            this.showGeometryForm = false
        },

        handleSetOrderGeometriesFail() {
            this.loading = false
            this.showGeometryForm = false
            EventBus.$emit('show-alert', this.$t('orders.add_geometry_failed'))
        },

        filterVisibleOrders() {
            if (this.updateOrderFilter.length < 1 && this.updateTaskTypeFilter.length < 1) {
                this.orders = this.allOrders;
            } else {
                this.orders = this.allOrders.filter(order => {
                    const isOrderStatusMatch = this.updateOrderFilter.length < 1 || this.updateOrderFilter.includes(order.status);
                    const isTaskTypeMatch = this.updateTaskTypeFilter.length < 1 || this.updateTaskTypeFilter.some(filter => filter.id === order.contract_task_type.task_type.id);

                    return isOrderStatusMatch && isTaskTypeMatch;
                });
            }
        },
        editOrder: function (order) {
            this.$emit('editOrder', this.createEditorOrder(order))
        },
        close: function () {
            this.$emit('showList')
        },
        closeAndUpdate: function (data) {
            if (this.contract.id < 1 && data.id > 0) {
                // If we just added a contract successfully, ask to create orders as well
                this.contractUpdate = data
                this.addOpenOrders()
                this.closeOnCancel = true
            } else {
                this.$emit('closeAndUpdate')
            }
        },
        addOrder: function () {
            this.$emit('addOrder', this.createEditorOrder())
        },
        isContractClosed: function () {
            // Check if end date is before today
            if (this.contractUpdate.id > 0) {
                let today = new Date()
                today.setHours(23)
                today.setMinutes(59)
                today.setSeconds(59)
                let endDate = Date.parse(this.contract.end_date)
                return today.getTime() > endDate
            }
            return false
        },
        closeOrders: function () {
            this.$refs['close_orders_modal'].show()
        },
        doCloseOrders: function () {
            this.$refs['close_orders_modal'].hide()
            this.loading = true
            let orderIds = []
            this.orders.forEach(item => {
                orderIds.push(item.id)
            })
            this.restUpdate(this.orderCloseUrl, {orders: orderIds}, this.handleCloseOrders, this.handleCloseOrdersFail)
        },
        handleCloseOrders: function () {
            this.loading = false
            this.fetchContractOrders()
            this.$emit('ordersUpdated')
        },
        handleCloseOrdersFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.close_error'))
        },
        addOpenOrders: function () {
            this.addMessage = this.$t('contracts.add_confirm')
            this.$refs['add_orders_modal'].show()
        },
        addNewOpenOrders: function () {
            this.addMessage = this.$t('contracts.new_add_confirm')
            this.$refs['add_orders_modal'].show()
        },
        doAddOpenOrders: function () {
            this.$refs['add_orders_modal'].hide()
            let contractTasks = this.contractUpdate.task_types
            this.ordersToAdd = []
            for (let i = 0; i < contractTasks.length; i++) {
                let item = contractTasks[i]
                let o = this.orders.find(order =>
                    order.contract_task_type.task_type.id === item.task_type.id &&
                    order.status < 10) // 10 = Completed - referring to mixin const not working
                if (o === undefined) {
                    let newOrder = this.createEditorOrder()
                    newOrder.executors.push({company: {id: this.contractUpdate.contractor.id}})
                    newOrder.contract = {id: this.contractUpdate.id}
                    newOrder.contract_task_type = {id: item.id}
                    newOrder.unit = item.unit
                    this.ordersToAdd.push(newOrder)
                }
            }
            // Submit orders one by one
            this.submitOrders()
        },
        submitOrders: function () {
            if (this.ordersToAdd && this.ordersToAdd.length > 0) {
                this.loading = true
                this.restAdd(this.orderUrl, this.ordersToAdd[0], this.handleSubmitSuccess, this.handleSubmitFail)
            } else {
                this.$emit('ordersUpdated')
            }
        },
        handleSubmitSuccess: function () {
            this.ordersToAdd.splice(0, 1)
            if (this.ordersToAdd.length > 0) {
                this.submitOrders()
            } else {
                this.fetchContractOrders()
                this.$emit('ordersUpdated')
            }
        },
        handleSubmitFail: function () {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.add_error'))
        },
        cancelOpening: function () {
            this.$refs['add_orders_modal'].hide()
            if (this.closeOnCancel) {
                this.$emit('closeAndUpdate')
            }
        },
        cancelClosing: function () {
            this.$refs['close_orders_modal'].hide()
        },
        setOrders: function (orders) {
            this.orders = orders
        },
        hasActiveOrders() {
            return this.orders.length > 0 &&
                this.orders.filter(item => item.status === orderStatus.OPEN || item.status === orderStatus.IN_PROGRESS).length > 0
        }
    }

}
</script>
