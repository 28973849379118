<template>
    <div class="col-sm-12 p-1">
        <div class="col-sm-12 nopads">
            <b-row class="nopads">
                <div class="col-sm-6 nopads">
                    <span v-if="isDateToday" class="tip-light">{{ $t('contracts.external.diary_date_hint')}}</span>
                </div>
                <div class="col-sm-6 nopads pt-2 pb-2 text-right">
                    <b-button
                        variant="info"
                        class="result-button"
                        v-on:click.stop="fetchDiary"
                        :disabled="isDateToday"
                    >
                        {{ $t('contracts.external.create_report') }}
                    </b-button>
                </div>
            </b-row>
            <div v-if="result" class="nopads p-2 pb-4" style="border: 1px #bcbcbc solid; border-radius: .5em;" >
                <h4 class="form-header pt-0">{{ $t('contracts.external.work_diary')}}</h4>
                <b-row class="nopads">
                    <span class="item-details">{{ toLocalTime(result.tyomaapaivakirja.tunniste.paivamaara) }}</span>
                </b-row>
                <b-row class="nopads">
                    <span class="item-details">{{ result.otsikko.lahettaja.jarjestelma}}</span>
                </b-row>
                <b-row class="nopads">
                    <span class="item-details">{{ result.otsikko.lahettaja.organisaatio.nimi}}</span>
                </b-row>
                <b-row class="nopads pb-3">
                    <span class="item-details">{{ result.otsikko.lahettaja.organisaatio.ytunnus}}</span>
                </b-row>
                <!-- Duty Officer -->
                <span class="span-title mt-3">{{ $t('contract_diary.duty_officer')}}</span>
                <table class="table table-sm">
                    <tr v-for="(item, index) in result.tyomaapaivakirja['paivystajan-tiedot']" v-bind:key="index">
                        <td>{{ toLocalTime(item.paivystaja.aloitus) }}</td><td>{{ toLocalTime(item.paivystaja.lopetus) }}</td><td>{{ item.paivystaja.nimi }}</td>
                    </tr>
                </table>
                <!-- Weather data -->
                <span class="span-title mt-3">{{ $t('weather_station.data_title')}}</span>
                <table class="table table-sm">
                    <tr>
                        <th>{{ $t('contracts.external.time') }}</th>
                        <th>{{ $t('contracts.external.station') }}</th>
                        <th>{{ $t('contracts.external.air_temp') }}</th>
                        <th>{{ $t('contracts.external.road_temp') }}</th>
                        <th>{{ $t('contracts.external.avg_wind') }}</th>
                        <th>{{ $t('contracts.external.rain_type') }}</th>
                        <th>{{ $t('contracts.external.rain_amount') }}</th>
                    </tr>
                    <tr v-for="(item, index) in result.tyomaapaivakirja.saatiedot" v-bind:key="index">
                        <td>{{ toLocalTime(item.saatieto.havaintoaika) }}</td>
                        <td>{{ item.saatieto['aseman-tunniste'] }}</td>
                        <td>{{ item.saatieto['ilman-lampotila'] }}</td>
                        <td>{{ item.saatieto['tien-lampotila'] }}</td>
                        <td>{{ item.saatieto['keskituuli'] }}</td>
                        <td>{{ item.saatieto['sateen-olomuoto'] }}</td>
                        <td>{{ item.saatieto['sadesumma'] }}</td>
                    </tr>
                </table>
                <!-- Exceptional weather data -->
                <span class="span-title mt-3">{{ $t('contract_diary.exceptional_weather')}}</span>
                <table class="table table-sm">
                    <tr v-for="(item, index) in result.tyomaapaivakirja['poikkeukselliset-saahavainnot']" v-bind:key="index">
                        <td></td>
                    </tr>
                    <span v-if="result.tyomaapaivakirja['poikkeukselliset-saahavainnot'].length < 1">-</span>
                </table>
                <!-- Fleet use -->
                <span class="span-title mt-3">{{ $t('contract_diary.fleet_use')}}</span>
                <table v-if="result.tyomaapaivakirja['kaluston-kaytto'].length > 0" class="table table-sm">
                    <tr>
                        <td>Työkoneita</td>
                        <td>{{ result.tyomaapaivakirja['kaluston-kaytto'][0]['kalusto']['tyokoneiden-lkm']}}</td>
                    </tr>
                    <tr>
                        <td>Lisäkalusto</td>
                        <td>{{ result.tyomaapaivakirja['kaluston-kaytto'][0]['kalusto']['lisakaluston-lkm']}}</td>
                    </tr>
                </table>
                <!-- Operations -->
                <span class="span-title mt-3">{{ $t('contract_diary.road_operations')}}</span>
                <div v-if="result.tyomaapaivakirja['tieston-toimenpiteet'].length > 0" class="nopads">
                    <table v-for="(item, index) in result.tyomaapaivakirja['tieston-toimenpiteet']" v-bind:key="index" class="table table-sm">
                        <tr v-for="(work, index) in item['tieston-toimenpide']['tehtavat']" v-bind:key="index">
                            <td>{{ work.tehtava.id }} {{ work.tehtava.kuvaus }}</td>
                        </tr>
                    </table>
                </div>
                <span v-else>-</span>
                <!-- Other operations -->
                <span class="span-title mt-3">{{ $t('contract_diary.other_operations')}}</span>
                <div v-if="result.tyomaapaivakirja['tieston-muut-toimenpiteet'].length > 0" class="nopads">
                    <table v-for="(item, index) in result.tyomaapaivakirja['tieston-muut-toimenpiteet']" v-bind:key="index" class="table table-sm">
                        <tr v-for="(work, index) in item['tieston-muu-toimenpide']['tehtavat']" v-bind:key="index">
                            <td>{{ work.tehtava.kuvaus }}</td>
                        </tr>
                    </table>
                </div>
                <span v-else>-</span>
                <!-- Traffic Control -->
                <span class="span-title mt-3">{{ $t('map.add_traffic_control')}}</span>
                <table v-if="result.tyomaapaivakirja['liikenteenohjaus-muutokset'].length > 0" class="table table-sm">
                    <tr v-for="(item, index) in result.tyomaapaivakirja['liikenteenohjaus-muutokset']" v-bind:key="index">
                        <td>{{ item['liikenteenohjaus-muutos'].kuvaus }}</td>
                    </tr>
                </table>
                <span v-else>-</span>
                <!-- Accidents -->
                <span class="span-title mt-3">{{ $t('contract_diary.accidents')}}</span>
                <table v-if="result.tyomaapaivakirja['onnettomuudet'].length > 0" class="table table-sm">
                    <tr v-for="(item, index) in result.tyomaapaivakirja['onnettomuudet']" v-bind:key="index">
                        <td>{{ item['onnettomuus'].kuvaus }}</td>
                    </tr>
                </table>
                <span v-else>-</span>
                <!-- Authorities Assistance -->
                <span class="span-title mt-3">{{ $t('contract_diary.authorities_assistance')}}</span>
                <table v-if="result.tyomaapaivakirja['viranomaisen-avustaminen'].length > 0" class="table table-sm">
                    <tr v-for="(item, index) in result.tyomaapaivakirja['viranomaisen-avustaminen']" v-bind:key="index">
                        <td>{{ item['viranomaisen-avustus'].kuvaus }}</td>
                        <td>{{ item['viranomaisen-avustus'].tunnit }} h</td>
                    </tr>
                </table>
                <span v-else>-</span>
                <!-- Feedback -->
                <span class="span-title mt-3">{{ $t('contract_diary.feedback')}}</span>
                <table v-if="result.tyomaapaivakirja['palautteet'].length > 0" class="table table-sm">
                    <tr v-for="(item, index) in result.tyomaapaivakirja['palautteet']" v-bind:key="index">
                        <td>{{ item.palaute.kuvaus }}</td>
                    </tr>
                </table>
                <span v-else>-</span>
                <!-- Customer contacts -->
                <span class="span-title mt-3">{{ $t('contract_diary.customer_contacts_info_title')}}</span>
                <table v-if="result.tyomaapaivakirja['tilaajan-yhteydenotot'].length > 0" class="table table-sm">
                    <tr v-for="(item, index) in result.tyomaapaivakirja['tilaajan-yhteydenotot']" v-bind:key="index">
                        <td>{{ item['tilaajan-yhteydenotto']['kuvaus'] }}</td>
                    </tr>
                </table>
                <span v-else>-</span>
                <!-- Other info -->
                <span class="span-title mt-3">{{ $t('contract_diary.other_info')}}</span>
                <table v-if="result.tyomaapaivakirja['muut-kirjaukset']" class="table table-sm">
                    <tr>
                        <td>{{ result.tyomaapaivakirja['muut-kirjaukset']['kuvaus'] }}</td>
                    </tr>
                </table>
                <span v-else>-</span>
            </div>
            <div v-if="result" class="col-sm-12 nopads pt-2 pb-2 text-right">
                <b-button
                    variant="success"
                    class="result-button"
                    v-on:click.stop="$emit('submitReport', result)"
                    :disabled="loading"
                >
                    {{ $t('contracts.external.submit_report') }}
                </b-button>
            </div>
        </div>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import {restApi} from '../../mixins/RestApiMixin'
import {timeUtils} from "@/components/mixins/TimeUtils";
import {EventBus} from "@/event-bus";

export default {
    name: 'ContractWorkDiary',
    mixins: [restApi, timeUtils],
    props: {
        contract: {
            type: Object,
            default: null
        },
        date: {
            type: Date,
            default: new Date()
        }
    },
    data: function () {
        return {
            loading: false,
            isDateToday: true,
            result: null
        }
    },
    watch: {
        date: function () {
            this.checkDate()
        }
    },
    mounted() {
        this.checkDate()
    },
    methods: {
        checkDate() {
            const today = new Date()
            this.isDateToday = (today.getFullYear() === this.date.getFullYear() && today.getMonth() === this.date.getMonth() && today.getDate() === this.date.getDate())
        },
        fetchDiary() {
            this.loading = true
            this.result = null
            let params = {
                contract: this.contract.id,
                date: this.date.toISOString()
            }
            this.restFetchParams(this.extWorkDiaryUrl, params, this.handleResult, this.handleError)
        },

        handleResult(response) {
            this.loading = false
            if (response && response.data) {
                this.result = response.data
            }
        },

        handleError() {
            this.loading = false
            EventBus.$emit('show-alert', this.$t('contracts.external.diary_error'))
        }
    }
}
</script>
