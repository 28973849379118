<template>
    <div
        class="details-container"
        @click.stop="$emit('close')">
        <transition name="fade">
            <div
                v-if="this.photo" class="fullscreen-image-container"
                v-bind:style="{ backgroundImage: 'url(' + getImage() + ')' }"
                @click.stop="$emit('close')"/>
        </transition>
        <font-awesome-icon icon="times-circle" class="close-dialog-icon"></font-awesome-icon>
        <transition name="fade">
            <div v-if="!this.photo" class="fullscreen-image-loading">{{ $t('common.loading') }}</div>
        </transition>
    </div>
</template>

<script>
import {restApi} from '../mixins/RestApiMixin'

export default {
    name: 'FullscreenImage',
    mixins: [restApi],
    props: {
        url: null,
        data: null
    },
    data() {
        return {
            photo: null
        }
    },
    created() {
        if (this.data) {
            this.photo = this.data
        } else if (this.url) {
            this.fetchPhoto()
        }
    },
    methods: {
        fetchPhoto() {
            this.loading = true
            this.restFetch(this.url, this.handleResponse)
        },
        handleResponse(response) {
            if (response && response.status === 200) {
                this.photo = response.data
            }
        },
        getImage() {
            return this.photo
        }
    }
}
</script>
