<template>
    <div class="col-12 nopads">
        <road-sign-filter
            @filtersUpdated="updateFilters"/>
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import Vue from 'vue'
import {timeUtils} from '../mixins/TimeUtils'
import {mapHelper} from '../mixins/MapMixin'
import {restApi} from '../mixins/RestApiMixin'
import {roadSignHelper} from '@/components/mixins/RoadSignMixin'
import MapSvgMarkers from "@/components/map/MapSvgMarkerStore";
import RoadSignFilter from "@/components/roadsign/RoadSignFilter";
import {geometryMixin} from "@/components/mixins/GeometryMixin";

const IMAGES = require.context('@/assets/roadsign/', true, /\.(?:jpg|png|svg|)$/)

export default {
    name: 'RoadSignMap',
    components: {RoadSignFilter},
    mixins: [timeUtils, mapHelper, restApi, roadSignHelper, geometryMixin],
    props: {
        map: {
            type: Object,
            default: null
        },
        boundingBox: {
            type: Array,
            default: null
        },
        infoVisible: {
            type: Boolean,
            default: true
        },
        zoomOnDrawMarker: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        selectedContractIds: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data: function () {
        return {
            loading: false,
            allSigns: [],
            visibleSigns: [],
            typeEntries: [],
            selectedTypes: [],
            selectedConditions: [],
            status: 1,
            listOpen: false,
            allSelected: false,
            indeterminate: false,
            visibilityZoomLevel: 15
        }
    },

    watch: {
      map() {
        this.$nextTick(function () {
            if (this.boundingBox) {
                this.initView()
            }
        })
      },
      boundingBox: function () {
          if (this.map) {
              this.initView()
          }
      },
      selectedContractIds() {
          if (this.map) {
              this.initView(true)
          }
      },
      selectedTypes(newVal) {
        if (newVal.length === 0) {
          this.indeterminate = false
          this.allSelected = false
        } else if (newVal.length === this.typeEntries.length) {
          this.indeterminate = false
          this.allSelected = true
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
        this.filterByTypes()
      }
    },
    mounted() {
        if (this.map && this.boundingBox) {
            this.initView()
        }
    },
    beforeDestroy() {
        this.hideSigns()
    },
    methods: {
        updateFilters(status, filters) {
            this.status = status
            this.selectedConditions = filters
            this.initView(true)
        },
        updateView() {
            this.initView(true)
        },
        initView: function (force) {
            if (this.map.getMapZoomLevel() < this.visibilityZoomLevel) {
                this.bbox = null
                this.hideSigns()
            } else if (this.boundingBox && (force || (!this.bbox || this.isBboxOutsideBbox(this.boundingBox, this.bbox)))) {
                const extendRate = 0.003
                this.bbox = [
                    this.boundingBox[0] - extendRate,
                    this.boundingBox[1] - extendRate,
                    this.boundingBox[2] + extendRate,
                    this.boundingBox[3] + extendRate
                ]
                this.fetchSigns()
            }
        },

        fetchSigns() {
            let params = {}
            if(this.status === 0) {
                this.status = null
            } else if(!this.status) {
                this.status = 1
            }
            if(this.selectedConditions && this.selectedConditions.length > 0) {
                let conditionValues = []
                this.selectedConditions.forEach(cond => {
                    conditionValues.push(cond.value)
                })
                params.condition = conditionValues
            }
            params.status = this.status
            params.contract = this.selectedContractIds
            params.bbox = this.bbox
            this.loading = true
            this.restFetchParams(this.roadSignUrl, params, this.handleSearch)
        },

        handleSearch(response) {
            this.loading = false
            if (response && response.data) {
                this.allSigns = response.data
                if (this.allSigns && this.allSigns.length > 0) {
                    this.visibleSigns = this.allSigns
                    this.initTypeEntries()
                    this.filterByTypes()
                }
            }
        },

        drawSigns: function () {
            this.visibleSigns.forEach(function (sign) {
                this.drawSign(sign)
            }, this)
        },

        drawSign: function (sign) {
            let mapIconName = this.getSignIcon(sign.sign)
            if (mapIconName) {
                var ComponentClass = Vue.extend(MapSvgMarkers)
                var markerStore = new ComponentClass()
                markerStore.$mount()
                let svgUrl = IMAGES('./' + mapIconName)
                markerStore.setSvgIcon(svgUrl, sign.sign_condition === 10);
                this.$nextTick(function () {
                    this.map.showMapMarker(sign.id, this.TRAFFIC_SIGN, sign.position.y, sign.position.x, markerStore.getSvgIcon())
                })
            }
        },

      hideSigns: function () {
        if (this.map) {
          this.map.removeMapItemsByType(this.TRAFFIC_SIGN)
        }
        this.visibleSigns = []
      },

        initTypeEntries: function () {
            this.typeEntries = []
            if (this.allSigns && this.allSigns.length > 0) {
                this.allSigns.forEach(item => {
                    let existingType = this.typeEntries.find(element => element.value === item.type_id)
                    if (!existingType) {
                        this.typeEntries.push({text: item.name, value: item.type_id})
                    }
                })
                this.sortTypeEntries()
            }
            // By default, select all types
            if (!this.selectedTypes || this.selectedTypes.length < 1) {
                this.toggleAll(true)
            }
        },

        sortTypeEntries: function () {
            this.typeEntries.sort(function (a, b) {
                if (a.text < b.text) {
                    return -1;
                }
                if (a.text > b.text) {
                    return 1;
                }
                return 0;
            });
        },


        filterByTypes: function () {
            this.hideSigns()
            this.visibleSigns = []
            if (this.allSelected) {
                this.visibleSigns = this.allSigns
            } else {
                this.allSigns.forEach(item => {

                    // TODO - Here we need to check the group where the signs belong
                    // TODO - Put that to mixin

                    let selectedType = this.selectedTypes.find(element => element === item.type_id)
                    if (selectedType) {
                        this.visibleSigns.push(item)
                    }
                })
            }
            this.drawSigns()
        },

        toggleList: function () {
            this.listOpen = !this.listOpen
        },

        toggleAll(checked) {
            this.selectedTypes = []
            if (checked) {
                this.typeEntries.forEach(item => {
                    this.selectedTypes.push(item.value)
                })
            }
        },

        getVisibleSigns() {
            return this.visibleSigns
        }
    }
}
</script>
